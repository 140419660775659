import {JournalEntryObject, JournalObject} from '../../Utils/types';
import {AppState} from '../../store/appState';

//deletedEntryAction state update
export const deleteEntriesFromJournals = (entryId: string, journalId: string, state: AppState) => {
  const userJournals = state.userJournals;
  
  const isActionJournal = (journal: JournalObject) => journal.id === journalId;
  const isDeletedEntry = (entry: JournalEntryObject) => entry.journalEntryId === entryId;
  const filteredEntries = (journal: JournalObject) => journal.entries.filter(entry => !isDeletedEntry(entry));
  
  return (
    userJournals?.map(journal => (
      isActionJournal(journal) ? {...journal, entries: filteredEntries(journal)} : journal
    ))
  );
};

//putEntryAction state update
export const entriesAfterPut = (payloadEntry: JournalEntryObject, journalId: string, state: AppState) => {
  const userJournals = state.userJournals;
  
  const isActionJournal = (journal: JournalObject) => journal.id === journalId;
  const isUpdatedEntry = (entry: JournalEntryObject) => entry.journalEntryId === payloadEntry.journalEntryId;
  const updatedEntries = (journal: JournalObject) => journal.entries.map(entry =>
    isUpdatedEntry(entry) ? payloadEntry : entry,
  );
  return (
    userJournals?.map(journal => (
      isActionJournal(journal) ? {...journal, entries: updatedEntries(journal)} : journal
    ))
  );
};

//postEntryAction state update
export const addNewEntry = (state: AppState, journalId: string, journalEntry: JournalEntryObject): JournalObject[] => {
  const journals = state.userJournals || [];
  
  return (
    journals.map(journal => {
      if (journal.id === journalId) {
        return {...journal, entries: [...journal.entries, journalEntry]};
      }
      return {...journal};
    }));
};

