import { SerializedStyles } from "@emotion/react";

//const primary: string = '#003399'; //https://hexcol.com/color/003399
//const primaryDark: string = '#00297a';
//const primaryLight: string = '#003db8';
//const primaryUltraLight: string = '#e8f0fe';
const secondary: string = "#D0F8DD"; //from new design
const secondaryDark: string = "#94efb2"; //10% darker

const primary: string = "#5C6DFF"; //from new design
const primaryDark: string = "#333A78"; //NOT UPDATED YET
const primaryLight: string = "#4b5261";
const primaryUltraLight: string = "#6d788e";

export const MENU_WIDTH_INTEGER = 246;
export const MENU_MARGIN = 40;
export const MENU_LEFT_OFFSET = MENU_WIDTH_INTEGER + MENU_MARGIN * 2;
export const DEFAULT_COLUMN_COUNT = 12;

export type WindowSizePropTypes = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | boolean;
export type ScreenSizeBreakpoints = "xs" | "sm" | "md" | "lg" | "xl";
export type StyleTypes = SerializedStyles | string;

const theme = {
  palette: {
    primary: {
      main: primary,
      dark: primaryDark,
      light: primaryLight,
      ultraLight: primaryUltraLight,
    },
    secondary: {
      main: secondary,
      dark: secondaryDark,
    },
    background: {
      default: "#e6e6e6",
    },
    toolbars: {
      activeBackground: "#e8f0fe",
      active: "#1a73e8",
      inactive: "#5f6368",
      hover: "#f1f3f4",
      fontColorPrimary: "#333A78",
      fontColorSecondary: "#5f6368",
      iconBackground: "#faf5f5",
    },
  },
  typography: {
    fontFamily: "DM Sans, roboto, sans-serif",
  },
  modal: {
    top: "30%",
  },
  divShapes: {
    radius: "9px",
  },
  journalWorkspaceHeights: {
    top: "40",
    toolbar: "12",
    bottom: "48", //these all add up to 100
  },
  toolbarHeight: {
    heightSmall: "48px",
    heightMedium: "56px",
    heightLarge: "64px",
  },

  menuWidth: MENU_LEFT_OFFSET + "px",
  menuMargin: MENU_MARGIN + "px",
  fontColor: {
    text: "#333A78",
    themePrimary: primary,
    lightText: "#f4f5f6",
    link: "#0062ff",
    googleBlue: "#1a73e8",
    disabled: "#808080",
  },
  beltColor: {
    grey: "#939598",
    yellow: "#ffd200",
    orange: "#f15a29",
    green: "#006838",
    white: "#ffffff",
    blue: "#1c75bc",
    purple: "#9b00db",
    brown: "#603913",
    black: "#000000",
    red: "#ff0000",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
  },
  colors: {
    //for text color, use theme.fontColor
    sparAnalysis: "#FF41F2",
    textEntry: "#FFA800",
    videoEntry: "#4CAF50",
    gradient: "linear-gradient(90deg, #FFA800 0%, #FF41F2 100%)",
    gradientShifted: "linear-gradient(90deg, #FFA800 0%, #FF41F2 50%)",
    cancel: "darkred",
    cancelDark: "#600000",
    confirm: "#30497a",
    confirmDark: "#00297a",
    contentBackground: "#ffffff",
    border: "#EDE8E8",
    background: "#F5F6FC",
    offense: "#FE4D01",
    defense: "#001AF6",
    neutral: "#EEE9EA",
    losses: "#FF2A02",
    lossesDark: "#DA2401",
    wins: "#4CAF50",
    winsDark: "#419644",
    dark: "#0a0a0a",
    black: "#000000",
    note: "#ff8502",
    grapple: "#402fd7",
    cardio: "#4CAF50",
    youTube: "#FF2A02",
    achievement: "#9f2fd7",
    submit: "#402fd7",
    inactive: "#787878",
    lightestGray: "#e6e6e6",
    lightGrayHover: "#d3d3d3",
    mediumGray: "#8b8b8b",
    darkGray: "#5a5a5a",
    label: "#ff0000",
    goldBadge: "#f8bc1c",
    silverBadge: "#C0C0C0",
    bronzeBadge: "#CD7F32",
    ultraLightBlue: "#e8f1fd",
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    videoEntry: 1170,
  },
};

export default theme;
