/** @jsxImportSource @emotion/react */ //include this in all jsx files

export type Routes = {
  home: string;
  profile: string;
  journalLibrary: string;
  journal: string;
  journalEntry: string;
  tagLibrary: string;
  tag: string;
  privacy: string;
  termsOfService: string;
  analyze: string;
  login: string;
};
export const BASE_ROUTES = {
  profile: "/profile",
  journalEntry: "/journalEntry",
  journal: "/journal",
  journalLibrary: "/journalLibrary",
  tagLibrary: "/tagLibrary",
  analyze: "/analyze",
};

export const getJournalRoute = (journalId: string) => `${BASE_ROUTES.journal}/${journalId}`;
export const getEntryRoute = (journalId: string, entryId: string) => `${BASE_ROUTES.journalEntry}/${journalId}/${entryId}`;
export const ROUTES: Routes = {
  home: "/",
  profile: `${BASE_ROUTES.profile}`,
  journalLibrary: `${BASE_ROUTES.journalLibrary}`,
  journal: `${BASE_ROUTES.journal}/:journalId`,
  journalEntry: `${BASE_ROUTES.journalEntry}/:journalId/:entryId`,
  analyze: `${BASE_ROUTES.analyze}`,
  tagLibrary: `${BASE_ROUTES.tagLibrary}`,
  tag: `${BASE_ROUTES.tagLibrary}/:tagId`,
  privacy: `/privacy`,
  termsOfService: `/termsOfService`,
  login: `/login`,
};
