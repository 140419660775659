import {ReactEditor} from 'slate-react';
import {BaseEditor, Descendant, Element as SlateElement} from 'slate';

export const LIST_TYPES = ['numbered-list', 'bulleted-list']; //todo how can I combine these?
export type ListTypes = 'numbered-list' | 'bulleted-list';

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']; //todo how can I combine these?
export type TextAlignTypes = 'left' | 'center' | 'right' | 'justify';

export type MarkTypes = 'bold' | 'italic' | 'underline';
export type ElementTypes =
  'paragraph'
  | 'block-quote'
  | 'list-item'
  | 'heading-one'
  | 'heading-two'
  | 'image'
  | 'figcaption'
  | 'figure'
  | 'link'
  | 'link-off';

export type CombinedElementTypes = ElementTypes | MarkTypes | TextAlignTypes | ListTypes;

export interface NewSlateElement extends SlateElement {
  align?: TextAlignTypes;
}

interface HotKeyType {
  [hotKey: string]: string
}

export const HOTKEYS: HotKeyType = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};
export type CustomText = {
  text?: string,
  children?: Descendant[],
  type?: string,
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export interface CustomElement {
  type?: CombinedElementTypes, //todo need optional?
  align?: 'left' | 'center' | 'right' | 'justify' | undefined,
  children?: CustomText[] | CustomElement[]
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement & {
      id?: string,
      url?: string,
      caption?: string
      text?: string
    }
    Text: CustomText
  }
}

export type ImageElement = {
  type: 'image',
  url: string,
  children: [{ text: string }],
  id?: string,
}
export type CaptionElement = {
  type: 'figcaption',
  children: CustomText[],
  id?: string,
}

export type Figure = {
  type: 'figure',
  children: [
    ImageElement,
  ]
}
export type LinkElement = {
  type: 'link';
  url: string;
  children: Descendant[]
}

export interface PickerCroppedData {
  cropArea: {
    /**
     * [x, y]
     */
    position: [number, number];
    /**
     * [width, height]
     */
    size: [number, number];
  };
  /**
   * [width, height]
   */
  originalImageSize: [number, number];
}

export enum RotateDirection {
  cw = 'CW',
  ccw = 'CCW',
}

export interface PickerRotatedData {
  /**
   * Amount rotated in degrees.
   */
  value: number;
  /**
   * Can be CW or CCW (clockwise / counter-clockwise)
   */
  direction: RotateDirection;
}

export interface PickerFileMetadata {
  /**
   * The cloud container for the uploaded file.
   */
  container?: string;
  /**
   * Position and size information for cropped images.
   */
  cropped?: PickerCroppedData;
  /**
   * Name of the file.
   */
  filename: string;
  /**
   * Filestack handle for the uploaded file.
   */
  handle: string;
  /**
   * The hash-prefixed cloud storage path.
   */
  key?: string;
  /**
   * The MIME type of the file.
   */
  mimetype: string;
  /**
   * Properties of the local binary file. Also see the pick option `exposeOriginalFile` if you want the underlying `File` object.
   */
  originalFile?: object | File;
  /**
   * The origin of the file, e.g. /Folder/file.jpg.
   */
  originalPath: string;
  /**
   * Direction and value information for rotated images.
   */
  rotated?: PickerRotatedData;
  /**
   * Size in bytes of the uploaded file.
   */
  size: number;
  /**
   * The source from where the file was picked.
   */
  source: string;
  /**
   * Indicates Filestack transit status.
   */
  status?: string;
  /**
   * A uuid for tracking this file in callbacks.
   */
  uploadId: string;
  /**
   * The Filestack CDN URL for the uploaded file.
   */
  url: string;
}

export interface PickerResponse {
  filesUploaded: PickerFileMetadata[];
  filesFailed: PickerFileMetadata[];
}

