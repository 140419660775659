import {ActionType} from '../../store/actionType';
import {AppState, makeLogoutAppState} from '../../store/appState';
import {addLoading, ApiHookReturn, ApiReducer, makeApiAction, makeApiReducer, makeRsaaTypes, Response} from '../../store/module';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {useCallback} from 'react';
import {ENDPOINTS} from '../../store/endpoints';
import {RefreshActionProps} from './refreshAccessTokenAction';
import {useHistory} from 'react-router-dom';
import {useLocalStorage} from '../../store/localStorage/useLocalStorage';
import {useDispatch} from 'react-redux';

const KEY_ACTION = ActionType.Logout;

/**
 * Dispatch me to log the user out
 */
// export const logoutAction = {
//   type: KEY_ACTION,
// };
export interface LogoutActionProps {
  requestId: string;
}

const ACTIONS = [
  ActionType.Logout,
  ActionType.LogoutResponse,
  ActionType.LogoutError,
];

interface Payload extends Response {
}

export const logoutAction = (body: LogoutActionProps, token?: string) =>
  makeApiAction<Payload, LogoutActionProps>({
    method: 'POST',
    credentials: 'include',
    endpoint: () => ENDPOINTS.Logout,
    headers: () => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }),
    types: makeRsaaTypes<Payload, LogoutActionProps>(ACTIONS, body),
  });

export const reducer: ApiReducer<Payload, RefreshActionProps> = (state, action, errorMsg, isLoading) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION, action.meta?.requestId);
  }
  return makeLogoutAppState();
};
export const logoutReducer = makeApiReducer<Payload, RefreshActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to logout.',
);
export const useLogout = (): ApiHookReturn<() => void> => {
  const dispatch = useDispatch();
  const localStorage = useLocalStorage('auth');
  const history = useHistory();
  
  const requestId = 'logout';
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  
  const logout = useCallback(
    () => {
      dispatch(logoutAction({requestId}, token));
      localStorage[2]();
      history.push('/');
    },
    [dispatch, token, history, localStorage],
  );
  
  const loading = useLoading(KEY_ACTION, requestId);
  const errors = useErrors(KEY_ACTION, requestId);
  
  return {
    loading, errors, value: logout, reload: logout,
  };
};
