/** @jsxImportSource @emotion/react */
import theme from '../Theme';
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const styles = {
	root: (link = '') => css`
		display: flex;
		flex-direction: column;
		background-color: #ffffff;
		width: 100%;
		border-radius: 20px;
		height: 250px;
		cursor: ${link && 'pointer'};
	`,
	paperCSS: css`
		justify-content: center;
		padding: 10px 15px;
		overflow: hidden;
	`,
	title: css`
		color: ${theme.fontColor.themePrimary};
		text-transform: uppercase;
		padding: 5px 0 0 10px;
		font-weight: 700;
	`,
};

interface Props {
	title?: string;
	link?: string;
	children?: any;
	className?: string;
}

const Card: React.FC<Props> = ({ title, children, link, className }) => {
	const history = useHistory();

	const handleNavigate = useCallback(() => {
		if (!link) return;
		return history.push(link);
	}, [history, link]);

	return (
		<div
			className={className}
			css={styles.root(link)}
			onClick={handleNavigate}
		>
			<span css={[styles.title]}>{title}</span>
			<div css={styles.paperCSS}>{children}</div>
		</div>
	);
};
export default Card;
