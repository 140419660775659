/** @jsxImportSource @emotion/react */
import React, { useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { JournalObject } from '../Utils/types';
import { hexToRGBA } from '../Utils/module';
import theme from '../Theme';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { useClickOutsideListener } from '../modal/hooks';
import { APP_LAYERS } from '../layers';
import { ApiHookReturn } from '../store/module';
import { useJournals } from './Actions/getJournalsAction';
import LibraryIcon from '../icons/LibraryIcon';
import EditIcon from '../icons/EditIcon';
import AddIcon from '../icons/AddIcon';
import { Link } from 'react-router-dom';
import { getJournalRoute } from '../Navigation/routesModule';

const styles = {
	journalRow: (color = theme.colors.contentBackground) => css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		border-radius: 6px;
		height: 36px;
		color: ${hexToRGBA(color, 1)};
		align-items: center;
		padding: 0 12px;
		margin-bottom: 2px;
		font-size: 14px;
		cursor: pointer;
		text-decoration: none;
		background-color: ${theme.colors.background};
		font-weight: 700;
		&:hover {
			background-color: ${hexToRGBA(theme.colors.background, 0.3)};
		}
	`,
	labelRow: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%;
	`,
	filterWrapperWrapper: css`
		position: relative;
		flex-direction: column;
		height: 44px;
		margin: 3px 0;
		width: 260px;
	`,
	selectorWrapper: css`
		background-color: ${theme.colors.contentBackground};
		border-radius: 400px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: rgba(0, 0, 0, 0.6);
		padding: 0 18px;
		flex: 1;
		height: 44px;
		user-select: none;
		cursor: pointer;
	`,
	expandIcon: (isOpen: boolean) => css`
		color: ${theme.fontColor.text};
		transform: rotate(${isOpen ? '-90deg' : '90deg'});
		transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		user-select: none;
	`,
	selector: (isOpen: boolean) => css`
		opacity: ${!isOpen ? 0 : 1};
		position: absolute;
		top: 48px;
		background-color: ${theme.colors.contentBackground};
		border-radius: 12px;
		padding: 16px;
		z-index: ${APP_LAYERS.overlays};
		right: 0;
		width: 100%;
		visibility: ${!isOpen ? 'hidden' : 'visible'};
		transition: ${!isOpen
			? 'opacity 300ms, max-height 500ms, visibility 500ms'
			: 'max-height 500ms , opacity 300ms  100ms, visibility 500ms'};
	`,
	iconWrapper: css`
		display: flex;
		flex-direction: row;
		gap: 6px;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
	`,
	journalList: css`
		max-height: 400px;
		overflow-y: auto;
		margin-bottom: 24px;
	`,
	journalSummary: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 12px;
		font-size: 14px;
		margin-bottom: 16px;
	`,
	editJournalButton: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		border-radius: 6px;
		height: 36px;
		background-color: ${hexToRGBA(theme.palette.primary.main, 0.5)};
		align-items: center;
		padding: 0 12px;
		margin-bottom: 12px;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			background-color: ${hexToRGBA(theme.palette.primary.main, 0.65)};
		}
	`,
	addJournalButton: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		border-radius: 6px;
		height: 36px;
		background-image: ${theme.colors.gradient};
		align-items: center;
		padding: 0 12px;
		margin-bottom: 2px;
		font-size: 14px;
		cursor: pointer;
		font-weight: 700;
		color: ${theme.colors.contentBackground};
		&:hover {
			background-image: ${theme.colors.gradientShifted};
		}
	`,
	link: css`
		text-decoration: none;
		color: ${theme.palette.toolbars.fontColorPrimary};
		display: flex;
		flex-direction: row;
		width: 100%;
	`,
};

interface Props {
	setCreateJournalSelected?: (boolean: boolean) => void;
	createJournalSelected?: boolean;
	editJournalSelected?: boolean;
	setEditJournalSelected?: (newState: boolean) => void;
	className?: string;
	ignoreCreateAndEdit?: boolean;
}

const LibraryJournalSelector: React.FC<Props> = (props) => {
	const {
		setCreateJournalSelected,
		createJournalSelected,
		setEditJournalSelected,
		editJournalSelected,
		className,
		ignoreCreateAndEdit = false,
	} = props;
	const journals: ApiHookReturn<JournalObject[]> = useJournals();

	const handleAddJournalClick = useCallback(() => {
		setCreateJournalSelected && setCreateJournalSelected(!createJournalSelected);
		setEditJournalSelected && setEditJournalSelected(false);
	}, [createJournalSelected, setCreateJournalSelected, setEditJournalSelected]);

	const getEntryCount = useCallback(() => {
		let entryCount = 0;
		journals?.value?.map((journal) => (entryCount += journal.entries.length));
		return entryCount;
	}, [journals]);

	const handleEditJournalClick = useCallback(() => {
		setEditJournalSelected && setEditJournalSelected(!editJournalSelected);
		setCreateJournalSelected && setCreateJournalSelected(false);
	}, [editJournalSelected, setEditJournalSelected, setCreateJournalSelected]);

	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const childrenRef = useRef<HTMLDivElement>(null);

	const handleToggleFilter = useCallback(() => {
		setFilterIsOpen((prev) => !prev);
	}, []);

	const handleCloseFilter = useCallback(() => {
		setFilterIsOpen(false);
	}, []);

	useClickOutsideListener(childrenRef, handleCloseFilter);

	return (
		<div
			className={className}
			css={styles.filterWrapperWrapper}
			ref={childrenRef}
		>
			<div
				css={styles.selectorWrapper}
				onClick={handleToggleFilter}
			>
				<div css={styles.iconWrapper}>
					<LibraryIcon
						css={css`
							color: ${theme.palette.toolbars.fontColorPrimary};
							font-size: 20px;
						`}
					/>
					<span>Journals</span>
				</div>
				<ChevronRightIcon css={styles.expandIcon(!filterIsOpen)} />
			</div>
			<div css={styles.selector(filterIsOpen)}>
				<div css={styles.journalList}>
					{journals.value?.map((journal) => (
						<label
							key={journal.id}
							css={styles.labelRow}
						>
							<Link
								css={styles.link}
								to={{ pathname: getJournalRoute(journal.id) }}
								onClick={handleCloseFilter}
							>
								<div css={styles.journalRow(journal.color)}>
									<span>{journal.name}</span>
									<span>{journal.entries?.length}</span>
								</div>
							</Link>
						</label>
					))}
				</div>
				<div css={styles.journalSummary}>
					<span>Total Entries</span>
					<span>{getEntryCount()}</span>
				</div>
				{!ignoreCreateAndEdit && (
					<>
						<div
							css={styles.editJournalButton}
							onClick={handleEditJournalClick}
						>
							<span>Edit a Journal</span>
							<EditIcon
								css={css`
									color: ${theme.palette.toolbars.fontColorPrimary};
									font-size: 18px;
								`}
								variant='outlined'
							/>
						</div>
						<div
							css={styles.addJournalButton}
							onClick={handleAddJournalClick}
						>
							<span>Create a New Journal</span>
							<AddIcon
								css={css`
									color: ${theme.colors.contentBackground};
									font-size: 22px;
								`}
								variant='outlined'
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default LibraryJournalSelector;
