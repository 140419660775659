/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import theme from '../Theme';
import { css } from '@emotion/react';
import { useGetUser } from '../login/Actions/getUserAction';
import Loader from '../Utils/Loader';
import { useGetUserProfile } from './Actions/getUserProfileAction';
import { useLogout } from '../login/Actions/logoutAction';
import UpdatePasswordModal from '../login/UpdatePasswordModal';
import GenericModal from '../modal/GenericModal';
import { BadgeLevel } from '../Utils/types';
import BadgeIcon from '../icons/BadgeIcon';
import { useRevokeGoogleAccessToken } from '../google/Actions/revokeGoogleAccessTokenAction';
import { useAppState, useErrors } from '../store/hooks';
import { ActionType } from '../store/actionType';
import { useGoogleOauth } from '../google/Actions/googleOauthAction';

//import {BELT_RANKS} from './module';
//import {DateTime} from 'luxon';
//import {usePostPromotion} from './Actions/postPromotionAction';
//import {useAppState} from '../store/hooks';
//import {AppState} from '../store/appState';
//import {Promotion} from '../Utils/types';
//import {useDeletePromotion} from './Actions/deletePromotionAction';
//import {playlistResource, YouTubeChannelResource} from '../google/module';
//import {useYouTubeChannels} from '../google/Actions/getYouTubeChannelsAction';
//import {useRefreshGoogleAccessToken} from '../google/Actions/refreshGoogleAccessTokenAction';
//import {useYouTubeVideosFromChannel} from '../google/Actions/getYouTubeVideosFromChannelAction';
//import {useRefreshAccessToken} from '../login/Actions/refreshAccessTokenAction';

const styles = {
	root: css`
		height: calc(100vh - ${theme.toolbarHeight.heightMedium});
		display: flex;
		flex-direction: row;
		width: 100%;
		background-color: #232323;
		background-image: url(background.jpeg);
		background-position-y: 43%;
		background-position-x: 53%;
		@media (max-width: ${theme.breakpoints.md}px) {
			margin-left: 0;
		}
	`,
	settingsWrapper: css`
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		flex-shrink: 0;
		min-width: 300px;
		padding: 20px 0;
		position: absolute;
		top: 100px;
		left: 50px;
		border-radius: 10px;
		margin-left: ${theme.menuWidth};
		@media (max-width: ${theme.breakpoints.md}px) {
			margin-left: 0;
		}
	`,
	title: css`
		font-size: 20px;
		font-weight: 700;
		width: 100%;
		border-bottom: solid 1px ${theme.colors.lightestGray};
		display: flex;
		padding: 0 0 20px 10px;
		justify-content: flex-start;
	`,
	subtitle: css`
		font-weight: 700;
		width: 100%;
	`,
	logoutWrapper: css`
		width: 100%;
		border-top: solid 1px ${theme.colors.lightestGray};
		display: flex;
		padding: 20px 10px 5px 10px;
		justify-content: space-between;
		align-items: center;
	`,
	logout: css`
		color: ${theme.colors.cancelDark};
		text-decoration: underline;
		font-weight: 700;
		cursor: pointer;
	`,
	settings: css`
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		gap: 10px;
		padding: 10px;
		align-items: center;
	`,
	row: css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	`,
	passwordButton: css`
		display: flex;
		justify-content: flex-end;
		gap: 6px;
		color: white;
		background-color: ${theme.colors.confirm};
		border: none;
		outline: none;
		border-radius: 4px;
		padding: 6px;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: ${theme.colors.confirmDark};
		}
	`,
	unlinkButton: css`
		display: flex;
		justify-content: flex-end;
		gap: 6px;
		color: white;
		background-color: ${theme.colors.cancel};
		border: none;
		outline: none;
		border-radius: 4px;
		padding: 6px;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: ${theme.colors.cancelDark};
		}
	`,
	linkButton: css`
		display: flex;
		justify-content: flex-end;
		gap: 6px;
		color: white;
		background-color: ${theme.colors.confirm};
		border: none;
		outline: none;
		border-radius: 4px;
		padding: 6px;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: ${theme.colors.confirmDark};
		}
	`,
	goldBadge: css`
		color: ${theme.colors.goldBadge};
	`,
	silverBadge: css`
		color: ${theme.colors.silverBadge};
	`,
	bronzeBadge: css`
		color: ${theme.colors.bronzeBadge};
	`,
	//paperStyle:        css`
	//  margin: 6px;
	//  align-items: center;
	//  display: flex;
	//  flex-direction: column;
	//  text-align: center;
	//  width: 300px;
	//`,
	//settingsIcon:      css`
	//  display: flex;
	//  color: ${theme.colors.inactive};
	//`,
	//settingsBar:       css`
	//  display: flex;
	//  flex-direction: row;
	//  width: 100%;
	//`,
	//datepicker:        css`
	//  outline: none;
	//  height: 38px;
	//  border-radius: 200px;
	//  padding: 0 8px;
	//  border: solid 1px lightgray;
	//
	//  &:hover {
	//    border-color: darkgray;
	//  }
	//`,
	//dropdownLabel:     (optionsFocused: boolean = true, value: string = '') => css`
	//  text-transform: capitalize;
	//  outline: none;
	//  height: 38px;
	//  border-radius: 200px;
	//  padding: 0 8px;
	//  border-color: ${optionsFocused ? theme.palette.primary.light : 'lightgray'};
	//  color: ${optionsFocused ? theme.palette.primary.light : value ? theme.fontColor.text : 'rgba(0, 0, 0, 0.6);'};
	//  appearance: none;
	//  width: 100%;
	//
	//  &:hover {
	//    ${!optionsFocused && 'border-color: darkgray'};
	//  }
	//
	//`,
	//dropdownContainer: css`
	//  position: relative;
	//  width: 100%;
	//`,
	//inputWrapper:      css`
	//  display: flex;
	//  flex-direction: column;
	//  gap: 10px;
	//`,
	//options:           css`
	//  color: ${theme.fontColor.text}
	//`,
	//blankOption:       css`
	//  color: lightgray;
	//`,
	//dropdownIcon:      css`
	//  position: absolute;
	//  right: 6px;
	//  top: 50%;
	//  transform: translateY(-50%);
	//  pointer-events: none;
	//`,
	//videoRow:          css`
	//  display: flex;
	//  flex-direction: row;
	//`,
};

//const getHighestRank = (promotions: Promotion[] = []) => { //todo abstract this and test
//  let highestRank: { promotion: Promotion | undefined, beltIndex: number | undefined } = {
//    promotion: undefined,
//    beltIndex: undefined,
//  };
//  if (promotions.length) {
//    promotions.forEach((promotion) => {
//      const beltIndex = BELT_RANKS.findIndex((rank) => rank.rank === promotion.rank);
//      if (!highestRank.promotion) {
//        highestRank = {promotion: promotion, beltIndex: beltIndex};
//      } else {
//        if (highestRank.beltIndex && beltIndex > highestRank.beltIndex) {
//          highestRank = {promotion: promotion, beltIndex: beltIndex};
//        }
//      }
//    });
//    return highestRank.promotion;
//  }
//  return undefined;
//};

const Profile: React.FC = () => {
	const logout = useLogout();
	//const localStorage = useLocalStorage('auth');
	const user = useGetUser();
	const state = useAppState((state) => state);
	const userName = user.value?.username;
	const userProfileHook = useGetUserProfile();
	const revokeGoogleToken = useRevokeGoogleAccessToken();
	//const unlinkGoogle = usePutUser(user.value && {...user.value, googleApi: {accessToken: null}});
	//
	const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
	const [googleModalIsOpen, setGoogleModalIsOpen] = useState(false);
	const updatePasswordErrors = useErrors(ActionType.UpdatePasswordRequest, 'updatePassword');

	const handleGoogleModalClose = useCallback(() => setGoogleModalIsOpen(false), []);
	const googleOauth = useGoogleOauth();

	//const [promotion, setPromotion] = useState<string | undefined>(undefined);
	//const [promotionDate, setPromotionDate] = useState<DateTime>(DateTime.now());
	//const [promotionGym, setPromotionGym] = useState('');
	//const [promotionProfessor, setPromotionProfessor] = useState('');
	//const [highestPromotion, setHighestPromotion] = useState<Promotion | undefined>(undefined);
	//const [rank, setRank] = useState<{ color: string, degree: number } | undefined>(undefined);
	//const [promotionToDelete, setPromotionToDelete] = useState<Promotion | undefined>(undefined);
	//const [confirmDelete, setConfirmDelete] = useState(false);
	//const [promotionModalOpen, setPromotionModalOpen] = useState(false);
	//const [dropdownFocus, setDropdownFocus] = useState(false);
	//

	//const userProfile = userProfileHook.value;
	//

	//
	//const youTubeChannels = useYouTubeChannels();
	//const userVideos = useYouTubeVideosFromChannel();

	//const [channels, setChannels] = useState<YouTubeChannelResource[]>([]);
	//const [videos, setVideos] = useState<playlistResource[]>([]);
	//const [showVideoModal, setShowVideoModal] = useState(false);
	//const [showChannelModal, setShowChannelModal] = useState(false);
	//
	//const handleGetVideoHook = useCallback(() => {
	//  if (!userVideos.value) {
	//    return userVideos.reload();
	//  }
	//  setVideos(userVideos.value || []);
	//  setShowVideoModal(true);
	//}, [userVideos]);
	//
	//const handleGetChannelHook = useCallback(() => {
	//  if (!youTubeChannels.value?.items) {
	//    return youTubeChannels.reload();
	//  }
	//  setChannels(youTubeChannels.value.items || []);
	//  setShowChannelModal(true);
	//}, [youTubeChannels]);
	//
	//const promotions = useAppState((state: AppState) => state.userProfile?.promotions);
	//

	//const refreshGoogleToken = useRefreshGoogleAccessToken();

	//const postPromotion = usePostPromotion(
	//  userProfile?.id || '',
	//  promotion || '',
	//  promotionDate.toUTC().toString(),
	//  promotionProfessor,
	//  promotionGym,
	//);
	//
	//const deletePromotion = useDeletePromotion(userProfile?.id || '', promotionToDelete?.promotionId || '');
	//
	//const handleDeletePromotion = useCallback(() => {
	//  deletePromotion.value && deletePromotion.value();
	//  setConfirmDelete(false);
	//}, [deletePromotion]);
	//
	//
	//const handlePromotionDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
	//  setPromotionDate(DateTime.fromISO(e.target.value));
	//}, []);
	//
	//const handleRankChange = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
	//  setPromotion(event.currentTarget.value);
	//}, []);
	//
	//
	//const handleModalClose = useCallback(() => {
	//  setPromotionToDelete(undefined);
	//  setConfirmDelete(false);
	//  //setShowChannelModal(false);
	//  //setShowVideoModal(false);
	//}, []);
	//
	//const handlePromotionModalOpen = useCallback(() => {
	//  setPromotionModalOpen(true);
	//}, []);
	//
	//const handlePromotionModalClose = useCallback(() => {
	//  setPromotionModalOpen(false);
	//  setPromotionProfessor('');
	//  setPromotionGym('');
	//  setPromotionDate(DateTime.now());
	//  setPromotion(undefined);
	//}, []);
	//
	//const handleSubmit = useCallback(() => {
	//  postPromotion.value && postPromotion.value();
	//  handlePromotionModalClose();
	//}, [postPromotion, handlePromotionModalClose]);
	//
	//const handlePromotionProfessorChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
	//  setPromotionProfessor(e.target.value);
	//}, []);
	//
	//const handlePromotionGymChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
	//  setPromotionGym(e.target.value);
	//}, []);
	//
	//const handleDelete = useCallback((promotion: Promotion) => {
	//  setPromotionToDelete(promotion);
	//  setConfirmDelete(true);
	//}, []);
	//

	const handleLogout = useCallback(() => {
		//localStorage[2]();
		logout.value && logout.value();
	}, [logout]);

	const handleUnlinkAccount = useCallback(async () => {
		revokeGoogleToken.value && revokeGoogleToken.value();
		setGoogleModalIsOpen(false);
	}, [revokeGoogleToken]);

	const getUserBadge = useCallback(() => {
		if (userProfileHook.value?.badgeLevel === BadgeLevel.None) return null;
		if (userProfileHook.value?.badgeLevel === BadgeLevel.Gold) return <BadgeIcon css={styles.goldBadge} />;
		if (userProfileHook.value?.badgeLevel === BadgeLevel.Silver) return <BadgeIcon css={styles.silverBadge} />;
		if (userProfileHook.value?.badgeLevel === BadgeLevel.Bronze) return <BadgeIcon css={styles.bronzeBadge} />;
		return null;
	}, [userProfileHook.value]);

	//const handleDropdownFocus = useCallback(() => setDropdownFocus(true), []);
	//const handleDropdownBlur = useCallback(() => setDropdownFocus(false), []);
	//
	//useEffect(() => {
	//  const highestRank = getHighestRank(promotions);
	//  highestRank ? setHighestPromotion(highestRank) : setHighestPromotion(undefined);
	//}, [promotions]);
	//
	//useEffect(() => {
	//  if (highestPromotion) {
	//    const beltRank = BELT_RANKS.find(beltRank => beltRank.rank === highestPromotion.rank);
	//    beltRank && setRank({color: beltRank.color, degree: beltRank.degree});
	//  } else {
	//    setRank(undefined);
	//  }
	//}, [highestPromotion]);
	console.log(userProfileHook.bailedOut || user.errors?.length);
	console.log(user.loading || userProfileHook.loading || !state.userProfile);
	console.log(revokeGoogleToken.loading);

	if (userProfileHook.bailedOut || user.errors?.length) {
		return <span>There was an error loading your user profile. Please refresh and try again.</span>;
	}
	if (user.loading || userProfileHook.loading || !state.userProfile) {
		return <Loader message={'Fetching user info...'} />;
	}

	if (revokeGoogleToken.loading) {
		return <Loader message={'Unlinking your google account...'} />;
	}

	//if (postPromotion.loading) {
	//  return <Loader message={'Saving...'}/>;
	//}
	//
	//if (deletePromotion.loading) {
	//  return <Loader message={'Deleting...'}/>;
	//}

	//todo add button for updating promotions
	//todo add button for creating promotions

	return (
		<div css={styles.root}>
			<div css={styles.settingsWrapper}>
				<span css={styles.title}>Profile {userProfileHook.value?.isBetaTester && '(Beta Tester!)'}</span>
				<div css={styles.settings}>
					<span css={styles.subtitle}>Name</span>
					<span css={styles.row}>
						{getUserBadge()}
						{user.value?.name.firstName} {user.value?.name.lastName}{' '}
					</span>
					<span css={styles.subtitle}>User Name</span>
					<span>{userName}</span>
					<span css={styles.subtitle}>Linked Google Account</span>
					{user.value?.googleApi?.accessToken ? (
						<span css={styles.row}>
							Yes{' '}
							<button
								css={styles.unlinkButton}
								onClick={() => setGoogleModalIsOpen(true)}
							>
								Unlink Account
							</button>{' '}
						</span>
					) : (
						<span css={styles.row}>
							No{' '}
							<button
								css={styles.linkButton}
								onClick={() => googleOauth()}
							>
								Link Google Account
							</button>{' '}
						</span>
					)}
				</div>
				{revokeGoogleToken.errors?.length && revokeGoogleToken.errors.map((err) => <span key={err}>{err}</span>)}
				{updatePasswordErrors?.length && updatePasswordErrors.map((err) => <span key={err}>{err}</span>)}
				<div css={styles.logoutWrapper}>
					<button
						css={styles.passwordButton}
						onClick={() => setPasswordModalIsOpen(true)}
					>
						Update Password
					</button>
					<span
						onClick={handleLogout}
						css={styles.logout}
					>
						Log out
					</span>
				</div>
			</div>
			{googleModalIsOpen && (
				<GenericModal
					confirmText={'Unlink Account'}
					isWarning={true}
					title={'Warning!'}
					onClose={handleGoogleModalClose}
					onConfirm={handleUnlinkAccount}
				>
					<span>This will remove Google/YouTube access for the Champions Keep Score app.</span>
					<span>Do you wish to proceed?</span>
					<span>If so, we also recommend removing CKS from your approved </span>
					<span>third-party apps on your Google account.</span>
				</GenericModal>
			)}
			<UpdatePasswordModal
				passwordModalIsOpen={passwordModalIsOpen}
				setPasswordModalIsOpen={setPasswordModalIsOpen}
			/>
		</div>
	);
};

export default Profile;

//<div css={styles.settingsBar}/>
//{rank && <UserBelt color={rank.color} degree={rank.degree}/>}
//
//{userProfile?.promotions.map((promotion) => (
//  <div key={promotion.promotionId}>
//    <span>{promotion.rank} </span>
//    <span>{DateTime.fromISO(promotion.promotionDateTimeUtc).toFormat(DATE_FORMAT.longDate)}</span>
//    <button onClick={() => handleDelete(promotion)}>delete</button>
//  </div>
//))}
//<div>{user.value?.name.firstName} {user.value?.name.lastName} ({userName})</div>
//{!rank && <button onClick={handlePromotionModalOpen}>Add a rank</button>}
//{promotionModalOpen
//&& <GenericModal
//  isWarning={false}
//  onConfirm={handleSubmit}
//  onClose={handlePromotionModalClose}
//  title={'Log a new promotion'}
//  confirmText={'Save'}
//>
//  <div css={styles.inputWrapper}>
//    <input
//      id="date-picker-promotion"
//      name="promotion-date"
//      type="date"
//      value={promotionDate.toFormat(DATE_FORMAT.dateInput)}
//      onChange={handlePromotionDateChange}
//      css={styles.datepicker}
//
//    />
//    <div css={styles.dropdownContainer}>
//      <select
//        onFocus={handleDropdownFocus}
//        onBlur={handleDropdownBlur}
//        css={styles.dropdownLabel(dropdownFocus, promotion)}
//        value={promotion}
//        onChange={handleRankChange}
//      >
//        <option css={styles.blankOption} value="" disabled selected hidden>Select rank...</option>
//        {BELT_RANKS.map((rank) => (
//          <option
//            css={styles.options}
//            value={rank.rank}
//            onClick={handleDropdownBlur}
//            key={rank.rank}
//          >
//            {rank.color + ' belt '}
//            {rank.degree !== 0 && 'with ' + rank.degree + ' stripe'}
//            {rank.degree > 1 && 's'}
//          </option>
//        ))}
//      </select>
//      <ChevronExpandMoreIcon css={styles.dropdownIcon}/>
//    </div>
//    <TextInput
//      value={promotionProfessor}
//      placeholder={'Professor'}
//      id={'promotion-professor'}
//      onChange={handlePromotionProfessorChange}
//      required={true}
//    />
//    <TextInput
//      value={promotionGym}
//      placeholder={'Gym'}
//      id={'promotion-gym'}
//      onChange={handlePromotionGymChange}
//      required={true}
//    />
//  </div>
//</GenericModal>}
//{confirmDelete
//&& <GenericModal
//  isWarning={true}
//  onConfirm={handleDeletePromotion}
//  onClose={handleModalClose}
//  title={'Warning!'}
//  confirmText={'Delete'}
//>
//  <span>This will permanently delete this promotion. Proceed?</span>
//</GenericModal>}
//{/*{showChannelModal*/
//}
//{/*&& <GenericModal*/
//}
//{/*  onConfirm={handleDeletePromotion}*/
//}
//{/*  onClose={handleModalClose}*/
//}
//{/*  title={'Here are your channels'}*/
//}
//{/*  confirmText={'Nice.'}*/
//}
//{/*>*/
//}
//{/*  {channels.map((channel, index) =>*/
//}
//{/*    <span key={channel.id}>{index}, {channel.snippet.title}</span>,*/
//}
//{/*  )}*/
//}
//{/*</GenericModal>}*/
//}
//{/*{showVideoModal*/
//}
//{/*&& <GenericModal*/
//}
//{/*  onConfirm={handleDeletePromotion}*/
//}
//{/*  onClose={handleModalClose}*/
//}
//{/*  title={'Please choose a video'}*/
//}
//{/*  confirmText={'Nice.'}*/
//}
//{/*>*/
//}
//{/*  {videos.map((video) =>*/
//}
//{/*    <div key={video.id} css={styles.videoRow}>*/
//}
//{/*      <img alt={'video thumbnail'} src={video.snippet.thumbnails.default.url}/>*/
//}
//{/*      <span >{video.snippet.title}</span>*/
//}
//{/*      <span>, {video.snippet.description}</span>*/
//}
//{/*    </div>,*/
//}
//{/*  )}*/
//}
//{/*</GenericModal>}*/
//}
//{/*<button onClick={() => refreshGoogleToken.reload()}>Oauth</button>*/
//}
//{/*<button onClick={() => handleGetChannelHook()}>get channel by hook</button>*/
//}
//{/*<button onClick={() => handleGetVideoHook()}>get video by hook</button>*/
//}
