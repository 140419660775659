import { ActionType } from "../../store/actionType";
import { AppState } from "../../store/appState";
import { Action, AnyAction } from "@reduxjs/toolkit";
import { useAppState, useLoggedIn } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";
import { ENDPOINTS } from "../../store/endpoints";
import { logoutAction } from "./logoutAction";
import { useHistory } from "react-router-dom";
import { Mutex } from "async-mutex";
import axios from "axios";
import { getLoading } from "../../store/module";

interface Error {
  error?: boolean;
}

const KEY_ACTION = ActionType.SetAuth;

export const setTokenReducer = (state: AppState, action: AnyAction) => {
  const KEY_ACTION = ActionType.SetToken;
  if (action.type !== KEY_ACTION) {
    return state;
  }
  return { ...state, auth: { ...state.auth, accessToken: action.meta } };
};

export const setAuthReducer = (state: AppState, action: AnyAction): AppState => {
  if (action.type !== KEY_ACTION) {
    return state;
  }
  return { ...state, auth: action.meta, error: {} };
};

export const setAuthTokenAction = (token: string) => ({
  type: ActionType.SetToken,
  meta: token,
});

export const setLoadingAction = (action: string | any) => {
  if (typeof action === "string") {
    return {
      type: ActionType.SetLoading,
      meta: action,
    };
  } else
    return {
      type: ActionType.SetLoading,
      meta: action.type,
    };
};
export const unSetLoadingAction = (action: string) => ({
  type: ActionType.UnsetLoading,
  meta: action,
});

const mutex = new Mutex();
const processingActions = new Set<string>();

export const useValidateAndDispatch = () => {
  const auth = useAppState((state) => state.auth);
  const state = useAppState((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useLoggedIn();

  const decodedToken = useMemo(() => auth?.accessToken && (jwt_decode(auth?.accessToken) as any), [auth]);

  //in milliseconds
  const expiresIn = decodedToken?.exp
    ? DateTime.fromMillis(decodedToken?.exp * 1000)
        .diff(DateTime.now(), "milliseconds")
        .toObject().milliseconds || 0
    : -1;

  const needsRefresh = expiresIn <= 5000;

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${auth?.accessToken}`,
    },
    withCredentials: true,
  };

  const data = JSON.stringify({ refreshToken: null });

  const refreshToken = async (attempt = 0) => {
    processingActions.add(ActionType.RefreshTokenRequest);
    if (!needsRefresh) {
      return {
        data: {
          data: {
            accessToken: auth?.accessToken,
          },
        },
      };
    }

    dispatch(setLoadingAction(ActionType.RefreshTokenRequest));

    return await axios
      .post(ENDPOINTS.refreshAccessToken, data, config)
      .then((response) => {
        dispatch(unSetLoadingAction(ActionType.RefreshTokenRequest));
        return response;
      })
      .catch((error) => {
        dispatch(unSetLoadingAction(ActionType.RefreshTokenRequest));
        console.error("refresh token error: ", error);
        if (error.response?.status === 401 || error.response?.status === 400) {
          dispatch(logoutAction({ requestId: "logout" }, auth?.accessToken || ""));
          history.push("/");
          return { data: { data: { accessToken: "" } } };
        }
        if (attempt >= 5 || !loggedIn) {
          return { data: { data: { accessToken: "" } } };
        }
        setTimeout(() => refreshToken(attempt + 1), 2000);
      });
  };

  let isRefreshing = false;
  let refreshPromise: Promise<void> | null = null;

  const validateAndDispatch = async (
    action: Action & Partial<{ "@@redux-api-middleware/RSAA": { types: ActionType[]; headers: any } }> & Error
  ) => {
    let newToken = "";

    const newAction = { ...action };
    const keyAction = (action["@@redux-api-middleware/RSAA"]?.types[0] as string) || "";

    if (!!getLoading(state, keyAction as ActionType)) return;

    dispatch(setLoadingAction(keyAction));

    if (loggedIn && auth && auth.accessToken.length) {
      if (processingActions.has(keyAction)) {
        return;
      }

      processingActions.add(keyAction);

      await mutex.waitForUnlock();

      if (!auth.accessToken) {
        return dispatch(logoutAction({ requestId: "logout" }, auth?.accessToken || ""));
      }

      if (needsRefresh) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          if (!isRefreshing && !processingActions.has(ActionType.RefreshTokenRequest)) {
            isRefreshing = true;
            refreshPromise = new Promise(async (resolve, reject) => {
              try {
                const response = await refreshToken();
                const accessToken = await response?.data.data.accessToken;
                if (accessToken) {
                  dispatch(setAuthTokenAction(accessToken));
                  newToken = accessToken;
                  resolve();
                } else {
                  console.error("There was an error refreshing token. Logging user out.");
                  dispatch(logoutAction({ requestId: "logout" }, auth?.accessToken || ""));
                  reject(new Error("Failed to refresh token"));
                }
              } catch (error) {
                reject(error);
              } finally {
                isRefreshing = false;
                refreshPromise = null;
                release();
                processingActions.delete(keyAction);
                processingActions.delete(ActionType.RefreshTokenRequest);
              }
            });
          }
          await refreshPromise;
        } else {
          await mutex.waitForUnlock();
        }
      }
      processingActions.delete(keyAction);
      processingActions.delete(ActionType.RefreshTokenRequest);
    }

    if (newToken && newAction["@@redux-api-middleware/RSAA"]?.headers) {
      newAction["@@redux-api-middleware/RSAA"].headers = Object.assign({}, newAction["@@redux-api-middleware/RSAA"].headers, {
        Authorization: `Bearer ${newToken}`,
        "content-type": "application/json",
      });
    }
    if (!loggedIn) return;
    return dispatch(newAction);
  };
  return { value: validateAndDispatch };
};
