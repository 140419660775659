/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, RefObject, useCallback, useState } from "react";
import { css } from "@emotion/react";
import {
  DeltaDominance,
  Dominance,
  DraftRollAnalysisType,
  DraftSparActions,
  DraftSparState,
  getNextRoundStartTime,
  MoveInitiator,
  RollType,
  Round,
  startingOptionsArray,
  VideoAnalysisNote,
} from "./module";
import GenericModal from "../modal/GenericModal";
import PostUserTagModal from "../Tags/PostUserTagModal";
import SetPositionModal from "./SetPositionModal";
import SetMovementModal from "./SetMovementModal";
import SetSubmissionModal from "./SetSubmissionModal";
import AnalysisNoteCard from "./AnalysisNoteCard";
import TextInput from "../Utils/TextInput";
import { seekTo } from "./playerModule";
import ReactPlayer from "react-player";
import { hexToRGBA } from "../Utils/module";
import theme from "../Theme";
import { useJournals } from "../Journal/Actions/getJournalsAction";

export const notePanelStyles = {
  container: css`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  `,
  buttonRow: css`
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: space-evenly;
    margin-top: 5px;
  `,
  button: (color: string) => css`
    border-radius: 200px;
    background-color: ${theme.colors.contentBackground};
    color: ${color};
    outline: none;
    border: solid 1px ${color};
    padding: 4px 6px;
    height: 22px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;

    :hover {
      background-color: ${color};
      color: ${theme.colors.contentBackground};
    }
  `,
  titleInput: css`
    margin: 10px;
  `,
  inputWrapper: css`
    padding: 5px;
    width: 100%;
    border-top: 1px solid ${hexToRGBA(theme.colors.mediumGray, 0.5)};
    color: black;
  `,
  dateWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 15px;
  `,
  datepicker: css`
    padding: 5px;
    margin: 8px 0;
  `,
  noteScrollCard: css`
    background-color: ${theme.colors.background};
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;

    margin-top: 20px;
    border-radius: 8px;
  `,
  noteScrollCardHeader: css`
    width: 100%;
    border-bottom: solid 1px ${theme.colors.border};
    padding: 16px;
  `,
  noteScrollContainer: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;
    padding: 16px;
  `,
};

const defaultSelectionState = {
  selectedMovementTagValue: "",
  selectedMovementSuccess: undefined,
  selectedMovementInitiatedBy: undefined,
  selectedPositionalDominance: undefined,
  selectedMovementTagId: undefined,
  selectedPositionalTagValue: "",
  selectedPositionalTagId: undefined,
  selectedRollType: RollType.gi,
  isPlaying: true,
  selectedSubmissionTagValue: "",
  selectedSubmissionSuccess: undefined,
  selectedSubmissionInitiatedBy: undefined,
  selectedSubmissionTagId: undefined,
  selectedStartingOption: startingOptionsArray[0].text,
};

interface Props {
  dispatch: React.Dispatch<DraftSparActions>;
  state: DraftSparState;
  played: number;
  isPlaying: boolean;
  rollAnalysisState: DraftRollAnalysisType;
  setRollAnalysisState: React.Dispatch<React.SetStateAction<DraftRollAnalysisType>>;
  currentRound: Partial<Round> | undefined;
  player: RefObject<ReactPlayer>;
}

const SparNotePanel: React.FC<Props> = ({
  dispatch,
  state,
  played,
  rollAnalysisState,
  setRollAnalysisState,
  currentRound,
  player,
}) => {
  const {
    previousRoundIsComplete,
    showStartRoundModal,
    showPositionalAddTagModal,
    showMovementAddTagModal,
    showSubmissionAddTagModal,
    selectedPositionalTagValue,
    selectedMovementTagValue,
    selectedSubmissionTagValue,
    videoDuration,
  } = state;
  const [showNewPositionModal, setShowNewPositionModal] = useState(false);
  const [showNewMovementModal, setShowNewMovementModal] = useState(false);
  const [showNewSubmissionModal, setShowNewSubmissionModal] = useState(false);
  const journals = useJournals();
  const journal = journals.value?.find((j) => j.id === rollAnalysisState.editedJournalId);
  const color = journal?.color || theme.fontColor.googleBlue;
  const isEditingARound = !!currentRound && !!currentRound.id;

  const hasNoRounds = rollAnalysisState.editedRounds?.length === 0 && rollAnalysisState.originalEntry?.rounds.length === 0;

  const canStartRound = hasNoRounds || previousRoundIsComplete;

  const handleStartRoundModalClose = useCallback(() => {
    dispatch({
      type: "setState",
      data: {
        showStartRoundModal: false,
        selectedPositionalTagValue: "",
        selectedStartingOption: startingOptionsArray[0].text,
      },
    });
  }, [dispatch]);

  const handleStartRound = useCallback(() => {
    const startTime = Math.floor(played);
    const nextRoundStartTime = getNextRoundStartTime(startTime, rollAnalysisState.editedRounds);
    const maximumScrollTime = nextRoundStartTime ? nextRoundStartTime - 1 : videoDuration;
    dispatch({
      type: "setState",
      data: {
        currentRound: {
          roundStartTimeSeconds: startTime,
          rollType: state.selectedRollType,
          videoAnalysisNotes: [
            {
              timeSeconds: startTime, //seconds (should this be seconds into the round or seconds into the video? Thinking round
              positionTagId: state.selectedPositionalTagId,
              userDominance: state.selectedPositionalDominance,
              userDominanceDelta: DeltaDominance.neutral,
            },
          ],
        },
        isPlaying: true,
        minimumScrollTime: startTime,
        maximumScrollTime: maximumScrollTime,
        previousRoundIsComplete: false,
        selectedPositionalTagValue: "",
        selectedPositionalTagId: "",
        selectedStartingOption: startingOptionsArray[0].text,
        selectedPositionalDominance: Dominance.neutral,
        selectedRollType: state.selectedRollType,
        showStartRoundModal: false,
      },
    });
    setRollAnalysisState({ ...rollAnalysisState, currentEditingRound: { roundStartTimeSeconds: startTime } });
  }, [
    played,
    rollAnalysisState,
    dispatch,
    setRollAnalysisState,
    videoDuration,
    state.selectedPositionalDominance,
    state.selectedPositionalTagId,
    state.selectedRollType,
  ]);

  const handlePositionChange = useCallback(() => {
    const noteTime = Math.floor(played);
    const newNote: VideoAnalysisNote = {
      timeSeconds: noteTime, //seconds (should this be seconds into the round or seconds into the video? Thinking round
      positionTagId: state.selectedPositionalTagId,
      userDominance: state.selectedPositionalDominance,
      userDominanceDelta: DeltaDominance.neutral,
    };

    const currentRoundNotes = state.currentRound?.videoAnalysisNotes
      ? [...state.currentRound?.videoAnalysisNotes, newNote]
      : [newNote];
    currentRoundNotes.sort((a, b) => a.timeSeconds - b.timeSeconds);

    dispatch({
      type: "setState",
      data: {
        currentRound: {
          ...state.currentRound,
          videoAnalysisNotes: currentRoundNotes,
        },
        selectedPositionalTagValue: "",
        selectedPositionalTagId: "",
        selectedStartingOption: startingOptionsArray[0].text,
        selectedPositionalDominance: Dominance.neutral,
        isPlaying: true,
      },
    });
    setShowNewPositionModal(false);
  }, [played, dispatch, state.selectedPositionalDominance, state.currentRound, state.selectedPositionalTagId]);

  const handleTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRollAnalysisState({ ...rollAnalysisState, editedTitle: event.target.value });
    },
    [rollAnalysisState, setRollAnalysisState]
  );

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRollAnalysisState({ ...rollAnalysisState, editedText: event.target.value });
    },
    [rollAnalysisState, setRollAnalysisState]
  );

  const positionID = useCallback(() => {
    if (state.selectedPositionalTagId)
      return {
        positionId: state.selectedPositionalTagId,
        dominance: state.selectedPositionalDominance,
      };
    const index = (state.currentRound?.videoAnalysisNotes?.length || 1) - 1;
    const lastRoundPosition = state.currentRound?.videoAnalysisNotes?.[index];
    return { positionId: lastRoundPosition?.positionTagId, dominance: lastRoundPosition?.userDominance };
  }, [state.selectedPositionalDominance, state.currentRound?.videoAnalysisNotes, state.selectedPositionalTagId]);

  const resetSelectionState = useCallback(() => {
    dispatch({
      type: "setState",
      data: { ...defaultSelectionState },
    });
  }, [dispatch]);

  const handleMovementChange = useCallback(() => {
    const noteTime = Math.floor(played);

    const newNote: VideoAnalysisNote = {
      timeSeconds: noteTime, //seconds into video
      positionTagId: positionID().positionId,
      userDominance: positionID().dominance || state.selectedPositionalDominance,
      userDominanceDelta: DeltaDominance.neutral,
      movementAttack:
        state.selectedMovementInitiatedBy === MoveInitiator.you &&
        state.selectedMovementTagId !== undefined &&
        state.selectedMovementSuccess !== undefined
          ? { tagId: state.selectedMovementTagId, wasSuccessful: state.selectedMovementSuccess }
          : undefined,
      movementDefended:
        state.selectedMovementInitiatedBy === MoveInitiator.opponent &&
        state.selectedMovementTagId !== undefined &&
        state.selectedMovementSuccess !== undefined
          ? { tagId: state.selectedMovementTagId, wasSuccessful: state.selectedMovementSuccess }
          : undefined,
    };

    const currentRoundNotes = state.currentRound?.videoAnalysisNotes
      ? [...state.currentRound?.videoAnalysisNotes, newNote]
      : [newNote];
    currentRoundNotes.sort((a, b) => a.timeSeconds - b.timeSeconds);

    dispatch({
      type: "setState",
      data: {
        currentRound: {
          ...state.currentRound,
          videoAnalysisNotes: currentRoundNotes,
        },
        ...defaultSelectionState,
      },
    });
    setShowNewMovementModal(false);
  }, [
    played,
    positionID,
    dispatch,
    state.selectedPositionalDominance,
    state.currentRound,
    state.selectedMovementInitiatedBy,
    state.selectedMovementSuccess,
    state.selectedMovementTagId,
  ]);

  const handleEndRound = useCallback(() => {
    if (!state.currentRound?.videoAnalysisNotes?.length) return;
    const lastNote = state.currentRound?.videoAnalysisNotes[state.currentRound?.videoAnalysisNotes.length - 1];
    const latestTime = lastNote?.timeSeconds;
    const earliestTime = state.currentRound?.videoAnalysisNotes[0].timeSeconds;
    const newRoundArray: Round[] = rollAnalysisState.editedRounds
      ? [
          ...rollAnalysisState.editedRounds,
          {
            ...state.currentRound,
            roundEndTimeSeconds: latestTime,
            roundStartTimeSeconds: earliestTime,
          } as Round,
        ].sort((a, b) => a.roundEndTimeSeconds - b.roundEndTimeSeconds)
      : [
          {
            ...state.currentRound,
            roundEndTimeSeconds: latestTime,
            roundStartTimeSeconds: earliestTime,
          } as Round,
        ].sort((a, b) => a.roundEndTimeSeconds - b.roundEndTimeSeconds);
    dispatch({
      type: "setState",
      data: {
        currentRound: undefined,
        minimumScrollTime: undefined,
        maximumScrollTime: videoDuration,
        previousRoundIsComplete: true,
        isPlaying: false,
        selectedRollType: RollType.gi,
      },
    });

    setRollAnalysisState({
      ...rollAnalysisState,
      currentEditingRound: undefined,
      editedRounds: newRoundArray,
    });
  }, [rollAnalysisState, dispatch, setRollAnalysisState, state.currentRound, videoDuration]);
  const handleSubmissionChange = useCallback(() => {
    const wasEndOfRound = !!state.selectedSubmissionSuccess;
    const noteTime = Math.floor(played);

    const newNote: VideoAnalysisNote = {
      timeSeconds: noteTime, //seconds into video
      positionTagId: positionID().positionId,
      userDominance: positionID().dominance || state.selectedPositionalDominance,
      userDominanceDelta: DeltaDominance.neutral,
      submissionAttack:
        state.selectedSubmissionInitiatedBy === MoveInitiator.you &&
        state.selectedSubmissionTagId !== undefined &&
        state.selectedSubmissionSuccess !== undefined
          ? { tagId: state.selectedSubmissionTagId, wasSuccessful: state.selectedSubmissionSuccess }
          : undefined,
      submissionDefended:
        state.selectedSubmissionInitiatedBy === MoveInitiator.opponent &&
        state.selectedSubmissionTagId !== undefined &&
        state.selectedSubmissionSuccess !== undefined
          ? { tagId: state.selectedSubmissionTagId, wasSuccessful: state.selectedSubmissionSuccess }
          : undefined,
    };
    const currentRoundNotes = state.currentRound?.videoAnalysisNotes
      ? [...state.currentRound?.videoAnalysisNotes, newNote]
      : [newNote];

    currentRoundNotes.sort((a, b) => a.timeSeconds - b.timeSeconds);

    if (wasEndOfRound && rollAnalysisState.editedRounds) {
      const earliestTime = currentRoundNotes[0].timeSeconds;
      const newRoundArray: Round[] = [
        ...rollAnalysisState.editedRounds,
        {
          ...state.currentRound,
          videoAnalysisNotes: currentRoundNotes,
          roundEndTimeSeconds: noteTime,
          roundStartTimeSeconds: earliestTime,
        } as Round,
      ].sort((a, b) => a.roundEndTimeSeconds - b.roundEndTimeSeconds);

      isEditingARound
        ? dispatch({
            type: "setState",
            data: {
              currentRound: { ...state.currentRound, videoAnalysisNotes: currentRoundNotes },
              minimumScrollTime: undefined,
              maximumScrollTime: videoDuration,
              previousRoundIsComplete: true,
              isPlaying: false,
              selectedRollType: RollType.gi,
            },
          })
        : dispatch({
            type: "setState",
            data: {
              currentRound: undefined,
              minimumScrollTime: undefined,
              maximumScrollTime: videoDuration,
              previousRoundIsComplete: true,
              isPlaying: false,
              selectedRollType: RollType.gi,
            },
          });

      isEditingARound
        ? setRollAnalysisState({
            ...rollAnalysisState,
            editedRounds: newRoundArray,
            currentEditingRound: {
              ...rollAnalysisState.currentEditingRound,
              videoAnalysisNotes: currentRoundNotes,
            },
          })
        : setRollAnalysisState({
            ...rollAnalysisState,
            editedRounds: newRoundArray,
            currentEditingRound: undefined,
          });
    } else {
      dispatch({
        type: "setState",
        data: {
          currentRound: {
            ...state.currentRound,
            videoAnalysisNotes: currentRoundNotes,
          },
          ...defaultSelectionState,
        },
      });
    }
    setShowNewSubmissionModal(false);
  }, [
    rollAnalysisState,
    setRollAnalysisState,
    videoDuration,
    played,
    dispatch,
    positionID,
    state.selectedPositionalDominance,
    state.currentRound,
    state.selectedSubmissionInitiatedBy,
    state.selectedSubmissionSuccess,
    state.selectedSubmissionTagId,
    isEditingARound,
  ]);

  const handleOpenStartRoundModal = useCallback(() => {
    dispatch({ type: "setState", data: { isPlaying: false, showStartRoundModal: true } });
  }, [dispatch]);

  const handleCloseAddTagModal = useCallback(() => {
    dispatch({
      type: "setState",
      data: {
        showPositionalAddTagModal: false,
        showSubmissionAddTagModal: false,
        showMovementAddTagModal: false,
      },
    });
  }, [dispatch]);

  const setNewPositionalTagValue = useCallback(
    (value: string | undefined) => {
      dispatch({ type: "setState", data: { selectedPositionalTagValue: value } });
    },
    [dispatch]
  );

  const setNewMovementTagValue = useCallback(
    (value: string | undefined) => {
      dispatch({ type: "setState", data: { selectedMovementTagValue: value } });
    },
    [dispatch]
  );

  const setNewSubmissionTagValue = useCallback(
    (value: string | undefined) => {
      dispatch({ type: "setState", data: { selectedSubmissionTagValue: value } });
    },
    [dispatch]
  );

  const handleOpenSetPositionModal = useCallback(() => {
    setShowNewPositionModal(true);
    dispatch({ type: "setState", data: { isPlaying: false } });
  }, [dispatch]);

  const handleSetPositionModalClose = useCallback(() => {
    setShowNewPositionModal(false);
    dispatch({
      type: "setState",
      data: {
        selectedPositionalTagValue: "",
        selectedStartingOption: startingOptionsArray[0].text,
        isPlaying: true,
      },
    });
  }, [dispatch]);

  const handleOpenSetMovementModal = useCallback(() => {
    setShowNewMovementModal(true);
    dispatch({ type: "setState", data: { isPlaying: false } });
  }, [dispatch]);

  const handleOpenSetSubmissionModal = useCallback(() => {
    setShowNewSubmissionModal(true);
    dispatch({ type: "setState", data: { isPlaying: false } });
  }, [dispatch]);

  // const handleEntryDateChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setRollAnalysisState({ ...rollAnalysisState, editedVideoDate: DateTime.fromISO(e.target.value) });
  //   },
  //   [rollAnalysisState, setRollAnalysisState]
  // );

  const handleSeek = useCallback(
    (time: number | undefined) => {
      time !== undefined &&
        time >= 0 &&
        seekTo({
          allRounds: rollAnalysisState.editedRounds || [],
          currentRound,
          dispatch,
          player,
          seconds: time,
          videoDuration,
        });
    },
    [dispatch, player, rollAnalysisState.editedRounds, currentRound, videoDuration]
  );

  const handleSetMovementModalClose = useCallback(() => {
    setShowNewMovementModal(false);
    resetSelectionState();
  }, [resetSelectionState]);

  const handleSetSubmissionModalClose = useCallback(() => {
    setShowNewSubmissionModal(false);
    resetSelectionState();
  }, [resetSelectionState]);

  const handleDeleteNote = useCallback(
    (deleteNote: VideoAnalysisNote) => {
      const newNotes = currentRound?.videoAnalysisNotes?.filter((note) => note.timeSeconds !== deleteNote.timeSeconds);
      setRollAnalysisState((prev) => ({
        ...prev,
        currentEditingRound: {
          ...prev.currentEditingRound,
          videoAnalysisNotes: newNotes,
        },
      }));
      dispatch({ type: "setState", data: { currentRound: { ...currentRound, videoAnalysisNotes: newNotes } } });
    },
    [currentRound, dispatch, setRollAnalysisState]
  );
  const renderRoundForm = () => (
    <>
      {" "}
      <div css={notePanelStyles.noteScrollCard}>
        <div css={notePanelStyles.noteScrollCardHeader}>
          <span>Notes</span>
          <div css={notePanelStyles.buttonRow}>
            <button
              css={notePanelStyles.button(color)}
              onClick={handleOpenSetPositionModal}
            >
              Position
            </button>
            <button
              css={notePanelStyles.button(color)}
              onClick={handleOpenSetMovementModal}
            >
              Movement
            </button>
            <button
              css={notePanelStyles.button(color)}
              onClick={handleOpenSetSubmissionModal}
            >
              Submission
            </button>
            <button
              css={notePanelStyles.button(color)}
              onClick={handleEndRound}
            >
              End Round
            </button>
          </div>
        </div>
        <div css={notePanelStyles.noteScrollContainer}>
          {state.currentRound?.videoAnalysisNotes?.map((note, index) => {
            const nextRound = state.currentRound?.videoAnalysisNotes?.[index + 1];
            const isCurrentNote = note.timeSeconds < played && (!nextRound || nextRound.timeSeconds > played);

            return (
              <AnalysisNoteCard
                handleSeek={handleSeek}
                key={note.timeSeconds.toString()}
                note={note}
                roundStartTime={state.currentRound?.roundStartTimeSeconds}
                canEdit={true}
                onDeleteNote={() => handleDeleteNote(note)}
                isCurrentNote={isCurrentNote}
                color={color}
              />
            );
          })}
        </div>
      </div>
    </>
  );

  return (
    <div css={notePanelStyles.container}>
      {!isEditingARound && (
        <>
          <TextInput
            css={notePanelStyles.titleInput}
            value={rollAnalysisState.editedTitle}
            placeholder={"Video Title"}
            id={"video-title"}
            onChange={handleTitleChange}
          />
          <TextInput
            css={notePanelStyles.titleInput}
            value={rollAnalysisState.editedText}
            placeholder={"Video Description"}
            id={"video-description"}
            onChange={handleTextChange}
          />
        </>
      )}
      {/* <div css={notePanelStyles.dateWrapper}>
        <label htmlFor={"date-picker-journal-entry"}>Video Date</label>
        <input
          id="date-picker-journal-entry"
          name="journal-entry-date"
          type="datetime-local"
          value={rollAnalysisState.editedVideoDate.toFormat(DATE_FORMAT.inputDateTimeLocal)}
          onChange={handleEntryDateChange}
          min="1900-01-01"
          css={notePanelStyles.datepicker}
        />
      </div> */}
      {canStartRound && !state.currentRound ? (
        <button onClick={handleOpenStartRoundModal}>Start Next Round!</button>
      ) : (
        renderRoundForm()
      )}
      {showStartRoundModal && (
        <SetPositionModal
          handleSetPositionModalClose={handleStartRoundModalClose}
          handlePositionChange={handleStartRound}
          dispatch={dispatch}
          newTagValue={selectedPositionalTagValue}
          state={state}
          isStartOfRound={true}
        />
      )}
      {showNewPositionModal && (
        <SetPositionModal
          handleSetPositionModalClose={handleSetPositionModalClose}
          handlePositionChange={handlePositionChange}
          dispatch={dispatch}
          newTagValue={selectedPositionalTagValue}
          state={state}
          isStartOfRound={false}
        />
      )}
      {showNewMovementModal && (
        <SetMovementModal
          handleSetMovementModalClose={handleSetMovementModalClose}
          handleMovementChange={handleMovementChange}
          dispatch={dispatch}
          newMovementTagValue={selectedMovementTagValue}
          newPositionalTagValue={selectedPositionalTagValue}
          state={state}
        />
      )}
      {showNewSubmissionModal && (
        <SetSubmissionModal
          handleSetSubmissionModalClose={handleSetSubmissionModalClose}
          handleSubmissionChange={handleSubmissionChange}
          dispatch={dispatch}
          newSubmissionTagValue={selectedSubmissionTagValue}
          newPositionalTagValue={selectedPositionalTagValue}
          state={state}
        />
      )}
      {showPositionalAddTagModal && (
        <GenericModal
          noButtons={true}
          confirmText={"Create New Tag"}
          title={"Add New Tag"}
          onClose={handleCloseAddTagModal}
        >
          <PostUserTagModal
            handleClose={handleCloseAddTagModal}
            tagName={selectedPositionalTagValue || ""}
            positionOnly={true}
            setNewTagValue={setNewPositionalTagValue}
          />
        </GenericModal>
      )}
      {showMovementAddTagModal && (
        <GenericModal
          noButtons={true}
          confirmText={"Create New Tag"}
          title={"Add New Tag"}
          onClose={handleCloseAddTagModal}
        >
          <PostUserTagModal
            handleClose={handleCloseAddTagModal}
            tagName={selectedMovementTagValue || ""}
            movementOnly={true}
            setNewTagValue={setNewMovementTagValue}
          />
        </GenericModal>
      )}
      {showSubmissionAddTagModal && (
        <GenericModal
          noButtons={true}
          confirmText={"Create New Tag"}
          title={"Add New Tag"}
          onClose={handleCloseAddTagModal}
        >
          <PostUserTagModal
            handleClose={handleCloseAddTagModal}
            tagName={selectedSubmissionTagValue || ""}
            submissionOnly={true}
            setNewTagValue={setNewSubmissionTagValue}
          />
        </GenericModal>
      )}
    </div>
  );
};

export default SparNotePanel;
