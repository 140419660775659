import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback, useMemo} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {PUT_ROUND_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';
import {Round, updateRoundData} from '../../VideoAnalysis/module';

export interface PutRoundActionProps {
  round: Partial<Round>;
}

const KEY_ACTION = ActionType.PutRoundRequest;
const ACTIONS = [
  ActionType.PutRoundRequest,
  ActionType.PutRoundResponse,
  ActionType.PutRoundError,
];

interface Payload extends Response {
  data?: null | {
    round?: null | Round
  };
}

export const putJournalAction = (body: PutRoundActionProps, token?: string) =>
  makeApiAction<Payload, PutRoundActionProps>({
    method: 'PUT',
    endpoint: () => PUT_ROUND_ENDPOINT(body.round.id || ''),
    headers: () => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<Payload, PutRoundActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<Payload, PutRoundActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.round) {
    return addError(state, KEY_ACTION, ['PUT round failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState, userJournals: undefined, rounds: undefined,
  };
};

export const putRoundReducer = makeApiReducer<Payload, PutRoundActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to edit round.',
);

export const usePutRound = (round?: Partial<Round>) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const updatedRound = useMemo(() => {
    if (!round) return null;
    return updateRoundData(round);
  }, [round]);

  const putRound = useCallback(
    async () => round && updatedRound && await validateAndDispatch(putJournalAction({round: updatedRound}, token),
    ), [round, validateAndDispatch, token, updatedRound]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: putRound, reload: putRound,
  };
  
};