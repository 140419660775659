/** @jsxImportSource @emotion/react */

import React, { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import theme from '../Theme';
import { hexToRGBA } from '../Utils/module';
import { ENTRY_TYPE, JournalObject } from '../Utils/types';
import YouTubeVideoSelector from './YouTubeVideoSelector';
import { useAppState } from '../store/hooks';
import GrapplingIcon from '../icons/GrapplingIcon';
import { useHistory } from 'react-router-dom';
import VideoCamIcon from '../icons/VideoCamIcon';
import NoteIcon from '../icons/NoteIcon';

const styles = {
	content: css`
		min-height: 100%;
		background-color: ${theme.colors.contentBackground};
	`,
	container: css`
		display: flex;
		flex-direction: column;
		gap: 6px;
	`,
	row: (color?: string) => css`
		display: grid;
		grid-template-columns: 30px auto;
		align-items: center;
		cursor: pointer;
		padding: 14px 20px;
		user-select: none;
		border-radius: 200px;
		background-color: ${theme.colors.background};
		color: ${theme.fontColor.text};
		:hover {
			background-color: ${theme.palette.primary.main};
			color: ${theme.colors.contentBackground};
			font-weight: 700;
		}
	`,
	videoRow: (color?: string, hasLinkedGoogleAccount = true) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		padding: 5px 0;
		user-select: none;
		color: ${hasLinkedGoogleAccount ? theme.fontColor.text : theme.colors.inactive};

		&:hover {
			background-color: ${hasLinkedGoogleAccount
				? hexToRGBA(color ? color : theme.palette.primary.light, 0.1)
				: hexToRGBA(theme.colors.inactive, 0.1)};
		}
	`,
};

interface Props {
	color: string;
	createEntry: (showCreateEntry: boolean) => void;
	closeModal: () => void;
	setEntryType: (entryType: ENTRY_TYPE) => void;
	setVideoUrl: (url: string) => void;
	setExcludeFromCksInstructionals: (boolean: boolean) => void;
	journal?: JournalObject;
}

const AddNewEntryModal: React.FC<Props> = ({
	color,
	createEntry,
	closeModal,
	setEntryType,
	setVideoUrl,
	setExcludeFromCksInstructionals,
	journal,
}) => {
	const [createVideoEntry, setCreateVideoEntry] = useState(false);
	const [showPersonalVideoSelector, setShowPersonalVideoSelector] = useState(false);
	const history = useHistory();
	const hasLinkedGoogleAccount = useAppState((state) => !!state.user?.googleApi?.accessToken);
	const handleCreateTextEntry = useCallback(() => {
		setEntryType(ENTRY_TYPE.Text);
		createEntry(true);
		closeModal();
	}, [createEntry, closeModal, setEntryType]);

	const handleCreateVideoEntry = useCallback(() => {
		setEntryType(ENTRY_TYPE.Video);
		setCreateVideoEntry(true);
		setExcludeFromCksInstructionals(false);
	}, [setEntryType, setExcludeFromCksInstructionals]);

	const handleCreateSparAnalysis = useCallback(() => {
		const url = '/analyze?journal=' + journal?.id;
		history.push(url);
	}, [history, journal?.id]);

	const handleVideoClick = useCallback(() => {
		createEntry(true);
		closeModal();
	}, [closeModal, createEntry]);

	const handlePersonalVideoClick = useCallback(() => {
		setShowPersonalVideoSelector(true);
		setExcludeFromCksInstructionals(true);
	}, [setExcludeFromCksInstructionals]);

	return (
		<div css={styles.container}>
			{!createVideoEntry ? (
				<>
					<div
						css={styles.row(color)}
						onClick={handleCreateTextEntry}
					>
						<NoteIcon />
						<span>Text based entry</span>
					</div>
					<div
						css={styles.row(color)}
						onClick={handleCreateVideoEntry}
					>
						<VideoCamIcon />
						<span>Video Notes</span>
					</div>
					<div
						css={styles.row(color)}
						onClick={handleCreateSparAnalysis}
					>
						<GrapplingIcon />
						<span>Analyze Sparring</span>
					</div>
				</>
			) : showPersonalVideoSelector ? (
				<>
					<YouTubeVideoSelector
						color={color}
						handleVideoClick={handleVideoClick}
						setVideoUrl={setVideoUrl}
					/>
				</>
			) : (
				<>
					<span
						onClick={handleVideoClick}
						css={styles.videoRow(color)}
					>
						YouTube Instructional
					</span>
					<span
						onClick={
							hasLinkedGoogleAccount
								? handlePersonalVideoClick
								: () => alert('ERROR: Please link a google account from your user profile page to access this feature.')
						}
						css={styles.videoRow(color, hasLinkedGoogleAccount)}
					>
						Personal Video
					</span>
				</>
			)}
		</div>
	);
};

export default AddNewEntryModal;
