/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import theme from "../../Theme";
import { css } from "@emotion/react";
import { ENTRY_TYPE, EntryFormState, JournalEntryObject, JournalObject } from "../../Utils/types";
import { useJournals } from "../Actions/getJournalsAction";
import { ApiHookReturn } from "../../store/module";
import { DateTime } from "luxon";
import { serialize, toEditedEntryObject } from "../module";
import { usePutEntry } from "../Actions/putEntryAction";
import { useDeleteEntry } from "../Actions/deleteEntryAction";
import { BASE_ROUTES } from "../../Navigation/routesModule";
import Loader from "../../Utils/Loader";
import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import SaveIcon from "../../icons/SaveIcon";
import { APP_LAYERS } from "../../layers";
import { useHover } from "../../tooltip/hooks";
import VideoNoteTaker from "../VideoNoteTaker/VideoNoteTaker";
import MoveEntryModal from "../MoveEntryModal";
import GenericModal from "../../modal/GenericModal";
import FloatingActionButton from "../../Utils/FloatingActionButton";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    text-decoration: none;
    gap: 20px;
  `,
  contentPage: css`
    background-color: ${theme.colors.contentBackground};
    border-radius: 12px;
    padding: 20px;
    display: flex;
    height: 100%;
    overflow: hidden;
  `,
  entryHeaderWrapperVideo: (color?: string) => css`
    color: ${color ? color : theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    text-align: center;
    font-weight: bold;
    z-index: ${APP_LAYERS.overlays};
    height: 56px;
  `,
  linkWrapperVideo: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  `,
  journalName: css`
    color: ${theme.fontColor.text};
    display: flex;
    align-self: center;
    justify-self: center;
    font-weight: 400;
  `,
  backIcon: (color?: string) => css`
    color: ${color ? color : theme.palette.primary.main};
    display: flex;
    align-self: center;
    justify-self: center;
  `,
  modalBody: css`
    display: flex;
    width: 100%;
    padding: 5px;
  `,
  saveIcon: (color?: string) => css`
    color: ${theme.colors.contentBackground};
    border-radius: 200px;
    background-color: ${color ? color : theme.palette.primary.main};
    font-size: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  link: (color: string) => css`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    border-radius: 200px;
    height: 40px;
    border: solid 1px ${color ? color : theme.palette.primary.main};
    padding: 0 12px;
    justify-content: center;
  `,

  actionButtonWrapper: css`
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    align-items: center;
  `,
};

interface Props {
  setUpdatedURL: (newURL: string) => void;
}

const VideoNoteEntryViewer: React.FC<Props> = ({ setUpdatedURL }) => {
  const { entryId, journalId } = useParams<{ entryId: string; journalId: string }>();

  const journals: ApiHookReturn<JournalObject[]> = useJournals();
  const history = useHistory();

  const journal: JournalObject | undefined =
    journals.value && JSON.parse(JSON.stringify(journals.value.find((journal) => journal.id === journalId)));

  const entry: JournalEntryObject | undefined =
    journal?.entries && JSON.parse(JSON.stringify(journal.entries?.find((entry) => entry.journalEntryId === entryId) || {}));

  const entryDate = useMemo(() => entry && entry.entryDateTimeUtc && DateTime.fromISO(entry.entryDateTimeUtc), [entry]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [hasEntry, setHasEntry] = useState(false);
  const [showMoveEntryModal, setShowMoveEntryModal] = useState(false);
  const [entryFormState, setEntryFormState] = useState<EntryFormState>({
    editedTitle: entry?.title || "",
    editedText: entry?.text || "",
    editedEntryDate: entryDate || DateTime.now(),
    entry: entry || {
      journalEntryId: "",
      title: "",
      text: "",
      createdDateTimeUtc: "",
      entryDateTimeUtc: "",
      videoUrl: "",
      type: 0,
      notes: [],
      tags: [],
    },
    editedTagIds: entry?.tags || [],
    type: entry?.type || ENTRY_TYPE.Text,
    editedVideoUrl: entry?.videoUrl || "",
    editedNotes: entry?.notes ? [...entry?.notes] : [],
  });

  const putEntry = usePutEntry(journalId, toEditedEntryObject(entryFormState));
  const deleteEntry = useDeleteEntry(journalId, entryId);

  const editsWereMade = useMemo(() => {
    if (entry) {
      const titleChanged = entryFormState.editedTitle !== entry.title;
      const dateChanged = !entryFormState.editedEntryDate.hasSame(DateTime.fromISO(entry.entryDateTimeUtc), "minute");
      const textChanged = entryFormState.editedText !== entry.text;
      const tagsChanged = JSON.stringify(entryFormState.editedTagIds) !== JSON.stringify(entry.tags);
      const videoUrlChanged = entryFormState.editedVideoUrl !== entry.videoUrl;
      const videoNotesChanged = JSON.stringify(entryFormState.editedNotes) !== JSON.stringify(entry?.notes);
      return titleChanged || dateChanged || textChanged || tagsChanged || videoUrlChanged || videoNotesChanged;
    }
    return false;
  }, [entry, entryFormState]);

  const [moveIconHoverRef] = useHover<HTMLDivElement>([journal?.id, editsWereMade]);

  const setDefaultEntryFormState = useCallback(() => {
    setEntryFormState({
      editedText: entry?.text || "",
      editedTitle: entry?.title || "",
      editedEntryDate: entryDate || DateTime.now(),
      editedTagIds: entry?.tags || [],
      entry: entry || {
        journalEntryId: "",
        title: "",
        text: "",
        createdDateTimeUtc: "",
        entryDateTimeUtc: "",
        videoUrl: "",
        type: 0,
        notes: [],
        tags: [],
      },
      type: entry?.type || ENTRY_TYPE.Text,
      editedVideoUrl: entry?.videoUrl || "",
      editedNotes: entry?.notes ? [...entry?.notes] : [],
    });
  }, [entry, entryDate]);

  const handleSave = useCallback(async () => {
    const hasDate = !!entryFormState.editedEntryDate;
    const needsSerialized = entryFormState.editedText.trim()[0] === "[";
    const editedText = needsSerialized ? serialize(entryFormState.editedText).trim() : entryFormState.editedText.trim();
    const isBlankEntry = entryFormState.editedText.length <= 50;
    if ((editedText || !isBlankEntry) && hasDate) {
      if (editsWereMade) {
        await putEntry.value().then((res) => {
          if (!res?.error) {
            return setHasEntry(false);
          }
          alert("There was an error. Please try again.");
        });
      }
    }
  }, [entryFormState, putEntry, editsWereMade]);

  const handleDelete = useCallback(async () => {
    if (journalId && entryId && deleteEntry.value) {
      await deleteEntry.value().then((res) => {
        if (!res?.error) {
          return history.push(BASE_ROUTES.journal + "/" + journalId);
        }
        setShowDeleteConfirm(false);
        alert("There was an error. Please try again.");
      });
    }
  }, [journalId, entryId, deleteEntry, history]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const handleCloseMoveEntryModal = useCallback(() => {
    setShowMoveEntryModal(false);
  }, []);

  const handleEntryMove = useCallback(() => {
    setShowMoveEntryModal(true);
  }, []);

  const menuItems = [
    {
      text: "Delete this entry",
      onClick: () => setShowDeleteConfirm(true),
    },
    {
      text: "Move this entry",
      onClick: () => entry && handleEntryMove(),
    },
  ];

  useEffect(() => {
    //once entry loads, sets it to state one time
    !hasEntry && entry && setDefaultEntryFormState();
    entry && setHasEntry(true);
  }, [entry, hasEntry, setDefaultEntryFormState]);

  useEffect(() => {
    putEntry.loading && setHasEntry(false);
  }, [putEntry]);

  if (deleteEntry.loading) {
    return <Loader message={"Deleting..."} />;
  }
  if (!journal) {
    return <Loader message={"Fetching Journal..."} />;
  }
  if (!entry) {
    return <span>Error: could not locate entry. Please try again later.</span>;
  }

  return (
    <>
      {!entry ? (
        <div>
          <span>Entry Not Found or Can't Be Displayed</span>
        </div>
      ) : (
        <div css={styles.root}>
          <div css={styles.linkWrapperVideo}>
            <Link
              css={styles.link(journal.color)}
              to={{
                pathname: `${BASE_ROUTES.journal + "/" + journalId}`,
              }}
            >
              <ChevronLeftIcon css={styles.backIcon(journal?.color)} />
              <span css={styles.journalName}>{journal?.name}</span>
            </Link>
            <div css={styles.actionButtonWrapper}>
              {editsWereMade ? (
                <SaveIcon
                  css={styles.saveIcon(editsWereMade ? journal.color : "lightgray")}
                  onClick={handleSave}
                />
              ) : (
                <div
                  ref={moveIconHoverRef}
                  key={journal.id}
                >
                  <FloatingActionButton
                    icon={"hamburgerMenu"}
                    menuItems={menuItems}
                    isRelativePosition={true}
                    menuPosition={"bottom"}
                  />
                </div>
              )}
            </div>
          </div>
          {putEntry.loading && <Loader message={"Saving..."} />}
          <div css={styles.contentPage}>
            <VideoNoteTaker
              entryFormState={entryFormState}
              setEntryFormState={setEntryFormState}
              color={journal?.color}
            />
          </div>
          {showMoveEntryModal && (
            <MoveEntryModal
              entry={{ journalEntry: entry, journalId: journalId }}
              handleCloseModal={handleCloseMoveEntryModal}
              setNewURL={setUpdatedURL}
            />
          )}
          {showDeleteConfirm && (
            <GenericModal
              onClose={handleDeleteCancel}
              onConfirm={handleDelete}
              title="Warning!"
              confirmText="Delete"
              isWarning={true}
            >
              <span css={styles.modalBody}>Are you sure you want to delete this entry?</span>
            </GenericModal>
          )}
        </div>
      )}
    </>
  );
};
export default VideoNoteEntryViewer;
