/** @jsxImportSource @emotion/react */
import React, { useCallback, useRef, useState } from "react";
import { useLogin } from "./Actions/loginAction";
import { css } from "@emotion/react";
import Loader from "../Utils/Loader";
import TextInput from "../Utils/TextInput";
import theme from "../Theme";
import AppHeader from "../Navigation/AppHeader";
import { hexToRGBA } from "../Utils/module";
import { Link } from "react-router-dom";

const styles = {
  background: css`
    background-color: #232323;
    background: url(background.jpeg) ${hexToRGBA(theme.palette.primary.dark, 0.9)};
    background-position-y: 27%;
    background-position-x: 54%;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.md}px) {
      background-position-y: 43%;
      background-position-x: 100%;
    }
    overflow-y: auto;
  `,
  loginWrapper: css`
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-width: 300px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  `,
  title: css`
    font-size: 20px;
    font-weight: 700;
    margin: 10px;
  `,
  contentTitle: css`
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    margin: 10px;
    margin-top: 40px;
    width: 75%;
    line-height: 1;
    align-self: center;
    text-align: center;
    @media (min-width: ${theme.breakpoints.md}px) {
      font-size: 5rem;
      width: 50%;
    }
    @media (min-width: ${theme.breakpoints.sm}px) {
      font-size: 5rem;
      width: 75%;
    }
  `,
  contentSubTitle: css`
    color: #fff;
    font-size: 18px;
    width: 90%;
    line-height: 1;
    align-self: center;
    text-align: center;
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 70%;
    }
    @media (min-width: ${theme.breakpoints.sm}px) {
      width: 80%;
    }
  `,
  form: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  `,
  privacyRow: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    text-decoration: underline;
    padding: 10px;
  `,
  loginButton: css`
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 9px 5px;
    border-radius: 100px;
    background-color: ${theme.palette.primary.light};
    color: white;
    border: none;
    margin: 10px;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  `,
  input: css`
    width: 100%;
  `,
  link: css`
    color: blue;
    cursor: pointer;
    align-self: flex-start;
    margin-top: 4px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
  `,
  error: css`
    color: ${theme.colors.losses};
  `,
  termsLink: css`
    color: #fff;
  `,
  descriptionBar: css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    align-items: center;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.6)};

    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      flex-direction: column;
    }
  `,

  list: css`
    &.description-list {
      list-style: initial;
      padding-left: 20px;
      text-align: left;
      font-size: 20px;

      li {
        margin-left: 20px;
        display: list-item;
      }
    }
  `,
  descriptionRow: css`
    color: ${theme.fontColor.text};
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-size: 40px;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 20px;
    }
    div {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      span {
        white-space: nowrap;
      }
    }
  `,
  descriptionRowMirror: css`
    color: ${theme.fontColor.text};
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-size: 40px;
    text-align: center;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 20px;
    }
    div {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      span {
        white-space: nowrap;
      }
    }
  `,
  image: css`
    display: flex;
    max-height: 500px;
    border-radius: 20px;
  `,
};

const Login: React.FC = () => {
  const [emailOrUserName, setEmailOrUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signIn, setSignIn] = useState<boolean>(false);

  const usernameRef = useRef(null);

  const login = useLogin(emailOrUserName, password);

  const onEmailOrUserNameChange = useCallback(
    (e: any) => {
      setEmailOrUserName(e.target?.value || "");
    },
    [setEmailOrUserName]
  );

  const onPasswordChange = useCallback(
    (e: any) => {
      setPassword(e.target?.value || "");
    },
    [setPassword]
  );

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      if (emailOrUserName.length && password.length) {
        login.value && login.value();
      }
    },
    [login, emailOrUserName, password]
  );

  if (login.errors?.length) {
    console.error(login.errors);
  }

  if (login.loading) {
    return (
      <div css={styles.background}>
        <Loader />
      </div>
    );
  }

  return (
    <div css={styles.background}>
      <AppHeader
        renderByDefault
        signIn={signIn}
        setSignIn={setSignIn}
      />
      <span css={styles.contentTitle}>Optimize your jiu jitsu</span>
      <span css={styles.contentSubTitle}>Champions Keep Score is where champions are made through progress and reflection</span>
      <div css={styles.descriptionBar}>
        <div css={styles.descriptionRow}>
          <div>
            <b>Track</b> <span> your video sparring sessions</span>
          </div>
          <img
            alt="analyzer"
            src={"analyzer.png"}
            css={styles.image}
          />
        </div>
        <div css={styles.descriptionRowMirror}>
          <img
            alt="analyzer"
            src={"analyzer.png"}
            css={styles.image}
          />
          <div>
            <b>Analyze</b> <span>your decisions with data</span>
          </div>
        </div>
        <div css={styles.descriptionRow}>
          <div>
            <b>Improve</b> <span>your jiu jitsu with note taking</span>
          </div>
          <img
            alt="notes"
            src={"notes.png"}
            css={styles.image}
          />
        </div>
      </div>
      <div css={styles.privacyRow}>
        <Link
          css={styles.termsLink}
          to={"/privacy"}
        >
          Privacy Policy
        </Link>
        <Link
          css={styles.termsLink}
          to={"/termsOfService"}
        >
          Terms of Service
        </Link>
      </div>
      {signIn && (
        <div css={styles.loginWrapper}>
          <form css={styles.form}>
            <span css={styles.title}>Champions Keep Score</span>
            <TextInput
              ref={usernameRef}
              css={styles.input}
              required
              id={"Username"}
              placeholder={"Username / Email"}
              type="text"
              value={emailOrUserName}
              onChange={onEmailOrUserNameChange}
            />
            <TextInput
              required
              id={"Password"}
              placeholder={"Password"}
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
            {login.errors?.length &&
              login.errors.map((err) => (
                <span
                  css={styles.error}
                  key={err}
                >
                  {err}
                </span>
              ))}
            {/*//todo forgot password path*/}
            <span
              onClick={() => alert("Please email zack@Championskeepscore.com for help with this")}
              css={styles.link}
            >
              Forgot password?
            </span>
            <button
              css={styles.loginButton}
              type="submit"
              onClick={onSubmit}
            >
              Sign in
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
