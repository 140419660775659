/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import theme from '../Theme';
import { css } from '@emotion/react';
import { useJournals } from './Actions/getJournalsAction';
import { JournalFormState } from '../Utils/types';
import { getJournalColorInfo } from './module';
import { hexToRGBA } from '../Utils/module';
import GenericModal from '../modal/GenericModal';
import JournalCreatorEditor from './JournalCreatorEditor';

const styles = {
	journalToEdit: (color: string) => css`
		color: ${color || theme.fontColor.text};
		background-color: ${theme.colors.contentBackground};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px;
		cursor: pointer;

		&:hover {
			background-color: ${hexToRGBA(color ? color : theme.palette.primary.light, 0.2)};
		}
	`,
	entryCount: css`
		color: ${theme.colors.mediumGray};
	`,
	modal: (color?: string) => css`
		& .modal-title {
			color: ${color};
		}
	`,
};

interface Props {
	handleCancel: () => void;
}

const blankJournalState = {
	journal: {
		name: '',
		id: '',
		userId: '',
		color: '',
		createdDateTimeUtc: '',
		entries: [],
	},
	editedDescription: '',
	editedColor: getJournalColorInfo(''),
	editedName: '',
};
const LibraryJournalEditor: React.FC<Props> = ({ handleCancel }) => {
	const journals = useJournals().value;
	const [journalFormState, setJournalFormState] = useState<JournalFormState>(blankJournalState);
	const [modalTitle, setModalTitle] = useState('Select journal to edit');

	const handleJournalSelect = useCallback(
		(journalId: string) => {
			const activeJournal = journals?.find((journal) => journal.id === journalId);
			if (activeJournal) {
				setJournalFormState({
					journal: activeJournal,
					editedDescription: activeJournal.description || '',
					editedColor: getJournalColorInfo(activeJournal.color),
					editedName: activeJournal.name,
				});
				setModalTitle(activeJournal.name);
			}
		},
		[journals]
	);

	const renderJournalSelector = useMemo(
		() =>
			journalFormState.journal.id ? (
				<JournalCreatorEditor
					createNewJournal={false}
					journal={journalFormState.journal}
					closeModal={handleCancel}
				/>
			) : (
				journals?.map((journal) => (
					<div
						key={journal.id}
						css={styles.journalToEdit(journal.color)}
						onClick={() => handleJournalSelect(journal.id)}
					>
						<span>{journal.name || 'Journal Not Yet Named'}</span>
						<span css={styles.entryCount}>{journal.entries.length}</span>
					</div>
				))
			),
		[handleJournalSelect, journalFormState, journals, handleCancel]
	);

	return (
		<GenericModal
			onClose={handleCancel}
			noButtons={true}
			title={modalTitle}
			css={styles.modal(journalFormState.editedColor.hexCode)}
		>
			{renderJournalSelector}
		</GenericModal>
	);
};
export default LibraryJournalEditor;
