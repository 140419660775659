import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {DELETE_USER_TAG_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {deleteTagFromAllEntries} from './tagActionsModule';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

export interface DeleteActionProps {
  tagId: string
}

const KEY_ACTION = ActionType.DeleteUserTagRequest;
const ACTIONS = [
  ActionType.DeleteUserTagRequest,
  ActionType.DeleteUserTagResponse,
  ActionType.DeleteUserTagError,
];

interface Payload extends Response {
}

export const deleteUserTagAction = (body: DeleteActionProps, token?: string) => makeApiAction<Payload, DeleteActionProps>({
  method: 'DELETE',
  endpoint: () => DELETE_USER_TAG_ENDPOINT(body.tagId || ''),
  headers: () => ({
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body: JSON.stringify(body),
  types: makeRsaaTypes<Payload, DeleteActionProps>(ACTIONS, body),
  bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, DeleteActionProps> = (state, action, errorMsg, isLoading) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
    tags: state.tags?.filter((tag) => tag.id !== action.meta.tagId),
    userJournals: deleteTagFromAllEntries(action.meta.tagId || '', state),
  };
};

export const deleteUserTagReducer = makeApiReducer<Payload, DeleteActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to delete tag.',
);

export const useDeleteUserTag = (
  tagId: string,
) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const deleteUserTag = useCallback(async () => await validateAndDispatch(deleteUserTagAction({tagId}, token)),
    [validateAndDispatch, token, tagId]);
  
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: deleteUserTag, reload: deleteUserTag,
  };
};