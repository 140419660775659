import { GOOGLE_API_KEY } from "../google/module";
import { IS_PROD_ENV } from "../environment/environment";

const baseUrl = IS_PROD_ENV ? "https://cks-api-prod.azurewebsites.net/api" : "https://cks-api-dev.azurewebsites.net/api";

const youtubeBaseUrl = "https://youtube.googleapis.com/youtube/v3";
export const ENDPOINTS = {
  Login: `${baseUrl}/Account/Login`,
  Logout: `${baseUrl}/Account/Logout`,
  updatePassword: `${baseUrl}/Account/ChangePassword`,
  refreshAccessToken: `${baseUrl}/Account/RefreshAccessToken`,
  OAuth: `${baseUrl}/Account/ExchangeGoogleCode`,
  RefreshGoogleAccessToken: `${baseUrl}/Account/RefreshGoogleAccessToken`,
  RevokeGoogleAccessToken: `${baseUrl}/Account/RevokeGoogleToken`,
  GetUser: `${baseUrl}/User`,
  GetUserProfile: `${baseUrl}/User/UserProfile`,
  GetUserJournals: `${baseUrl}/Journal/Journals`,
  PostJournal: `${baseUrl}/Journal`,
  GetTags: `${baseUrl}/Tag`,
  PostUserTags: `${baseUrl}/Tag/UserTag`,
  GetUserAnalysis: `${baseUrl}/Analyzer/UserAnalysis`,
};

export const GOOGLE_ENDPOINTS = {
  GetYouTubeChannel:
    `${youtubeBaseUrl}/channels?part=snippet%2CcontentDetails%2Cstatistics&mine=true&` + `key=${GOOGLE_API_KEY} HTTP/1.1`,
  GetYouTubeVideosFromChannel:
    `${youtubeBaseUrl}/channels?part=snippet%2CcontentDetails%2Cstatistics&mine=true&` + `key=${GOOGLE_API_KEY} HTTP/1.1`,
};
export const GOOGLE_GET_YOUTUBE_VIDEOS_FROM_CHANNEL = (ChannelId: string) =>
  `${youtubeBaseUrl}/playlistItems?playlistId=${ChannelId}&key=${GOOGLE_API_KEY}&part=snippet&mine=true&maxResults=50`;

export const PUT_JOURNAL_ENDPOINT = (journalId: string) => `${baseUrl}/Journal/${journalId}`;
export const PUT_USER_ENDPOINT = (userId: string) => `${baseUrl}/User/${userId}`;
export const DELETE_JOURNAL_ENDPOINT = (journalId: string) => `${baseUrl}/Journal/${journalId}`;
export const POST_JOURNAL_ENTRY_ENDPOINT = (journalId: string) => `${baseUrl}/Journal/${journalId}/JournalEntry`;
export const PUT_JOURNAL_ENTRY_ENDPOINT = (journalId: string, journalEntryId: string) =>
  `${baseUrl}/Journal/${journalId}/JournalEntry/${journalEntryId}`;
export const MOVE_JOURNAL_ENTRY_ENDPOINT = (journalId: string, journalEntryId: string) =>
  `${baseUrl}/Journal/${journalId}/JournalEntry/${journalEntryId}/Move`;
export const DELETE_ROUND_ENDPOINT = (roundId: string) => `${baseUrl}/Round/${roundId}`;
export const PUT_ROUND_ENDPOINT = (roundId: string) => `${baseUrl}/Round/${roundId}`;
export const DELETE_JOURNAL_ENTRY_ENDPOINT = (journalId: string, journalEntryId: string) =>
  `${baseUrl}/Journal/${journalId}/JournalEntry/${journalEntryId}`;

export const PUT_USER_TAG_ENDPOINT = (tagId: string) => `${baseUrl}/Tag/UserTag/${tagId}`;

export const DELETE_USER_TAG_ENDPOINT = (tagId: string) => `${baseUrl}/Tag/UserTag/${tagId}`;

export const POST_PROMOTION_ENDPOINT = (userProfileId: string) => `${baseUrl}/User/UserProfile/${userProfileId}/Promotion`;

export const DELETE_PROMOTION_ENDPOINT = (userProfileId: string, promotionId: string) =>
  `${baseUrl}/User/UserProfile/${userProfileId}/Promotion/${promotionId}`;

export const GET_ROUNDS_ENDPOINT = (rounds: string[]) => {
  const commaSeparatedListOfRounds = rounds.join(",");
  return `${baseUrl}/Round/Rounds/${commaSeparatedListOfRounds}`;
};

export const GET_ROUNDS_ANALYSIS_ENDPOINT = (roundId: string) => {
  return `${baseUrl}/Analyzer/RoundAnalysis/${roundId}`;
};
