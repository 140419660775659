import { AppState } from "../appState";
import { AnyAction } from "@reduxjs/toolkit";
import { ActionType } from "../actionType";
import { addLoading, removeLoading } from "../module";

export const setLoadingReducer = (state: AppState, action: AnyAction) => {
  const KEY_ACTION = ActionType.SetLoading;
  if (action.type !== KEY_ACTION) {
    return state;
  }
  return addLoading(state, action.meta);
};

export const unsetLoadingReducer = (state: AppState, action: AnyAction) => {
  const KEY_ACTION = ActionType.UnsetLoading;
  if (action.type !== KEY_ACTION) {
    return state;
  }
  return removeLoading(state, action.meta);
};
