/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import theme from '../Theme';
import { TagType } from '../Utils/types';
import { filterByCustomTag, filterTagsByState, tagCategoryOptions } from './tagModule';
import FilterChip from '../Utils/FilterChip';

const styles = {
	selected: (selected: boolean) => css`
		align-items: center;
		display: flex;
		margin: 10px 0;
		padding: 4px 8px 4px 3px;
		border-radius: 200px;
		color: ${selected ? '#ffffff' : theme.fontColor.text};
		background-color: ${selected ? theme.palette.primary.ultraLight : theme.colors.contentBackground};
		border: solid 1px ${selected ? theme.palette.primary.ultraLight : theme.colors.lightestGray};
		cursor: pointer;
		transition: background-color 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 100ms cubic-bezier(0.3, 0.7, 0.4, 1.5) 1s;
		gap: 5px;
		height: 25px;
		& span {
			font-size: 14px;
			user-select: none;
		}
	`,
	checked: (selected: boolean) => css`
		visibility: ${selected ? 'visible' : 'hidden'}; //todo this isn't working
		width: ${!selected && 0};
		opacity: ${selected ? 1 : 0};
		transition: opacity 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5) 1s;
	`,
	filterWrapper: css`
		display: flex;
		flex-direction: row;
		white-space: nowrap;

		width: 100%;
		flex-wrap: wrap;
		gap: 5px;
		padding: 8px;
	`,
};

interface Props {
	setFilteredTags: (tags: TagType[]) => void;
	filteredTags: TagType[];
	allTags: TagType[];
}

const TagFilterChips: React.FC<Props> = (props) => {
	const tagTypeCategories = tagCategoryOptions;
	const { filteredTags, setFilteredTags, allTags } = props;

	const [unfilteredState, setUnfilteredState] = useState(true);
	const [filteredState, setFilteredState] = useState(new Array(tagTypeCategories.length).fill(true));

	const [showCustomOnly, setShowCustomOnly] = useState(false);

	const filteredNumberRef = useRef('');

	const handleFilterTags = useCallback(
		(position: number) => {
			setUnfilteredState(false);
			const newFilteredState = filteredState.map((checked, index) => (index === position ? !checked : checked));
			setFilteredState(newFilteredState);
		},
		[filteredState]
	);

	const handleFilterCustomTags = useCallback(() => {
		setShowCustomOnly((prev) => !prev);
	}, []);

	const getFilterCount = useCallback(() => {
		let testFilterCount = 0;
		filteredState.forEach((state) => {
			if (state) testFilterCount = testFilterCount + 1;
		});
		return testFilterCount;
	}, [filteredState]);

	useEffect(() => {
		if (unfilteredState && filteredTags.length !== allTags.length && !showCustomOnly) {
			return setFilteredTags(allTags);
		}
		const tagsToFilter = filterByCustomTag(showCustomOnly, allTags);
		const { filteredString, newFilteredTags } = filterTagsByState(tagsToFilter, filteredState);
		if (filteredString !== filteredNumberRef.current) {
			filteredNumberRef.current = filteredString;
			setFilteredTags(newFilteredTags);
		}
	}, [setFilteredTags, filteredState, unfilteredState, filteredTags.length, getFilterCount, allTags, showCustomOnly]);

	return (
		<div css={styles.filterWrapper}>
			{tagTypeCategories.map((category, index) => (
				<FilterChip
					key={category.name}
					checked={filteredState[index]}
					handleChecked={() => handleFilterTags(index)}
					name={category.name}
				/>
			))}
			<FilterChip
				checked={showCustomOnly}
				handleChecked={handleFilterCustomTags}
				name={'Only Editable'}
			/>
		</div>
	);
};

export default TagFilterChips;
