import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {MOVE_JOURNAL_ENTRY_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {JournalEntryObject} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';


export interface MoveEntryActionProps {
  journalEntry: JournalEntryObject
  currentJournalId: string;
  newJournalId: string;
}

const KEY_ACTION = ActionType.MoveEntryRequest;
const ACTIONS = [
  ActionType.MoveEntryRequest,
  ActionType.MoveEntryResponse,
  ActionType.MoveEntryError,
];

export interface MoveEntryPayload extends Response {
  data?: null | {
    journalEntry?: null | JournalEntryObject
  }
}

export const moveEntryAction = (body: MoveEntryActionProps, token?: string) =>
  makeApiAction<MoveEntryPayload, MoveEntryActionProps>({
    method: 'PUT',
    endpoint: () => MOVE_JOURNAL_ENTRY_ENDPOINT(body.currentJournalId, body.journalEntry.journalEntryId),
    headers: () => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }),
    body: JSON.stringify({newJournalId: body.newJournalId}),
    types: makeRsaaTypes<MoveEntryPayload, MoveEntryActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<MoveEntryPayload, MoveEntryActionProps> = (state, action, errorMsg, isLoading ) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
 
  return {
    ...successState, userJournals: undefined,
  };
};

export const moveEntryReducer = makeApiReducer<MoveEntryPayload, MoveEntryActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to update entry.',
);

export const useMoveEntry = (
  currentJournalId: string,
  newJournalId: string,
  entry: JournalEntryObject,
): ApiHookReturn<() => void> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  
  
  const moveEntry = useCallback(() => {
    currentJournalId && newJournalId && entry && validateAndDispatch(moveEntryAction({
      currentJournalId,
      journalEntry: entry,
      newJournalId,
    }, token));
  }, [currentJournalId, validateAndDispatch, entry, newJournalId, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: moveEntry, reload: moveEntry,
  };
};
