/** @jsxImportSource @emotion/react */
import React, { useCallback, useRef, useState } from "react";
import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { usePostEntry } from "./Actions/postEntryAction";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import { ENTRY_TYPE, EntryFormState, JournalObject } from "../Utils/types";
import { DEFAULT_JOURNAL_INPUT } from "./Actions/journalDefaultsModule";
import theme from "../Theme";
import { hexToRGBA } from "../Utils/module";
import { createEditor } from "slate";
import { ReactEditor, withReact } from "slate-react";
import { RICH_TEXT_INITIAL_VALUE, withCorrectVoidBehavior, withImages, withInlines } from "../Utils/RichText/richTextModule";
import { withHistory } from "slate-history";
import GenericModal from "../modal/GenericModal";
import VideoNoteTaker from "./VideoNoteTaker/VideoNoteTaker";
import Loader from "../Utils/Loader";
import TextEntryViewer from "./EntryViewers/TextEntryViewer";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background};
    width: 100%;
    position: relative;
    height: 100%;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
    }
    padding: 40px 40px 20px 0;
  `,
  entryWrapper: css`
    width: 100%;
    align-self: center;
    padding: 32px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${theme.colors.contentBackground};
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
  `,
  backButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  `,
  inputWrapper: css`
    padding: 5px;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 60%;
    max-width: 400px;
  `,
  saveButton: (color: string) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: ${color};
    color: ${theme.colors.contentBackground};
    border-radius: 200px;
    padding: 5px 20px;
  `,
  header: (journalColor?: string) => css`
    background-color: ${journalColor ? journalColor : DEFAULT_JOURNAL_INPUT.color.hexCode};
    color: ${theme.colors.contentBackground};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
  `,
  headerTitle: css`
    align-items: center;
    display: flex;
    user-select: none;
  `,
  dateWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,
  datePicker: (color?: string) => css`
    padding: 5px;
    border-color: lightgray;
    box-shadow: none;
    border-width: 2px;
    border: none;
    width: 100%;
    border-radius: 6px;
    background-color: ${hexToRGBA(color || "white", 0.05)};
    color: ${color ? color : "black"};
    ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="${
    color ? "%23" + color.slice(1) : "%23bbbbbb"
  }" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
}

  `,
  headerEntry: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${theme.colors.background};
  `,
  input: css`
    width: 100%;
    .input-fieldset {
      border-radius: 6px;
    }
  `,
  tagViewer: css`
    width: 60%;
    display: flex;
    flex: 1;
  `,
  textEditor: (color: string = "lightgray") => css`
    border: solid 1px ${color};
    align-self: center;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
};

interface Props {
  journal: JournalObject;
  handleCreateCancel: () => void;
  entryType?: ENTRY_TYPE;
  videoUrl?: string;
  excludeFromCksInstructionals: boolean;
}

const EntryCreator: React.FC<Props> = ({
  journal,
  handleCreateCancel,
  entryType = ENTRY_TYPE.Text,
  videoUrl = "",
  excludeFromCksInstructionals,
}) => {
  const [showBlankTitleModal, setShowBlankTitleModal] = useState(false);
  const [entryFormState, setEntryFormState] = useState<EntryFormState>({
    editedNotes: [],
    editedVideoUrl: videoUrl,
    type: entryType,
    editedTitle: "",
    editedText: entryType === ENTRY_TYPE.Text ? JSON.stringify(RICH_TEXT_INITIAL_VALUE) : "",
    editedEntryDate: DateTime.now(),
    entry: {
      journalEntryId: "",
      title: "",
      text: "",
      createdDateTimeUtc: "",
      entryDateTimeUtc: "",
      type: entryType,
      notes: [],
      tags: [],
      videoUrl: "",
      excludeFromCksInstructionals: excludeFromCksInstructionals,
    },
    editedTagIds: [],
  });

  const editorRef = useRef<ReactEditor | null>(null);
  if (!editorRef.current)
    editorRef.current = withInlines(withCorrectVoidBehavior(withImages(withReact(withHistory(createEditor())))));

  const postEntry = usePostEntry({
    journalId: journal.id,
    text: entryFormState.editedText,
    entryDateTimeUtc: entryFormState.editedEntryDate.toUTC().toString(),
    title: entryFormState.editedTitle /*|| entryText.substring(0, 50)+"..."*/,
    tags: entryFormState.editedTagIds,
    type: entryFormState.type,
    videoUrl: entryFormState.editedVideoUrl,
    notes: entryFormState.editedNotes,
    excludeFromCksInstructionals,
  });

  const handleModalClose = useCallback(() => {
    setShowBlankTitleModal(false);
  }, []);

  const handleSave = useCallback(async () => {
    if (entryFormState.type === ENTRY_TYPE.Text) {
      if (entryFormState.editedText === JSON.stringify(RICH_TEXT_INITIAL_VALUE)) {
        return alert("Entry cannot be blank"); //todo make modal
      }
      if (
        entryFormState.editedText.length &&
        entryFormState.editedTitle.length &&
        entryFormState.editedEntryDate &&
        postEntry.value
      ) {
        await postEntry.value().then((res) => {
          if (!res?.error) {
            return handleCreateCancel();
          }
          alert("There was an error. Please try again.");
        });
      } else {
        setShowBlankTitleModal(true);
      }
    } else {
      if (!entryFormState.editedVideoUrl) {
        return alert("URL cannot be blank.");
      }
      if (!entryFormState.editedTitle) {
        return alert("Please add a title to this entry.");
      }
      if (!entryFormState.editedTagIds?.length) {
        return alert("Please add at least one tag.");
      }
      if (entryFormState.editedTitle.length && postEntry.value) {
        await postEntry.value().then((res) => {
          if (!res?.error) {
            return handleCreateCancel();
          }
          alert("There was an error. Please try again.");
        });
      }
    }
  }, [entryFormState, postEntry, handleCreateCancel]);

  return (
    <div css={styles.root}>
      {postEntry.loading && <Loader message={"Saving Entry"} />}
      {entryType === ENTRY_TYPE.Text && (
        <TextEntryViewer
          newEntry
          selectedJournal={journal}
          handleCreateCancel={handleCreateCancel}
        />
      )}
      {entryType === ENTRY_TYPE.Video && (
        <>
          <div css={styles.headerEntry}>
            <div
              onClick={handleCreateCancel}
              css={styles.backButton}
            >
              <ChevronLeftIcon />
              <span>Back</span>
            </div>
            <div
              onClick={handleSave}
              css={styles.saveButton(journal.color)}
            >
              <span>Save</span>
            </div>
          </div>
          <VideoNoteTaker
            entryFormState={entryFormState}
            setEntryFormState={setEntryFormState}
            color={journal.color}
          />
          {postEntry.errors?.length && postEntry.errors.map((err) => <span key={err}>{err}</span>)}
          {showBlankTitleModal && (
            <GenericModal
              onClose={handleModalClose}
              title={"Warning!"}
              onlyConfirmButton={true}
              confirmText={"Got it"}
              onConfirm={handleModalClose}
            >
              <span>Title cannot be blank</span>
            </GenericModal>
          )}
        </>
      )}
      {entryType === ENTRY_TYPE.SparAnalysis && (
        <>
          <div css={styles.headerEntry}>
            <div
              onClick={handleCreateCancel}
              css={styles.backButton}
            >
              <ChevronLeftIcon />
              <span>Back</span>
            </div>
            <div
              onClick={handleSave}
              css={styles.backButton}
            >
              <span>Save</span>
            </div>
          </div>
          {/*//TODO*/}
          {/*<SparAnalyzer entryFormState={entryFormState} setEntryFormState={setEntryFormState} color={journal.color}/>*/}
          {postEntry.errors?.length && postEntry.errors.map((err) => <span key={err}>{err}</span>)}
          {showBlankTitleModal && (
            <GenericModal
              onClose={handleModalClose}
              title={"Warning!"}
              onlyConfirmButton={true}
              confirmText={"Got it"}
              onConfirm={handleModalClose}
            >
              <span>Title cannot be blank</span>
            </GenericModal>
          )}
        </>
      )}
    </div>
  );
};
export default EntryCreator;
