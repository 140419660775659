import {AppState} from '../appState';
import {LocalStorageKey} from './localStorageKey';


export const getLocalStorageValue = <T>(key: LocalStorageKey): T | undefined => {
  try {
    const serialized = localStorage.getItem(key);
    
    if (serialized === null || serialized === undefined || serialized === 'undefined') {
      return undefined;
    }
    
    return JSON.parse(serialized) as T;
    
  } catch (ex) {
    console.error(`Failed to deserialize local storage value for key (${key}):`, ex);
    return undefined;
  }
};


/**
 * Loads global app state from local storage.
 */
export const loadLocalStorage = (): Partial<AppState> => ({
  auth: getLocalStorageValue('auth'),
});
