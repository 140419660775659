import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

/**
 * Hook that detects if a user has hovered over the html element
 * T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
 * use key prop to ensure ref updates if target component rerenders
 */
export const useHover = <T>(key?: any): [MutableRefObject<T>, boolean] => {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = useCallback((): void => setValue(true), [setValue]);
  const handleMouseOut = useCallback((): void => setValue(false), [setValue]);

  useEffect(() => {
    const node: any = ref.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver, key]);
  return [ref, value];
};

// How to use:
// const [hoverRef, isHovered] = useHover<HTMLDivElement>();
// return <div ref={hoverRef}>{isHovered ? yes : no}</div>;

export const useHoverOfRef = <T>(ref: MutableRefObject<T | null>): boolean => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [value, setValue] = useState<boolean>(false);

  const handleMouseOver = useCallback((): void => setValue(true), [setValue]);
  const handleMouseOut = useCallback((): void => setValue(false), [setValue]);
  const handleTouchStart = useCallback((): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setValue(false);
    }, 2000);
    setValue(true);
  }, [setValue]);
  const handleTouchEnd = useCallback((): void => setValue(false), [setValue]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (ref.current !== null) {
      const node: any = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        node.addEventListener("touchstart", handleTouchStart);
        // node.addEventListener("touchend", handleTouchEnd);
        // node.addEventListener("touchcancel", handleTouchEnd);
        // node.addEventListener("mouseDown", handleTouchStart);
        // node.addEventListener("mouseUp", handleTouchEnd);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
          node.removeEventListener("touchstart", handleTouchStart);
          // node.removeEventListener("touchend", handleTouchEnd);
          // node.addEventListener("touchcancel", handleTouchEnd);
          // node.addEventListener("mouseDown", handleTouchStart);
          // node.addEventListener("mouseUp", handleTouchEnd);
        };
      }
    }
  }, [handleMouseOut, handleMouseOver, handleTouchStart, handleTouchEnd, ref]);

  return value;
};
