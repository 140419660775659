import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {JournalObject} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

export interface PostActionProps {
  name: string;
  color: string;
  userId: string;
  description?: string;
}

const KEY_ACTION = ActionType.PostJournalRequest;
const ACTIONS = [
  ActionType.PostJournalRequest,
  ActionType.PostJournalResponse,
  ActionType.PostJournalError,
];

interface Payload extends Response {
  data?: null | {
    journal?: null | JournalObject
  }
}

export const postJournalAction = (body: PostActionProps, token?: string) => makeApiAction<Payload, PostActionProps>({
  method: 'POST',
  endpoint: () => ENDPOINTS.PostJournal,
  headers: () => ({
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body: JSON.stringify(body),
  types: makeRsaaTypes<Payload, PostActionProps>(ACTIONS, body),
  bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, PostActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.journal) {
    return addError(state, KEY_ACTION, ['POST journal failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState, userJournals: [...state.userJournals || [], payload.data.journal],
  };
};

export const postJournalReducer = makeApiReducer<Payload, PostActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to create journal.',
);

export const usePostJournal = (name: string, color: string, description?: string) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const userId = useAppState(state => state.auth?.id);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  
  const postJournal = useCallback(async () => await validateAndDispatch(postJournalAction({
    name,
    userId: userId || '',
    color,
    description,
  }, token)), [name, userId, color, description, validateAndDispatch, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: postJournal, reload: postJournal,
  };
};