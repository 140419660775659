/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useState } from "react";
import theme from "../Theme";
import { css } from "@emotion/react";
import MenuIcon from "../icons/MenuIcon";
import MenuOpenIcon from "../icons/MenuOpenIcon";
import Drawer from "./Drawer";
import AccountIcon from "../icons/AccountIcon";
import { Link } from "react-router-dom";
import { useAppState, useLoggedIn, useRetryCount } from "../store/hooks";
import CKSBG from "../Images/CKSBG.jpg";
import { ActionType } from "../store/actionType";

const styles = {
  root: css`
    justify-content: center;
    user-select: none;
    border-radius: 0 0 16px 16px;
    width: 100%;
    height: ${theme.toolbarHeight.heightMedium};
  `,
  headerWrapper: css`
    background-color: ${theme.colors.contentBackground};

    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    position: relative;
    gap: 20px;
    padding: 0;
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 12px;
    }
  `,
  title: css`
    color: ${theme.palette.toolbars.fontColorPrimary};
    display: flex;
    align-self: center;
    font-size: 15px;
    font-weight: 600;
    pointer-events: none;
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 15px;
      border-right: none;
      border-bottom: none;
    }
    border-right: solid 1px ${theme.colors.contentBackground}2b;
    height: ${theme.toolbarHeight.heightMedium};
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    width: 77px;
  `,
  iconWrapper: css`
    display: flex;
    background-color: ${theme.palette.toolbars.iconBackground};
    border-radius: 500px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  menuIcon: css`
    color: ${theme.palette.toolbars.fontColorPrimary};
    font-size: 30px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    &:hover {
      color: ${theme.palette.toolbars.fontColorSecondary};
    }
  `,
  settingsIcon: css`
    color: ${theme.palette.toolbars.fontColorPrimary};
    font-size: 30px;
    display: flex;
    justify-self: flex-end;
    padding: 5px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  logout: css`
    color: ${theme.palette.secondary.main};
    display: flex;
    justify-self: flex-end;
    padding: 5px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    background-color: ${theme.palette.primary.main};
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.primary.dark};
      color: ${theme.palette.secondary.dark};
    }
  `,
  logo: css`
    width: 40px;
    height: 40px;
  `,
  link: css`
    text-decoration: none;
  `,
  titleBlock: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 3px;
    margin-left: 24px;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
    }
  `,
  userInfo: css`
    margin-left: auto;
    padding-right: 24px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
  `,
  userName: css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.toolbars.fontColorPrimary};
  `,
  confirmButton: css`
    border: none;
    outline: none;
    border-radius: 12px;
    padding: 10px 15px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: white;
    background-color: ${theme.palette.primary.main};

    &:hover {
      color: white;
      background-color: ${theme.palette.primary.dark};
    }
  `,
};

interface Props {
  renderByDefault?: boolean;
  signIn?: boolean;
  setSignIn?: (value: boolean) => void;
  hideSignIn?: boolean;
}

const AppHeader: React.FC<Props> = ({ renderByDefault = false, signIn, setSignIn, hideSignIn }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const authToken = useAppState((state) => state.auth?.accessToken);
  const loggedIn = useLoggedIn();
  const user = useAppState((state) => state.user);
  const userError = useRetryCount(ActionType.GetUserRequest) >= 3;

  const toggleDrawer = useCallback(() => {
    setDrawerIsOpen(!drawerIsOpen);
  }, [drawerIsOpen]);

  const handleSetSignIn = useCallback(() => {
    setSignIn && setSignIn(!signIn);
  }, [setSignIn, signIn]);

  useEffect(() => {
    loggedIn && setShowHeader(true);
  }, [showHeader, authToken, loggedIn]);

  if ((authToken && showHeader) || renderByDefault) {
    return (
      <div css={styles.root}>
        <div css={styles.headerWrapper}>
          {loggedIn && (
            <div
              onClick={() => setDrawerIsOpen(true)}
              css={styles.iconWrapper}
            >
              {drawerIsOpen ? <MenuOpenIcon css={styles.menuIcon} /> : <MenuIcon css={styles.menuIcon} />}
            </div>
          )}
          <div css={styles.titleBlock}>
            <img
              css={styles.logo}
              alt="brand"
              src={CKSBG}
            />
            <span css={styles.title}> Champions Keep Score</span>
          </div>
          <div css={styles.userInfo}>
            {loggedIn && (
              <Link
                to={{ pathname: "/profile" }}
                css={css`
                  text-decoration: none;
                  margin-left: auto;
                  display: flex;
                  background-color: ${theme.palette.toolbars.iconBackground};
                  border-radius: 500px;
                  width: 40px;
                  height: 40px;
                  visibility: ${loggedIn ? "visible" : "hidden"};
                `}
              >
                <AccountIcon css={styles.settingsIcon} />
              </Link>
            )}
            {((!loggedIn && !hideSignIn) || userError) && (
              <button
                css={styles.confirmButton}
                onClick={handleSetSignIn}
              >
                {signIn ? "Cancel" : "Sign In"}
              </button>
            )}
            <span css={styles.userName}>{user?.name.firstName}</span>
          </div>
        </div>

        <Drawer
          toggleDrawer={toggleDrawer}
          open={drawerIsOpen}
        />
      </div>
    );
  }
  return null;
};
export default AppHeader;
