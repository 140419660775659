/** @jsxImportSource @emotion/react */ //include this in all jsx files
import { ReactEditor, RenderElementProps, RenderLeafProps, useFocused, useSelected, useSlateStatic } from "slate-react";
import { Descendant, Editor, Element as SlateElement, Location, Node, Path, Range, Transforms } from "slate";
import {
  CombinedElementTypes,
  Figure,
  LinkElement,
  LIST_TYPES,
  NewSlateElement,
  TEXT_ALIGN_TYPES,
  TextAlignTypes,
} from "./richTextTypesModule";
import React, { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import DeleteIcon from "../../icons/DeleteIcon";
import theme from "../../Theme";

// @ts-ignore
import { isImageUrl, isUrl } from "../../Journal/module";
import { nanoid } from "nanoid";
import FigCaption from "./FigCaption";

export const DEFAULT_CAPTION_TEXT = "Add a Caption...";
export const RICH_TEXT_INITIAL_VALUE: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const isValidJSONTest = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
};

export const toggleMark = (editor: ReactEditor, hotKey: string) => {
  const isActive = isMarkActive(editor, hotKey);

  if (isActive) {
    Editor.removeMark(editor, hotKey);
  } else {
    Editor.addMark(editor, hotKey, true);
  }
};

export const isBlockActive = (editor: ReactEditor, format: CombinedElementTypes, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType as keyof NewSlateElement] === format,
    })
  );
  return !!match;
};

export const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as keyof object] === true : false;
};

export const toggleBlock = (editor: ReactEditor, format: CombinedElementTypes) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: Node) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      !!n.type &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<NewSlateElement>;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : (format as TextAlignTypes),
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    css={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const RichTextElement = (props: Partial<RenderElementProps>) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element?.align };
  const styles = {
    figure: css`
      text-align: center;
      position: relative;
      // transform: translateX(-50%);
      // left: 50%;
    `,
  };
  const handlePaste = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  switch (element?.type) {
    case "block-quote":
      return (
        <blockquote
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul
          style={style}
          {...attributes}
        >
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1
          style={style}
          {...attributes}
        >
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2
          style={style}
          {...attributes}
        >
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li
          style={style}
          {...attributes}
        >
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol
          style={style}
          {...attributes}
        >
          {children}
        </ol>
      );
    case "image":
      return <Image {...props} />;
    case "figcaption":
      return <FigCaption {...props}>{children}</FigCaption>;
    case "figure":
      return (
        <figure
          draggable={true}
          onPaste={handlePaste}
          css={styles.figure}
          {...attributes}
        >
          {children}
        </figure>
      );
    case "link":
      return <LinkComponent {...props} />;
    default:
      return (
        <p
          style={style}
          {...attributes}
        >
          {children}
        </p>
      );
  }
};

//todo abstract this out
const Image = ({ attributes, children, element }: Partial<RenderElementProps>) => {
  const editor = useSlateStatic();
  const path = element && ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();
  const [clicked, setClicked] = useState(false);

  const handlePaste = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const handleClick = useCallback(() => {
    setClicked(true);
  }, []);

  useEffect(() => {
    if (selected && path) {
      Transforms.select(editor, Path.parent(path));
    }
  }, [selected, path, editor]);

  useEffect(() => {
    if (clicked && path) {
      Transforms.select(editor, Path.parent(path));
      setClicked((prev) => !prev);
    }
  }, [clicked, path, editor]);

  return (
    <div
      onPaste={handlePaste}
      {...attributes}
      contentEditable={false}
      draggable={false}
    >
      {children}
      <div
        draggable={false}
        onPaste={handlePaste}
        contentEditable={false}
        css={css`
          display: flex;
          justify-content: center;
          user-select: none;
        `}
      >
        <div
          draggable={false}
          onPaste={handlePaste}
          contentEditable={false}
          css={css`
            position: relative;
            display: flex;
            user-select: none;
            max-height: 400px;

            width: fit-content;
          `}
        >
          <img
            draggable={false}
            onMouseDown={handleClick}
            src={element?.url}
            alt={"User Uploaded"}
            css={css`
              object-fit: cover;
              display: flex;
              max-width: 100%;
              border: solid 1px lightgray;
              box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
              user-select: none;
            `}
          />
          <DeleteIcon
            onClick={() => Transforms.removeNodes(editor, { at: path && Path.parent(path) })}
            css={css`
              display: ${focused ? "inline" : "none"};
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 30px;
              color: red;
              background-color: white;
              border-radius: 200px;
              border: 1px solid red;
              user-select: none;
            `}
          />
        </div>
      </div>
    </div>
  );
};

const LinkComponent = (props: Partial<RenderElementProps>) => {
  const { attributes, children, element } = props;
  const styles = {
    link: css`
      text-decoration: none;
      font-weight: bold;
      position: relative;
      color: ${theme.fontColor.link};

      &:before,
      :after {
        content: "";
        position: absolute;
        width: 100%;
        pointer-events: none;
      }
      &:before {
        height: 0;
        border-top: 2px solid ${theme.fontColor.link};
        bottom: 0;
        left: 0;
        transition: height 0.3s ease;
      }

      &:after {
        height: 100%;
        border-bottom: 2px solid ${theme.fontColor.link};
        bottom: 0;
        left: 0;
        transition: transform 0.2s ease;
      }

      &:hover:before {
        height: 100%;
        transform: scale(1.02);
        transition: all 0.3s ease;
      }

      &:hover:after {
        transform: scale(1.02);
        transition: transform 0.4s ease;
      }
    `,
  };
  return (
    <a
      {...attributes}
      href={element?.url}
      target="_blank"
      css={styles.link}
      rel="noreferrer"
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};
export const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};
export const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};
const wrapLink = (editor: ReactEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};
const RichTextLeaf = ({ attributes, children, leaf }: Partial<RenderLeafProps>) => {
  if (leaf?.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf?.italic) {
    children = <em>{children}</em>;
  }
  if (leaf?.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

export const renderElement = (props: Partial<RenderElementProps>) => <RichTextElement {...props} />;
export const renderLeaf = (props: Partial<RenderLeafProps>) => <RichTextLeaf {...props} />;

export const insertImage = (editor: ReactEditor, url: string) => {
  if (editor.selection) {
    const id = nanoid();
    const figure: Figure = {
      type: "figure",
      children: [{ type: "image", url, id: id, children: [{ text: "" }] }],
    };
    const selectedNodePath: Location = Path.parent(editor.selection.anchor.path);
    const parentNode = Node.get(editor, Path.parent(editor.selection.anchor.path)) as NewSlateElement;
    const parentNodeType = parentNode.type;

    if (parentNodeType !== "figcaption") {
      const insertedNodePath = selectedNodePath[0] + 1;
      const insertedNode: Location = [insertedNodePath, 0];
      const figureNode: Descendant[] = [figure, RICH_TEXT_INITIAL_VALUE[0]];
      Transforms.insertNodes(editor, figureNode);
      Transforms.select(editor, insertedNode);
    }
  }
};
//plugin to ensure images are treated as void elements
export const withImages = (editor: ReactEditor) => {
  const { insertData, isVoid } = editor;
  editor.isVoid = (element) => (element.type === "image" ? true : isVoid(element));
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;
    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");
        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            typeof url === "string" && insertImage(editor, url);
          });
          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };
  return editor;
};
//plugin to allow inline links
export const withInlines = (editor: ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => (element.type && ["link"].includes(element.type)) || isInline(element);

  editor.insertText = (text) => {
    if (text && isImageUrl(text)) {
      insertImage(editor, text);
    }
    if (text && !isImageUrl(text) && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertLink = (editor: ReactEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

//plugin to allow a return keypress while selected on an image(for example) produces a new line below it.
export const withCorrectVoidBehavior = (editor: ReactEditor) => {
  const { deleteBackward, insertBreak } = editor;

  // if current selection is void node, insert a default node below
  editor.insertBreak = () => {
    if (!editor.selection /*|| !Range.isCollapsed(editor.selection)*/) {
      return insertBreak();
    }
    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    if (Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, {
        type: "paragraph",
        children: [{ text: "" }],
      });
      return;
    }
    insertBreak();
  };

  // if prev node is a void node, remove the current node and select the void node
  editor.deleteBackward = (unit) => {
    if (!editor.selection || editor.selection.anchor.offset !== 0) {
      return deleteBackward(unit);
    }

    const parentPath = Path.parent(editor.selection.anchor.path);
    const parentNode = Node.get(editor, parentPath);
    const parentIsEmpty = Node.string(parentNode).length === 0;

    if (parentIsEmpty && Path.hasPrevious(parentPath)) {
      const prevNodePath = Path.previous(parentPath);
      const prevNode = Node.get(editor, prevNodePath);
      if (Editor.isVoid(editor, prevNode)) {
        return Transforms.removeNodes(editor);
      }
    }
    deleteBackward(unit);
  };
  return editor;
};
