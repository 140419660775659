/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useGetTags } from './Actions/getTagsAction';
import TagList from './TagList';
import { TagType } from '../Utils/types';
import { searchFilteredTags } from '../Journal/module';
import theme from '../Theme';
import Searcher from '../Utils/Searcher';
import GenericModal from '../modal/GenericModal';
import PostUserTagModal from './PostUserTagModal';
import Loader from '../Utils/Loader';
import TagFilterChips from './TagFilterChips';
import { useLoading } from '../store/hooks';
import { ActionType } from '../store/actionType';
import FloatingActionButton from '../Utils/FloatingActionButton';

const styles = {
	root: css`
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
		height: calc(100vh - ${theme.toolbarHeight.heightMedium});
		padding: 40px 40px 0 0;
		@media (max-width: ${theme.breakpoints.md}px) {
			margin-left: 0;
			padding: 10px 40px;
		}
	`,
	layout: css`
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
		height: 100%;
		gap: 10px;
	`,
	body: css`
		overflow-y: auto;
		display: flex;
		width: 100%;
		height: 100%;
		margin-top: 10px;
	`,
	headerPill: css`
		display: flex;
		flex-direction: row;
		border-radius: 200px;
		border: solid 1px ${theme.palette.primary.main};
		overflow: hidden;
		align-items: center;
		margin: 10px;
		width: 330px;
	`,
	addWrapper: css`
		background-color: ${theme.palette.primary.main};
		display: flex;
		padding: 10px;
		height: 60px;
		width: 60px;
		justify-content: center;
		cursor: pointer;
	`,
	chipRowDesktop: css`
		display: flex;
		flex-direction: row;
		background-color: ${theme.colors.contentBackground};
		width: fit-content;
		border-radius: 500px;
		padding: 6px 14px;
		gap: 6px;
		min-height: 44px;
		align-items: center;

		// @media (max-width: ${theme.breakpoints.md}px) {
		// 	display: none;
		// }
	`,
	chipRowSmallScreen: css`
		display: none;
		flex-direction: row;
		background-color: ${theme.colors.contentBackground};
		width: fit-content;
		border-radius: 500px;
		padding: 6px 14px;
		gap: 6px;
		height: 44px;
		align-items: center;
		@media (max-width: ${theme.breakpoints.md}px) {
			display: flex;
		}
	`,
	headerRow: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 10px;
		gap: 8px;
		background-color: ${theme.colors.contentBackground};
		border-radius: 12px;
		flex-wrap: wrap;
		@media (min-width: ${theme.breakpoints.sm}px) {
			padding-left: 10px;
		}
	`,
};

const TagLibrary: React.FC = () => {
	const globalTagHook = useGetTags();
	const allTags = globalTagHook.value;
	const postTagState = useLoading(ActionType.PostUserTagRequest);
	const putTagLoading = useLoading(ActionType.PutUserTagRequest);
	const deleteTagLoading = useLoading(ActionType.DeleteUserTagRequest);

	const [filteredTags, setFilteredTags] = useState<TagType[]>(allTags || []);
	const [hasTags, setHasTags] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [searched, setSearched] = useState(false);
	const [tagsToRender, setTagsToRender] = useState<TagType[]>(allTags || []);
	const [showAddTagModal, setShowAddTagModal] = useState(false);

	const handleSearch = useCallback((searchTerm: string) => {
		setSearchTerm(searchTerm);
	}, []);

	const handleOpenTagModal = useCallback(() => setShowAddTagModal(true), []);
	const handleCloseTagModal = useCallback(() => setShowAddTagModal(false), []);

	useEffect(() => {
		//once tags load, sets it to state one time
		if (!hasTags && allTags?.length) {
			setFilteredTags(allTags);
			setTagsToRender(allTags);
			setHasTags(true);
		}
	}, [hasTags, allTags]);

	useEffect(() => {
		searchTerm ? setSearched(true) : setSearched(false);
		setTagsToRender(searchFilteredTags(searchTerm, filteredTags));
	}, [searchTerm, filteredTags]);

	return (
		<div css={styles.root}>
			{globalTagHook.loading && <Loader message={'Fetching tags...'} />}
			<div css={styles.layout}>
				<FloatingActionButton
					onClick={handleOpenTagModal}
					icon='add'
				/>

				<div css={styles.chipRowDesktop}>
					<TagFilterChips
						allTags={allTags || []}
						setFilteredTags={setFilteredTags}
						filteredTags={filteredTags}
					/>
				</div>
				<div css={styles.headerRow}>
					<Searcher
						placeholder={'Search Tags...'}
						onClick={handleSearch}
						showIcon={false}
					/>
				</div>
				<div css={styles.body}>
					{allTags && (
						<TagList
							tagsToRender={searched ? tagsToRender : filteredTags}
							setFilteredTags={setFilteredTags}
						/>
					)}
					{showAddTagModal && (
						<GenericModal
							noButtons={true}
							confirmText={'Create New Tag'}
							title={'Add New Tag'}
							onClose={handleCloseTagModal}
						>
							<PostUserTagModal
								handleClose={handleCloseTagModal}
								tagName={''}
							/>
						</GenericModal>
					)}
					{postTagState && <Loader message={'Saving Tag...'} />}
					{putTagLoading && <Loader message={'Updating Tag...'} />}
					{deleteTagLoading && <Loader message={'Deleting Tag...'} />}
				</div>
			</div>
		</div>
	);
};
export default TagLibrary;
