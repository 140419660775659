/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useMemo } from "react";
import theme from "../Theme";
import { css } from "@emotion/react";
import EditIcon from "../icons/EditIcon";
import { JournalObject } from "../Utils/types";
import { DateTime, Interval } from "luxon";

const styles = {
  editIcon: css`
    color: ${theme.colors.contentBackground};
    align-self: center;
    font-size: 20px;
  `,
  editButton: (journalColor?: string) => css`
    background-color: ${journalColor || "#000000"};
    align-self: center;
    display: flex;
    flex-direction: row;
    border-radius: 200px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.contentBackground};
    font-size: 14px;
    font-weight: 600;
    gap: 2px;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
    @media (max-width: ${theme.breakpoints.sm}px) {
      align-self: flex-end;
    }
  `,

  noteAddIcon: (journalColor?: string) => css`
    color: ${journalColor ? journalColor : theme.palette.primary.main};
  `,
  addEntryBoxWrapper: css`
    display: flex;
    background-color: ${theme.colors.contentBackground};
    justify-content: flex-start;
    padding: 5px;
  `,
  journalName: (journalColor?: string) => css`
    color: ${journalColor || "#000000"};
    font-weight: 600;
    font-size: 20px;
  `,
  journalDescription: css`
    display: -webkit-box;
    line-break: anywhere;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: ${theme.fontColor.text};
  `,
  titleBlock: css`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 33%;
    @media (max-width: ${theme.breakpoints.sm}px) {
      max-width: 100%;
    }
  `,
  divider: css`
    display: flex;
    flex: 1;
    position: relative;
    @media (max-width: ${theme.breakpoints.sm}px) {
      display: none;
    }
    :after {
      content: "";
      background-color: ${theme.colors.border};
      position: absolute;
      width: 1px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      display: block;
    }
  `,
  journalSummaryWrapper: css`
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    width: 100%;
    background-color: ${theme.colors.contentBackground};
    border-radius: 12px;
    justify-content: flex-start;

    align-items: center;
    padding: 32px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      flex-direction: column;
      padding: 10px;
    }
  `,
  actionPanel: css`
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: ${theme.breakpoints.sm}px) {
      flex-direction: column;
    }
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  statWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
    padding: 5px;
    gap: 40px;
    max-width: 425px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      display: none;
    }
  `,
  statBox: css`
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    justify-content: center;
    gap: 6px;
    white-space: nowrap;
    color: ${theme.fontColor.text};
  `,
  statValue: (journalColor?: string) => css`
    color: ${journalColor || "#000000"};
    font-weight: 600;
    font-size: 20px;
  `,
  stat: css`
    font-size: 14px;
  `,
  link: css`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
  `,
};

interface Props {
  handleShowEditForm: () => void;
  journal: JournalObject;
}

const JournalViewerSummary: React.FC<Props> = (props) => {
  const { handleShowEditForm, journal } = props;

  const journalDayCount = useMemo(
    () => Math.floor(Interval.fromDateTimes(DateTime.fromISO(journal.createdDateTimeUtc), DateTime.now()).length("days")),
    [journal]
  );

  const daysSinceLast = useCallback(() => {
    let latestEntry: string = "";
    journal.entries.forEach((entry) => {
      const currentDate = DateTime.fromISO(entry.createdDateTimeUtc).startOf("day");
      const testDate = DateTime.fromISO(latestEntry).startOf("day");
      if (!latestEntry) {
        latestEntry = entry.createdDateTimeUtc;
      } else {
        if (currentDate > testDate) {
          latestEntry = entry.createdDateTimeUtc;
        }
      }
    });
    return latestEntry.length && Math.floor(Interval.fromDateTimes(DateTime.fromISO(latestEntry), DateTime.now()).length("days"));
  }, [journal.entries]);

  const renderSummary = useCallback(
    () => (
      <div css={styles.actionPanel}>
        <div css={styles.titleBlock}>
          <span css={styles.journalName(journal?.color)}>{journal?.name}</span>
          <span css={styles.journalDescription}>{journal?.description || ""}</span>
        </div>
        <div css={styles.divider} />
        <div css={styles.statWrapper}>
          <div css={styles.statBox}>
            <span css={styles.statValue(journal?.color)}>{journal.entries.length}</span>
            <span css={styles.stat}>Entries</span>
          </div>
          <div css={styles.statBox}>
            <span css={styles.statValue(journal?.color)}>{journalDayCount}</span>
            <span css={styles.stat}>Days Active</span>
          </div>
          <div css={styles.statBox}>
            <span css={styles.statValue(journal?.color)}>{daysSinceLast()}</span>
            <span css={styles.stat}>Days Since Last Entry</span>
          </div>
        </div>
        <div css={styles.divider} />
        <div
          onClick={handleShowEditForm}
          css={styles.editButton(journal?.color)}
        >
          <span>Edit Journal</span>
          <EditIcon css={styles.editIcon} />
        </div>
      </div>
    ),
    [handleShowEditForm, journal, journalDayCount, daysSinceLast]
  );

  return (
    <div css={styles.journalSummaryWrapper}>
      <div css={styles.flexColumn}>{renderSummary()}</div>
    </div>
  );
};

export default JournalViewerSummary;
