import {KeyboardEvent} from 'react';

export const mediaBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};


/*https://www.joshwcomeau.com/snippets/javascript/debounce/*/
export const debounce = (callback: Function, wait: number) => {
  let timeoutId: number | undefined = undefined;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

//TODO keyboard actions to become separate module
export const isEnterPressed = (event: KeyboardEvent) => (
  event.key === 'Enter'
);

export const isBackspacePressed = (event: KeyboardEvent) => (
  event.key === 'Backspace'
);

export const isUpArrowPressed = (event: KeyboardEvent) => (
  event.key === 'ArrowUp'
);

export const isDownArrowPressed = (event: KeyboardEvent) => (
  event.key === 'ArrowDown'
);

//functions for converting hex colors and alpha to RGBA
//https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
const getChunksFromString = (st: string, chunkSize: number) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));
const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16);
const getAlphaFloat = (a: number, alpha: string | number) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if ((typeof alpha != 'number') || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};
export const hexToRGBA = (hex: string, alpha: string | number) => {
  if (!isValidHex(hex)) {
    throw new Error('Invalid HEX');
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr?.map(convertHexUnitTo256) || [];
  return `rgba(${r}, ${g}, ${b}, ${getAlphaFloat(a, alpha)})`;
};

