/** @jsxImportSource @emotion/react */  //include this in all jsx files
import {css} from '@emotion/react';
import CKSIcon from './CKSIcon';
import React from 'react';
import {IconTypes} from '../Utils/types';

const styles = {
  icon: css`
    color: black;
  `,
};

const CheckIcon: React.FC<IconTypes> = props => (
  <CKSIcon
    variant={props.variant}
    onClick={props.onClick}
    icon="check"
    className={props.className + ' ' + styles.icon}
  />
);

export default CheckIcon;