import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { ENDPOINTS } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useCallback, useEffect } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { JournalObject } from "../../Utils/types";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";

const KEY_ACTION = ActionType.GetJournalsRequest;

const ACTIONS = [ActionType.GetJournalsRequest, ActionType.GetJournalsResponse, ActionType.GetJournalsError];

interface Payload extends Response {
  data?: null | {
    journals?: JournalObject[];
  };
}

export const getJournalAction = (userId: string) =>
  makeApiAction<Payload>({
    method: "GET",
    endpoint: () => ENDPOINTS.GetUserJournals + `/${userId}`,
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.auth?.accessToken}`,
      "content-type": "application/json",
    }),
    types: makeRsaaTypes<Payload>(ACTIONS),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ["get journals failure"]);
  }

  const successState: AppState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    userJournals: payload.data.journals,
  };
};

export const getJournalsReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to fetch journals. Please try again."
);

export const useJournals = (): ApiHookReturn<JournalObject[]> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const authId = useAppState((state) => state.auth?.id);
  const getJournals = useCallback(
    async () => await validateAndDispatch(getJournalAction(authId || "")),
    [validateAndDispatch, authId]
  );

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  const journals = useAppState((state) => state.userJournals);

  useEffect(() => {
    if (!journals && !loading && !errors) {
      getJournals().catch((error) => console.error(error));
    }
  }, [loading, errors, journals, getJournals]);

  return {
    loading,
    errors,
    value: journals,
    reload: getJournals,
  };
};
