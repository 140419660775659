import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from "../../store/module";
import { useCallback } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { DELETE_JOURNAL_ENTRY_ENDPOINT } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { deleteEntriesFromJournals } from "./journalActionsModule";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";

export interface DeleteActionProps {
  journalId: string;
  journalEntryId: string;
}

const KEY_ACTION = ActionType.DeleteEntryRequest;
const ACTIONS = [ActionType.DeleteEntryRequest, ActionType.DeleteEntryResponse, ActionType.DeleteEntryError];

export interface DeleteEntryPayload extends Response {}

export const deleteEntryAction = (body: DeleteActionProps, token?: string) =>
  makeApiAction<DeleteEntryPayload, DeleteActionProps>({
    method: "DELETE",
    endpoint: () => DELETE_JOURNAL_ENTRY_ENDPOINT(body.journalId, body.journalEntryId),
    headers: () => ({
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<DeleteEntryPayload, DeleteActionProps>(ACTIONS, body),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<DeleteEntryPayload, DeleteActionProps> = (state, action, errorMsg, isLoading) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  const successState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    userJournals: deleteEntriesFromJournals(action.meta.journalEntryId, action.meta.journalId, state),
  };
};

export const deleteEntryReducer = makeApiReducer<DeleteEntryPayload, DeleteActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to delete journal."
);

export const useDeleteEntry = (journalId: string, journalEntryId: string) => {
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const deleteEntry = useCallback(
    async () => await validateAndDispatch(deleteEntryAction({ journalId, journalEntryId }, token)),
    [journalId, validateAndDispatch, token, journalEntryId]
  );

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);

  return {
    loading,
    errors,
    value: deleteEntry,
    reload: deleteEntry,
  };
};
