export const CLIENT_ID = '873284035202-vvs6268ojnv26c99sg9fc1qn9u1s0oge.apps.googleusercontent.com';
export const GOOGLE_API_KEY = 'AIzaSyAcMGKEzkIZ6lRCyTHLQSDsciu-FT1iPhA';

declare global {
  interface Window {
    gapi?: any;
  }
}
type etag = string;

export interface GoogleState {
  youTube: {
    channelList?: YouTubeChannelListResponse,
    videoUpload?: any,
    videoList?: YouTubeVideoListResponse,
  }
}

export interface ErrorInfo {
  message: string;
  domain: string;
  reason: string;
  location: string;
  locationType: string;
}

export interface Error {
  code: number;
  message: string;
  errors: ErrorInfo[];
  status: string;
}

export interface Result {
  error: Error;
}

export interface Headers {
  'cache-control': string;
  'content-encoding': string;
  'content-length': string;
  'content-type': string;
  date: string;
  server: string;
  vary: string;
  'www-authenticate'?: string;
}

export interface GoogleErrorResponse {
  result: Result;
  body: string;
  headers: Headers;
  status: number;
  statusText?: any;
}


export interface YouTubeChannelResource {
  kind: 'youtube#channel',
  etag: etag,
  id: string,
  snippet: {
    title: string,
    description: string,
    customUrl: string,
    publishedAt: string,
    thumbnails: {
      [key: string]: {
        url: string,
        width: string,
        height: string
      }
    },
    defaultLanguage?: string,
    localized: {
      title: string,
      description: string
    },
    country?: string
  },
  contentDetails: {
    relatedPlaylists: {
      likes: string,
      favorites: string,
      uploads: string
    }
  },
  statistics: {
    viewCount: number,
    subscriberCount: number,  // this value is rounded to three significant figures
    hiddenSubscriberCount: boolean,
    videoCount: number
  },
  topicDetails?: {
    topicIds: [
      string
    ],
    topicCategories: [
      string
    ]
  },
  status?: {
    privacyStatus: string,
    isLinked: boolean,
    longUploadsStatus: string,
    madeForKids: boolean,
    selfDeclaredMadeForKids: boolean
  },
  brandingSettings?: {
    channel: {
      title: string,
      description: string,
      keywords: string,
      trackingAnalyticsAccountId: string,
      moderateComments: boolean,
      unsubscribedTrailer: string,
      defaultLanguage: string,
      country: string
    },
    watch?: {
      textColor: string,
      backgroundColor: string,
      featuredPlaylistId: string
    }
  },
  auditDetails?: {
    overallGoodStanding: boolean,
    communityGuidelinesGoodStanding: boolean,
    copyrightStrikesGoodStanding: boolean,
    contentIdClaimsGoodStanding: boolean
  },
  contentOwnerDetails?: {
    contentOwner: string,
    timeLinked: string
  },
  localizations?: {
    [key: string]: {
      title: string,
      description: string
    }
  }
}

enum YouTubeThumbnailKeys {
  DEFAULT = 'default',
  MEDIUM = 'medium',
  HIGH = 'high',
  STANDARD = 'standard',
}

export type YouTubeThumbnailArray = {
  url: string,
  width: string,
  height: string
}
export type YouTubeThumbnail = {
  [key in YouTubeThumbnailKeys]: YouTubeThumbnailArray
}

//
// export type YouTubeThumbnailKeys = 'default' | 'medium' | 'high' | 'standard'


export interface playlistResource {
  kind: 'youtube#playlist',
  etag: etag,
  id: string,
  snippet: {
    publishedAt: string,
    channelId: string,
    title: string,
    description: string,
    thumbnails: YouTubeThumbnail,
    channelTitle: string,
    defaultLanguage: string,
    resourceId: { kind: string, videoId: string },
    localized: {
      title: string,
      description: string
    }
  },
  status: {
    privacyStatus: string
  },
  contentDetails: {
    itemCount: number
  },
  player: {
    embedHtml: string
  },
  localizations: {
    (key: string): {
      title: string,
      description: string
    }
  }
  
}

export interface YouTubeChannelListResponse {
  kind: 'youtube#channelListResponse',
  etag: etag,
  nextPageToken?: string,
  prevPageToken?: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number,
  },
  items: YouTubeChannelResource[]
}

export interface YouTubeVideoListResponse {
  kind: 'youtube#playlistListResponse',
  etag: etag,
  nextPageToken: string,
  prevPageToken: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: playlistResource[]
}
