/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import theme from "../Theme";
import CheckCircleIcon from "../icons/CheckCircleIcon";
import NotCheckCircleIcon from "../icons/NotCheckCircleIcon";

const styles = {
  selected: (selected: boolean) => css`
    align-items: center;
    display: flex;
    padding: 4px 8px 4px 8px;
    border-radius: 200px;
    color: ${selected ? "#ffffff" : theme.fontColor.text};
    background-color: ${selected ? theme.palette.primary.main : theme.colors.contentBackground};
    cursor: pointer;
    transition: background-color 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5) 1s;
    gap: 5px;
    height: 32px;
    width: 130px;
    font-size: 14px;
    user-select: none;
    font-weight: ${selected ? 600 : 400};
    :hover {
      background-color: ${selected ? theme.palette.primary.dark : theme.colors.border};
    }
    @media (max-width: ${theme.breakpoints.sm}px) {
      font-size: 12px;
      width: unset;
    }
  `,
  tagRow: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  `,

  filterWrapper: css`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    height: fit-content;
  `,
  checkedIcon: css`
    font-size: 16px;
  `,
};

interface Props {
  checked: boolean;
  handleChecked: () => void;
  name: string;
}

const FilterChip: React.FC<Props> = (props) => {
  const { checked, handleChecked, name } = props;

  return (
    <div css={styles.filterWrapper}>
      <div
        css={styles.selected(checked)}
        onClick={() => handleChecked()}
      >
        {checked ? <CheckCircleIcon css={styles.checkedIcon} /> : <NotCheckCircleIcon css={styles.checkedIcon} />}
        <span>{name}</span>
      </div>
    </div>
  );
};

export default FilterChip;
