/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useMemo, useState } from "react";
import theme from "../Theme";
import { css } from "@emotion/react";
import Chip from "../Utils/Chip";
import { EntryFormState, TagType } from "../Utils/types";
import { useGetTags } from "./Actions/getTagsAction";
import TagAutofill from "./TagAutofill";
import { getTagNameFromId, renderTags } from "../Journal/module";
import Loader from "../Utils/Loader";
import { AutocompleteOptionType, DEFAULT_ADD_OPTION } from "../Utils/Autocomplete";
import GenericModal from "../modal/GenericModal";
import PostUserTagModal from "./PostUserTagModal";
import { useAppState } from "../store/hooks";
import { useHistory } from "react-router-dom";

const styles = {
  labelWrapper: (editPost: boolean) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    @media (min-width: ${theme.breakpoints.sm}px) {
      flex-direction: row;
    }
  `,
  chip: css`
    margin: 5px;
    text-transform: capitalize;
  `,
  inputRoot: css`
    padding: 2px 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
    // @media (min-width: ${theme.breakpoints.sm}px) {
    // 	flex-direction: row;
    // }
  `,
  chipColor: (color?: string) => css`
    & div {
      border-color: ${color ? color : theme.colors.label};
      & span {
        color: ${color ? color : theme.colors.label};
        white-space: nowrap;
      }
      ,
      & svg {
        color: "black";
      }
    }
  `,
  noLabels: css`
    color: ${theme.colors.mediumGray};
    width: 100%;
    text-align: left;
    padding-left: 10px;
  `,
  tagWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px ${theme.colors.border};
  `,
  tagWrapperNoEdit: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  `,
  autofill: css`
    min-width: 300px;
  `,
};

interface Props {
  editPost: boolean;
  setEntryFormState: (formState: EntryFormState) => void;
  entryFormState: EntryFormState;
  journalColor?: string;
  className?: string;
}

const TagViewer: React.FC<Props> = (props) => {
  const { editPost, setEntryFormState, entryFormState, journalColor } = props;
  const [value, setValue] = useState<string | undefined>("");
  const [tagsHaveLoaded, setTagsHaveLoaded] = useState(false);
  const [entryStateHasLoaded, setEntryStateHasLoaded] = useState(false);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [newUserTags, setNewUserTags] = useState<string[]>([]);
  const history = useHistory();

  const tagState = useAppState((state) => state.tags);

  const globalTagHook = useGetTags();
  const globalTags = useMemo(() => tagState || globalTagHook.value || [], [globalTagHook, tagState]);

  const handleTagDelete = useCallback(
    (labelToDelete: string) => () => {
      const tagList = entryFormState.editedTagIds.filter((label) => label !== labelToDelete);
      setEntryFormState({ ...entryFormState, editedTagIds: tagList });
    },
    [setEntryFormState, entryFormState]
  );

  const handleChange = useCallback(
    (input: string | undefined) => {
      input ? setValue(input) : setValue("");
    },
    [setValue]
  );

  const addNewLabel = useCallback(
    (newLabel: string) => {
      const updatedLabelArray = entryFormState.editedTagIds ? [...entryFormState.editedTagIds, newLabel] : [newLabel];
      const duplicateItems =
        entryFormState.editedTagIds &&
        [...entryFormState.editedTagIds].some((oldLabel) => oldLabel.toUpperCase() === newLabel.toUpperCase());
      !duplicateItems && !!newLabel && setEntryFormState({ ...entryFormState, editedTagIds: updatedLabelArray });
    },
    [entryFormState, setEntryFormState]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      value && addNewLabel(value);
      setValue("");
    },
    [addNewLabel, value]
  );

  const handleClick = useCallback(
    (event: any) => {
      const id = event.target.id;
      if (id && id === DEFAULT_ADD_OPTION) {
        setShowAddTagModal(true);
      } else {
        addNewLabel(id);
        setValue("");
      }
    },
    [addNewLabel, setValue]
  );

  const handleEnterPress = useCallback(
    (option: AutocompleteOptionType) => {
      const tag = option?.option as TagType;
      if (!option.userInput) {
        addNewLabel(tag.id);
        setValue("");
      } else {
        setShowAddTagModal(true);
      }
    },
    [addNewLabel]
  );

  const handleClose = useCallback(() => setShowAddTagModal(false), []);

  const editedTags = entryFormState.editedTagIds;
  const savedTags = entryFormState.entry.tags;

  const renderEntryTags = useCallback(
    (tagList: string[] | undefined, editPost: boolean) => {
      if (tagList) {
        return (
          <>
            {!tagList || tagList.length === 0 ? (
              <div css={styles.noLabels}>
                <span css={styles.noLabels}>(Entry has no tags)</span>
              </div>
            ) : (
              <div css={editPost ? styles.tagWrapper : styles.tagWrapperNoEdit}>
                {editPost
                  ? tagList.map((tag: string) => (
                      <div key={tag}>
                        <li css={styles.chipColor(journalColor)}>
                          <Chip
                            label={getTagNameFromId(tag, globalTags)}
                            css={styles.chip}
                            onDelete={handleTagDelete(tag)}
                          />
                        </li>
                      </div>
                    ))
                  : renderTags(entryFormState.entry, globalTags, journalColor || theme.fontColor.text, true, history)}
              </div>
            )}
          </>
        );
      }
    },
    [entryFormState.entry, globalTags, handleTagDelete, journalColor, history]
  );

  useEffect(() => {
    !tagsHaveLoaded && globalTags.length > 1 && setTagsHaveLoaded(true);
  }, [globalTags, tagsHaveLoaded, entryFormState]);

  useEffect(() => {
    //once entry loads, sets it to state one time
    if (!entryStateHasLoaded && entryFormState.entry) {
      setEntryStateHasLoaded(true);
      setEntryFormState({ ...entryFormState, editedTagIds: entryFormState.editedTagIds || entryFormState.entry.tags || [] });
    }
  }, [entryFormState, entryStateHasLoaded, setEntryFormState]);

  //Once postUserTag call is successful, adds the tag to the entry
  useEffect(() => {
    if (tagState && newUserTags.length) {
      const remainingTags: string[] = [];
      newUserTags.forEach((userTag) => {
        const foundTag = tagState.find((tag) => tag.name === userTag);
        foundTag ? addNewLabel(foundTag.id) : remainingTags.push(userTag);
      });
      remainingTags.length !== newUserTags.length && setNewUserTags(remainingTags);
    }
  }, [tagState, newUserTags, addNewLabel]);

  if (!entryStateHasLoaded) return <Loader message={"loading"} />;
  return (
    <div
      className={props.className}
      css={styles.labelWrapper(editPost)}
    >
      {!editPost && renderEntryTags(savedTags, editPost)}
      {editPost && (
        <form
          css={styles.inputRoot}
          onSubmit={handleSubmit}
        >
          <TagAutofill
            css={styles.autofill}
            onEnterPress={handleEnterPress}
            onClick={handleClick}
            value={value}
            showBorder={true}
            onChange={handleChange}
          />
          {editPost ? renderEntryTags(editedTags, editPost) : renderEntryTags(savedTags, editPost)}
        </form>
      )}

      {editPost && showAddTagModal && (
        <GenericModal
          noButtons={true}
          confirmText={"Create New Tag"}
          title={"Add New Tag"}
          onClose={() => setShowAddTagModal(false)}
        >
          <PostUserTagModal
            handleClose={handleClose}
            tagName={value || ""}
          />
        </GenericModal>
      )}
    </div>
  );
};

export default TagViewer;
