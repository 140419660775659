/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useState} from 'react';
import GenericModal from '../modal/GenericModal';
import {AutocompleteOptionType, DEFAULT_ADD_OPTION} from '../Utils/Autocomplete';
import TagAutofill from '../Tags/TagAutofill';
import {TagType} from '../Utils/types';
import {useGetTags} from '../Tags/Actions/getTagsAction';
import {useLoading} from '../store/hooks';
import {ActionType} from '../store/actionType';
import Loader from '../Utils/Loader';
import {
  Dominance,
  initiatorEnumAsArray,
  MoveInitiator,
  renderSetPosition,
  DraftSparActions,
  DraftSparState,
} from './module';
import {modalStyles} from './VideoModalStyles';


interface Props {
  handleSetSubmissionModalClose: () => void,
  handleSubmissionChange: () => void,
  dispatch: React.Dispatch<DraftSparActions>,
  newSubmissionTagValue?: string,
  newPositionalTagValue?: string,
  state: DraftSparState,
}

const SetSubmissionModal: React.FC<Props> = (
  {
    handleSetSubmissionModalClose,
    handleSubmissionChange,
    newSubmissionTagValue,
    newPositionalTagValue,
    dispatch,
    state,
  }) => {
  
  const [autofillSubmissionValue, setAutofillSubmissionValue] = useState<string | undefined>('');
  const [autofillPositionValue, setAutofillPositionValue] = useState<string | undefined>('');
  
  const postTagIsLoading = useLoading(ActionType.PostUserTagRequest);
  
  const {value: tags} = useGetTags();
  
  const handlePositionAutofillChange = useCallback((position: string | undefined) => {
    setAutofillPositionValue(position);
    dispatch({type: 'setState', data: {selectedPositionalTagValue: position, selectedPositionalTagId: undefined}});
  }, [dispatch]);
  
  const handleSubmissionAutofillChange = useCallback((submission: string | undefined) => {
    setAutofillSubmissionValue(submission);
    dispatch({type: 'setState', data: {selectedSubmissionTagValue: submission, selectedSubmissionTagId: undefined}});
  }, [dispatch]);
  
  const handleSubmissionClick = useCallback((event: any) => {
    const id = event.target.id;
    if (id && id === DEFAULT_ADD_OPTION) {
      dispatch({type: 'setState', data: {showSubmissionAddTagModal: true}});
    } else {
      const tagName = tags?.find(tag => tag.id === id)?.name;
      dispatch({type: 'setState', data: {selectedSubmissionTagId: id, selectedSubmissionTagValue: tagName}});
      setAutofillSubmissionValue(tagName);
    }
  }, [tags, dispatch]);
  
  const handleSubmissionEnterPress = useCallback((option: AutocompleteOptionType) => {
    const tag = option?.option as TagType;
    if (!option.userInput) {
      dispatch({type: 'setState', data: {selectedSubmissionTagId: tag.id, selectedSubmissionTagValue: tag.name}});
      setAutofillSubmissionValue(tag.name);
    } else {
      dispatch({type: 'setState', data: {showSubmissionAddTagModal: true}});
      
    }
  }, [dispatch]);
  
  
  const handleSelectInitiatedBy = useCallback((option: MoveInitiator) => {
    dispatch({type: 'setState', data: {selectedSubmissionInitiatedBy: option}});
  }, [dispatch]);
  
  const handleSelectDominance = useCallback((option: Dominance) => {
    dispatch({type: 'setState', data: {selectedPositionalDominance: option}});
  }, [dispatch]);
  
  const handleSelectSubmissionSuccess = useCallback((option: boolean) => {
    dispatch({type: 'setState', data: {selectedSubmissionSuccess: option}});
  }, [dispatch]);
  
  const handlePositionClick = useCallback((event: any) => {
    const id = event.target.id;
    if (id && id === DEFAULT_ADD_OPTION) {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
    } else {
      const tagName = tags?.find(tag => tag.id === id)?.name;
      dispatch({type: 'setState', data: {selectedPositionalTagId: id, selectedPositionalTagValue: tagName}});
      setAutofillPositionValue(tagName);
    }
  }, [tags, dispatch]);
  
  const handlePositionEnterPress = useCallback((option: AutocompleteOptionType) => {
    const tag = option?.option as TagType;
    if (!option.userInput) {
      dispatch({type: 'setState', data: {selectedPositionalTagId: tag.id, selectedPositionalTagValue: tag.name}});
      setAutofillPositionValue(tag.name);
    } else {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
    }
  }, [dispatch]);
  const getIsDisabled = () => {
    let isDisabled = false;
    if (state.selectedSubmissionInitiatedBy === undefined || state.selectedSubmissionInitiatedBy === null) {
      isDisabled = true;
    }
    if (!state.selectedSubmissionTagId) {
      isDisabled = true;
    }
    if (state.selectedSubmissionSuccess === undefined) {
      isDisabled = true;
    }
    if (state.selectedSubmissionSuccess === false) {
      if (state.selectedPositionalDominance === undefined) {
        isDisabled = true;
      }
      if (state.selectedPositionalDominance !== Dominance.neutral) {
        if (!state.selectedPositionalTagId) {
          isDisabled = true;
        }
      }
    }
    return isDisabled;
  };
  
  useEffect(() => {
    const currentSubmissionTag = tags?.find(tag => tag.name.toLocaleLowerCase() === autofillSubmissionValue?.toLocaleLowerCase());
    if (currentSubmissionTag) {
      return dispatch({type: 'setState', data: {selectedSubmissionTagId: currentSubmissionTag.id}});
    }
    return dispatch({type: 'setState', data: {selectedSubmissionTagId: ''}});
  }, [tags, autofillSubmissionValue, dispatch]);
  
  useEffect(() => {
    const currentPositionTag = tags?.find(tag => tag.name.toLocaleLowerCase() === autofillPositionValue?.toLocaleLowerCase());
    if (currentPositionTag) {
      return dispatch({type: 'setState', data: {selectedPositionalTagId: currentPositionTag.id}});
    }
    return dispatch({type: 'setState', data: {selectedPositionalTagId: ''}});
  }, [tags, autofillPositionValue, dispatch]);
  
  useEffect(() => {
    setAutofillSubmissionValue(newSubmissionTagValue);
  }, [newSubmissionTagValue]);
  
  useEffect(() => {
    setAutofillPositionValue(newPositionalTagValue);
  }, [newPositionalTagValue]);
  
  return (
    <GenericModal
      onClose={handleSetSubmissionModalClose}
      title={'Log a submission attempt'}
      confirmText={'Log it!'}
      onConfirm={handleSubmissionChange}
      css={modalStyles.modalContainer}
      isDisabled={getIsDisabled()}
    >
      {postTagIsLoading && <Loader message={'Adding Tag...'}/>}
      <div css={[modalStyles.modal, modalStyles.modalBody]}>
        <span css={modalStyles.label}>Who initiated the submission?</span>
        <div css={modalStyles.radioButtonContainer}>
          {initiatorEnumAsArray.map(option => (
            <div
              key={option}
              css={modalStyles.radioButton(state.selectedSubmissionInitiatedBy === MoveInitiator[option])}
              onClick={() => handleSelectInitiatedBy(MoveInitiator[option])}
            >
              <span>{option}</span>
            </div>
          ))}
        </div>
        <span css={modalStyles.label}>Attempted submission was:</span>
        <TagAutofill
          onChange={handleSubmissionAutofillChange}
          onClick={handleSubmissionClick}
          value={autofillSubmissionValue}
          onEnterPress={handleSubmissionEnterPress}
          labelText={'Select Submission'}
          submissionsOnly={true}
          ignoreOnBlur={true}
          showBorder={true}
          hideLabelOnFocus={true}
        />
        <span css={modalStyles.label}>And it was...</span>
        <div css={modalStyles.radioButtonContainer}>
          <div
            css={modalStyles.radioButton(state.selectedSubmissionSuccess)}
            onClick={() => handleSelectSubmissionSuccess(true)}
          >
            <span>Successful</span>
          </div>
          <div
            css={modalStyles.radioButton(state.selectedSubmissionSuccess === false)}
            onClick={() => handleSelectSubmissionSuccess(false)}
          >
            <span>Defended</span>
          </div>
        </div>
        {state.selectedSubmissionSuccess === false
        && (
          <>
            {renderSetPosition({
              autofillValue: autofillPositionValue,
              handleAutofillChange: handlePositionAutofillChange,
              handleClick: handlePositionClick,
              handleEnterPress: handlePositionEnterPress,
              handleSelectDominance,
              state,
              fromPositionModal: true,
            })}
          </>
        )
        }
      </div>
    </GenericModal>
  );
};
export default SetSubmissionModal;
