/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { ColorObject, JournalFormState } from '../Utils/types';
import { journalColorChoices } from './Actions/journalDefaultsModule';
import theme from '../Theme';
import { getJournalColorInfo } from './module';
import { hexToRGBA } from '../Utils/module';
import StarIcon from '../icons/StarIcon';

const styles = {
	colorSelector: (colorChoice: string, isCurrentColor: boolean) => css`
		background-image: linear-gradient(45deg, ${colorChoice} 50%, white 51%);
		border: solid 1px lightgray;
		transform: scale(${isCurrentColor && 1.25});
		width: 40px;
		height: 40px;
		margin: 10px;
		border-radius: 200px;
		cursor: pointer;
		transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		position: relative;

		&:hover {
			transform: scale(1.25);
		}
	`,
	colorPicker: css`
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;
	`,
	colorPickerWrapper: css`
		display: flex;
		flex-direction: column;
	`,
	fill: (isCurrentColor: boolean, color: string) => css`
		width: 100%;
		height: 100%;
		transform: scale(${isCurrentColor ? '100%' : '0'});
		border-radius: 200px;
		background-color: ${color};
		transition: ${isCurrentColor ? 'all 400ms' : 'all 200ms'};
	`,
	confirmButton: css`
		border: none;
		outline: none;
		border-radius: 4px;
		padding: 10px;
		cursor: pointer;
		color: white;
		background-color: ${theme.palette.primary.light};

		&:hover {
			color: white;
			background-color: ${theme.palette.primary.dark};
		}
	`,
	cancelButton: css`
		padding: 10px;
		text-align: center;
		display: flex;
		cursor: pointer;
		justify-content: space-evenly;
		color: red;
		background-color: ${theme.colors.contentBackground};
		border: 1px solid ${hexToRGBA(theme.colors.mediumGray, 0.5)};
		border-radius: 4px;

		&:hover {
			background-color: ${theme.colors.lightestGray};
		}
	`,
	buttonWrapper: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 20px;
		align-items: center;
		margin-top: 30px;
	`,
	currentIcon: css`
		position: absolute;
		top: 15%;
		left: 85%;
		transform: translate(-50%, -50%);
		color: ${theme.palette.primary.light};
		-webkit-text-stroke: 0.75px white;
	`,
};

interface Props {
	setColor: (color: ColorObject) => void;
	journalFormState: JournalFormState;
	onCloseColorPicker: () => void;
}

const JournalColorPicker: React.FC<Props> = (props) => {
	const { setColor, onCloseColorPicker, journalFormState } = props;

	const handleColorClick = useCallback(
		(color: ColorObject) => {
			setColor(color);
		},
		[setColor]
	);

	const handleCancel = useCallback(() => {
		setColor(getJournalColorInfo(journalFormState.journal.color));
		onCloseColorPicker();
	}, [journalFormState.journal.color, onCloseColorPicker, setColor]);

	return (
		<div css={styles.colorPickerWrapper}>
			<div css={styles.colorPicker}>
				{journalColorChoices.map((color) => {
					const isSelectedColor = journalFormState.editedColor.hexCode === color.hexCode;
					const isSavedColor = journalFormState.journal.color === color.hexCode;
					return (
						<div
							key={color.hexCode}
							css={styles.colorSelector(color.hexCode, isSelectedColor)}
							onClick={() => handleColorClick(color)}
						>
							<div css={styles.fill(isSelectedColor, color.hexCode)} />
							{isSavedColor && <StarIcon css={styles.currentIcon} />}
						</div>
					);
				})}
			</div>
			<div css={styles.buttonWrapper}>
				<button
					css={styles.cancelButton}
					onClick={handleCancel}
				>
					Discard Changes
				</button>
				<button
					css={styles.confirmButton}
					onClick={onCloseColorPicker}
				>
					Confirm
				</button>
			</div>
		</div>
	);
};
export default JournalColorPicker;
