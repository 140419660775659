/**
 * Returns whether or not the two arguments are strictly equal (which, in the case of objects, means referential
 * equality) AKA `===` equality
 */
export const strictEquals = (one: any, two: any): boolean => one === two;

/**
 * Javascript has the Map type, but it's mutable only. This is a convenience type over a key-value pair JS object.
 * (JS objects are really just hash maps)
 */
export type HashMap<T> = { readonly [key: string]: T | undefined }; // | undefined because keys may not have a value