/** @jsxImportSource @emotion/react */
import React, { createRef, useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import theme from '../Theme';
import { matchSorter } from 'match-sorter';
import { isDownArrowPressed, isEnterPressed, isUpArrowPressed } from './module';
import { APP_LAYERS } from '../layers';

//look into match-sorter for better word suggestions
// https://www.npmjs.com/package/match-sorter

enum SCROLL_OPTIONS {
	increment = -1,
	decrement = 1,
}

const styles = {
	root: (fullWidth: boolean) => css`
		display: flex;
		flex-direction: column;
		position: relative;
		min-width: 0;
		border: 0;
		vertical-align: top;
		padding: 0;
		${fullWidth && 'width: 100%;'};
	`,
	optionWrapper: css`
		position: relative;
	`,
	options: css`
		position: absolute;
		max-height: 200px;
		right: 0;
		overflow-y: auto;
		width: 100%;
		background-color: ${theme.colors.contentBackground};
		border: solid 1px darkgrey;
		cursor: pointer;
		z-index: ${APP_LAYERS.overlays};
		color: ${theme.fontColor.text};

		li {
			padding: 4px 0;
		}

		& > .active {
			background-color: ${theme.colors.lightestGray};
			color: ${theme.fontColor.text};
		}
	`,
	inputContainer: (focused: boolean) => css`
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.4375em;
		letter-spacing: 0.00938em;
		color: rgba(0, 0, 0, 0.87);
		box-sizing: border-box;
		position: relative;
		cursor: text;
		display: inline-flex;
		align-items: center;
		border-radius: 6px;

		&:hover > .input-fieldset {
			${!focused && 'border-color: black'};
		}

		& > .input-fieldset {
			border-color: ${focused ? theme.palette.primary.main : theme.colors.border};
		}
	`,
	label: (focused: boolean) => css`
		color: ${focused ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.6)'};
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.4375em;
		letter-spacing: 0.00938em;
		padding: 0;
		display: block;
		transform-origin: top left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: ${focused ? 'calc(133% - 24px)' : 'calc(100% - 24px)'};
		position: absolute;
		left: 0;
		top: 0;
		transform: ${focused ? 'translate(14px, -9px) scale(.75)' : 'translate(14px, 8px) scale(1)'};
		transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		pointer-events: none;
	`,
	fieldset: css`
		text-align: left;
		position: absolute;
		bottom: 0;
		right: 0;
		top: -5px;
		left: 0;
		margin: 0;
		padding: 0 8px;
		pointer-events: none;
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		border-color: ${theme.colors.border}
		min-width: 0;
	`,
	legend: (focused: boolean) => css`
		float: unset;
		overflow: hidden;
		display: block;
		width: auto;
		padding: 0;
		height: 11px;
		font-size: 0.75em;
		visibility: ${focused ? 'hidden' : 'visible'};
		background: #fff;
		max-width: ${focused ? '100%' : '0.01px'};
		transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		white-space: nowrap;
	`,
	textInput: css`
		padding: 0 14px;
		font: inherit;
		letter-spacing: inherit;
		color: currentColor;
		border: 0;
		outline: none;
		box-sizing: content-box;
		background: none;
		height: 38px;
		margin: 0;
		-webkit-tap-highlight-color: transparent;
		display: block;
		min-width: 0;
		width: 100%;
	`,
	labelText: css`
		padding-left: 5px;
		padding-right: 5px;
		display: inline-block;
		opacity: 0;
		visibility: visible;
	`,
};

interface Props {
	/**
	 * The string value of the text input
	 */
	value: string | undefined;
	/**
	 * The array of autocomplete options.
	 * If more complex than an array of strings, prop getOptionLabel will be required for proper function
	 */
	options: readonly any[];
	/**
	 * Used to determine the string value for a given option if the list is in a complex data structure
	 * @param option
	 */
	getOptionLabel?: (option: any) => string;
	/**
	 * if true, a user will have the option to add a new option if it doesn't already exist.
	 * @param boolean
	 * @default false
	 */
	canAdd?: boolean;
	/**
	 * Optional function for custom rendering of options.
	 * Required format: (Props, option <can be object or string>) =>
	 *     < Li {...Props}>{option<if string> || option.string<output the desired string>}</ Li>
	 * @param arg0 use Props and use {...Props} it in the <Li/> tag
	 * @param arg1 the string or object to be included in the <Li> element.
	 */
	//TODO get rid of any type
	renderOption?: (arg0: object, arg1: any) => JSX.Element; //format (props, option) => <Li {props...}>option</Li>
	defaultText: string;
	id: string;
	onChange: (newValue: string | undefined, event?: Event) => string | void;
	onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
	onEnterPress: (option: AutocompleteOptionType) => void;
	fullWidth?: boolean;
	className?: string;
	ignoreOnBlur?: boolean;
	dropdownOnFocus?: boolean;
}

export const DEFAULT_ADD_OPTION = 'add-option';

export interface AutocompleteOptionType {
	label: string;
	option?: object | string;
	userInput?: string;
	id?: string;
}

const Autocomplete: React.FC<Props> = (props) => {
	const {
		options,
		id,
		getOptionLabel,
		defaultText,
		canAdd = false,
		onChange,
		renderOption,
		value,
		fullWidth,
		onClick,
		onEnterPress,
		className,
		ignoreOnBlur = false,
		dropdownOnFocus = false,
	} = props;
	const [activeOption, setActiveOption] = useState(0);
	const [filteredOptions, setFilteredOptions] = useState<AutocompleteOptionType[]>([]);
	const [showOptions, setShowOptions] = useState(false);
	const [focused, setFocused] = useState(false);
	const fullWidthInput = !!fullWidth;
	const itemRefs: React.RefObject<HTMLLIElement>[] = useMemo(
		() =>
			Array(filteredOptions.length)
				.fill(0)
				.map(() => createRef()),
		[filteredOptions]
	);
	const getOptions = useCallback(() => {
		const optionArray: AutocompleteOptionType[] = [];
		if (!getOptionLabel) {
			options.forEach((option: string | object) => typeof option === 'string' && optionArray.push({ label: option }));
		} else {
			options.forEach((option: { id: string; userInput?: string }) =>
				optionArray.push({ label: getOptionLabel(option), option: option })
			);
		}
		return optionArray;
	}, [getOptionLabel, options]);

	const handleInputChange = useCallback(
		(event: any) => {
			const input = event.target.value;
			const newFilteredOptions: AutocompleteOptionType[] = matchSorter(getOptions(), input, { keys: [(item) => item.label] });
			const noExactMatch = !newFilteredOptions.find(
				(option) => option.label.toString().toLowerCase() === input.toString().toLowerCase()
			);

			if (canAdd && input.length && noExactMatch) {
				newFilteredOptions.push({
					userInput: input,
					label: `Add "${input}"`,
				});
			}
			setActiveOption(0);
			setShowOptions(true);
			setFilteredOptions(newFilteredOptions);
			onChange(input, event);
		},
		[setFilteredOptions, setActiveOption, setShowOptions, onChange, getOptions, canAdd]
	);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLLIElement>) => {
			if (filteredOptions[activeOption]?.id) {
				onChange(filteredOptions[activeOption]?.label);
			}
			setShowOptions(false);
			onClick(event);
		},
		[filteredOptions, activeOption, setShowOptions, onChange, onClick]
	);

	const handleKeyDown = useCallback(
		(event: any) => {
			if (value) {
				if (isEnterPressed(event) && filteredOptions) {
					setShowOptions(false);
					onEnterPress(filteredOptions[activeOption]);
				} else if (isUpArrowPressed(event)) {
					if (activeOption === 0) {
						return;
					}

					setActiveOption(activeOption + SCROLL_OPTIONS.increment);
					itemRefs[activeOption - 2]?.current?.scrollIntoView({ behavior: 'smooth' });
				} else if (isDownArrowPressed(event) && filteredOptions) {
					//down arrow
					if (activeOption === filteredOptions.length - 1) {
						return;
					}
					setActiveOption(activeOption + SCROLL_OPTIONS.decrement);
					itemRefs[activeOption]?.current?.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		[filteredOptions, setActiveOption, setShowOptions, activeOption, onEnterPress, value, itemRefs]
	);

	const handleBlur = useCallback(() => {
		if (ignoreOnBlur) return;
		setActiveOption(0);
		setFilteredOptions([]);
		setShowOptions(false);
		setFocused(false);
		onChange('');
	}, [setActiveOption, setFilteredOptions, setShowOptions, setFocused, onChange, ignoreOnBlur]);

	const handleFocus = useCallback(() => {
		setFocused(true);
	}, [setFocused]);

	const handleInputFocus = useCallback(() => {
		if (dropdownOnFocus) {
			setShowOptions(true);
			setFocused(true);
			setFilteredOptions(matchSorter(getOptions(), value || '', { keys: [(item) => item.label] }));
		}
	}, [dropdownOnFocus]);

	const handleInputBlur = useCallback(() => {
		if (dropdownOnFocus) {
			setShowOptions(false);
		}
	}, [dropdownOnFocus]);
	const handleMouseEnter = useCallback(
		(event: any, index: number) => {
			if (isDownArrowPressed(event) || isUpArrowPressed(event)) {
				return;
			}
			event.stopPropagation();
			event.preventDefault();
			setActiveOption(index);
		},
		[setActiveOption]
	);

	const renderOptionList = useCallback(() => {
		const activeStyle = (index: number) => {
			if (index === activeOption) {
				return 'autocomplete-option active';
			} else {
				return 'autocomplete-option';
			}
		};

		const createListProps = (label: string, index: number) => ({
			className: activeStyle(index),
			key: label + index,
			onClick: (e: React.MouseEvent<HTMLLIElement>) => handleClick(e),
			onMouseDown: (e: React.MouseEvent<HTMLLIElement>) => e.preventDefault(), //prevents onBlur
			ref: itemRefs[index],
			onMouseMove: (e: React.MouseEvent<HTMLLIElement>) => handleMouseEnter(e, index), //TODO why is this being flagged as unused
		});
		if (showOptions) {
			if (filteredOptions.length) {
				return (
					<div css={styles.optionWrapper}>
						<ul css={styles.options}>
							{filteredOptions.map((option: AutocompleteOptionType, index) =>
								!renderOption ? (
									<li {...createListProps(option.label, index)}>{option.label}</li>
								) : !option.userInput ? (
									renderOption(createListProps(option.label, index), option.option) //renders the "Add 'userInput'" option
								) : (
									<li
										{...createListProps(option.label, index)}
										id={DEFAULT_ADD_OPTION}
									>
										{option.label}
									</li>
								)
							)}
						</ul>
					</div>
				);
			} else {
				return (
					<div css={styles.optionWrapper}>
						<ul css={styles.options}>
							{filteredOptions.map(
								(
									option: AutocompleteOptionType,
									index //renders the "Add 'userInput'" option
								) => (
									<>
										<li {...createListProps(option.label, index)}>No Suggestions</li>
										<li {...createListProps(option.label, index + 1)}>{option.label}</li>
									</>
								)
							)}
						</ul>
					</div>
				);
			}
		}
	}, [filteredOptions, showOptions, value, activeOption, handleClick, handleMouseEnter, renderOption, itemRefs]);

	return (
		<div
			id={id}
			css={styles.root(fullWidthInput)}
			onBlur={handleBlur}
			onFocus={handleFocus}
			className={className}
		>
			<div css={styles.inputContainer(focused)}>
				<input
					type='search'
					className='search-box'
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					value={value}
					css={styles.textInput}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
				/>
				<fieldset
					className='input-fieldset'
					css={styles.fieldset}
				>
					<legend
						css={styles.legend(focused)}
						className='default-text'
					>
						<span css={styles.labelText}>{defaultText}</span>
					</legend>
				</fieldset>
				<label css={styles.label(focused)}>{defaultText}</label>
			</div>
			{renderOptionList()}
		</div>
	);
};
export default Autocomplete;
