/** @jsxImportSource @emotion/react */ //includ in all jsx files

import React, { useCallback, useState } from 'react';
import { getUpdatedTag, radioButtonStyle, TAG_CATEGORY_TYPES, tagCategoryOptions } from './tagModule';
import { positionPriorityOptions } from '../bjjModules/positionModule';
import { bodyPartOptions } from '../bjjModules/bodyModule';
import { css } from '@emotion/react';
import { movementOptions } from '../bjjModules/movementModule';
import TextInput from '../Utils/TextInput';
import { useAppState } from '../store/hooks';
import theme from '../Theme';
import { TagType } from '../Utils/types';
import { usePutUserTag } from './Actions/putUserTagAction';
import { useDeleteUserTag } from './Actions/deleteUserTagAction';
import { renderMenu } from './PostUserTagModal';

const styles = {
	root: css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 10px 25px;
		width: 350px;
		gap: 20px;
	`,
	inputField: css`
		margin: 10px 0 0 0;
		width: 100%;
		position: relative;
		padding: 6px 6px 6px 0;
		border-radius: 3px;
		border-color: lightgray;
		border-width: 2px;

		&:hover {
			border-color: black;
		}
	`,
	radioWrapper: css`
		display: flex;
		flex-direction: row;
		margin-top: 10px;
	`,
	radioButton: (selected?: boolean) => radioButtonStyle(selected),

	buttonWrapper: css`
		display: flex;
		flex-direction: row;
		gap: 16px;
		justify-content: flex-end;
		align-items: flex-end;
		margin-top: 20px;
	`,
	saveButton: css`
		padding: 5px;
		background-color: ${theme.palette.primary.light};
		color: ${theme.colors.contentBackground};
		border-radius: 6px;
		border: none;
		width: 75px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.palette.primary.dark};
		}
	`,
	deleteConfirmButton: css`
		padding: 5px;
		background-color: ${theme.colors.cancel};
		color: ${theme.colors.contentBackground};
		border-radius: 6px;
		border: none;
		width: 75px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.colors.cancelDark};
		}
	`,
	cancelButton: css`
		padding: 5px;
		border-radius: 6px;
		border: none;
		width: 75px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.colors.lightGrayHover};
		}
	`,
	inputLabel: css`
		color: rgba(0, 0, 0, 0.6);
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.4375em;
		letter-spacing: 0.00938em;
		padding: 0;
		display: block;
		transform-origin: top left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: calc(133% - 24px);
		position: absolute;
		left: 0;
		top: 0;
		transform: translate(14px, -9px) scale(0.75);
		transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		pointer-events: none;
	`,
	deleteButton: css`
		border-bottom: solid 1px ${theme.colors.cancel};
		cursor: pointer;
		padding: 0 3px;
		color: ${theme.colors.cancel};
		margin-right: auto;
		text-align: center;

		&:hover {
			color: ${theme.colors.cancelDark};
			border-bottom: solid 1px ${theme.colors.cancelDark};
		}
	`,
	column: css`
		display: flex;
		flex-direction: column;
	`,
	label: css`
		font-weight: 700;
		text-transform: uppercase;
		padding-bottom: 10px;
	`,
	content: css``,
};

interface Props {
	handleClose: () => void;
	currentTag: TagType;
}

const PutUserTagModal: React.FC<Props> = ({ handleClose, currentTag }) => {
	const [tagInput, setTagInput] = useState(currentTag.name);
	const [tagType, setTagCategory] = useState(currentTag.tagType);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	const [selectedPriority, setSelectedPriority] = useState(
		currentTag.tagType === TAG_CATEGORY_TYPES.position ? currentTag.category : ''
	);

	const [selectedBodyPart, setSelectedBodyPart] = useState(
		currentTag.tagType === TAG_CATEGORY_TYPES.submission ? currentTag.bodyPart || '' : ''
	);
	const [selectedMovement, setSelectedMovement] = useState(
		currentTag.tagType === TAG_CATEGORY_TYPES.movement ? currentTag.category : ''
	);

	const tagState = useAppState((state) => state.tags);

	const putUserTag = usePutUserTag(
		getUpdatedTag(
			currentTag,
			tagInput?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()),
			tagType,
			selectedBodyPart || selectedPriority || selectedMovement,
			selectedBodyPart
		)
	);

	const deleteUserTag = useDeleteUserTag(currentTag.id);

	const handleDelete = useCallback(async () => {
		if (deleteUserTag.value) {
			await deleteUserTag.value().then((res) => {
				if (!res?.error) {
					return handleClose();
				}
				alert('There was an error. Please try again.');
			});
		}
	}, [deleteUserTag, handleClose]);

	const toggleShowDeleteConfirm = useCallback(() => {
		setShowDeleteConfirm((prev) => !prev);
	}, []);

	const handleSave = useCallback(async () => {
		if (tagInput) {
			const tagIsUnique = !tagState?.find((tag) => tag.name.toLowerCase() === tagInput.toLowerCase());
			const tagNameChanged = currentTag.name.toLowerCase() !== tagInput.toLowerCase();
			if (!tagIsUnique && tagNameChanged) {
				alert('Tag name is a duplicate, please select another');
			} else if ((selectedBodyPart || selectedPriority || selectedMovement) && tagType) {
				await putUserTag.value().then((res) => {
					if (!res?.error) {
						return handleClose();
					}
					alert('There was an error. Please try again.');
				});
			}
		}
	}, [handleClose, currentTag, tagType, putUserTag, selectedBodyPart, selectedMovement, selectedPriority, tagInput, tagState]);

	const handleChangeLabelCategory = useCallback((label: string) => {
		setTagCategory(label);
		label === tagCategoryOptions[0].name ? setSelectedPriority(positionPriorityOptions[0].name) : setSelectedPriority('');
		label === tagCategoryOptions[1].name ? setSelectedBodyPart(bodyPartOptions[0].name) : setSelectedBodyPart('');
		label === tagCategoryOptions[2].name ? setSelectedMovement(movementOptions[0].name) : setSelectedMovement('');
	}, []);

	const handleChangePosition = useCallback((event: any) => {
		setSelectedPriority(event.target.value);
	}, []);

	const handleChangeSubBodyPart = useCallback((event: any) => {
		setSelectedBodyPart(event.target.value);
	}, []);

	const handleChangeMovement = useCallback((event: any) => {
		setSelectedMovement(event.target.value);
	}, []);

	const handleTagNameChange = useCallback((event: any) => {
		setTagInput(event.target.value);
	}, []);

	const renderChildMenu = useCallback(
		(selection: string) => {
			switch (selection) {
				case TAG_CATEGORY_TYPES.submission: {
					return renderMenu('body-part', 'Submission Target', selectedBodyPart, handleChangeSubBodyPart, bodyPartOptions);
				}
				case TAG_CATEGORY_TYPES.position: {
					return renderMenu('category', 'Positional priority', selectedPriority, handleChangePosition, positionPriorityOptions);
				}
				case TAG_CATEGORY_TYPES.movement: {
					return renderMenu('category', 'Movement Type', selectedMovement, handleChangeMovement, movementOptions);
				}
				default:
					return null;
			}
		},
		[handleChangeMovement, handleChangePosition, handleChangeSubBodyPart, selectedBodyPart, selectedMovement, selectedPriority]
	);

	return (
		<div css={styles.root}>
			{showDeleteConfirm ? (
				<>
					<div css={styles.column}>
						<span css={styles.label}>WARNING</span>
						<span css={styles.content}>This will permanently delete this tag and can't be undone. Proceed anyway?</span>
					</div>
					<div css={styles.buttonWrapper}>
						<button
							css={styles.cancelButton}
							onClick={toggleShowDeleteConfirm}
						>
							cancel
						</button>
						<button
							css={styles.deleteConfirmButton}
							onClick={handleDelete}
						>
							delete
						</button>
					</div>
				</>
			) : (
				<>
					<TextInput
						required={true}
						value={tagInput}
						placeholder={'Tag Name'}
						id={'tag-input'}
						onChange={handleTagNameChange}
					/>
					<div css={styles.inputField}>
						<label css={styles.inputLabel}>Tag Category</label>
						<div css={styles.radioWrapper}>
							{tagCategoryOptions.map((label) => (
								<div
									css={styles.radioButton(tagType === label.name)}
									onClick={() => handleChangeLabelCategory(label.name)}
									key={label.name}
								>
									<span>{label.name}</span>
								</div>
							))}
						</div>
					</div>
					{renderChildMenu(tagType)}

					<div css={styles.buttonWrapper}>
						<div
							onClick={toggleShowDeleteConfirm}
							css={styles.deleteButton}
						>
							<span>Delete this Tag</span>
						</div>
						<button
							css={styles.cancelButton}
							onClick={handleClose}
						>
							cancel
						</button>
						<button
							css={styles.saveButton}
							onClick={handleSave}
						>
							save
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default PutUserTagModal;
