import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { ENDPOINTS } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useCallback, useEffect, useRef } from "react";
import { useAppState, useErrors, useLoading, useRetryCount } from "../../store/hooks";
import { useValidateAndDispatch } from "./setAuthAction";

export interface User {
  email: string;
  lastLoginDateTimeUtc: string;
  signUpDateTimeUtc: string;
  username: string;
  id: string;
  name: {
    firstName: string;
    lastName: string;
  };
  googleApi?: {
    accessToken?: string | null;
    scope?: string[];
  };
}

export interface GetUserActionProps {
  requestId: string;
}

const KEY_ACTION = ActionType.GetUserRequest;

const ACTIONS = [ActionType.GetUserRequest, ActionType.GetUserResponse, ActionType.GetUserError];

interface Payload extends Response {
  data?: null | {
    user?: User;
  };
}

export const getUserAction = (authId: string) =>
  makeApiAction<Payload, GetUserActionProps>({
    method: "GET",
    endpoint: () => ENDPOINTS.GetUser + `/${authId}`,
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.auth?.accessToken}`,
      "content-type": "application/json",
    }),
    types: makeRsaaTypes<Payload, GetUserActionProps>(ACTIONS),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload, GetUserActionProps> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ["get user failure"]);
  }

  const successState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    user: payload.data.user,
  };
};

export const getUserReducer = makeApiReducer<Payload, GetUserActionProps>(ACTIONS, KEY_ACTION, reducer, "failed to get user");

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const useGetUser = () => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const authId = useAppState((state) => state.auth?.id);

  const retryCount = useRetryCount(KEY_ACTION);

  const getUser = useCallback(async () => {
    await validateAndDispatch(getUserAction(authId || ""));
  }, [validateAndDispatch, authId]);

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);

  const user = useAppState((state) => state.user);
  const delaying = useRef<boolean>(false);

  useEffect(() => {
    if (!user && !loading && retryCount < 3 && !delaying.current) {
      const callWithDelay = async () => {
        if (retryCount > 0) {
          delaying.current = true;
          const randomDelay = 1000 + Math.floor(Math.random() * 1000); // Random delay between 1 and 2 seconds
          await delay(randomDelay);
        }
        getUser().catch((error) => console.error(error));
        delaying.current = false;
      };
      callWithDelay();
    }
  }, [loading, errors, user, getUser, retryCount]);

  return {
    loading,
    errors,
    value: user,
    reload: getUser,
  };
};
