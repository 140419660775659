import React from 'react';
import {css} from '@emotion/react';
import {radioButtonStyle} from '../Tags/tagModule';


export const modalStyles = {
  modal: css`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  radioButton: (selected?: boolean) => radioButtonStyle(selected),
  radioButtonContainer: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    white-space: nowrap;
    text-transform: capitalize;

    div {
      display: flex;
      width: unset;
      min-width: 150px;
    }
  `,
  label: css`
    font-size: 14px;
    margin-bottom: -16px;
    color: gray;
  `,
  modalBody: css`
    width: 485px;
  `,
  modalContainer: css`
    > div {
      position: absolute;
      top: 10%;
    }
  `,
  inputField: css`
    margin: 10px 0 0 0;
    width: 100%;
    position: relative;
    padding: 6px 6px 6px 0;
    border-radius: 3px;
    border-color: lightgray;
    border-width: 2px;

    &:hover {
      border-color: black;
    }
  `,
  inputLabel: css`
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, -9px) scale(.75);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  `,
};