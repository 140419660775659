/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { LegacyRef, useEffect, useState } from "react";
import { getJournalEntryDates } from "./module";
import EntryPreview from "./EntryPreview";
import { css } from "@emotion/react";
import { EntryWithJournalId } from "../Utils/types";
import theme from "../Theme";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowRenderer } from "react-virtualized";
import useWindowDimensions from "../Utils/useWindowDimensions";

const styles = {
  wrapper: css`
    height: 80%;
    background-color: ${theme.colors.contentBackground};
    border-radius: 12px;
    padding: 32px 0px 32px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 8px;
    }
  `,
};

interface Props {
  entries: EntryWithJournalId[];
}

const EntrySummary: React.FC<Props> = ({ entries }) => {
  const { width } = useWindowDimensions();

  const [cache, setCache] = useState<CellMeasurerCache>(
    new CellMeasurerCache({
      keyMapper: (rowIndex: number) => entries[rowIndex]?.journalEntry?.journalEntryId,
      defaultHeight: 164,
      fixedWidth: true,
    })
  );

  const renderSummary: ListRowRenderer = ({ key, index, style, parent }) => {
    const entry = entries[index];
    if (!entry) return;
    const dateG = getJournalEntryDates([entry]);

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        overscanRowCount={3}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild as LegacyRef<HTMLDivElement>}
            style={{ ...style }}
            key={key}
          >
            <EntryPreview
              previewLines={2}
              entries={[entry]}
              entryDate={dateG[0]}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };
  useEffect(() => {
    setCache(
      new CellMeasurerCache({
        keyMapper: (rowIndex: number) => entries[rowIndex]?.journalEntry?.journalEntryId,
        defaultHeight: width > theme.breakpoints.md ? 164 : 163,
        fixedWidth: true,
      })
    );
  }, [entries, width]);

  return (
    <div css={styles.wrapper}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            gap={"12px"}
            rowCount={entries?.length || 1}
            rowRenderer={renderSummary}
            rowHeight={cache?.rowHeight || 164}
            overscanRowCount={5}
            data={entries}
            marginTop={20}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default EntrySummary;
