/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import theme from "../Theme";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.contentBackground};
    margin: 40px;
    padding: 20px;
    overflow-y: hidden;
    border-radius: 12px;
  `,
  policyBlock: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 50px;
    height: 100%;
    overflow-y: auto;
  `,
  dateText: css`
    color: ${theme.colors.inactive};
  `,
};

const PrivacyNotice: React.FC = () => (
  <div css={styles.container}>
    <div css={styles.policyBlock}>
      <span css={styles.dateText}>Last updated: October 28, 2024</span>
      <h1>CKS Privacy Notice</h1>

      <p>
        Protecting your private information is our priority. This Statement of Privacy applies to Champions Keep Score (CKS) and
        Student of BJJ, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise
        noted, all references to Student of BJJ, LLC include{" "}
        <a href="https://cks-web-prod.azurewebsites.net">https://cks-web-prod.azurewebsites.net</a> and CKS. The CKS application
        is a sports knowledge and skills tracking application. By using the CKS application, you consent to the data practices
        described in this statement.
      </p>

      <h2>Collection of Your Personal Information</h2>
      <p>
        In order to better provide you with products and services, CKS may collect personally identifiable information such as:
      </p>
      <ul>
        <li>First and Last Name</li>
        <li>E-mail Address</li>
        <li>User-generated data</li>
      </ul>
      <p>
        CKS does not collect any personal information about you unless you voluntarily provide it to us. However, certain products
        or services may require specific personal information, which may include:
      </p>
      <ul>
        <li>Registering for an account</li>
        <li>Entering a sweepstakes or contest</li>
        <li>Signing up for special offers</li>
        <li>Communicating with us by email</li>
        <li>Submitting payment information for purchases</li>
      </ul>
      <p>CKS may gather additional personal or non-personal information in the future.</p>

      <h2>Use of Your Personal Information</h2>
      <p>
        CKS collects and uses your personal information to operate and deliver services you request. CKS may also use your
        personally identifiable information to inform you of other products or services available from CKS and its affiliates.
      </p>

      <h2>Sharing Information with Third Parties</h2>
      <p>
        CKS does not sell, rent, or lease its customer lists to third parties. CKS may share data with trusted partners to help
        perform statistical analysis, send communications, provide customer support, or arrange deliveries. These third parties
        are prohibited from using your personal information except to provide these services to CKS and are required to maintain
        its confidentiality.
      </p>

      <h2>Google API and YouTube Data</h2>
      <p>
        If you connect your CKS account to Google/YouTube services, CKS will access specific information in accordance with
        Google’s and YouTube’s privacy policies. CKS only uses YouTube API services to enhance features within CKS, and this data
        will not be shared with third parties. You may disconnect from Google/YouTube at any time through your Google account
        security settings or your CKS profile.
      </p>

      <h2>Disclosure of Personal Information</h2>
      <p>
        CKS may disclose your personal information, without notice, if required to do so by law or in good faith belief that such
        action is necessary to:
      </p>
      <ol>
        <li>Comply with legal processes or requirements,</li>
        <li>Protect the rights or property of CKS,</li>
        <li>Safeguard personal safety of users of CKS or the public.</li>
      </ol>

      <h2>Your Rights</h2>
      <p>
        You have the right to delete your personal information from CKS records, with certain exceptions. CKS may retain
        information required to:
      </p>
      <ul>
        <li>Complete transactions,</li>
        <li>Detect or protect against fraud,</li>
        <li>Debug functionality,</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>Children Under Thirteen</h2>
      <p>
        CKS does not knowingly collect information from children under the age of thirteen. If you are under thirteen, please ask
        your parent or guardian for permission to use this application.
      </p>

      <h2>Email Communications</h2>
      <p>
        From time to time, CKS may contact you by email with updates or offers. To opt out, click "UNSUBSCRIBE" in the email
        footer.
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        CKS reserves the right to update this Privacy Policy periodically. We will notify users of significant changes through
        email, by posting a notice on our app, or by updating this document. Your continued use of CKS after changes confirms your
        acknowledgment and agreement to the updated policy.
      </p>

      <h2>Contact Information</h2>
      <p>If you have questions or believe CKS has not adhered to this statement, please contact us at:</p>
      <p>
        Student of BJJ, LLC <br />
        <strong>Email Address:</strong> <a href="mailto:zack@championskeepscore.com">zack@championskeepscore.com</a> <br />
        <strong>Telephone:</strong> 479-313-3168
      </p>

      <p>
        <strong>Effective Date:</strong> June 07, 2023
      </p>
    </div>
  </div>
);

export default PrivacyNotice;
