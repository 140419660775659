/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useState} from 'react';
import {css} from '@emotion/react';
import GenericModal from '../modal/GenericModal';
import {useUpdatePassword} from './Actions/updatePasswordAction';
import TextInput from '../Utils/TextInput';
import Loader from '../Utils/Loader';
import {useErrors, useLoading} from '../store/hooks';
import {ActionType} from '../store/actionType';
import theme from '../Theme';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 15px;
  `,
  errors:    css`
    color: ${theme.colors.cancel};
  `,
};

interface Props {
  passwordModalIsOpen: boolean,
  setPasswordModalIsOpen: (isOpen: boolean) => void,
}

export interface Errors {
  message: string
}

const UpdatePasswordModal: React.FC<Props> = ({passwordModalIsOpen, setPasswordModalIsOpen}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<Errors[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const submitLoading = useLoading(ActionType.UpdatePasswordRequest );
  const submitErrors = useErrors(ActionType.UpdatePasswordRequest);
  
  
  const updatePassword = useUpdatePassword(newPassword);
  
  const handleFirstPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  }, []);
  
  const handleConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  }, []);
  
  const onClose = useCallback(() => {
    setNewPassword('');
    setConfirmPassword('');
    setErrors([]);
    setPasswordModalIsOpen(false);
  }, [setPasswordModalIsOpen]);
  
  const postNewPassword = useCallback(async () => {
    await updatePassword.value().then((res) => {
      if (!res?.error) {
        return onClose();
      }
      alert('There was an error. Please try again.');
    });
  }, [updatePassword, onClose]);
  
  const handlePostNewPassword = useCallback(() => {
    const errorList: Errors[] = [];
    if (!newPassword.trim().length) {
      errorList.push({message: 'Your password must not be blank.'});
      return setErrors(errorList);
    }
    if (newPassword.length < 8) {
      errorList.push({message: 'Password must be at least 8 characters.'});
    }
    if (!confirmPassword.length || newPassword.localeCompare(confirmPassword)) {
      errorList.push({message: 'Your passwords do not match.'});
      return setErrors(errorList);
    }
    if (!!errorList.length) {
      return setErrors(errorList);
    }
    return postNewPassword();
  }, [newPassword, postNewPassword, confirmPassword]);
  

  
  useEffect(() => {
    if (submitLoading) {
      setSubmitting(true);
    }
  }, [submitLoading, submitting]);
  
  useEffect(() => {
    if (submitting && !submitLoading) {
      setSubmitting(false);
      if (submitErrors?.length) {
        setErrors([{message: 'There was an error updating your password'}]);
        return console.error(submitErrors);
      }
      alert('Password successfully updated');
      return onClose();
    }
  }, [setPasswordModalIsOpen, submitErrors, submitLoading, submitting, onClose]);
  
  
  return passwordModalIsOpen
    ? <GenericModal
      title={'Enter your new password'}
      onClose={onClose}
      onConfirm={handlePostNewPassword}
      confirmText={'Submit'}
    >
      {submitting && <Loader message={'Updating Password'}/>}
      <div css={styles.container}>
        <TextInput
          type={'password'}
          value={newPassword}
          placeholder={'New Password'}
          id={'newPassword'}
          onChange={handleFirstPasswordChange}
        />
        <TextInput
          type={'password'}
          value={confirmPassword}
          placeholder={'Confirm Password'}
          id={'confirmPassword'}
          onChange={handleConfirmPasswordChange}
        />
        {errors.length > 0
        && <ul css={styles.errors}>
          {errors.map(error => (
            <li>{error.message}</li>
          ))}
        </ul>
        }
      </div>
    </GenericModal>
    : null;
};

export default UpdatePasswordModal;
