/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { APP_LAYERS } from '../layers';
//import {useClickOutsideListener} from './hooks';
import { hexToRGBA } from '../Utils/module';
import theme, { StyleTypes } from '../Theme';
import CloseIcon from '../icons/CloseIcon';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';

const styles = {
	root: (modalIsOpen: boolean) => css`
		position: fixed;
		z-index: ${APP_LAYERS.modal};
		height: 100vh;
		width: 100vw;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${modalIsOpen && hexToRGBA(theme.colors.darkGray, 0.4)};
		backdrop-filter: ${modalIsOpen && 'blur(3px)'};
		transition: ${modalIsOpen ? 'all 500ms' : 'all 150ms'};
	`,
	defaultModal: (modalIsOpen: boolean) => css`
		background-color: ${theme.colors.contentBackground};
		border-radius: 8px;
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		min-width: 350px;
		min-height: 120px;
		padding: 28px;
		opacity: ${modalIsOpen ? 1 : 0};
		filter: ${!modalIsOpen && 'blur(3px)'};
		transition: ${modalIsOpen ? 'all 500ms' : 'all 150ms'};
	`,
	titleBar: css`
		font-size: 20px;
		font-weight: 700;
		height: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
		color: ${theme.fontColor.text};

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	`,
	buttons: css`
		display: flex;
		justify-content: flex-end;
		gap: 6px;
		padding-top: 5px;
	`,
	button: css`
		border: none;
		outline: none;
		border-radius: 4px;
		padding: 6px;
		cursor: pointer;
	`,
	confirmButton: (warning?: boolean, isDisabled = false) => css`
		color: white;
		background-color: ${warning ? theme.colors.cancel : theme.colors.confirm};
		opacity: ${isDisabled && '.5'};
		cursor: ${isDisabled && 'unset'};
		&:hover {
			color: white;
			background-color: ${isDisabled ? '' : warning ? theme.colors.cancelDark : theme.colors.confirmDark};
		}
	`,
	cancelButton: css`
		background-color: ${theme.colors.contentBackground};
		border: solid 1px ${theme.colors.lightestGray};

		&:hover {
			background-color: ${theme.colors.lightestGray};
		}
	`,
	closeIcon: css`
		color: ${theme.fontColor.text};
		transition: all 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		border-radius: 200px;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		background-color: ${theme.colors.background};
		&:hover {
			transform: rotate(90deg);
		}
	`,
	backButton: css`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-left: -5px;
		cursor: pointer;
		transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

		&:hover {
			transform: scale(130%);
		}
	`,
};

interface Props {
	children: ReactNode;
	onClose: () => void;
	onConfirm?: () => void;
	onBack?: () => void;
	title?: string;
	confirmText?: string;
	cancelText?: string;
	isDisabled?: boolean;
	className?: StyleTypes;
	/**
	 * warning?: boolean.
	 * Set to true if modal will be used to communicate a warning such as permanently deleting something.
	 * Will style the confirm button dark red to better communicate the severity
	 */
	isWarning?: boolean;
	/**
	 * onlyConfirm?: boolean.
	 * Set to true if there is no need for a cancel button.
	 */
	onlyConfirmButton?: boolean;
	/**
	 * noButtons?: boolean.
	 * Use when the modal doesn't need cancel/confirm buttons.
	 */
	noButtons?: boolean;
}

const GenericModal: React.FC<Props> = (props) => {
	const { onlyConfirmButton = false, noButtons = false, isDisabled = false } = props;
	const childrenRef = useRef<HTMLDivElement>(null);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [readyToClose, setReadyToClose] = useState(false);

	const handleClose = useCallback(() => {
		setModalIsOpen(false);
		setReadyToClose(true);
	}, []);

	//useClickOutsideListener(childrenRef, handleClose);

	//allows fade in transition to render
	useEffect(() => {
		setModalIsOpen(true);
	}, []);

	//Allows the fade out transition effect to render before closing the component
	useEffect(() => {
		readyToClose &&
			setTimeout(() => {
				props.onClose();
			}, 250);
	}, [readyToClose, props]);

	return (
		<div
			className={props.className + ''}
			css={styles.root(modalIsOpen)}
		>
			<div
				ref={childrenRef}
				css={styles.defaultModal(modalIsOpen)}
			>
				<div css={styles.titleBar}>
					{props.onBack && (
						<div
							className='modal-title'
							onClick={props.onBack}
						>
							<ChevronLeftIcon css={styles.backButton} />
						</div>
					)}
					<span className='modal-title'>{props.title || ''}</span>
					<CloseIcon
						css={styles.closeIcon}
						onClick={handleClose}
					/>
				</div>
				{props.children}
				{!noButtons && (
					<div css={styles.buttons}>
						{!onlyConfirmButton && (
							<button
								css={[styles.button, styles.cancelButton]}
								onClick={handleClose}
							>
								{props.cancelText || 'Cancel'}
							</button>
						)}
						<button
							css={[styles.button, styles.confirmButton(props.isWarning, isDisabled)]}
							onClick={!isDisabled ? props.onConfirm : undefined}
						>
							{props.confirmText || 'Done'}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default GenericModal;
