/** @jsxImportSource @emotion/react */  //include this in all jsx files
import React, {useCallback, useEffect} from 'react';
import {ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic} from 'slate-react';
import {css} from '@emotion/react';
import {DEFAULT_CAPTION_TEXT} from './richTextModule';
import {Node, Path, Transforms} from 'slate';

const styles = {
  figCaption: (focused: boolean, hasCaption: boolean) => css`
    background-color: ${!hasCaption && '#d3d3d3'};
    width: 100%;
    visibility: ${focused || hasCaption ? 'visible' : 'hidden'};
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    padding: 30px;
    :before {
      content: '';
      position: absolute;
      width: 26px;
      height: 26px;
      top: -6px;
      left: -5px;
      cursor: pointer;
    }
  `,
};

const FigCaption = ({attributes, children, element}: Partial<RenderElementProps>) => {
  
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlateStatic();
  const path = element && ReactEditor.findPath(editor, element);
  const caption: string = children[0].props.text.text;
  const hasCaption = !!caption && caption !== DEFAULT_CAPTION_TEXT;
  const figurePath = path && Node.get(editor, Path.parent(path));
  const figurePathSize = figurePath && figurePath.children?.length;
  
  
  //Prevents images/html/ other undesirables from being pasted in caption.
  const handlePaste = useCallback((event: any) => {
    event.preventDefault();
  }, []);
  
  //Replaces placeholder text if it is deleted out and not replaced with a caption
  useEffect(() => {
    if (!caption && !selected) {
      Transforms.insertText(editor, DEFAULT_CAPTION_TEXT, {at: path});
    }
  }, [path, editor, selected, caption]);
  
  //ensures caption is deleted if image is.
  useEffect(() => {
    if (figurePathSize && figurePathSize < 2) {
      path && Transforms.removeNodes(editor, {at: Path.parent(path)});
    }
  }, [editor, path, figurePathSize]);
  
  return (
    <figcaption  onPaste={handlePaste} css={styles.figCaption(focused, hasCaption)} {...attributes}>
      {children}
    </figcaption>
  
  );
};
export default FigCaption;