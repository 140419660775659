import {ActionType} from '../../store/actionType';
import {
  addError,
  ApiReducer,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from '../../store/module';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useCallback, useEffect, useState} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {Promotion} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';


export interface UserProfile {
  id: string;
  userId: string;
  promotions: Promotion[];
  badgeLevel: number;
  isBetaTester: boolean;
}

const KEY_ACTION = ActionType.GetUserProfileRequest;

const ACTIONS = [
  ActionType.GetUserProfileRequest,
  ActionType.GetUserProfileResponse,
  ActionType.GetUserProfileError,
];

interface Payload extends Response {
  data?: null | {
    userProfile?: UserProfile;
  }
}

export const getUserProfileAction = (userId: string) => (
  makeApiAction<Payload, string>({
    method: 'GET',
    endpoint: () => ENDPOINTS.GetUserProfile + `/${userId}`,
    headers: (state: AppState) => ({
      'Authorization': `Bearer ${state.auth?.accessToken}`,
      'content-type': 'application/json',
    }),
    types: makeRsaaTypes<Payload, string>(ACTIONS),
  })
);

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
 
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data) {
    return addError(state, KEY_ACTION, ['get userProfile failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
    userProfile: payload.data.userProfile,
  };
};

export const getUserProfileReducer = makeApiReducer<Payload>(ACTIONS, KEY_ACTION, reducer, 'failed to get user profile');

export const useGetUserProfile = () => {
  
  const auth = useAppState(state => state.auth);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  const [attempts, setAttempts] = useState(0);
  
  const getUserProfile = useCallback(
    async () => {
      setAttempts(attempts + 1);
      return await validateAndDispatch(getUserProfileAction(auth?.id || ''));
    },
    [validateAndDispatch, auth, attempts]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  const userProfile = useAppState(state => state.userProfile);
  const bailedOut = attempts >= 5 && !userProfile;

  useEffect(
    () => {
      if (!userProfile && !loading && !errors && auth?.accessToken && attempts <= 5) {
        getUserProfile().catch(err => console.error(err));
      }
    },
    [loading, errors, userProfile, getUserProfile, auth?.accessToken, attempts],
  );
  
  return {
    loading, errors, value: userProfile, reload: getUserProfile, bailedOut,
  };
};