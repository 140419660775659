/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useMemo, useRef } from 'react';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor } from 'slate';
import { isValidJSONTest, renderElement, renderLeaf } from './richTextModule';

interface Props {
	entryText: string;
}

//https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
const RichTextViewer = (props: Props) => {
	const editorRef = useRef<ReactEditor | null>(null);
	if (!editorRef.current) editorRef.current = withReact(createEditor());
	const editor = editorRef.current;
	const isValidJSON = useMemo(() => isValidJSONTest(props.entryText), [props.entryText]);

	if (isValidJSON) {
		return (
			<Slate
				css={{ backgroundColor: 'red' }}
				editor={editor}
				value={JSON.parse(props.entryText)}
			>
				<Editable
					readOnly
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder='Enter some plain text...'
				/>
			</Slate>
		);
	}
	return <span>{props.entryText}</span>;
};

export default RichTextViewer;
