/**
 * Hook that detects if a user has clicked outside of the HTML element and closes it based on state.
 */
import {RefObject, useCallback, useEffect} from 'react';

export const useClickOutsideListener = (
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void,
): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        onClickOutside();
      }
    },
    [ref, onClickOutside],
  );
  
  useEffect(
    () => {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => document.removeEventListener('mousedown', handleClickOutside, true);
    },
    [handleClickOutside],
  );
};

/**
 * Hook that detects if a user has clicked outside of the 2 HTML elements and closes based on state.
 */
export const useClickOutsideDualElementsListener = (
  ref1: RefObject<HTMLElement>,
  ref2: RefObject<HTMLElement>,
  onClickOutside: () => void,
): void => {
  const handleClickOutsideDualElements = useCallback(
    (event: MouseEvent) => {
      if (!ref1.current?.contains(event.target as Node) && !ref2.current?.contains(event.target as Node)) {
        onClickOutside();
      }
    },
    [ref1, ref2, onClickOutside],
  );
  
  useEffect(
    () => {
      document.addEventListener('mousedown', handleClickOutsideDualElements, true);
      return () => document.removeEventListener('mousedown', handleClickOutsideDualElements, true);
    },
    [handleClickOutsideDualElements],
  );
};