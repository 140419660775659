/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import { ColorObject, JournalFormState, JournalObject } from "../Utils/types";
import { useDeleteJournal } from "./Actions/deleteJournalAction";
import theme from "../Theme";
import { DEFAULT_JOURNAL_INPUT } from "./Actions/journalDefaultsModule";
import { hexToRGBA } from "../Utils/module";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../Navigation/routesModule";
import GenericModal from "../modal/GenericModal";
import JournalColorPicker from "./JournalColorPicker";
import { BLANK_JOURNAL, getJournalColorInfo, isInvalidTitle, toEditedJournalObject } from "./module";
import { usePutJournal } from "./Actions/putJournalAction";
import { useJournals } from "./Actions/getJournalsAction";
import { usePostJournal } from "./Actions/postJournalAction";
import TextInput from "../Utils/TextInput";
import Loader from "../Utils/Loader";

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
  `,
  pickColorButton: (journalColor: string) => css`
    display: flex;
    width: 40px;
    height: 20px;
    border-radius: 3px;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    background-color: ${journalColor};
  `,
  input: css`
    .input-fieldset {
      border-radius: 6px;
    }
  `,
  colorInputText: css`
    color: ${theme.fontColor.text};
    cursor: pointer;
    font-size: 16px;
  `,
  inputField: (journalColor?: string) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border-color: darkgrey;
    width: 100%;
    color: ${journalColor ? journalColor : theme.fontColor.text};
    resize: none;

    &:hover {
      border: solid 1px black;
    }
  `,
  inputWrapper: css`
    background-color: ${theme.colors.contentBackground};
    padding: 5px;
    width: 100%;
    margin-top: -10px;
  `,
  deleteButton: css`
    padding: 10px 15px;
    text-align: center;
    display: flex;
    cursor: pointer;
    width: 90px;
    justify-content: space-evenly;
    color: ${theme.fontColor.text};
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    background-color: ${theme.colors.background};
    border: 1px solid ${theme.colors.border};
    border-radius: 200px;

    &:hover {
      background-color: ${theme.colors.lightestGray};
    }
  `,
  textAreaTitle: css`
    color: ${hexToRGBA(theme.colors.mediumGray, 0.95)};
    font-size: smaller;
  `,
  colorPickerWrapper: css`
    padding: 14px 16px;
    cursor: pointer;
    background-color: ${theme.colors.background};
  `,
  confirmButton: css`
    border: none;
    outline: none;
    border-radius: 200px;
    padding: 10px 15px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: white;
    background-color: ${theme.palette.primary.main};

    &:hover {
      color: white;
      background-color: ${theme.palette.primary.dark};
    }
  `,
  buttonWrapper: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
  `,
};

interface Props {
  journal?: JournalObject;
  createNewJournal: boolean;
  closeModal: () => void;
}

const JournalCreatorEditor: React.FC<Props> = (props) => {
  const { closeModal, journal, createNewJournal = true } = props;

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [colorPickerIsOpen, setColorPickerIsOpen] = useState<boolean>(false);
  const [showNullTitleModal, setShowNullTitleModal] = useState(false);
  const [showInvalidTitleModal, setShowInvalidTitleModal] = useState(false);

  const [journalFormState, setJournalFormState] = useState<JournalFormState>({
    journal: journal || BLANK_JOURNAL,
    editedDescription: journal?.description || "",
    editedColor: getJournalColorInfo(journal?.color || DEFAULT_JOURNAL_INPUT.color.hexCode),
    editedName: journal?.name || "",
  });

  const journalId = journalFormState?.journal?.id;
  const deleteJournal = useDeleteJournal(journalId);
  const history = useHistory();
  const journalHook = useJournals();
  const journals = journalHook.value;

  const putJournal = usePutJournal(toEditedJournalObject(journalFormState));
  const postJournal = usePostJournal(
    journalFormState.editedName,
    journalFormState.editedColor.hexCode,
    journalFormState.editedDescription
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setJournalFormState({ ...journalFormState, editedName: e.target.value });
    },
    [setJournalFormState, journalFormState]
  );

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setJournalFormState({ ...journalFormState, editedDescription: e.target.value || "" });
    },
    [setJournalFormState, journalFormState]
  );

  const handleColorEditorClick = useCallback(() => {
    setColorPickerIsOpen(true);
  }, []);

  const toggleDeleteConfirmationModal = useCallback(() => {
    setShowConfirmDelete((prev) => !prev);
  }, []);

  const handleDelete = useCallback(async () => {
    if (journalId && journalId.length && deleteJournal.value) {
      await deleteJournal.value().then((res) => {
        if (!res?.error) {
          return history.push(ROUTES.journalLibrary);
        }
        alert("There was an error. Please try again.");
      });
    }
    closeModal();
  }, [deleteJournal, journalId, closeModal, history]);

  const handleCloseColorPicker = useCallback(() => {
    setColorPickerIsOpen(false);
  }, []);

  const setColor = useCallback(
    (color: ColorObject) => {
      setJournalFormState({ ...journalFormState, editedColor: color });
    },
    [setJournalFormState, journalFormState]
  );

  const handleEditSave = useCallback(async () => {
    if (!journals) {
      closeModal();
    }
    const titleIsNull = !journalFormState.editedName;
    const titleIsInvalid = isInvalidTitle(journalFormState.journal.name, journalFormState.editedName, journals);
    if (titleIsNull) {
      setShowNullTitleModal(true);
    } else if (titleIsInvalid) {
      setShowInvalidTitleModal(true);
    } else {
      await putJournal.value().then((res) => {
        if (!res?.error) {
          return closeModal();
        }
        alert("There was an error. Please try again.");
      });
    }
  }, [journalFormState, putJournal, journals, closeModal]);

  const handleNewJournalSave = useCallback(async () => {
    const titleIsNull = !journalFormState.editedName;
    const titleIsInvalid = isInvalidTitle(journalFormState.journal.name, journalFormState.editedName, journals);
    if (titleIsNull) {
      setShowNullTitleModal(true);
    } else if (titleIsInvalid) {
      setShowInvalidTitleModal(true);
    } else {
      await postJournal.value().then((res) => {
        if (!res?.error) {
          return closeModal();
        }
        alert("There was an error. Please try again.");
      });
    }
  }, [journalFormState, journals, closeModal, postJournal]);

  const handleCloseWarningModals = useCallback(() => {
    setShowInvalidTitleModal(false);
    setShowNullTitleModal(false);
  }, []);

  const renderWarningModals = useCallback(
    () => (
      <>
        {showNullTitleModal && (
          <GenericModal
            onClose={handleCloseWarningModals}
            onConfirm={handleCloseWarningModals}
            onlyConfirmButton={true}
            title={"Warning"}
            confirmText={"Got it"}
          >
            <span>Title is a required field.</span>
          </GenericModal>
        )}
        {showInvalidTitleModal && (
          <GenericModal
            onClose={handleCloseWarningModals}
            onConfirm={handleCloseWarningModals}
            onlyConfirmButton={true}
            title={"Warning"}
            confirmText={"Got it"}
          >
            <span>Journal name already exists. Please select a different name.</span>
          </GenericModal>
        )}
      </>
    ),
    [handleCloseWarningModals, showInvalidTitleModal, showNullTitleModal]
  );

  return (
    <div css={styles.body}>
      {colorPickerIsOpen ? (
        <JournalColorPicker
          setColor={setColor}
          onCloseColorPicker={handleCloseColorPicker}
          journalFormState={journalFormState}
        />
      ) : (
        <>
          <div css={styles.inputWrapper}>
            <div
              onClick={handleColorEditorClick}
              css={[styles.inputField(), styles.colorPickerWrapper]}
            >
              <span css={styles.colorInputText}>Select Color</span>
              <div css={styles.pickColorButton(journalFormState?.editedColor.hexCode)} />
            </div>
            <br />
            <TextInput
              value={journalFormState?.editedName}
              placeholder={DEFAULT_JOURNAL_INPUT.editTitleBlank}
              id={"journal-name-edit-input"}
              onChange={handleNameChange}
              css={styles.input}
            />
            <br />
            <TextInput
              value={journalFormState?.editedDescription}
              placeholder={DEFAULT_JOURNAL_INPUT.editDescriptionBlank}
              id={"journal-description-edit-input"}
              onChange={handleDescriptionChange}
              css={styles.input}
            />
          </div>
          <div css={styles.buttonWrapper}>
            {createNewJournal ? (
              <button
                css={styles.confirmButton}
                onClick={handleNewJournalSave}
              >
                Create Journal
              </button>
            ) : (
              <>
                <button
                  css={styles.confirmButton}
                  onClick={handleEditSave}
                >
                  Save Changes
                </button>
                <button
                  onClick={toggleDeleteConfirmationModal}
                  css={styles.deleteButton}
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {showConfirmDelete && (
            <GenericModal
              onClose={toggleDeleteConfirmationModal}
              onConfirm={handleDelete}
              title="Warning!"
              isWarning={true}
              confirmText={"Delete"}
            >
              <span>This action will permanently delete this journal and everything in it.</span>
            </GenericModal>
          )}
        </>
      )}
      {putJournal.loading && <Loader message={"Saving..."} />}
      {renderWarningModals()}
    </div>
  );
};
export default JournalCreatorEditor;
