import {
  ApiError as ReduxApiError,
  createAction,
  RSAACall,
  RSAAFailureType,
  RSAARequestType,
  RSAAResultAction,
  RSAASuccessType,
} from "redux-api-middleware";
import { Action } from "@reduxjs/toolkit";
import { AppState } from "./appState";
import { ActionType } from "./actionType";
import { assocPath, dissocPath, path } from "ramda";

export interface Response {
  messages: string[];
  success: boolean;
}

/**
 * A model for an errored HTTP request -- of the type that we put into Redux.
 */
export interface ApiError<T = any> {
  requestId: string | number;
  actionType: ActionType;
  errorMsg: string[];
  extras?: T;
  retryCount?: number;
}

/**
 * The type of the response when using a common API hook (some API hooks may not make sense to use this type)
 */
export interface ApiHookReturn<T> {
  loading: boolean;
  errors: string[] | undefined;
  value: T | undefined;
  reload: () => void;
}

/**
 * Predicate returning whether or not the given action is an API response and has the given status code.
 */
export const isResponseWithStatusCode = (action: Action, statusCode: number) => {
  const resultAction = action as RSAAResultAction;
  const payload = path(["payload"], resultAction) as ReduxApiError;

  if (!resultAction) {
    return false;
  }

  if (!payload) {
    return false;
  }

  const responseStatus = path(["status"], payload) as number | undefined;

  if (!responseStatus) {
    return false;
  }

  return responseStatus === statusCode;
};
const isResponseWithStatusCodeRange = (action: Action, minStatusCode: number, maxStatusCode: number): boolean => {
  const resultAction = action as RSAAResultAction;
  const payload = path(["payload"], resultAction) as ReduxApiError;

  if (!resultAction) {
    return false;
  }

  if (!payload) {
    return false;
  }

  const responseStatus = path(["status"], payload) as number | undefined;

  if (!responseStatus) {
    return false;
  }
  return responseStatus >= minStatusCode && responseStatus <= maxStatusCode;
};

/**
 * Predicate returning whether or not the given action is an API Response with Status Code 401 (forbidden)
 */
export const is200Response = (action: Action) => isResponseWithStatusCode(action, 200);
export const is400Response = (action: Action) => isResponseWithStatusCode(action, 400);
export const is401Response = (action: Action) => isResponseWithStatusCode(action, 401);
export const is403Response = (action: Action) => isResponseWithStatusCode(action, 403);
export const is500Response = (action: Action) => isResponseWithStatusCode(action, 500);
export const wasSuccessful = (action: Action) => isResponseWithStatusCodeRange(action, 200, 299);
/**
 * Constructs an action that, when dispatched, RSAA Middleware intercepts and turns into an HTTP Request.
 */
interface ActionWithErrors extends Action {
  error?: boolean;
}
export const makeApiAction = <TPayload = any, TMeta = undefined>(
  request: RSAACall<AppState, TPayload, TMeta>
): ActionWithErrors => createAction<AppState, TPayload, TMeta>(request) as unknown as ActionWithErrors; // Redux needs this to be an Action even though it doesn't have a `type` attribute

/**
 * Internal type for a function that maps an ActionType into an RSAA Action Type
 */
type RsaaTypeMapper<TPayload, TMeta> = (action: ActionType) => RSAARequestType<AppState, TPayload, TMeta>;

/**
 * Each RSAA Action results in 2 Redux actions being dispatched:
 * 1. The action at index 0 to indicate the HTTP request has been made and is in progress
 * 2. A result action to indicate success/failure of the HTTP request, including the server response
 *    Index 1 = Successful Response Action Type
 *    Index 2 = Unsuccessful Response Action Type
 */
export type RSAATypes<TPayload = any, TMeta = undefined> = [
  RSAARequestType<AppState, TPayload, TMeta>,
  RSAASuccessType<AppState, TPayload, TMeta>,
  RSAAFailureType<AppState, TPayload, TMeta>
];

/**
 * A function that helps turn ActionTypes into RSAATypes, optionally with some meta.
 * @param actions - The Redux ActionTypes RSAA should dispatch to show the state of the HTTP request
 * @param meta - An optional object (or other thing) to pass along on each Redux action to the reducer
 *               (useful for tracking e.g. which product this request was made for)
 */
export const makeRsaaTypes = <TPayload = any, TMeta = undefined>(
  actions: ActionType[],
  meta?: TMeta
): RSAATypes<TPayload, TMeta> => {
  // Map meta into an object, otherwise a good old fashioned string[] is fine
  const mapper: RsaaTypeMapper<TPayload, TMeta> = meta
    ? (action) => ({ type: action, meta: meta })
    : (action) => action as string;

  // RSAATypes is an array of length 3, and map returns an array of length indefinite -- so we cast :(
  return actions.map(mapper) as RSAATypes<TPayload, TMeta>;
};

/**
 * A function of the kind accepted by `makeApiReducer` that takes information about a reduced API action such as whether
 * or not there was an HTTP error, whether we're waiting on a response, what the current redux store looks like...
 */
export type ApiReducer<TPayload = any, TMeta = undefined> = (
  state: AppState,
  action: RSAAResultAction<TPayload, TMeta> & { meta: TMeta }, // RSAA typing missed this property for error actions
  errorMsgs: string[] | undefined,
  isLoading: boolean,
  payload: TPayload | undefined
) => AppState;

/**
 * A model for a loading HTTP request -- of the type that we put into Redux.
 */
export interface ApiLoading<T = any> {
  requestId: string | number;
  actionType: ActionType;
  extras?: T;
}

/**
 * When we store loading/error information into Redux, we need a key to associate that information with for easy lookup.
 * This function makes that key.
 */
export const keyFor = (actionType: ActionType, requestId: string | number | ActionType): string => `${actionType}-${requestId}`;

/**
 * Retrieves loading information (if it exists) from Redux for the specified request
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 */
export const getLoading = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined
): ApiLoading | undefined => state.loading[keyFor(actionType, requestId ?? actionType)];

/**
 * Adds loading state to AppState, producing a new AppState. This also removes error from app state, as it doesn't make
 * sense to have both loading and error.
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 * @param extras - Any extras you want to track with the error
 */
export const addLoading = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined,
  extras?: any
): AppState => {
  const key = keyFor(actionType, requestId ?? actionType);

  return dissocPath(
    [],
    assocPath(
      ["loading", key],
      {
        requestId: requestId,
        actionType: actionType,
        extras: extras,
      } as ApiLoading, // Cast so the type checker gets angry if we ever change ApiLoading
      state
    )
  );
};

/**
 * Produces a new app state without the given loading information inside
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 */
export const removeLoading = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined
): AppState => dissocPath(["loading", keyFor(actionType, requestId ?? actionType)], state);

/**
 * Adds an error to AppState, producing a new AppState. This also removes loading from app state, as it doesn't make
 * sense to have both loading and error.
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param errorMsg - The error message proper
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 * @param extras - Any extras you want to track with the error
 */
export const addError = (
  state: AppState,
  actionType: ActionType,
  errorMsg: string[],
  requestId: string | number | undefined = undefined,
  extras?: any
): AppState => {
  const key = keyFor(actionType, requestId ?? actionType);
  const oldError = getError(state, actionType, requestId);
  let retryCount = oldError?.retryCount !== undefined ? oldError?.retryCount : 0;
  const newRetryCount = retryCount !== undefined ? retryCount + 1 : 0;
  return dissocPath(
    ["loading", key],
    assocPath(
      ["error", key],
      {
        requestId: requestId,
        actionType: actionType,
        errorMsg: errorMsg,
        extras: extras,
        retryCount: newRetryCount,
      } as ApiError, // Cast so the type checker gets angry if we ever change ApiError
      state
    )
  );
};

/**
 * Produces a new app state without the given error information inside
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 */
export const removeError = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined
): AppState => dissocPath(["error", keyFor(actionType, requestId ?? actionType)], state);

/**
 * Retrieves error information (if it exists) from Redux for the specified request
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 */
export const getError = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined
): ApiError | undefined => state.error[keyFor(actionType, requestId ?? actionType)];

/**
 * Removes both the loading and error info from state for the specified request, producing a new AppState
 * @param state - The app state to use to make a new App state
 * @param actionType - The Redux action type that triggered this thing
 * @param requestId - the optional unique ID of the request (defaults to ActionType)
 */
export const noLoadingNoError = (
  state: AppState,
  actionType: ActionType,
  requestId: string | number | undefined = undefined
): AppState => {
  const key = keyFor(actionType, requestId ?? actionType);

  return dissocPath(["error", key], dissocPath(["loading", key], state));
};

/**
 * Constructs a reducer for handling actions from ReduxApiMiddleware.
 * @param actions - The list of actions you care about
 * @param loadingAction - The API Middleware's loading action
 * @param withResults - Your reducer logic
 * @param defaultErrorMsg - Sometimes the API returns no error message -- here's where you can use this!
 */
export const makeApiReducer =
  <TPayload = any, TMeta = undefined>(
    actions: ActionType[],
    loadingAction: ActionType,
    withResults: ApiReducer<TPayload, TMeta>,
    defaultErrorMsg: string = ""
  ) =>
  (state: AppState, incomingAction: Action): AppState => {
    if (!actions.includes(incomingAction.type)) {
      return state;
    }

    const action = incomingAction as RSAAResultAction<TPayload, TMeta>;
    const payload = path(["payload"], action) as TPayload;

    const isLoading = action.type === loadingAction && !action.error;

    // Try to read an error off the response, otherwise return the default error -- if !success
    const errorMsgs =
      !isLoading && (action.error || path<boolean>(["success"], payload) === false)
        ? ([] as string[]).concat(path<string[]>(["messages"], payload) ?? defaultErrorMsg)
        : undefined;

    return withResults(
      state,
      action as RSAAResultAction<TPayload, TMeta> & { meta: TMeta; error?: boolean }, // RSAA typing missed this property for
      // error actions
      errorMsgs,
      isLoading,
      payload
    );
  };
