/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import theme from '../Theme';
import { hexToRGBA } from '../Utils/module';
import { useJournals } from './Actions/getJournalsAction';
import Loader from '../Utils/Loader';
import GenericModal from '../modal/GenericModal';
import { useMoveEntry } from './Actions/moveEntryAction';
import { EntryWithJournalId } from '../Utils/types';
import { useHistory } from 'react-router-dom';

const styles = {
	container: css`
		display: flex;
		flex-direction: column;
	`,
	confirmContainer: css`
		display: flex;
		flex-direction: row;
	`,
	journalToEdit: (color: string) => css`
		color: ${color || theme.fontColor.text};
		background-color: ${theme.colors.contentBackground};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px;
		cursor: pointer;

		&:hover {
			background-color: ${hexToRGBA(color ? color : theme.palette.primary.light, 0.2)};
		}
	`,
};

interface Props {
	handleCloseModal: () => void;
	entry: EntryWithJournalId;
	setNewURL: (url: string) => void;
}

const MoveEntryModal: React.FC<Props> = ({ handleCloseModal, entry, setNewURL }) => {
	const [selectedJournal, setSelectedJournal] = useState<string>('');

	const history = useHistory();

	const journalHook = useJournals();
	const moveJournalHook = useMoveEntry(entry.journalId, selectedJournal, entry.journalEntry);
	const journals = journalHook.value;
	const handleJournalSelect = useCallback((journal: string) => {
		setSelectedJournal(journal);
	}, []);
	const handleConfirm = useCallback(() => {
		if (entry && selectedJournal) {
			moveJournalHook.value && moveJournalHook.value();
		}
	}, [entry, moveJournalHook, selectedJournal]);

	useEffect(() => {
		if (moveJournalHook.loading) {
			setNewURL('/journalEntry/' + selectedJournal + '/' + entry.journalEntry.journalEntryId);
		}
	}, [
		setNewURL,
		entry.journalId,
		history,
		moveJournalHook.errors,
		moveJournalHook.loading,
		selectedJournal,
		entry.journalEntry.journalEntryId,
	]);

	if (journalHook.loading) {
		return <Loader />;
	}

	if (selectedJournal)
		return (
			<GenericModal
				onClose={handleCloseModal}
				title={'Please confirm move'}
				onBack={() => setSelectedJournal('')}
				onConfirm={handleConfirm}
				confirmText={'Move it!'}
				// css={styles.modal(journal.color)}
			>
				<div css={styles.container}>
					<div css={styles.confirmContainer}></div>
					{moveJournalHook.errors && moveJournalHook.errors.length && moveJournalHook.errors.map((error) => <span>{error}</span>)}
				</div>
			</GenericModal>
		);
	return (
		<GenericModal
			noButtons={true}
			onClose={handleCloseModal}
			title={'Move Entry?'}
		>
			{!selectedJournal && (
				<div css={styles.container}>
					<span>Please select destination journal:</span>
					{journals?.map((journal) => {
						if (journal.id === entry.journalId) return null;
						return (
							<div
								css={styles.journalToEdit(journal.color)}
								onClick={() => handleJournalSelect(journal.id)}
								key={journal.id}
							>
								<span>{journal.name || 'Journal Not Yet Named'}</span>
							</div>
						);
					})}
				</div>
			)}
		</GenericModal>
	);
};

export default MoveEntryModal;
