/** @jsxImportSource @emotion/react */

import { css, ThemeProvider } from "@emotion/react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./Theme";
import Authenticator from "./login/Authenticator";
import AppHeader from "./Navigation/AppHeader";
import AppRoutes from "./Navigation/AppRoutes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CLIENT_ID } from "./google/module";

const styles = {
  app: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: ${theme.colors.background};
    font-family: ${theme.typography.fontFamily};
    overflow: hidden;
    text-wrap: pretty;
  `,
};

const App: React.FC = () => {
  return (
    <div css={styles.app}>
      <Router>
        <Authenticator>
          <ThemeProvider theme={theme}>
            <GoogleOAuthProvider clientId={CLIENT_ID}>
              <AppRoutes />
            </GoogleOAuthProvider>
          </ThemeProvider>
        </Authenticator>
      </Router>
    </div>
  );
};

export default React.memo(App);
