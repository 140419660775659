/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback } from "react";
import { useSlate } from "slate-react";
import { css } from "@emotion/react";
import theme from "../../Theme";
import { isMarkActive, toggleMark } from "./richTextModule";
import { CombinedElementTypes } from "./richTextTypesModule";

const styles = {
  button: (active: boolean) => css`
    cursor: pointer;
    background-color: ${!active ? theme.colors.background : theme.palette.toolbars.activeBackground};
    color: ${!active ? theme.colors.darkGray : theme.palette.toolbars.active};
    display: flex;
    align-items: center;
    border: none;
    padding: 10px;

    > span {
      font-size: 18px;
    }
    &:hover {
      background-color: ${!active ? theme.palette.toolbars.hover : theme.palette.toolbars.activeBackground};
    }
  `,
};

interface Props {
  format: CombinedElementTypes;
  icon: string;
}

const RichTextMarkButton: React.FC<Props> = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      toggleMark(editor, format);
    },
    [editor, format]
  );

  return (
    <button
      css={styles.button(isActive)}
      onMouseDown={handleMouseDown}
    >
      <span className="material-icons">{icon}</span>
    </button>
  );
};

export default RichTextMarkButton;
