export const movementOptions = [
  {
    name: 'Sweeps/Reversals',
  },
  {
    name: 'Takedowns',
  },
  {
    name: 'Judo Throws',
  },
  {
    name: 'Guard Passes',
  },
];



