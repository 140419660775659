/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { mapMenuItems, radioButtonStyle, TAG_CATEGORY_TYPES, tagCategoryOptions } from './tagModule';
import { positionPriorityOptions } from '../bjjModules/positionModule';
import { bodyPartOptions } from '../bjjModules/bodyModule';
import { css } from '@emotion/react';
import { movementOptions } from '../bjjModules/movementModule';
import TextInput from '../Utils/TextInput';
import { usePostUserTag } from './Actions/postUserTagAction';
import { useAppState } from '../store/hooks';
import theme from '../Theme';

const styles = {
	root: css`
		display: flex;
		flex-direction: column;
		margin: 10px 25px;
		gap: 20px;
	`,
	title: css`
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	`,
	inputField: css`
		margin: 10px 0 0 0;
		width: 100%;
		position: relative;
		padding: 6px 6px 6px 0;
		border-radius: 3px;
		border-color: lightgray;
		border-width: 2px;

		&:hover {
			border-color: black;
		}
	`,
	radioWrapper: css`
		display: flex;
		flex-direction: row;
		margin-top: 10px;
	`,
	radioButton: (selected?: boolean) => radioButtonStyle(selected),
	buttonWrapper: css`
		display: flex;
		flex-direction: row;
		gap: 16px;
		justify-content: flex-end;
		margin-top: auto;
	`,
	saveButton: css`
		padding: 5px;
		background-color: ${theme.palette.primary.light};
		color: ${theme.colors.contentBackground};
		border-radius: 6px;
		border: none;
		width: 75px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.palette.primary.dark};
		}
	`,
	cancelButton: css`
		padding: 5px;
		border-radius: 6px;
		border: none;
		width: 75px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.colors.lightGrayHover};
		}
	`,
	inputLabel: css`
		color: rgba(0, 0, 0, 0.6);
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.4375em;
		letter-spacing: 0.00938em;
		padding: 0;
		display: block;
		transform-origin: top left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: calc(133% - 24px);
		position: absolute;
		left: 0;
		top: 0;
		transform: translate(14px, -9px) scale(0.75);
		transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		pointer-events: none;
	`,
};

export const renderMenu = (
	id: string,
	placeholder: string,
	value: string,
	onChange: (event: any) => void,
	options?: { name: string }[]
) => {
	if (!options) return null;
	return (
		<div css={styles.inputField}>
			<label css={styles.inputLabel}>{placeholder}</label>
			<select
				css={styles.inputField}
				required
				// placeholder={placeholder}
				id={id}
				name={id}
				value={value}
				onChange={onChange}
			>
				{mapMenuItems(options)}
			</select>
		</div>
	);
};

interface Props {
	tagName: string;
	handleClose: () => void;
	positionOnly?: boolean;
	submissionOnly?: boolean;
	movementOnly?: boolean;
	setNewTagValue?: (value: string | undefined) => void;
}

const PostUserTagModal: React.FC<Props> = ({
	tagName,
	handleClose,
	positionOnly = false,
	submissionOnly = false,
	movementOnly = false,
	setNewTagValue,
}) => {
	const [tagInput, setTagInput] = useState(tagName);
	const initialTagType = () => {
		if (positionOnly) return TAG_CATEGORY_TYPES.position;
		if (submissionOnly) return TAG_CATEGORY_TYPES.submission;
		if (movementOnly) return TAG_CATEGORY_TYPES.movement;
		return tagCategoryOptions[0].name;
	};
	const [tagType, setTagType] = useState(initialTagType());

	const [selectedPriority, setSelectedPriority] = useState('');
	const [selectedBodyPart, setSelectedBodyPart] = useState('');
	const [selectedMovement, setSelectedMovement] = useState('');

	const tagState = useAppState((state) => state.tags);

	const postUserTag = usePostUserTag({
		name: tagInput?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()), //converts it to title case
		tagType,
		category: selectedBodyPart || selectedPriority || selectedMovement || '',
		bodyPart: selectedBodyPart,
	});

	const resetState = useCallback(() => {
		setTagInput('');
		setTagType(tagCategoryOptions[0].name);
		setSelectedPriority('');
		setSelectedBodyPart('');
		setSelectedMovement('');
	}, []);

	const closeModal = useCallback(() => {
		resetState();
		handleClose();
	}, [handleClose, resetState]);

	const handleSave = useCallback(async () => {
		if (tagInput) {
			const tagIsUnique = !tagState?.find((tag) => tag.name.toLowerCase() === tagInput.toLowerCase());
			if (!tagIsUnique) {
				alert('Tag name is a duplicate, please select another');
			} else if (tagType) {
				await postUserTag.value().then((res) => {
					if (!res?.error) {
						resetState();
						return handleClose();
					}
					alert('There was an error. Please try again.');
				});
			}
		}
	}, [handleClose, tagType, postUserTag, tagInput, tagState, resetState]);

	const handleChangeSubBodyPart = useCallback((event: any) => {
		setSelectedBodyPart(event.target.value);
	}, []);

	const handleChangePosition = useCallback((event: any) => {
		setSelectedPriority(event.target.value);
	}, []);

	const handleChangeMovement = useCallback((event: any) => {
		setSelectedMovement(event.target.value);
	}, []);

	const handleTagNameChange = useCallback(
		(event: any) => {
			setTagInput(event.target.value);
			setNewTagValue && setNewTagValue(event.target.value);
		},
		[setNewTagValue]
	);

	const handleChangeLabelCategory = useCallback((label: string) => {
		setTagType('');
		label === tagCategoryOptions[0].name ? setSelectedPriority(positionPriorityOptions[0].name) : setSelectedPriority('');
		label === tagCategoryOptions[2].name ? setSelectedMovement(movementOptions[0].name) : setSelectedMovement('');
		label === tagCategoryOptions[1].name ? setSelectedBodyPart(bodyPartOptions[0].name) : setSelectedBodyPart('');
		setTagType(label);
	}, []);

	const renderChildMenu = useCallback(
		(selection: string) => {
			if (positionOnly) {
				return renderMenu('category', 'Positional priority', selectedPriority, handleChangePosition, positionPriorityOptions);
			}
			if (submissionOnly) {
				return renderMenu('category', 'Body part', selectedBodyPart, handleChangeSubBodyPart, bodyPartOptions);
			}
			if (movementOnly) {
				return renderMenu('category', 'Movement Type', selectedMovement, handleChangeMovement, movementOptions);
			}
			switch (selection) {
				case TAG_CATEGORY_TYPES.submission: {
					return renderMenu('body-part', 'Submission Target', selectedBodyPart, handleChangeSubBodyPart, bodyPartOptions);
				}
				case TAG_CATEGORY_TYPES.position: {
					return renderMenu('category', 'Positional priority', selectedPriority, handleChangePosition, positionPriorityOptions);
				}
				case TAG_CATEGORY_TYPES.movement: {
					return renderMenu('category', 'Movement Type', selectedMovement, handleChangeMovement, movementOptions);
				}
				case TAG_CATEGORY_TYPES.custom: {
					return null;
				}
				default:
					return null;
			}
		},
		[
			handleChangeMovement,
			handleChangePosition,
			handleChangeSubBodyPart,
			selectedBodyPart,
			selectedMovement,
			selectedPriority,
			positionOnly,
			submissionOnly,
			movementOnly,
		]
	);

	return (
		<div css={styles.root}>
			<TextInput
				required={true}
				value={tagInput}
				placeholder={'Tag Name'}
				id={'tag-input'}
				onChange={handleTagNameChange}
			/>
			{!positionOnly && !submissionOnly && !movementOnly && (
				<div css={styles.inputField}>
					<label css={styles.inputLabel}>Tag Category</label>
					<div css={styles.radioWrapper}>
						{tagCategoryOptions.map((label) => (
							<div
								css={styles.radioButton(tagType === label.name)}
								onClick={() => handleChangeLabelCategory(label.name)}
								key={label.name}
							>
								<span>{label.name}</span>
							</div>
						))}
					</div>
				</div>
			)}
			{renderChildMenu(tagType)}
			<div css={styles.buttonWrapper}>
				<button
					css={styles.cancelButton}
					onClick={closeModal}
				>
					cancel
				</button>
				<button
					css={styles.saveButton}
					onClick={handleSave}
				>
					save
				</button>
			</div>
		</div>
	);
};

export default PostUserTagModal;
