/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useState} from 'react';
import GenericModal from '../modal/GenericModal';
import {AutocompleteOptionType, DEFAULT_ADD_OPTION} from '../Utils/Autocomplete';
import TagAutofill from '../Tags/TagAutofill';
import {TagType} from '../Utils/types';
import {useGetTags} from '../Tags/Actions/getTagsAction';
import {useLoading} from '../store/hooks';
import {ActionType} from '../store/actionType';
import Loader from '../Utils/Loader';
import {
  Dominance,
  DraftSparActions,
  DraftSparState,
  initiatorEnumAsArray,
  MoveInitiator,
  renderSetPosition,
} from './module';
import {modalStyles} from './VideoModalStyles';


interface Props {
  handleSetMovementModalClose: () => void,
  handleMovementChange: () => void,
  dispatch: React.Dispatch<DraftSparActions>,
  newMovementTagValue?: string,
  newPositionalTagValue?: string,
  state: DraftSparState,
}

const SetMovementModal: React.FC<Props> = (
  {
    handleSetMovementModalClose,
    handleMovementChange,
    newMovementTagValue,
    newPositionalTagValue,
    dispatch,
    state,
  }) => {
  
  const [autofillMovementValue, setAutofillMovementValue] = useState<string | undefined>('');
  const [autofillPositionValue, setAutofillPositionValue] = useState<string | undefined>('');
  
  const postTagIsLoading = useLoading(ActionType.PostUserTagRequest);
  
  const {value: tags} = useGetTags();
  
  const handlePositionAutofillChange = useCallback((position: string | undefined) => {
    setAutofillPositionValue(position);
    dispatch({type: 'setState', data: {selectedPositionalTagValue: position, selectedPositionalTagId: undefined}});
  }, [dispatch]);
  
  const handleMovementAutofillChange = useCallback((movement: string | undefined) => {
    setAutofillMovementValue(movement);
    dispatch({type: 'setState', data: {selectedMovementTagValue: movement, selectedMovementTagId: undefined}});
  }, [dispatch]);
  
  const handleMovementClick = useCallback((event: any) => {
    const id = event.target.id;
    if (id && id === DEFAULT_ADD_OPTION) {
      dispatch({type: 'setState', data: {showMovementAddTagModal: true}});
    } else {
      const tagName = tags?.find(tag => tag.id === id)?.name;
      dispatch({type: 'setState', data: {selectedMovementTagId: id, selectedMovementTagValue: tagName}});
      setAutofillMovementValue(tagName);
    }
  }, [tags, dispatch]);
  
  const handleMovementEnterPress = useCallback((option: AutocompleteOptionType) => {
    const tag = option?.option as TagType;
    if (!option.userInput) {
      dispatch({type: 'setState', data: {selectedMovementTagId: tag.id, selectedMovementTagValue: tag.name}});
      setAutofillMovementValue(tag.name);
    } else {
      dispatch({type: 'setState', data: {showMovementAddTagModal: true}});
      
    }
  }, [dispatch]);
  
  
  const handleSelectInitiatedBy = useCallback((option: MoveInitiator) => {
    dispatch({type: 'setState', data: {selectedMovementInitiatedBy: option}});
  }, [dispatch]);
  
  const handleSelectDominance = useCallback((option: Dominance) => {
    dispatch({type: 'setState', data: {selectedPositionalDominance: option}});
  }, [dispatch]);
  
  const handleSelectMovementSuccess = useCallback((option: boolean) => {
    dispatch({type: 'setState', data: {selectedMovementSuccess: option}});
  }, [dispatch]);
  
  const handlePositionClick = useCallback((event: any) => {
    const id = event.target.id;
    if (id && id === DEFAULT_ADD_OPTION) {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
    } else {
      const tagName = tags?.find(tag => tag.id === id)?.name;
      dispatch({type: 'setState', data: {selectedPositionalTagId: id, selectedPositionalTagValue: tagName}});
      setAutofillPositionValue(tagName);
    }
  }, [tags, dispatch]);
  
  const handlePositionEnterPress = useCallback((option: AutocompleteOptionType) => {
    const tag = option?.option as TagType;
    if (!option.userInput) {
      dispatch({type: 'setState', data: {selectedPositionalTagId: tag.id, selectedPositionalTagValue: tag.name}});
      setAutofillPositionValue(tag.name);
    } else {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
    }
  }, [dispatch]);
  
  const getIsDisabled = () => {
    let isDisabled = false;
    if (state.selectedMovementInitiatedBy === undefined || state.selectedMovementInitiatedBy === null) {
      isDisabled = true;
    }
    if (!state.selectedMovementTagId) {
      isDisabled = true;
    }
    if (state.selectedMovementSuccess === undefined) {
      isDisabled = true;
    }
    if (state.selectedMovementSuccess === true) {
      if (state.selectedPositionalDominance === undefined) {
        isDisabled = true;
      }
      if (state.selectedPositionalDominance !== Dominance.neutral) {
        if (!state.selectedPositionalTagId) {
          isDisabled = true;
        }
      }
    }
    return isDisabled;
  };
  
  useEffect(() => {
    const currentMovementTag = tags?.find(tag => tag.name.toLocaleLowerCase() === autofillMovementValue?.toLocaleLowerCase());
    if (currentMovementTag) {
      return dispatch({type: 'setState', data: {selectedMovementTagId: currentMovementTag.id}});
    }
    return dispatch({type: 'setState', data: {selectedMovementTagId: ''}});
  }, [tags, autofillMovementValue, dispatch]);
  
  useEffect(() => {
    const currentPositionTag = tags?.find(tag => tag.name.toLocaleLowerCase() === autofillPositionValue?.toLocaleLowerCase());
    if (currentPositionTag) {
      return dispatch({type: 'setState', data: {selectedPositionalTagId: currentPositionTag.id}});
    }
    return dispatch({type: 'setState', data: {selectedPositionalTagId: ''}});
  }, [tags, autofillPositionValue, dispatch]);
  
  useEffect(() => {
    setAutofillMovementValue(newMovementTagValue);
  }, [newMovementTagValue]);
  
  useEffect(() => {
    setAutofillPositionValue(newPositionalTagValue);
  }, [newPositionalTagValue]);
  return (
    <GenericModal
      onClose={handleSetMovementModalClose}
      title={'Log a movement attempt'}
      confirmText={'Log it!'}
      onConfirm={handleMovementChange}
      css={modalStyles.modalContainer}
      isDisabled={getIsDisabled()}
    >
      {postTagIsLoading && <Loader message={'Adding Tag...'}/>}
      <div css={[modalStyles.modal, modalStyles.modalBody]}>
        <span css={modalStyles.label}>Who initiated the movement?</span>
        <div css={modalStyles.radioButtonContainer}>
          {initiatorEnumAsArray.map(option => (
            <div
              key={option}
              css={modalStyles.radioButton(state.selectedMovementInitiatedBy === MoveInitiator[option])}
              onClick={() => handleSelectInitiatedBy(MoveInitiator[option])}
            >
              <span>{option}</span>
            </div>
          ))}
        </div>
        <span css={modalStyles.label}>Attempted movement was:</span>
        <TagAutofill
          onChange={handleMovementAutofillChange}
          onClick={handleMovementClick}
          value={autofillMovementValue}
          onEnterPress={handleMovementEnterPress}
          labelText={'Select Movement'}
          movementsOnly={true}
          ignoreOnBlur={true}
          showBorder={true}
          hideLabelOnFocus={true}
        />
        <span css={modalStyles.label}>And it was...</span>
        <div css={modalStyles.radioButtonContainer}>
          <div
            css={modalStyles.radioButton(state.selectedMovementSuccess)}
            onClick={() => handleSelectMovementSuccess(true)}
          >
            <span>Successful</span>
          </div>
          <div
            css={modalStyles.radioButton(state.selectedMovementSuccess === false)}
            onClick={() => handleSelectMovementSuccess(false)}
          >
            <span>Defended</span>
          </div>
        </div>
        {state.selectedMovementSuccess !== undefined
          && (
            <>
              {renderSetPosition({
                autofillValue: autofillPositionValue,
                handleAutofillChange: handlePositionAutofillChange,
                handleClick: handlePositionClick,
                handleEnterPress: handlePositionEnterPress,
                handleSelectDominance,
                state,
                fromPositionModal: true,
              })}
            </>
          )
        }
      </div>
    </GenericModal>
  );
};
export default SetMovementModal;
