import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {DELETE_PROMOTION_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';

export interface ActionProps {
  userProfileId: string,
  promotionId: string,
}

const KEY_ACTION = ActionType.DeletePromotionRequest;
const ACTIONS = [
  ActionType.DeletePromotionRequest,
  ActionType.DeletePromotionResponse,
  ActionType.DeletePromotionError,
];

interface Payload extends Response {
}

export const deletePromotionAction = (body: ActionProps, token?: string) => makeApiAction<Payload, ActionProps>({
  method:   'DELETE',
  endpoint: () => DELETE_PROMOTION_ENDPOINT(body.userProfileId, body.promotionId),
  headers:  () => ({
    'content-type':  'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body:     JSON.stringify(body),
  types:    makeRsaaTypes<Payload, ActionProps>(ACTIONS, body),
  bailout:  state => !!getLoading(state, KEY_ACTION)
    || !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, ActionProps> = (state, action, errorMsg, isLoading ) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!state.userProfile) {
    return addError(state, KEY_ACTION, ['DELETE failure: no user profile']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  return {
    ...successState,
    userProfile: {
      ...state.userProfile, promotions: state.userProfile?.promotions.filter((promotion) =>
        promotion.promotionId !== action.meta.promotionId,
      ),
    },
  };
};

export const deletePromotionReducer = makeApiReducer<Payload, ActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to create promotion.',
);

export const useDeletePromotion = (
  userProfileId: string,
  promotionId: string,
): ApiHookReturn<() => void> => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  
  const dispatch = useDispatch();
  
  const deletePromotion = useCallback(() => {
    //TODO update dispatch
    
    // userProfileId && promotionId && dispatch(deletePromotionAction({
    //   userProfileId,
    //   promotionId,
    // }, token));
  }, [userProfileId, promotionId, dispatch, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: deletePromotion, reload: deletePromotion,
  };
};