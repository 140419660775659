import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  is500Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';

export interface RevokeGoogleAccessTokenActionProps {
  requestId: string,
  token: string, //google API token
}


const KEY_ACTION = ActionType.Google_RevokeAccessTokenRequest;
const ACTIONS = [
  ActionType.Google_RevokeAccessTokenRequest,
  ActionType.Google_RevokeAccessTokenResponse,
  ActionType.Google_RevokeAccessTokenError,
];

interface Payload extends Response {
  data?: null | {
    success: boolean,
    error?: string,
    errorDescription?: string
  }
}

export const revokeGoogleAccessTokenAction = (
  body: RevokeGoogleAccessTokenActionProps,
) =>
  makeApiAction<Payload, RevokeGoogleAccessTokenActionProps>({
    method: 'POST',
    endpoint: () => ENDPOINTS.RevokeGoogleAccessToken,
    headers: (state: AppState) => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${state.auth?.accessToken}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<Payload, RevokeGoogleAccessTokenActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, KEY_ACTION, body.requestId)
      || !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<Payload, RevokeGoogleAccessTokenActionProps> = (
  state,
  action,
  errorMsg,
  isLoading,
  payload,
) => {

  if (isLoading) {
    return addLoading(state, KEY_ACTION, action.meta?.requestId);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION, action.meta?.requestId);
  }
  if (is500Response(action)) {
    return removeLoading(state, KEY_ACTION, action.meta?.requestId);
  }

  if (errorMsg && errorMsg[0] !== 'Token expired or revoked') {
    return addError(state, KEY_ACTION, errorMsg, action.meta?.requestId);
  }

  if (payload?.data?.error
    && payload.data.errorDescription
    && payload.data.errorDescription !== 'Token expired or revoked'
  ) {
    return addError(state, KEY_ACTION, [payload.data?.errorDescription], action.meta?.requestId);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ['revoke token Error'], action.meta?.requestId);
  }

  const successState = noLoadingNoError(state, KEY_ACTION, action.meta?.requestId);
  if (!state.user) return successState;
  return {
    ...successState, user: undefined, google: undefined,
  };

};

export const Google_revokeAccessTokenReducer = makeApiReducer<Payload, RevokeGoogleAccessTokenActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to revoke google access token.',
);
export const REVOKE_GOOGLE_TOKEN_REQUEST_ID = 'Google_revokeAccessToken';
export const useRevokeGoogleAccessToken = (): ApiHookReturn<() => void> => {

  const dispatch = useDispatch();
  const requestId = REVOKE_GOOGLE_TOKEN_REQUEST_ID;
  const token = useAppState(state => state.user?.googleApi?.accessToken);

  const revokeToken = useCallback(() => {
    token && dispatch(
      revokeGoogleAccessTokenAction({token: token, requestId: requestId}),
    );
  }, [dispatch, requestId, token]);

  const loading = useLoading(KEY_ACTION, requestId);
  const errors = useErrors(KEY_ACTION, requestId);

  return {
    loading, errors, value: revokeToken, reload: revokeToken,
  };
};