/** @jsxImportSource @emotion/react */

import React from "react";
import { ENTRY_TYPE, TagType, VideoNote } from "../Utils/types";
import { DateTime } from "luxon";
import { PLAYBACK_RATE_OPTIONS } from "../Journal/VideoNoteTaker/VideoNoteTaker";
import { modalStyles } from "./VideoModalStyles";
import TagAutofill from "../Tags/TagAutofill";
import { AutocompleteOptionType } from "../Utils/Autocomplete";
import { getTagNameFromId } from "../Journal/module";

export enum Dominance {
  attacking,
  defending,
  neutral = 2,
}

export enum MoveInitiator {
  you,
  opponent,
}

export interface SparState {
  videoDuration: number;
  played: number;
  loadedPosition: number;
  playedPosition: number;
  isPlaying: boolean;
  isMuted: boolean;
  playbackRate: PLAYBACK_RATE_OPTIONS;
  currentRound?: Partial<Round>;
  minimumScrollTime?: number;
  maximumScrollTime?: number;
  errorCode: string | null;
  seeking: boolean;
}

export interface DraftSparState extends SparState {
  updatedNote?: number;
  createdNote: VideoNote | undefined;
  showDraftNote: boolean;
  previousRoundIsComplete: boolean;
  showStartRoundModal: boolean;
  selectedStartingOption: StartingOptionsEnum;
  selectedPositionalTagId: string | undefined;
  selectedPositionalTagValue: string | undefined;
  selectedPositionalDominance: Dominance;
  selectedMovementTagId: string | undefined;
  selectedMovementTagValue: string | undefined;
  selectedMovementSuccess: boolean | undefined;
  selectedMovementInitiatedBy: MoveInitiator | undefined;
  selectedSubmissionTagId: string | undefined;
  selectedSubmissionTagValue: string | undefined;
  selectedSubmissionSuccess: boolean | undefined;
  selectedSubmissionInitiatedBy: MoveInitiator | undefined;
  selectedRollType: RollType | undefined;
  showPositionalAddTagModal: boolean;
  showSubmissionAddTagModal: boolean;
  showMovementAddTagModal: boolean;
  newUserTags: string[];
}

export interface DraftSparActions {
  type: "setState";
  data: Partial<DraftSparState>;
}

export interface SparActions {
  type: "setState";
  data: Partial<SparState>;
}

export const draftSparReducer = (state: DraftSparState, action: DraftSparActions): DraftSparState => {
  switch (action.type) {
    case "setState":
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export const sparReducer = (state: SparState, action: SparActions): SparState => {
  switch (action.type) {
    case "setState":
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export enum StartingOptionsEnum {
  neutral = "Neutral Position",
  specific_position = "Specific Position",
}

export const startingOptionsArray: { text: StartingOptionsEnum }[] = [
  { text: StartingOptionsEnum.neutral },
  { text: StartingOptionsEnum.specific_position },
];

export enum RollType {
  "gi",
  "noGi",
  "wrestling",
  "judo",
  "notListed" = 9999,
}

export const sparInitialState: DraftSparState = {
  videoDuration: 0,
  played: 0,
  loadedPosition: 0,
  playedPosition: 0,
  isPlaying: false,
  isMuted: true,
  playbackRate: PLAYBACK_RATE_OPTIONS.One,
  updatedNote: undefined,
  currentRound: undefined,
  minimumScrollTime: undefined,
  errorCode: null,
  createdNote: undefined,
  seeking: false,
  showDraftNote: false,
  previousRoundIsComplete: true,
  showStartRoundModal: false,
  selectedStartingOption: startingOptionsArray[0].text,
  selectedPositionalTagId: "",
  selectedPositionalTagValue: "",
  selectedPositionalDominance: Dominance.neutral,
  selectedMovementTagId: "",
  selectedMovementTagValue: "",
  selectedMovementInitiatedBy: undefined,
  selectedMovementSuccess: undefined,
  selectedSubmissionTagId: "",
  selectedSubmissionTagValue: "",
  selectedSubmissionInitiatedBy: undefined,
  selectedSubmissionSuccess: undefined,
  selectedRollType: RollType.gi,
  showPositionalAddTagModal: false,
  showSubmissionAddTagModal: false,
  showMovementAddTagModal: false,
  newUserTags: [],
};

export enum DeltaDominance {
  loss = -1,
  neutral,
  gain,
}

export interface VideoAnalysisNote {
  description?: string; //in case the user wants to add a description
  timeSeconds: number; //seconds (should this be seconds into the round or seconds into the video? Thinking round
  pointsAwarded?: number;
  opponentPointsAwarded?: number;
  positionTagId?: string;
  userDominance: Dominance;
  userDominanceDelta: DeltaDominance;
  submissionAttack?: { tagId: string; wasSuccessful: boolean }; //submission tagID
  submissionDefended?: { tagId: string; wasSuccessful: boolean }; //submission tagID
  movementAttack?: { tagId: string; wasSuccessful: boolean }; //movement tagID
  movementDefended?: { tagId: string; wasSuccessful: boolean }; //movement tagID
}

export const RollTypeDisplayValue = {
  [RollType.gi]: "Gi",
  [RollType.noGi]: "No Gi",
  [RollType.wrestling]: "Wrestling",
  [RollType.judo]: "Judo",
  [RollType.notListed]: "Not listed",
};

export enum RoundConclusion {
  "win",
  "lose",
  "draw",
}

export interface Round {
  id?: string; //tbd
  roundStartTimeSeconds: number; //seconds for video playback
  roundEndTimeSeconds: number; //seconds for video playback
  videoAnalysisNotes?: VideoAnalysisNote[];
  tags?: string[]; // the notes will have the bjj tags, but might still use custom tags here
  roundType?: string; //TODO string or enum?  //open mat, competition, after class,flow, etc.
  rollType?: RollType;
  roundConclusion?: RoundConclusion;
  userId: string;
}

export interface RollAnalysisType {
  rank?: string;
  opponentRank?: string;
  rounds: Round[];
}

export interface DraftRollAnalysisType {
  originalEntry?: RollAnalysisType;
  editedVideoUrl: string;
  editedVideoDate: DateTime;
  editedJournalEntryId: string;
  editedJournalId: string;
  editedTitle: string;
  editedText: string; // description
  editedType: ENTRY_TYPE; //0 = text, 1 = video, 3 = videoAnalysis, 4 = provideoanalysis
  editedRounds?: Round[];
  editedTagIds: string[];
  currentEditingRound: Partial<Round> | undefined;
  clearCurrentRoundFlag?: boolean;
}

export interface UserAnalysis {
  userId: string;
  startDateTimeUtc: string;
  endDateTimeUtc: string;
}

//filters out the enum number values and returns just the keys that are strings
export const dominanceEnumAsArray = Object.keys(Dominance).filter((option) => isNaN(Number(option))) as Array<
  keyof typeof Dominance
>;

//filters out the enum number values and returns just the keys that are strings
export const initiatorEnumAsArray = Object.keys(MoveInitiator).filter((option) => isNaN(Number(option))) as Array<
  keyof typeof MoveInitiator
>;

export const rollTypeAsArray = Object.keys(RollType).filter((option) => isNaN(Number(option))) as Array<keyof typeof RollType>;

export const rollTypeAsEnumArray = Object.keys(RollType).filter((option) => !isNaN(Number(option))) as Array<
  keyof typeof RollType
>;

export const getDraftRoundNumber = ({ testRound, allRounds }: { testRound?: Partial<Round>; allRounds?: Round[] }) => {
  let roundIndex = 1;
  if (testRound?.roundStartTimeSeconds === undefined) return undefined;
  if (!allRounds || allRounds.length === 0) return roundIndex;
  allRounds.forEach((round) => {
    if (testRound?.roundStartTimeSeconds && testRound?.roundStartTimeSeconds <= round.roundStartTimeSeconds) return;
    if (testRound?.roundStartTimeSeconds && testRound?.roundStartTimeSeconds > round.roundEndTimeSeconds) return roundIndex++;
  });
  return roundIndex;
};

type NextStartTimeFunction = (testRoundStartTime?: number, allRounds?: Round[]) => number | undefined;

export const getNextRoundStartTime: NextStartTimeFunction = (testRoundStartTime, allRounds) => {
  if (!allRounds || allRounds.length === 0 || testRoundStartTime === undefined) return undefined;
  let nextRound: number | undefined = undefined;
  allRounds?.forEach((round) => {
    if (nextRound) return;
    if (testRoundStartTime >= round.roundStartTimeSeconds) return;
    if (testRoundStartTime < round.roundStartTimeSeconds) {
      nextRound = round.roundStartTimeSeconds;
      return;
    }
  });
  return nextRound;
};

interface RenderSetPositionProps {
  state: DraftSparState;
  handleSelectDominance: (value: Dominance) => void;
  handleAutofillChange: (input?: string | undefined) => void;
  autofillValue: string | undefined;
  handleClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  handleEnterPress: (option: AutocompleteOptionType) => void;
  fromPositionModal?: boolean;
}

export const renderSetPosition: React.FC<RenderSetPositionProps> = ({
  state,
  handleSelectDominance,
  handleAutofillChange,
  autofillValue,
  handleClick,
  handleEnterPress,
  fromPositionModal = false,
}) => (
  <>
    <span css={modalStyles.label}>{fromPositionModal ? "In the resulting position, you were..." : "You were..."}</span>
    <div css={modalStyles.radioButtonContainer}>
      {dominanceEnumAsArray.map((option) => (
        <div
          key={option}
          css={modalStyles.radioButton(state.selectedPositionalDominance === Dominance[option])}
          onClick={() => handleSelectDominance(Dominance[option])}
        >
          <span>{option}</span>
        </div>
      ))}
    </div>
    <span css={modalStyles.label}>this position:</span>
    <TagAutofill
      onChange={handleAutofillChange}
      onClick={handleClick}
      value={autofillValue}
      onEnterPress={handleEnterPress}
      labelText={"Select Position"}
      positionsOnly={true}
      ignoreOnBlur={true}
      showBorder={true}
      hideLabelOnFocus={true}
    />
  </>
);

export const getSeekToSeconds = ({
  seekingPositionInSeconds,
  currentRound,
  allRounds,
}: {
  seekingPositionInSeconds: number;
  currentRound: Partial<Round> | undefined;
  allRounds: Round[];
}) => {
  const nextRoundStartTime =
    currentRound?.roundStartTimeSeconds && getNextRoundStartTime(currentRound?.roundStartTimeSeconds, allRounds);

  //viewing video without focusing on specific round
  if (!currentRound?.roundStartTimeSeconds) {
    return seekingPositionInSeconds;
  }
  //round is focused, can't seek before round start time
  if (seekingPositionInSeconds <= currentRound.roundStartTimeSeconds) {
    return currentRound.roundStartTimeSeconds;
  }
  //round is focused, can't seek after round end time (used in entry viewer, N/A for creator)
  if (!!currentRound.roundEndTimeSeconds && seekingPositionInSeconds > currentRound.roundEndTimeSeconds) {
    return currentRound.roundEndTimeSeconds;
  }
  //if creating a round, can't scroll into next round and create an overlap
  if (nextRoundStartTime && seekingPositionInSeconds >= nextRoundStartTime) {
    return nextRoundStartTime - 1;
  }
  return seekingPositionInSeconds;
};

export const getCurrentNote = (time: number, round?: Partial<Round>) => {
  if (!round || !round.videoAnalysisNotes) return undefined;
  const roundIndex = round.videoAnalysisNotes.findIndex((note) => note.timeSeconds > time) - 1;
  if (roundIndex === -1) return undefined;
  return round.videoAnalysisNotes[roundIndex];
};

export const getCurrentPosition = (note?: VideoAnalysisNote, tagList?: TagType[]) => {
  if (!tagList || !note || !note.positionTagId) return "";
  return getTagNameFromId(note.positionTagId, tagList);
};

export const getCurrentSummary = (note?: VideoAnalysisNote, tagList?: TagType[]) => {
  if (!tagList || !note) return;
  if (!note.positionTagId) {
    return "in a neutral position.";
  }
  if (note.userDominance === Dominance.neutral) return "in neutral  " + getTagNameFromId(note.positionTagId, tagList);
  if (note.userDominance === Dominance.attacking) return "Attacking  " + getTagNameFromId(note.positionTagId, tagList);
  if (note.userDominance === Dominance.defending) return "Defending  " + getTagNameFromId(note.positionTagId, tagList);
};

export const updateRoundData = (round: Partial<Round>) => {
  const updatedRound = { ...round };
  const numberOfNotes = round.videoAnalysisNotes?.length || 0;
  if (!numberOfNotes) return round;
  round.videoAnalysisNotes?.forEach((note, index) => {
    //update start time if first note
    if (index === 0) {
      updatedRound.roundStartTimeSeconds = note.timeSeconds;
    }
    //update user dominanceDelta
    if (index > 0 && round.videoAnalysisNotes && updatedRound.videoAnalysisNotes) {
      const oldDominance = round.videoAnalysisNotes[index - 1].userDominance;
      const newDominance = round.videoAnalysisNotes[index].userDominance;
      if (oldDominance === newDominance) {
        updatedRound.videoAnalysisNotes[index].userDominanceDelta = DeltaDominance.neutral;
      } else if (oldDominance !== Dominance.defending && newDominance === Dominance.defending) {
        updatedRound.videoAnalysisNotes[index].userDominanceDelta = DeltaDominance.loss;
      } else if (oldDominance === Dominance.attacking && newDominance !== Dominance.attacking) {
        updatedRound.videoAnalysisNotes[index].userDominanceDelta = DeltaDominance.loss;
      } else {
        updatedRound.videoAnalysisNotes[index].userDominanceDelta = DeltaDominance.gain;
      }
    }
    //update end time and round conclusion if last note
    if (index === numberOfNotes - 1) {
      updatedRound.roundEndTimeSeconds = note.timeSeconds;
      if (note.submissionAttack?.wasSuccessful) {
        updatedRound.roundConclusion = RoundConclusion.win;
      } else if (note.submissionDefended?.wasSuccessful) {
        updatedRound.roundConclusion = RoundConclusion.lose;
      } else {
        updatedRound.roundConclusion = RoundConclusion.draw;
      }
    }
  });
  return updatedRound;
};
