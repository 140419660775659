import { StyleTypes } from "../Theme";
import { DateTime } from "luxon";
import { Dominance, RollType, Round, RoundConclusion } from "../VideoAnalysis/module";

export interface Promotion {
  promotionId: string;
  rank: string;
  promotionDateTimeUtc: string;
  professor: string;
  gym: string;
}

export type VideoNote = {
  timeInSeconds: number;
  text: string;
};

export enum ENTRY_TYPE {
  Text,
  Video,
  SparAnalysis,
}

export interface JournalEntryObject {
  journalEntryId: string;
  excludeFromCksInstructionals?: boolean;
  title: string;
  text: string;
  createdDateTimeUtc: string;
  entryDateTimeUtc: string;
  type: ENTRY_TYPE; //0 = text, 1 = video
  tags?: string[];
  notes?: VideoNote[];
  videoUrl?: string;
  rollAnalysisRank?: string;
  rollAnalysisOpponent?: {
    opponentRank: string;
  };
  rollAnalysisLocation?: string;
  rollAnalysisRounds?: Round[];
  rollAnalysis?: {
    rank: string | null;
    opponent: string | null;
    roundIds: string[] | null;
    location: string | null;
  };
}

export interface JournalObject {
  name: string;
  id: string;
  userId: string;
  color: string;
  createdDateTimeUtc: string;
  entries: JournalEntryObject[];
  description?: string;
}

export interface IconTypes {
  variant?: "outlined" | "rounded" | "sharp" | "twoTone" | "filled";
  isSymbol?: boolean;
  onClick?: (any: any) => void;
  className?: StyleTypes | string;
}

export interface ColorObject {
  hexCode: string;
  name?: string;
}

export interface SearchEntries {
  entryText: string;
  entry: EntryWithJournalId;
}

export interface EntryWithJournalId {
  journalId: string;
  journalEntry: JournalEntryObject;
}

export interface TagType {
  id: string;
  name: string;
  bodyPart?: string;
  category: string;
  tagType: string;
  tagSource: 0 | 1; //0 for SYSTEM 1 for USER
  userId: string;
}

export interface TagSummary {
  id: string;
  name: string;
  count: number;
}

export const ICON_VARIANTS = {
  outlined: "outlined",
  rounded: "rounded",
  sharp: "sharp",
  twoTone: "twoTone",
  filled: "filled",
};

export interface EntryFormState {
  entry: JournalEntryObject;
  editedTitle: string;
  editedText: string;
  editedEntryDate: DateTime;
  editedTagIds: string[];
  type: ENTRY_TYPE;
  editedVideoUrl: string;
  editedNotes: VideoNote[];
  videoUrl?: string;
  excludeFromCksInstructionals?: boolean;
  rollAnalysis?: {
    rank: string | null;
    opponent: string | null;
    roundIds: string[] | null;
    location: string | null;
  };
}

export interface JournalFormState {
  journal: JournalObject;
  editedName: string;
  editedDescription: string;
  editedColor: ColorObject;
}

export interface TagInfo {
  tagId: string;
  uses: {
    journalEntryIds: string[];
  };
}

export enum BadgeLevel {
  None,
  Bronze,
  Silver,
  Gold,
}

export interface RoundAnalysis {
  id: string;
  roundId: string;
  userId: string;
  createdDateTimeUtc: string;
  updatedDateTimeUtc: string;
  totalRoundTimeSeconds: number;
  totalTimeInOffenseSeconds: number;
  totalTimeInNeutralSeconds: number;
  totalTimeInDefenseSeconds: number;
  submissionAttackCount: number;
  submissionDefendedCount: number;
  successfulMovementAttackCount: number;
  movementAttackCount: number;
  failedMovementDefendedCount: number;
  movementDefendedCount: number;
  attackingSubmissionAttemptsPerMinute: number;
  aggressivenessIndex: number;
  defendingSubmissionAttemptsPerMinute: number;
  defensivenessIndex: number;
  rollType: RollType;
  roundConclusion: RoundConclusion;
  winLossPositionTagId: string;
  winLossUserDominance: Dominance;
  finalPositionTagId: string;
  finalSubmissionTagId: string;
}
