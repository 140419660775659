/** @jsxImportSource @emotion/react */
import React, {useCallback} from 'react';
import {ReactEditor} from 'slate-react';

// @ts-ignore //known typing issue with the filestack-react
import {PickerOverlay} from 'filestack-react';
import {PickerResponse} from './richTextTypesModule';
import {insertImage} from './richTextModule';


interface Props {
  editor: ReactEditor
  setShowUploader: (showUploader: boolean) => void
}

const PickerOverlayWrapper: React.FC<Props> = ({editor, setShowUploader}) => {
  //filestack documentation at https://www.filestack.com/docs/uploads/pickers/web/

  const handleImageSuccess = useCallback((res: PickerResponse) => {
    const files = res.filesUploaded;
    files.forEach(async (file) => {
      insertImage(editor, file.url);
    });
    ReactEditor.focus(editor);
  }, [editor]);

  const options = {
    fromSources: ['local_file_system'/*"instagram","facebook"*/], //can add IG and FB integration
    accept: ['image/*'],
    onClose: () => setShowUploader(false),
    onFileSelected: (file: File) => {
      // If you throw any error in this function it will reject the file selection.
      // The error message will be displayed to the user as an alert.
      if (file.size > 7000 * 1000) {
        throw new Error('File too big, select something smaller than 7MB');
      }
      return file;
    },
  };

  return (
    <div id={'picker-container'}>
      <PickerOverlay
        apikey={'AnEcaGUopRPqWCLqZIutIz'}
        onUploadDone={handleImageSuccess}
        pickerOptions={options}
      />
    </div>
  );
};

export default PickerOverlayWrapper;