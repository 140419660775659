import {AppState} from '../../store/appState';
import {JournalEntryObject, TagType} from '../../Utils/types';

//putEntryAction state update
export const tagsAfterPut = (payloadTag: TagType, state: AppState) => {
  const tags = state.tags;
  const isActionTag = (tag: TagType) => tag.id === payloadTag.id;
  return (
    tags?.map(tag => (
      isActionTag(tag) ? payloadTag : tag
    ))
  );
};

//deletedTagAction state update
export const deleteTagFromAllEntries = (tagId: string, state: AppState) => {
  const userJournals = state.userJournals;
  
  const isActionEntry = (entry: JournalEntryObject) => entry.tags?.includes(tagId);
  const isDeletedTag = (testTag: string) => testTag === tagId;
  const filteredTags = (entry: JournalEntryObject) => entry.tags?.filter(tag => !isDeletedTag(tag));
  
  return (
    userJournals?.map(journal => (
      {
        ...journal, entries: journal.entries.map(entry =>
          isActionEntry(entry) ? {...entry, tags: filteredTags(entry)} : entry),
      }
    ))
  );
};
