/**
 * Makes the initial app state for Redux from any saved app state.
 */
import { AppState, makeDefaultAppState } from "./appState";
import { Action, applyMiddleware, compose, createStore, Middleware, Store } from "@reduxjs/toolkit";
import { ActionType } from "./actionType";
import { IS_DEV_ENV } from "../environment/environment";
import { apiMiddleware } from "redux-api-middleware";
import { rootReducer } from "./rootReducer";
import { is400Response, is401Response, is500Response } from "./module";
import { CLIENT_ID } from "../google/module";
import { refreshGoogleAccessTokenAction } from "../google/Actions/refreshGoogleAccessTokenAction";
import { logoutAction } from "../login/Actions/logoutAction";
import { refreshAccessTokenAction } from "../login/Actions/refreshAccessTokenAction";

/**
 * Must go after the API Middleware or else it won't get the right action types
 */
export const googleOAuthRefreshMiddleware: Middleware<{}, AppState> = (store) => (next) => (action) => {
  if (!is401Response(action) && !is500Response(action)) {
    return next(action);
  }
  if (action.type.slice(0, 6).toLowerCase() !== "google") return next(action);
  // if (action.payload.status === 500) {
  //   const token = store.getState()?.user?.googleApi?.accessToken;
  //   // store.dispatch({type: ActionType.GOOGLE_UPDATE_NEED_OAUTH, payload: true});
  //   token && store.dispatch(revokeGoogleAccessTokenAction({token: token, requestId: REVOKE_GOOGLE_TOKEN_REQUEST_ID}),
  //   );
  //   return next(action);
  // }
  if (action.payload.status === 401) {
    store.dispatch(refreshAccessTokenAction({ requestId: "refreshAccessToken" }));
    store.dispatch(refreshGoogleAccessTokenAction({ clientId: CLIENT_ID }));
    return next(action);
  }
};

export const authRefreshMiddleware: Middleware<{}, AppState> = (store) => (next) => (action) => {
  if (!is401Response(action) && !is400Response(action)) {
    return next(action);
  }
  const token = store.getState().auth?.accessToken;

  if (action.type.slice(0, 6).toLowerCase() === "google") return next(action);

  const requestId = "refreshAccessToken";

  if (is401Response(action) || is400Response(action)) {
    //check if refresh auth token failed
    if (action.type.slice(0, 7).toLowerCase() === "refresh") {
      console.info("refreshing token failed, logging user out");
      store.dispatch(logoutAction({ requestId: "logout" }, token));
      return next(action);
    }
    if (action.type === ActionType.LogoutError) {
      return next(action);
    }
    if (action.type === ActionType.LoginError) {
      return next(action);
    }
    // console.info('Intercepted HTTP 401 and refreshing the user\'s session...');
    store.dispatch(refreshAccessTokenAction({ requestId }));
    // return next(action); // Send action along to turn off the loading state for the request
    //
    return next(action);
  } else {
    console.info("Intercepted some other action");
    console.info("The action was: ", action);
    return next(action);
  }
};

export const makeAppStore = (): Store<AppState, Action<ActionType>> => {
  const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
  const devMiddleware = IS_DEV_ENV && devTools ? devTools() : (f: any) => f;

  const initialState = makeDefaultAppState();
  const middlewares = [apiMiddleware, authRefreshMiddleware, googleOAuthRefreshMiddleware];

  // if (process.env.NODE_ENV === `development`) {
  //   const {logger} = require(`redux-logger`);
  //   middlewares.push(logger);
  // }

  const enhancers = compose(applyMiddleware(...middlewares), devMiddleware);

  return createStore(rootReducer, initialState, enhancers);
};
