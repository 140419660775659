/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import SearchIcon from "../icons/SearchIcon";
import TextInput from "../Utils/TextInput";
import theme from "../Theme";
import { isEnterPressed } from "./module";

const styles = {
  searchWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
  `,
  icon: css`
    cursor: pointer;
    color: ${theme.palette.toolbars.fontColorPrimary};
    aspect-ratio: 1;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 0;
    transform: translateX(-5px) translateY(-1px);
    padding-right: 4px;
    border-radius: 0 400px 400px 0;
    height: 39px;
    border: solid 1px transparent;
    position: absolute;
    right: 20px;
  `,
  textInput: css`
    // fieldset {
    // 	border-top-right-radius: 0;
    // 	border-bottom-right-radius: 0;
    // }
  `,
};

interface Props {
  onClick: (searchTerm: string) => void;
  placeholder?: string;
  className?: string;
  showIcon?: boolean;
}

const Searcher: React.FC<Props> = ({ onClick, placeholder = "Search...", className, showIcon = true }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (isEnterPressed(event)) {
        onClick(searchTerm.trim());
      }
    },
    [onClick, searchTerm]
  );

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      onClick(searchTerm.trim());
    }, 200);
    return () => clearTimeout(debounceSearch);
  }, [searchTerm, onClick]);

  return (
    <div
      className={className}
      css={styles.searchWrapper}
    >
      <TextInput
        onChange={handleSearchTextChange}
        value={searchTerm}
        id={"libraryEntrySearch"}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        css={styles.textInput}
        icon={showIcon && !searchTerm && <SearchIcon onClick={() => onClick(searchTerm.trim() || "")} />}
      />
    </div>
  );
};

export default Searcher;
