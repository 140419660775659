/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import theme from "../../Theme";
import { css } from "@emotion/react";
import { ENTRY_TYPE, EntryFormState, JournalEntryObject, JournalObject } from "../../Utils/types";
import { useJournals } from "../Actions/getJournalsAction";
import { ApiHookReturn } from "../../store/module";
import Loader from "../../Utils/Loader";
import { useGetRounds } from "../Actions/getRoundsAction";
import AnalysisNoteCard from "../../VideoAnalysis/AnalysisNoteCard";
import { DraftRollAnalysisType, draftSparReducer, sparInitialState } from "../../VideoAnalysis/module";
import {
  getVideoDuration,
  handleError,
  handleOnReady,
  handlePause,
  handlePlay,
  handleProgress,
  handleVideoEnd,
  seekTo,
} from "../../VideoAnalysis/playerModule";
import ReactPlayer from "react-player";
import { useGetTags } from "../../Tags/Actions/getTagsAction";
import SparringPlayerControls from "../VideoNoteTaker/SparringPlayerControls";
import { useContainerDimensions } from "../../Utils/useContainerDimensions";

import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import GenericModal from "../../modal/GenericModal";
import { BASE_ROUTES } from "../../Navigation/routesModule";
import { useDeleteEntry } from "../Actions/deleteEntryAction";
import MoveEntryModal from "../MoveEntryModal";
import { DateTime } from "luxon";
import { DATE_FORMAT, serialize, toEditedEntryObject } from "../module";
import FloatingActionButton, { MenuItem } from "../../Utils/FloatingActionButton";
import { useDeleteRound } from "../Actions/deleteRoundAction";
import { useLoading } from "../../store/hooks";
import { ActionType } from "../../store/actionType";
import SparNotePanel from "../../VideoAnalysis/SparNotePanel";
import { usePutRound } from "../Actions/putRoundAction";
import RoundAnalysisAccordion from "../../Analyze/RoundAnalysisAccordion";
import { usePutEntry } from "../Actions/putEntryAction";
import Scoreboard from "../../VideoAnalysis/Scoreboard";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import MoveItemIcon from "../../icons/MoveItemIcon";
import { hexToRGBA } from "../../Utils/module";
import { useHoverOfRef } from "../../tooltip/hooks";
import { APP_LAYERS } from "../../layers";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 15px;
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 10px 10px 10px;
    }
  `,
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    max-width: 100%;
    position: relative;
    border-radius: 12px;

    background-color: ${theme.colors.contentBackground};
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      padding: 24px;
    }
  `,
  noteInputWrapper: css`
    resize: none;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;

    @media (min-width: ${theme.breakpoints.md}px) {
      width: 300px;
    }
  `,
  titleContainerLg: (color: string) => css`
    background-color: ${color};
    padding: 10px 16px;
    gap: 4px;
    border-radius: 8px;
    height: fit-content;
    display: none;
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
  titleContainerSm: (color: string) => css`
    background-color: ${color};
    padding: 10px 16px;
    width: 100%;
    gap: 4px;
    border-radius: 8px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  titleRow: css`
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    align-items: center;
    gap: 8px;
  `,
  titleInput: css`
    resize: none;
    width: 100%;
    outline: none;
    border: none;
    color: ${theme.colors.contentBackground};
    font-weight: 700;
    font-size: 24px;
    background-color: transparent;
  `,
  descriptionInput: css`
    width: 100%;
    outline: none;
    border: none;
    overflow: hidden;
    color: ${theme.colors.contentBackground};
    background-color: transparent;
    font-weight: 400;
    font-size: 12px;
    resize: vertical;
  `,
  entryDate: css`
    width: 100%;
    color: ${theme.colors.contentBackground};
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
  `,
  noteContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  `,
  noteScrollCard: css`
    background-color: ${theme.colors.background};
    display: none;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;
    overflow-y: hidden;
    margin-top: 20px;
    border-radius: 8px;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
  noteScrollCardHeader: css`
    width: 100%;
    border-bottom: solid 1px ${theme.colors.border};
    padding: 16px;
  `,
  noteScrollContainer: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;
    padding: 16px;
    overflow-y: auto;
  `,
  roundTitle: css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.fontColor.text};
  `,
  modalBody: css`
    display: flex;
    width: 100%;
    padding: 5px;
  `,
  iframe: css`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.lightestGray};
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  `,
  wrapper: css`
    position: relative;
    display: flex;
    flex: 1;
    min-height: 400px;

    @media (min-width: ${theme.breakpoints.md}px) {
      margin: 14px;
    }
  `,
  playerWindow: css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    gap: 12px;
  `,
  header: css`
    color: ${theme.fontColor.text};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
   
    @media (min-width: ${theme.breakpoints.md}px) {
     height: 48px;
},
  `,
  backButton: (color?: string) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${color || theme.fontColor.text};
    border-radius: 200px;
    border: 1px solid ${color || theme.fontColor.text};
    background-color: ${theme.colors.contentBackground};
    width: 88px;
    height: 40px;
    gap: 2px;
    > span {
      color: ${theme.fontColor.text};
      font-size: 14px;
      font-weight: 400;
      padding-right: 8px;
    }
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 8px;
  `,

  cancelButton: css`
    color: ${theme.fontColor.text};
    background-color: ${theme.colors.contentBackground};
    border: solid 1px ${theme.fontColor.text};
    border-radius: 200px;
    width: 90px;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  saveButton: (color?: string) => css`
    background-color: ${color ? color : theme.palette.primary.main};
    color: ${theme.colors.contentBackground};
    border-radius: 200px;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  `,
  datePicker: (color?: string) => css`
    padding: 12px 14px;
    border-color: lightgray;
    border: 1px solid ${theme.colors.border};
    box-shadow: none;
    width: 100%;
    border-radius: 6px;

    // background-color: ${hexToRGBA(color || "white", 0.05)};
    color: ${theme.fontColor.text};
    ::-webkit-calendar-picker-indicator {
      cursor: pointer;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="${
        color ? "%23" + color.slice(1) : "%23bbbbbb"
      }" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
}
  `,
  altDatePicker: (color?: string) => css`
      padding-right: 50px;
  border: none;
  box-shadow: none;
  width: 100%;
  border-radius: 6px;
    
  background-color: ${color};
  color: ${theme.colors.contentBackground};
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="${"%23ffffff"}" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
}
`,
  label: css`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -8px;
    color: ${theme.fontColor.text};
  `,
  scoreboardSm: css`
    display: none;

    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
  noteCard: css`
    display: none;
    position: absolute;
    height: 48px;
    top: 0;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
    color: ${theme.fontColor.text};
    div {
      background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
      color: ${theme.fontColor.text};
    }
    span {
      border-left: none;
      background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
      color: ${theme.fontColor.text};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      height: 60px;
    }
  `,
  scoreboardLg: css`
    @media (max-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  mobilePlayer: (isPlayerHovered: boolean) => css`
    display: none;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 1)};
    bottom: 0;
    transition: height 100ms 50ms, opacity 100ms 50ms, visibility 500ms;
    z-index: ${APP_LAYERS.overlays};
    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      height: ${isPlayerHovered ? "100px" : "0"};
      opacity: ${isPlayerHovered ? 1 : 0};
    }
  `,
  desktopPlayer: css`
    display: none;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
};

const opts = {
  youtube: {
    playerVars: {
      loop: true,
      origin: "https://www.youtube.com",
    },
    embedOptions: {
      host: "https://www.youtube-nocookie.com",
    },
  },
};

interface Props {
  setUpdatedURL: (newURL: string) => void;
}

const defaultRollAnalysisState: DraftRollAnalysisType = {
  editedTagIds: [],
  editedJournalId: "",
  editedVideoUrl: "",
  editedVideoDate: DateTime.now(),
  editedJournalEntryId: "",
  editedTitle: "",
  editedText: "text", // description
  editedType: ENTRY_TYPE.SparAnalysis,
  editedRounds: [],
  currentEditingRound: undefined,
};
const AnalyzerEntryViewer: React.FC<Props> = ({ setUpdatedURL }) => {
  const [state, dispatch] = useReducer(draftSparReducer, sparInitialState);
  const { videoDuration, played, isPlaying, isMuted, currentRound, minimumScrollTime, maximumScrollTime } = state;
  const [rollAnalysisState, setRollAnalysisState] = useState<DraftRollAnalysisType>(defaultRollAnalysisState);
  const { currentEditingRound: editingRound } = rollAnalysisState;
  const componentRef = useRef(null);
  const player = useRef<ReactPlayer>(null);
  const { entryId, journalId } = useParams<{ entryId: string; journalId: string }>();
  const journals: ApiHookReturn<JournalObject[]> = useJournals();
  const { width: playerWidth } = useContainerDimensions(componentRef);
  const tagList = useGetTags().value;
  const deleteEntry = useDeleteEntry(journalId, entryId);
  const deleteRound = useDeleteRound(currentRound?.id || "");
  const history = useHistory();
  const [showEntryDeleteConfirm, setShowEntryDeleteConfirm] = useState(false);
  const [showRoundDeleteConfirm, setShowRoundDeleteConfirm] = useState(false);
  const [showMoveEntryModal, setShowMoveEntryModal] = useState(false);
  const [currentNote, setCurrentNote] = useState(currentRound?.videoAnalysisNotes?.[0]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [playerWasClicked, setPlayerWasClicked] = useState(false);

  const playerWindow = useRef<HTMLDivElement | null>(null);
  const isPlayerHovered = useHoverOfRef<HTMLDivElement | null>(playerWindow);

  const showPlayerControls = useCallback(() => {
    return playerWasClicked || !isPlaying || isPlayerHovered;
  }, [isPlayerHovered, isPlaying, playerWasClicked]);

  const notesRef = useRef<(HTMLDivElement | null)[]>([]);

  const deleteRoundLoading = useLoading(ActionType.DeleteRoundRequest);

  //this bullshit is to sever the reference to global state
  const journal: JournalObject | undefined =
    journals.value && JSON.parse(JSON.stringify(journals.value.find((journal) => journal.id === journalId)));

  const entry: JournalEntryObject | undefined =
    journal?.entries && JSON.parse(JSON.stringify(journal.entries?.find((entry) => entry.journalEntryId === entryId) || {}));
  const entryDate = useMemo(() => entry && entry.entryDateTimeUtc && DateTime.fromISO(entry.entryDateTimeUtc), [entry]);

  const [entryFormState, setEntryFormState] = useState<EntryFormState>({
    editedTitle: entry?.title || "",
    editedText: entry?.text || "",
    editedEntryDate: entryDate || DateTime.now(),
    entry: entry || {
      journalEntryId: "",
      title: "",
      text: "",
      createdDateTimeUtc: "",
      entryDateTimeUtc: "",
      videoUrl: "",
      type: 0,
      notes: [],
      tags: [],
    },
    editedTagIds: entry?.tags || [],
    type: entry?.type || ENTRY_TYPE.Text,
    editedVideoUrl: entry?.videoUrl || "",
    editedNotes: entry?.notes ? [...entry?.notes] : [],
  });

  const putEntry = usePutEntry(journalId, toEditedEntryObject(entryFormState));
  const roundsHook = useGetRounds(entry?.rollAnalysis?.roundIds || []);
  const allRounds = roundsHook.value;

  const putRound = usePutRound(state.currentRound);

  const editsWereMade = useMemo(() => {
    if (entry) {
      const titleChanged = entryFormState.editedTitle !== entry.title;
      const dateChanged = !entryFormState.editedEntryDate.hasSame(DateTime.fromISO(entry.entryDateTimeUtc), "minute");
      const textChanged = entryFormState.editedText !== entry.text;
      const tagsChanged = JSON.stringify(entryFormState.editedTagIds) !== JSON.stringify(entry.tags);
      const videoUrlChanged = entryFormState.editedVideoUrl !== entry.videoUrl;
      const videoNotesChanged = JSON.stringify(entryFormState.editedNotes) !== JSON.stringify(entry?.notes);
      return titleChanged || dateChanged || textChanged || tagsChanged || videoUrlChanged || videoNotesChanged;
    }
    return false;
  }, [entry, entryFormState]);

  const handleSaveRound = useCallback(async () => {
    await putRound.value().then((res) => {
      if (!res?.error) {
        setRollAnalysisState(defaultRollAnalysisState);
        return dispatch({ type: "setState", data: { currentRound: undefined } });
      }
      alert("There was an error. Please try again.");
    });
  }, [putRound]);

  const handleSaveEntry = useCallback(async () => {
    const hasDate = !!entryFormState.editedEntryDate;
    const needsSerialized = entryFormState.editedText.trim()[0] === "[";
    const editedText = needsSerialized ? serialize(entryFormState.editedText).trim() : entryFormState.editedText.trim();
    const isBlankEntry = entryFormState.editedText.length <= 50;
    if ((editedText || !isBlankEntry) && hasDate) {
      if (editsWereMade) {
        await putEntry.value().then((res) => {
          if (!res?.error) {
            return;
          }
          alert("There was an error. Please try again.");
        });
      }
    }
  }, [entryFormState, putEntry, editsWereMade]);

  const handleTogglePlay = useCallback(() => {
    if (isPlaying) {
      dispatch({ type: "setState", data: { isPlaying: false } });
    } else {
      dispatch({ type: "setState", data: { isPlaying: true } });
    }
  }, [isPlaying]);

  useEffect(() => {
    if (entry && allRounds && !rollAnalysisState.editedJournalEntryId) {
      setRollAnalysisState({
        editedTagIds: entry.tags || [],
        editedJournalId: journalId,
        editedVideoUrl: entry.videoUrl || "",
        editedVideoDate: DateTime.fromISO(entry.entryDateTimeUtc),
        editedJournalEntryId: entry.journalEntryId,
        editedTitle: entry.title,
        editedText: entry.text, // description
        editedType: ENTRY_TYPE.SparAnalysis,
        editedRounds: allRounds,
        currentEditingRound: undefined,
      });
    }
  }, [entry, allRounds, journalId, rollAnalysisState.editedJournalEntryId]);

  //populates notes on entry load of first round
  useEffect(() => {
    if (entry?.type === ENTRY_TYPE.SparAnalysis && allRounds?.length && !currentRound) {
      dispatch({ type: "setState", data: { currentRound: allRounds[0], played: allRounds[0].roundStartTimeSeconds } });
      player.current?.seekTo(allRounds[0].roundStartTimeSeconds);
    }
  }, [entry?.type, allRounds, currentRound]);

  const handleSeek = useCallback(
    (time: number | undefined) => {
      time !== undefined &&
        time >= 0 &&
        seekTo({
          allRounds: allRounds || [],
          currentRound,
          dispatch,
          player,
          seconds: time,
          videoDuration,
        });
    },
    [allRounds, currentRound, videoDuration]
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedTitle: e.target?.value || "" });
    },
    [setEntryFormState, entryFormState]
  );

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setEntryFormState({ ...entryFormState, editedText: e.target?.value || "" });
    },
    [setEntryFormState, entryFormState]
  );

  const handleEntryDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedEntryDate: DateTime.fromISO(e.target.value) });
    },
    [setEntryFormState, entryFormState]
  );

  const handleDeleteCancel = useCallback(() => {
    setShowEntryDeleteConfirm(false);
  }, []);

  const handleCloseMoveEntryModal = useCallback(() => {
    setShowMoveEntryModal(false);
  }, []);

  const handleEntryDelete = useCallback(async () => {
    if (journalId && entryId && deleteEntry.value) {
      await deleteEntry.value().then((res) => {
        if (!res?.error) {
          return history.push(BASE_ROUTES.journal + "/" + journalId);
        }
        setShowEntryDeleteConfirm(false);
        alert("There was an error. Please try again.");
      });
    }
  }, [journalId, entryId, deleteEntry, history]);

  const handleRoundDelete = useCallback(async () => {
    if (currentRound && deleteRound.value) {
      await deleteRound.value().then((res) => {
        if (!res?.error) {
          return;
        }
        setShowRoundDeleteConfirm(false);
        alert("There was an error. Please try again.");
      });
    }
  }, [currentRound, deleteRound]);

  const getRoundIndex = () => {
    const index = entry?.rollAnalysis?.roundIds?.findIndex((id) => id === state.currentRound?.id || "");
    if (index !== undefined && index >= 0) return index + 1;
    return -1;
  };

  const menuItems: MenuItem[] = [
    {
      text: "Edit round " + getRoundIndex() + " of " + (entry?.rollAnalysis?.roundIds?.length || 0),
      onClick: () => {
        setRollAnalysisState((prev) => ({ ...prev, currentEditingRound: state.currentRound }));
        dispatch({
          type: "setState",
          data: {
            //set endtime to undefined so round can be extended when editing.
            currentRound: { ...currentRound, roundEndTimeSeconds: undefined },
          },
        });
      },
      icon: <EditIcon />,
    },
    {
      text: "Delete round " + getRoundIndex() + " of " + (entry?.rollAnalysis?.roundIds?.length || 0),
      onClick: () => setShowRoundDeleteConfirm(true),
      icon: <DeleteIcon variant="outlined" />,
    },
    {
      text: "Move this entry & rounds",
      onClick: () => setShowMoveEntryModal(true),
      icon: <MoveItemIcon />,
    },
    {
      text: "Delete this entry & rounds",
      onClick: () => setShowEntryDeleteConfirm(true),
      icon: <DeleteIcon variant="outlined" />,
    },
  ];

  const handleCancel = useCallback(() => {
    setRollAnalysisState((prev) => ({ ...prev, currentEditingRound: undefined }));
    dispatch({
      type: "setState",
      data: {
        //set endtime to undefined so round can be extended when editing.
        currentRound: allRounds?.length
          ? allRounds.find((round) => round.roundStartTimeSeconds === currentRound?.roundStartTimeSeconds)
          : currentRound,
      },
    });
  }, [allRounds, currentRound]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handlePlayerClick = useCallback(() => {
    setPlayerWasClicked(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setPlayerWasClicked(false);
    }, 2000);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isPlaying && playerWasClicked) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setPlayerWasClicked(false);
      }, 2000);
    }
  }, [isPlaying, playerWasClicked]);

  const renderNotes = useCallback(() => {
    if (!editingRound) {
      return state.currentRound?.videoAnalysisNotes?.map((note, index) => {
        const nextRound = state.currentRound?.videoAnalysisNotes?.[index + 1];
        const isCurrentNote = note.timeSeconds < played && (!nextRound || nextRound.timeSeconds > played);
        if (isCurrentNote && note.timeSeconds !== currentNote?.timeSeconds) {
          setCurrentNote(note);
          notesRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return (
          <div
            key={note.timeSeconds.toString() + index}
            ref={(el) => (notesRef.current[index] = el as HTMLDivElement)}
          >
            <AnalysisNoteCard
              handleSeek={handleSeek}
              note={note}
              roundStartTime={currentRound?.roundStartTimeSeconds}
              isCurrentNote={isCurrentNote}
              color={journal?.color}
            />
          </div>
        );
      });
    } else {
      return editingRound?.videoAnalysisNotes?.map((note, index) => {
        const nextNote = state.currentRound?.videoAnalysisNotes?.[index + 1];
        const isCurrentNote = note.timeSeconds < played && (!nextNote || nextNote.timeSeconds > played);
        if (isCurrentNote && note.timeSeconds !== currentNote?.timeSeconds) {
          setCurrentNote(note);
          notesRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return (
          <div ref={(el) => (notesRef.current[index] = el as HTMLDivElement)}>
            <AnalysisNoteCard
              key={note.timeSeconds.toString()}
              handleSeek={handleSeek}
              note={note}
              roundStartTime={currentRound?.roundStartTimeSeconds}
              canEdit={true}
              isCurrentNote={isCurrentNote}
              color={journal?.color}
            />
          </div>
        );
      });
    }
  }, [
    editingRound,
    state.currentRound?.videoAnalysisNotes,
    played,
    currentNote?.timeSeconds,
    handleSeek,
    currentRound?.roundStartTimeSeconds,
    journal?.color,
  ]);

  if (!journal) {
    return <Loader message={"Fetching Journal..."} />;
  }
  if (!entry) {
    return <span>Error: could not locate entry. Please try again later.</span>;
  }

  return (
    <div css={styles.root}>
      {deleteRoundLoading && <Loader message={"Deleting Round..."} />}
      <div css={styles.header}>
        <div
          onClick={handleBack}
          css={styles.backButton(journal.color)}
        >
          <ChevronLeftIcon />
          <span>Back </span>
        </div>

        <div css={styles.buttonContainer}>
          {editingRound && (
            <span
              css={styles.cancelButton}
              onClick={handleCancel}
            >
              Cancel
            </span>
          )}
          {editingRound && (
            <div
              css={styles.saveButton(journal.color)}
              onClick={handleSaveRound}
            >
              Save
            </div>
          )}{" "}
          {!editingRound &&
            (editsWereMade ? (
              <div
                css={styles.saveButton(editsWereMade ? journal.color : "lightgray")}
                onClick={handleSaveEntry}
              >
                Save
              </div>
            ) : (
              <FloatingActionButton
                icon={"hamburgerMenu"}
                menuItems={currentRound ? menuItems : menuItems.slice(2)}
                color={journal.color}
                isRelativePosition={true}
                menuPosition={"bottom"}
                variant="outlined"
              />
            ))}
        </div>
      </div>
      {!editingRound && (
        <>
          <div css={styles.titleContainerSm(journal.color)}>
            <div css={styles.titleRow}>
              <input
                placeholder={"Please enter a title"}
                css={styles.titleInput}
                onChange={handleTitleChange}
                value={entryFormState.editedTitle || ""}
                required
              />

              <input
                id="date-picker-journal-entry"
                name="journal-entry-date"
                type="datetime-local"
                value={entryFormState.editedEntryDate.toFormat(DATE_FORMAT.inputDateTimeLocal)}
                onChange={handleEntryDateChange}
                min="1900-01-01"
                css={styles.altDatePicker(journal.color)}
              />
            </div>
            <textarea
              placeholder={"Please enter a Description"}
              css={styles.descriptionInput}
              onChange={handleTextChange}
              value={entryFormState.editedText}
              required
            />
            {/* <span css={styles.entryDate}>{entryFormState.editedEntryDate.toFormat(DATE_FORMAT.longDateTime)}</span> */}
          </div>
        </>
      )}
      <div css={styles.container}>
        <div
          css={styles.wrapper}
          ref={playerWindow}
          onClick={handlePlayerClick}
          onTouchStart={handlePlayerClick}
        >
          <div
            ref={componentRef}
            css={styles.playerWindow}
          >
            {allRounds !== undefined && currentRound && !editingRound && (
              <Scoreboard
                currentRound={currentRound}
                allRounds={allRounds}
                played={played}
                tagList={tagList}
                css={styles.scoreboardLg}
              />
            )}
            <SparringPlayerControls
              player={player}
              playerWidth={playerWidth}
              dispatch={dispatch}
              state={state}
              seekTo={(seconds: number) =>
                allRounds &&
                seekTo({
                  allRounds,
                  currentRound,
                  dispatch,
                  player,
                  seconds,
                  videoDuration,
                })
              }
              color={journal.color}
              allRounds={allRounds}
              isEditing={!!editingRound}
              isOverlay={true}
              css={styles.mobilePlayer(showPlayerControls())}
            />
            <div
              css={styles.iframe}
              onClick={handleTogglePlay}
            >
              {allRounds !== undefined && currentRound && !editingRound && (
                <>
                  {currentNote && (
                    <AnalysisNoteCard
                      handleSeek={handleSeek}
                      note={currentNote}
                      roundStartTime={currentRound?.roundStartTimeSeconds}
                      isCurrentNote={true}
                      color={journal?.color}
                      css={styles.noteCard}
                    />
                  )}
                  <Scoreboard
                    currentRound={currentRound}
                    allRounds={allRounds}
                    played={played}
                    tagList={tagList}
                    css={styles.scoreboardSm}
                    isOverlay={true}
                  />
                </>
              )}

              <ReactPlayer
                ref={player}
                width={"100%"}
                height={"auto"}
                className={"react-player"}
                url={entry.videoUrl}
                playing={isPlaying}
                onReady={() => handleOnReady(dispatch)}
                // onBuffer={handleBuffer}
                // onBufferEnd={handleBufferEnd}
                onPause={() => handlePause(dispatch)}
                onPlay={() => handlePlay(dispatch)}
                onDuration={() => getVideoDuration({ player, dispatch })}
                onEnded={() => handleVideoEnd(player, minimumScrollTime)}
                onSeek={(e) => console.log("onSeek", e)}
                controls={false}
                onError={(e) => handleError(e)}
                onProgress={(event) =>
                  handleProgress({
                    event,
                    dispatch,
                    maximumScrollTime,
                    videoDuration,
                    isPlaying,
                    isFirstRender,
                    setIsFirstRender,
                    currentRound,
                  })
                }
                progressInterval={250}
                config={opts}
                volume={undefined}
                muted={isMuted}
                css={{ backgroundColor: "#fff", borderRadius: "10px", pointerEvents: "none" }}
                playbackRate={state.playbackRate}
                loading={"false"}
              />
            </div>
            <SparringPlayerControls
              player={player}
              playerWidth={playerWidth}
              dispatch={dispatch}
              state={state}
              seekTo={(seconds: number) =>
                allRounds &&
                seekTo({
                  allRounds,
                  currentRound,
                  dispatch,
                  player,
                  seconds,
                  videoDuration,
                })
              }
              color={journal.color}
              allRounds={allRounds}
              isEditing={!!editingRound}
              css={styles.desktopPlayer}
            />
          </div>
        </div>
        <div css={styles.noteInputWrapper}>
          {!editingRound && (
            <>
              <div css={styles.titleContainerLg(journal.color)}>
                <input
                  placeholder={"Please enter a title"}
                  css={styles.titleInput}
                  onChange={handleTitleChange}
                  value={entryFormState.editedTitle || ""}
                  required
                />
                <input
                  id="date-picker-journal-entry"
                  name="journal-entry-date"
                  type="datetime-local"
                  value={entryFormState.editedEntryDate.toFormat(DATE_FORMAT.inputDateTimeLocal)}
                  onChange={handleEntryDateChange}
                  min="1900-01-01"
                  css={styles.altDatePicker(journal.color)}
                />
                {/* <span css={styles.entryDate}>{entryFormState.editedEntryDate.toFormat(DATE_FORMAT.longDateTime)}</span> */}
                <textarea
                  placeholder={"Please enter a Description"}
                  css={styles.descriptionInput}
                  onChange={handleTextChange}
                  value={entryFormState.editedText}
                  required
                />
              </div>
            </>
          )}
          {/* <label
            css={styles.label}
            htmlFor="date-picker-journal-entry"
          >
            Video Date
          </label>
          <input
            id="date-picker-journal-entry"
            name="journal-entry-date"
            type="datetime-local"
            value={entryFormState.editedEntryDate.toFormat(DATE_FORMAT.inputDateTimeLocal)}
            onChange={handleEntryDateChange}
            min="1900-01-01"
            css={styles.datePicker(journal.color)}
          /> */}
          {editingRound ? (
            <SparNotePanel
              dispatch={dispatch}
              state={state}
              played={played}
              isPlaying={isPlaying}
              rollAnalysisState={rollAnalysisState}
              setRollAnalysisState={setRollAnalysisState}
              currentRound={editingRound}
              player={player}
            />
          ) : (
            <div css={styles.noteContainer}>
              {state?.currentRound ? (
                <>
                  <span css={styles.roundTitle}>Round {getRoundIndex()}</span>
                  {state.currentRound.id && <RoundAnalysisAccordion roundId={state.currentRound.id} />}
                </>
              ) : null}
              <div css={styles.noteScrollCard}>
                <div css={styles.noteScrollCardHeader}>
                  <span>Notes</span>
                </div>
                <div css={styles.noteScrollContainer}>{renderNotes()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!showEntryDeleteConfirm ? null : (
        <GenericModal
          onClose={handleDeleteCancel}
          onConfirm={handleEntryDelete}
          title="Warning!"
          confirmText="Delete"
          isWarning={true}
        >
          <span css={styles.modalBody}>Are you sure you want to delete this entry?</span>
        </GenericModal>
      )}
      {!showRoundDeleteConfirm ? null : (
        <GenericModal
          onClose={handleDeleteCancel}
          onConfirm={handleRoundDelete}
          title="Warning!"
          confirmText="Delete"
          isWarning={true}
        >
          <span css={styles.modalBody}>Are you sure you want to delete this round?</span>
        </GenericModal>
      )}
      {showMoveEntryModal && entry && (
        <MoveEntryModal
          entry={{ journalId, journalEntry: entry }}
          handleCloseModal={handleCloseMoveEntryModal}
          setNewURL={setUpdatedURL}
        />
      )}
    </div>
  );
};

export default AnalyzerEntryViewer;
