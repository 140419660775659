import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  Response,
} from "../../store/module";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useErrors, useLoading, useLoggedIn } from "../../store/hooks";
import { ENDPOINTS } from "../../store/endpoints";
import { makeLogoutAppState } from "../../store/appState";

export interface RefreshActionProps {
  requestId: string;
}

export interface UserAuth {
  id: string;
  accessToken: string;
}

const KEY_ACTION = ActionType.RefreshTokenRequest;
const ACTIONS = [ActionType.RefreshTokenRequest, ActionType.RefreshTokenResponse, ActionType.RefreshTokenError];

interface Payload extends Response {
  data?: UserAuth | null;
}

export const refreshAccessTokenAction = (body: RefreshActionProps) =>
  makeApiAction<Payload, RefreshActionProps>({
    method: "POST",
    credentials: "include",
    endpoint: () => ENDPOINTS.refreshAccessToken,
    headers: (state) => ({
      "content-type": "application/json",
      Authorization: `Bearer ${state.auth?.accessToken}`,
    }),
    body: JSON.stringify({ refreshToken: null }),
    types: makeRsaaTypes<Payload, RefreshActionProps>(ACTIONS, body),
    bailout: (state) =>
      !!getLoading(state, KEY_ACTION, body.requestId) || !!getLoading(state, ActionType.RefreshTokenRequest, body.requestId),
  });

const reducer: ApiReducer<Payload, RefreshActionProps> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION, action.meta?.requestId);
  }

  if (is401Response(action)) {
    console.error("Refresh token could not be refreshed with a 401");
    return makeLogoutAppState();
  }
  if (errorMsg) {
    console.error("error", errorMsg);
    addError(state, KEY_ACTION, errorMsg, action.meta?.requestId);
    // return makeLogoutAppState();
  }

  if (!payload?.data?.id || !payload?.data?.accessToken) {
    return addError(state, KEY_ACTION, ["refresh failure"], action.meta?.requestId);
  }

  const successState = noLoadingNoError(state, KEY_ACTION, action.meta?.requestId);

  return {
    ...successState,
    auth: payload.data,
    error: {},
  };
};

export const refreshAccessTokenReducer = makeApiReducer<Payload, RefreshActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to refresh token."
);

export const useRefreshAccessToken = (): ApiHookReturn<() => void> => {
  const dispatch = useDispatch();
  const requestId = "refreshAccessToken";
  const loading = useLoading(KEY_ACTION, requestId);
  const errors = useErrors(KEY_ACTION, requestId);
  const loggedIn = useLoggedIn();

  const refreshAccessToken = useCallback(() => {
    loggedIn && !loading && dispatch(refreshAccessTokenAction({ requestId }));
  }, [loading, dispatch, requestId, loggedIn]);

  return {
    loading,
    errors,
    value: refreshAccessToken,
    reload: refreshAccessToken,
  };
};
