/** @jsxImportSource @emotion/react */

import React from 'react';
import {css} from '@emotion/react';
import {useHistory} from 'react-router-dom';
import theme from '../Theme';


const styles = {
  background:   css`
    background-color: #232323;
    background-image: url(background.jpeg);
    background-position-y: 43%;
    background-position-x: 53%;
    width: 100vw;
    height: 100vh;
    position: relative;
  `,
  loginWrapper: css`
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-width: 300px;
    padding: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    border-radius: 10px;
  `,
  backButton:   css`
    border: solid 1px ${theme.colors.mediumGray};
    padding: 4px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    
    :hover {
      background-color: darkgrey;
    }
  `,
};

const FourOhFour: React.FC = () => {
  const history = useHistory();
  return (
    <div css={styles.background}>
      <div css={styles.loginWrapper}>
        <span>404 - Page Not Found!</span>
        <span>(It's probably taking a rest round)</span>
        <div css={styles.backButton} onClick={() => history.push('/')}>
          <span>Back to Home</span>
        </div>
      </div>
    
    </div>
  );
};
export default FourOhFour;