/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getEntriesForJournal, getEntriesFromSearchResults, searchFilteredEntries } from "./module";
import { css } from "@emotion/react";
import { useJournals } from "./Actions/getJournalsAction";
import EntrySummary from "./EntrySummary";
import JournalViewerSummary from "./JournalViewerSummary";
import EntryCreator from "./EntryCreator";
import { ActionType } from "../store/actionType";
import { getLoading } from "../store/module";
import { useAppState, useLoading } from "../store/hooks";
import { ENTRY_TYPE, EntryWithJournalId } from "../Utils/types";
import GenericModal from "../modal/GenericModal";
import JournalCreatorEditor from "./JournalCreatorEditor";
import Loader from "../Utils/Loader";
import EntryFilter from "./EntryFilter";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import Searcher from "../Utils/Searcher";
import theme from "../Theme";
import AddNewEntryModal from "./AddNewEntryModal";
import FilterChip from "../Utils/FilterChip";
import FloatingActionButton from "../Utils/FloatingActionButton";
import LibraryJournalSelector from "./LibraryJournalSelector";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const styles = {
  root: css`
    height: calc(100vh - ${theme.toolbarHeight.heightMedium});
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 12px;
    background-color: ${theme.colors.background};
    width: 100%;
    padding: 40px 40px 40px 0;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
      padding: 10px;
    }
  `,
  filterWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 5px;
    flex-wrap: wrap;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  filterSection: (isOpen: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    row-gap: 16px;
    padding: 16px;
    flex-wrap: wrap;
    background-color: ${theme.colors.contentBackground};
    ${!isOpen && "padding: 16px 16px 8px 0; row-gap: 0;"}
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 12px 4px;
    }
  `,

  desktopOnly: css`
    display: none;
    flex-direction: row;
    gap: 16px;
    white-space: nowrap;
    flex-wrap: wrap;
    width: 285px;
    justify-content: flex-start;
    @media (min-width: ${theme.breakpoints.sm}px) {
      width: 300px;
      display: flex;
    }
  `,
  chipRow: css`
    display: flex;
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
  `,
  searchRow: css`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  navigationButton: css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  `,
  entryPanel: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  searcher: css``,
  modal: (color?: string) => css`
    & .modal-title {
      color: ${color};
    }
  `,
  filterHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
  `,
  expandButton: (isOpen: boolean) => css`
    background: none;
    outline: none;
    border: none;
    transform: rotate(${isOpen ? "90deg" : "-90deg"});
    transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    user-select: none;
  `,
  areaToExpand: (isOpen: boolean) => css`
    visibility: ${isOpen ? "visible" : "hidden"};
    opacity: ${!isOpen ? 0 : 1};
    height: ${isOpen ? "fit-content" : "0"};
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    transition: ${!isOpen
      ? "opacity 100ms, height 500ms, visibility 500ms"
      : "height 500ms 200ms , opacity 300ms  100ms, visibility 500ms"};
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `,
};

const JournalViewer: React.FC = () => {
  const { journalId } = useParams<{ journalId: string }>();
  const journalHook = useJournals();
  const globalTagList = useGetTags().value;

  const journals = journalHook.value;
  const state = useAppState((state) => state);

  const postEntryRequestLoading = getLoading(state, ActionType.PostJournalEntryRequest);
  const journal = useMemo(() => journals?.find((journal) => journal.id === journalId), [journalId, journals]);
  const journalEntries = useMemo(() => getEntriesForJournal(journalId, journals), [journalId, journals]);
  const putJournalLoading = useLoading(ActionType.PutJournalRequest);
  const [hasJournal, setHasJournal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showCreateEntry, setShowCreateEntry] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [firstFilteredEntries, setFirstFilteredEntries] = useState<EntryWithJournalId[]>(journalEntries);
  const [secondFilteredEntries, setSecondFilteredEntries] = useState<EntryWithJournalId[]>(journalEntries);
  const [searchedEntries, setSearchedEntries] = useState<EntryWithJournalId[]>(journalEntries);
  const [searched, setSearched] = useState(false);
  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
  const [draftEntryType, setDraftEntryType] = useState(ENTRY_TYPE.Text);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoFilterChecked, setVideoFilterChecked] = useState(true);
  const [textFilterChecked, setTextFilterChecked] = useState(true);
  const [analysisFilterChecked, setAnalysisFilterChecked] = useState(true);
  const [excludeFromCksInstructionals, setExcludeFromCksInstructionals] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleAddEntry = useCallback(() => {
    setShowAddEntryModal(true);
    setShowCreateEntry(false);
    setShowEditForm(false);
    setVideoUrl("");
  }, []);

  const handleShowEditForm = useCallback(() => {
    setShowCreateEntry(false);
    setShowEditForm(true);
  }, []);

  const handleCreateCancel = useCallback(() => {
    setShowCreateEntry(false);
    setShowAddEntryModal(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEditForm(false);
    setShowAddEntryModal(false);
  }, []);

  const getFilteredEntries = useCallback(() => {
    let filteredEntries = [...journalEntries];
    if (!textFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Text);
    }
    if (!videoFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Video);
    }
    if (!analysisFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.SparAnalysis);
    }
    return filteredEntries;
  }, [journalEntries, videoFilterChecked, textFilterChecked, analysisFilterChecked]);

  const handleVideoFilterChecked = useCallback(() => {
    setVideoFilterChecked((prev) => !prev);
  }, []);

  const handleTextFilterChecked = useCallback(() => {
    setTextFilterChecked((prev) => !prev);
  }, []);
  const handleAnalysisFilterChecked = useCallback(() => {
    setAnalysisFilterChecked((prev) => !prev);
  }, []);

  const handleSearch = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  const handleToggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    //once journals load, sets it to state one time
    if (!hasJournal && journals) {
      setFirstFilteredEntries(journalEntries);
      setSearchedEntries(journalEntries);
      setHasJournal(true);
    }
  }, [hasJournal, journals, journalEntries]);

  useEffect(() => {
    searchTerm ? setSearched(true) : setSearched(false);
    setSearchedEntries(
      getEntriesFromSearchResults(searchFilteredEntries(searchTerm, secondFilteredEntries, globalTagList || []))
    );
  }, [searchTerm, secondFilteredEntries, globalTagList]);

  useEffect(() => {
    setFirstFilteredEntries(getFilteredEntries());
  }, [getFilteredEntries]);

  if (!journal && !journalHook.loading) {
    return <span>Journal not found! Please contact customer service if this error continues.</span>;
  }

  return (
    <>
      {journalHook.loading && <Loader message={"Loading"} />}
      {putJournalLoading && <Loader message={"Saving"} />}
      {showCreateEntry ? (
        journal && (
          <EntryCreator
            entryType={draftEntryType}
            journal={journal}
            handleCreateCancel={handleCreateCancel}
            videoUrl={videoUrl}
            excludeFromCksInstructionals={excludeFromCksInstructionals}
          />
        )
      ) : (
        <div css={styles.root}>
          <FloatingActionButton onClick={handleAddEntry} />
          <LibraryJournalSelector
            css={styles.desktopOnly}
            ignoreCreateAndEdit={true}
          />

          {journal && (
            <JournalViewerSummary
              handleShowEditForm={handleShowEditForm}
              journal={journal}
            />
          )}

          <div css={styles.entryPanel}>
            <div css={styles.column}>
              {!!postEntryRequestLoading ? (
                <Loader message={"Saving..."} />
              ) : (
                <div css={styles.column}>
                  <div css={styles.filterSection(isOpen)}>
                    <div
                      onClick={handleToggleOpen}
                      css={styles.filterHeader}
                    >
                      <span>{isOpen ? "Hide " : "Show "} Filters</span>
                      <div css={styles.expandButton(isOpen)}>
                        <ChevronLeftIcon />
                      </div>
                    </div>
                    <div css={styles.areaToExpand(isOpen)}>
                      <div css={styles.chipRow}>
                        <FilterChip
                          name={"Video Entries"}
                          checked={videoFilterChecked}
                          handleChecked={handleVideoFilterChecked}
                        />
                        <FilterChip
                          name={"Text Entries"}
                          checked={textFilterChecked}
                          handleChecked={handleTextFilterChecked}
                        />
                        <FilterChip
                          name={"Spar Analysis"}
                          checked={analysisFilterChecked}
                          handleChecked={handleAnalysisFilterChecked}
                        />
                      </div>
                      <div css={styles.row}>
                        <EntryFilter
                          setFilteredEntries={setSecondFilteredEntries}
                          filteredEntries={secondFilteredEntries}
                          tagList={globalTagList || []}
                          entriesToFilter={firstFilteredEntries}
                          journal={journal}
                        />
                        <Searcher
                          css={styles.searcher}
                          placeholder={"Search Entries..."}
                          onClick={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {<EntrySummary entries={searched ? searchedEntries : secondFilteredEntries} />}
        </div>
      )}
      {showAddEntryModal && journal && (
        <GenericModal
          noButtons={true}
          onClose={() => {
            setShowAddEntryModal(false);
          }}
          title={"Add New Entry"}
        >
          <AddNewEntryModal
            setEntryType={setDraftEntryType}
            closeModal={handleCloseModal}
            createEntry={setShowCreateEntry}
            color={journal.color}
            setVideoUrl={setVideoUrl}
            setExcludeFromCksInstructionals={setExcludeFromCksInstructionals}
            journal={journal}
          />
        </GenericModal>
      )}
      {showEditForm && journal && (
        <GenericModal
          noButtons={true}
          onClose={() => setShowEditForm(false)}
          title={journal.name}
          css={styles.modal(theme.fontColor.text)}
        >
          <JournalCreatorEditor
            createNewJournal={false}
            closeModal={handleCloseModal}
            journal={journal}
          />
        </GenericModal>
      )}
    </>
  );
};

export default JournalViewer;
