/** @jsxImportSource @emotion/react */
import React, { Dispatch, RefObject, useCallback, useState } from "react";
import { css } from "@emotion/react";
import { formatPlayerTime } from "./module";
import ReactPlayer from "react-player";
import { EntryFormState, VideoNote } from "../../Utils/types";
import PlayIcon from "../../icons/PlayIcon";
import PauseIcon from "../../icons/PauseIcon";
import VolumeUpIcon from "../../icons/VolumeUpIcon";
import VolumeOffIcon from "../../icons/VolumeOffIcon";
import { PLAYBACK_RATE_OPTIONS } from "./VideoNoteTaker";
import PlaybackRateIcon from "../../icons/PlaybackRateIcon";
import CheckIcon from "../../icons/CheckIcon";
import theme, { MENU_LEFT_OFFSET } from "../../Theme";
import TagViewer from "../../Tags/TagViewer";
import GenericModal from "../../modal/GenericModal";
import ReplayTenIcon from "../../icons/ReplayTenIcon";
import ForwardTenIcon from "../../icons/ForwardTenIcon";
import useWindowDimensions from "../../Utils/useWindowDimensions";

const styles = {
  makeColumn: css`
    display: flex;
    flex-direction: column;
  `,
  makeRow: css`
    display: flex;
    flex-direction: row;
  `,
  spaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  iconCluster: css`
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
  `,
  controlWrapper: (isOverlay: boolean) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    user-select: none;
    height: 120px;
    padding: 5px;
    background-color: #fff;
    color: #000;
    position: relative;
    font-size: 12px;
    ${isOverlay && "position: absolute;"}
  `,
  meterFlexBox: css`
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #000;
  `,
  meterWrapper: (width: number) => css`
    width: ${width}px;
    position: relative;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin: 15px 0 15px 0;
    height: 6px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

    .meterFlexBox:hover & {
      height: 8px;
    }
  `,
  tagIcon: (color = "#000000") => css`
    color: ${color};
  `,
  baseMeter: (width: number) => css`
    width: ${width}%;
    height: 100%;
    background-color: #6f6f6f;
    position: absolute;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,

  playedMeter: css`
    width: var(--played);
    height: 100%;
    left: 0;
    position: absolute;
    background-color: red;
    pointer-events: none;
    transition: height 50ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 30ms linear;
    overflow: hidden;
  `,
  loadedMeter: css`
    width: var(--loaded);
    height: 100%;
    left: 0;
    position: absolute;
    background-color: #8e8e8e;
    pointer-events: none;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  noteNode: (location: number) => css`
    position: absolute;
    left: ${location}%;
    height: 100%;
    width: 2px;
    transform: translateX(-50%); //to offset border
    pointer-events: none;
    background-color: black;
    // clip-path: path("M 0 0 A 3 2.5 0 1 1 0 6 L 8 6 A 2.5 2.5 0 1 1 8 0 Z");
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    .meterFlexBox:hover & {
      height: 8px;
      // clip-path: path("M 0 0 A 3 3 0 1 1 0 6 L 8 6 A 3 3 0 1 1 8 0 Z");
    }
  `,
  playerIcon: css`
    justify-content: center;
    display: flex;
    background-color: transparent;
    color: ${theme.fontColor.text};
    border: none;
    padding: 0;
    font-size: 24px;
  `,
  playButton: css`
    color: ${theme.palette.primary.main};
  `,
  genericModal: css`
    > div {
      max-width: 650px;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -5px;
  `,

  modalContainer: css`
    position: relative;
  `,
  modal: css`
    position: absolute;
    width: 150px;
    bottom: 44px;
    left: 18px;
    background-color: #0f0f0fbf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    font-size: 14px;
  `,
  seekingNode: css`
    left: var(--seeking-left);
    transform: translateX(var(--seeking-transform));
    position: absolute;
    width: var(--seeking-size);
    height: var(--seeking-size);
    border-radius: 30px;
    background-color: red;
    pointer-events: none;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5), left 30ms linear;
  `,
  speedButton: css`
    background-color: transparent;
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;

    :hover {
      background-color: ${theme.colors.mediumGray};
    }
  `,
};

const snapSensitivity = 0.03; //higher percentage will create a stickier node when scrubbing tracker
const PLAYER_WINDOW_PADDING = 14;
const LARGER_SCREEN_OFFSET = 20;
const PLAYER_WINDOW_OFFSET = MENU_LEFT_OFFSET + 2 * LARGER_SCREEN_OFFSET;

export interface Props {
  player: RefObject<ReactPlayer>;
  seekTo: Function;
  seeking: boolean;
  setSeeking: Dispatch<boolean>;
  playerWidth: number;
  setPlayed: Dispatch<number>;
  playBackRate: number;
  setPlaybackRate: Dispatch<number>;
  played: number;
  isMuted: boolean;
  setIsMuted: Dispatch<boolean>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<boolean>;
  loadedPosition: number;
  playedPosition: number;
  videoDuration: number;
  notesArray: VideoNote[] | undefined;
  color: string;
  setEntryFormState: (entryFormState: EntryFormState) => void;
  entryFormState: EntryFormState;
  isOverlay?: boolean;
  className?: string;
}

const PlayerControls = (props: Props) => {
  const {
    seekTo,
    seeking,
    setSeeking,
    playerWidth,
    played,
    isMuted,
    isPlaying,
    setIsMuted,
    setIsPlaying,
    loadedPosition,
    playedPosition,
    videoDuration,
    notesArray,
    playBackRate,
    setPlaybackRate,
    setEntryFormState,
    entryFormState,
    isOverlay = false,
    className = "",
  } = props;
  const [closestEntry, setClosestEntry] = useState<VideoNote | undefined>(undefined);
  const [showTracker, setShowTracker] = useState<boolean>(false);
  const [showPlaybackRateModal, setShowPlaybackRateModal] = useState(false);
  const [showTagViewer, setShowTagViewer] = useState(false);

  const { width } = useWindowDimensions();
  const isMediumOrLarger = width > theme.breakpoints.md;

  const convertToSeconds = useCallback(
    (position: number) => (position / playerWidth) * videoDuration,
    [playerWidth, videoDuration]
  );

  const convertToPosition = useCallback(
    (seconds: number) => (seconds / videoDuration) * playerWidth,
    [playerWidth, videoDuration]
  );

  const handlePause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const handlePlay = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const toggleMute = useCallback(() => {
    setIsMuted(!isMuted);
  }, [isMuted, setIsMuted]);

  const seekingSnapToClosest = useCallback(
    (mousePosition: number) => {
      if (notesArray) {
        const mousePosToSeconds = convertToSeconds(mousePosition);
        const snapRange = snapSensitivity * videoDuration;
        const distanceToClosest = closestEntry ? Math.abs(closestEntry.timeInSeconds - mousePosToSeconds) : videoDuration;

        //find closest node to tracker if notes exist

        notesArray.forEach((entry) => {
          !closestEntry && setClosestEntry(entry);
          const distanceToTracker = Math.abs(entry.timeInSeconds - mousePosToSeconds);
          distanceToTracker < distanceToClosest && setClosestEntry(entry);
        });

        //snap to closest node if it's within range
        const closestEntryToPosition = closestEntry
          ? convertToPosition(closestEntry.timeInSeconds)
          : convertToPosition(videoDuration);

        return distanceToClosest > snapRange ? mousePosition : closestEntryToPosition;
      }
    },
    [videoDuration, closestEntry, notesArray, convertToPosition, convertToSeconds]
  );

  const handleMouseMoveWhenSeeking = useCallback(
    (event: any) => {
      if (seeking) {
        const mousePosition = event.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : PLAYER_WINDOW_PADDING); //pixels
        const mousePosIsValid = mousePosition >= 0 && mousePosition <= playerWidth;
        const seekTime = Math.floor(convertToSeconds(Number(seekingSnapToClosest(mousePosition))));
        mousePosIsValid && seekTo(seekTime);
      }
    },
    [seeking, seekingSnapToClosest, convertToSeconds, playerWidth, seekTo, isMediumOrLarger]
  );
  const handleTouchMoveWhenSeeking = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (seeking) {
        const touch = event.touches[0];
        const mousePosition = touch.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : PLAYER_WINDOW_PADDING); //pixels
        const mousePosIsValid = mousePosition >= 0 && mousePosition <= playerWidth;
        const seekTime = Math.floor(convertToSeconds(Number(seekingSnapToClosest(mousePosition))));
        mousePosIsValid && seekTo(seekTime);
      }
    },
    [seeking, seekingSnapToClosest, convertToSeconds, playerWidth, seekTo, isMediumOrLarger]
  );

  const handleSkip = useCallback(
    (skipSeconds: number) => {
      const tempTime = played + skipSeconds;
      const newTime = () => {
        if (tempTime < 0) {
          return 0;
        }
        if (tempTime > videoDuration) {
          return videoDuration;
        }
        return tempTime;
      };
      seekTo(newTime());
    },
    [played, seekTo, videoDuration]
  );

  const handleSeekingMouseUp = useCallback(() => {
    setSeeking(false);
    setIsPlaying(true);
  }, [setSeeking, setIsPlaying]);

  const handleSeekingMouseDown = useCallback(
    (event: any) => {
      setSeeking(true);
      setIsPlaying(false);
      const mousePosition = event.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : PLAYER_WINDOW_PADDING);

      const mousePosToSeconds = convertToSeconds(mousePosition);
      seekTo(mousePosToSeconds);
    },
    [seekTo, convertToSeconds, setIsPlaying, setSeeking, isMediumOrLarger]
  );
  const handleSeekingTouchDown = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      setSeeking(true);
      setIsPlaying(false);
      const touch = event.touches[0];
      const touchPosition = touch.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : PLAYER_WINDOW_PADDING);

      const touchPosToSeconds = convertToSeconds(touchPosition);
      seekTo(touchPosToSeconds);
    },
    [seekTo, convertToSeconds, setIsPlaying, setSeeking, isMediumOrLarger]
  );

  const handleMeterMouseEnter = useCallback(() => {
    setShowTracker(true);
  }, []);

  const handleMeterMouseLeave = useCallback(() => {
    setShowTracker(false);
    setSeeking(false);
  }, [setSeeking]);

  const renderNoteNodes = useCallback(
    () =>
      notesArray?.map((note) => {
        const nodePosition = (note.timeInSeconds / videoDuration) * 100;
        return (
          <div
            key={note.timeInSeconds + "node"}
            css={styles.noteNode(nodePosition)}
          />
        );
      }),
    [notesArray, videoDuration]
  );
  const togglePlaybackRateModal = useCallback((e: any) => {
    e.preventDefault();
    setShowPlaybackRateModal((prev) => !prev);
  }, []);

  const handlePlaybackRateChange = useCallback(
    (e: any, rate: number) => {
      togglePlaybackRateModal(e);
      setPlaybackRate(rate);
    },
    [setPlaybackRate, togglePlaybackRateModal]
  );

  const renderMeter = useCallback(
    () => (
      <div
        onMouseDown={handleSeekingMouseDown}
        onMouseUp={handleSeekingMouseUp}
        onMouseMove={handleMouseMoveWhenSeeking}
        onMouseLeave={handleMeterMouseLeave}
        onMouseEnter={handleMeterMouseEnter}
        onTouchStart={handleSeekingTouchDown}
        onTouchMove={handleTouchMoveWhenSeeking}
        css={styles.meterFlexBox}
        className="meterFlexBox"
      >
        <div css={styles.meterWrapper(playerWidth)}>
          <div css={styles.baseMeter(100)} />
          <div
            css={styles.loadedMeter}
            style={{
              ["--loaded" as any]: loadedPosition * 100 + "%",
            }}
          />
          <div
            css={styles.playedMeter}
            style={{
              ["--played" as any]: playedPosition * 100 + "%",
            }}
          />
          {renderNoteNodes()}
          {showTracker || seeking ? (
            <div
              css={styles.seekingNode}
              style={{
                ["--seeking-left" as any]: playedPosition * 100 + "%",
                ["--seeking-size" as any]: 14 + "px",
                ["--seeking-transform" as any]: -7 + "px",
              }}
            />
          ) : (
            <div
              css={styles.seekingNode}
              style={{
                ["--seeking-left" as any]: playedPosition * 100 + "%",
                ["--seeking-size" as any]: 0 + "px",
                ["--seeking-transform" as any]: -7 + "px",
              }}
            />
          )}
        </div>
      </div>
    ),
    [
      handleSeekingMouseDown,
      handleSeekingMouseUp,
      handleMouseMoveWhenSeeking,
      handleMeterMouseLeave,
      handleMeterMouseEnter,
      handleSeekingTouchDown,
      handleTouchMoveWhenSeeking,
      playerWidth,
      loadedPosition,
      playedPosition,
      renderNoteNodes,
      showTracker,
      seeking,
    ]
  );

  const renderPlaybackRateModal = useCallback(() => {
    const options = Object.keys(PLAYBACK_RATE_OPTIONS)
      .filter((key) => Number.isNaN(+key))
      .map((key: string) => ({
        key,
        value: PLAYBACK_RATE_OPTIONS[key as keyof typeof PLAYBACK_RATE_OPTIONS],
      }));
    return (
      <div css={styles.modal}>
        {options.map((option) => (
          <button
            css={styles.speedButton}
            key={option.key}
            onClick={(e) => handlePlaybackRateChange(e, option.value)}
          >
            {playBackRate === option.value ? <CheckIcon /> : <div />}
            {PLAYBACK_RATE_OPTIONS[option.key as keyof typeof PLAYBACK_RATE_OPTIONS] === 1
              ? "Normal"
              : PLAYBACK_RATE_OPTIONS[option.key as keyof typeof PLAYBACK_RATE_OPTIONS]}
          </button>
        ))}
      </div>
    );
  }, [handlePlaybackRateChange, playBackRate]);

  return (
    <div
      className={className}
      css={[styles.controlWrapper(isOverlay), styles.makeColumn]}
    >
      <div css={[styles.makeRow, styles.spaceBetween]}>
        <div css={styles.iconCluster}>
          <button
            css={styles.playerIcon}
            onClick={() => handleSkip(-10)}
          >
            <ReplayTenIcon css={styles.playerIcon} />
          </button>
          {isPlaying ? (
            <button
              css={styles.playerIcon}
              onClick={handlePause}
            >
              <PauseIcon css={[styles.playerIcon, styles.playButton]} />
            </button>
          ) : (
            <button
              css={styles.playerIcon}
              onClick={handlePlay}
            >
              <PlayIcon css={[styles.playerIcon, styles.playButton]} />
            </button>
          )}
          <button
            css={styles.playerIcon}
            onClick={() => handleSkip(10)}
          >
            <ForwardTenIcon css={styles.playerIcon} />
          </button>
        </div>
        <div css={styles.iconCluster}>
          <button
            css={[styles.playerIcon, styles.modalContainer]}
            onClick={togglePlaybackRateModal}
          >
            <PlaybackRateIcon css={styles.playerIcon} />
          </button>
          {showPlaybackRateModal && renderPlaybackRateModal()}

          <button
            css={styles.playerIcon}
            onClick={toggleMute}
          >
            {isMuted ? <VolumeOffIcon css={styles.playerIcon} /> : <VolumeUpIcon css={styles.playerIcon} />}
          </button>
        </div>
      </div>
      {renderMeter()}
      <div css={styles.row}>
        <span>{formatPlayerTime(played)}</span>
        <span>{formatPlayerTime(videoDuration)}</span>
      </div>
      {showTagViewer ? (
        <GenericModal
          css={styles.genericModal}
          onClose={() => {
            setShowTagViewer(false);
            setEntryFormState({ ...entryFormState, editedTagIds: entryFormState.entry.tags || [] });
          }}
          title={"Add Tags"}
          onConfirm={() => setShowTagViewer(false)}
        >
          <TagViewer
            editPost={true}
            setEntryFormState={setEntryFormState}
            entryFormState={entryFormState}
          />
        </GenericModal>
      ) : null}
    </div>
  );
};
export default PlayerControls;
