/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { Dispatch, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { formatPlayerTime } from "./module";
import ReactPlayer from "react-player";
import { EntryFormState, VideoNote } from "../../Utils/types";
import theme from "../../Theme";
// import TagIcon from "../../icons/TagIcon";
import GenericModal from "../../modal/GenericModal";
import TagViewer from "../../Tags/TagViewer";
import { hexToRGBA } from "../../Utils/module";
import useWindowDimensions from "../../Utils/useWindowDimensions";
import { useParams } from "react-router-dom";

const styles = {
  makeRow: css`
    display: flex;
    flex-direction: row;
  `,
  makeColumn: css`
    display: flex;
    flex-direction: column;
  `,
  createEntry: (isCreate: boolean) => css`
    display: ${isCreate ? "flex" : "none"};
    height: 100%;
  `,
  viewEntry: (isCreate: boolean) => css`
    display: ${!isCreate ? "flex" : "none"};
    height: 100%;
    overflow: hidden;
  `,

  noteSectionWrapper: css`
    display: flex;
    justify-content: space-between;
    user-select: none;
    height: 100%;
    align-items: stretch;
    overflow: hidden;
    width: 100%;
  `,
  noteListWrapper: css`
    overflow-y: scroll;
    height: 100%;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  card: (isCurrentNote: boolean, color: string) => css`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 60px 1fr 10px;
    width: 100%;
    font-size: 12px;
    align-items: flex-start;
    background-color: ${isCurrentNote
      ? color
        ? hexToRGBA(color, 0.1)
        : theme.colors.ultraLightBlue
      : theme.colors.contentBackground};
    transition: background-color 300ms linear;
    border-left: ${isCurrentNote ? "solid 5px " + (color ? color : theme.fontColor.googleBlue) : "transparent"};
    transition: background-color 300ms linear, border-left-color 300ms linear;
    border-radius: 4px;
  `,
  userNotes: css`
    border: none;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    transition: background-color 300ms linear;
    color: ${theme.fontColor.text};
    font-weight: 500;
  `,

  timestamp: (color: string) => css`
    user-select: none;
    font-weight: 600;
    color: ${color ? color : theme.fontColor.googleBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  `,
  tagListWrapper: css`
    margin-bottom: 10px;
    padding: 0 10px 10px 5px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  notepadForEntryViewer: css`
    margin-bottom: 10px;
    padding: 0 10px 10px 5px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  inputColumn: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  `,
  viewEntryTitleWrapper: (color: string) => css`
    background-color: ${color ? color : theme.colors.ultraLightBlue};
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    width: 100%;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
  `,
  titleInputViewer: css`
    background-color: transparent;
    color: ${theme.colors.contentBackground};
    outline: none;
    border: none;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
  `,
  descriptionInputViewer: css`
    text-wrap: prettify;
    background-color: transparent;
    color: ${theme.colors.contentBackground};
    outline: none;
    border: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    resize: none;
  `,
  responsiveInputWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      gap: 10px;
      flex-direction: row;
    }
  `,
  tagWrapper: css`
    display: flex;
    flex-direction: row;
    height: fit-content;
    column-gap: 11px;
    flex-wrap: wrap;
    row-gap: 5px;
    border: solid 1px ${theme.colors.border};
    padding: 12px 14px;
    border-radius: 6px;
    color: ${theme.colors.mediumGray};
    max-height: 74px;
    overflow-y: auto;
  `,
  titleInput: (color: string) => css`
    width: 100%;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
    height: 42px;
    padding: 0 14px;
    &:focus {
      outline: solid 2px ${color};
    }
  `,
  descriptionInput: (color: string) => css`
    width: 100%;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
    height: 60px;
    padding: 12px 14px 0 12px;
    resize: none;
    &:focus {
      outline: solid 2px ${color};
    }
  `,
  hashtags: (color: string) => css`
    cursor: pointer;
    border-radius: 300px;
    justify-content: center;
    color: #ffffff;
    line-height: 100%;
    padding: 3px 7px 2px 7px;
    background-color: ${color ? color : theme.palette.primary.main};
  `,
  genericModal: css`
    > div {
      max-width: 650px;
    }
  `,
  tagIcon: css`
    color: ${theme.fontColor.text};
  `,
  tagViewerButton: (color = theme.palette.primary.main) => css`
    display: flex;
    flex-direction: row;
    margin-top: 28px;
    padding: 0 10px;
    height: 42px;
    align-items: center;
    gap: 10px;
    justify-content: center;
    border-radius: 200px;
    border: 1px solid ${color};
    color: ${theme.fontColor.text};
    font-weight: 600;
    cursor: pointer;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      display: none;
    }
  `,
  tagViewerButtonForViewer: (color = theme.palette.primary.main) => css`
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    height: 42px;
    align-items: center;
    gap: 10px;
    justify-content: center;
    border-radius: 200px;
    border: 1px solid ${color};
    color: ${theme.fontColor.text};
    font-weight: 600;
    cursor: pointer;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      display: none;
    }
  `,
  inputLabel: css`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -10px;
  `,
  videoNoteLabel: css`
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;
    border-bottom: solid 1px ${theme.colors.border};
  `,
  videoNotesWrapper: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 60px;
    overflow-y: hidden;
    background-color: ${theme.colors.background};
    border-radius: 8px;
  `,
};

export interface Props {
  player: RefObject<ReactPlayer>;
  handlePlay: Function;
  notesArray: VideoNote[];
  setNotesArray: Dispatch<VideoNote[]>;
  hashtagArray: string[] | undefined;
  setHashtagArray: Dispatch<string[] | undefined>;
  videoDuration: number;
  setPlayed: Dispatch<number>;
  setPlayedPosition: Dispatch<number>;
  setIsPlaying: Dispatch<boolean>;
  setUpdatedNote: Dispatch<number | undefined>;
  updatedNote: number | undefined;
  entryFormState: EntryFormState;
  setEntryFormState: (entryFormState: EntryFormState) => void;
  color: string;
  played: number;
}

const Notepad = (props: Props) => {
  const {
    player,
    handlePlay,
    notesArray,
    setNotesArray,
    hashtagArray,
    setHashtagArray,
    videoDuration,
    setPlayed,
    setPlayedPosition,
    setIsPlaying,
    setUpdatedNote,
    updatedNote,
    entryFormState,
    setEntryFormState,
    color,
    played,
  } = props;

  const handleSeekTo = useCallback(
    (seconds: number) => {
      setPlayedPosition(seconds / videoDuration);
      setPlayed(seconds);
      player?.current?.seekTo(seconds);
      handlePlay();
    },
    [player, handlePlay, videoDuration, setPlayed, setPlayedPosition]
  );

  const { width } = useWindowDimensions();
  const { entryId } = useParams<{ entryId: string }>();
  const isCreate = !entryId;
  const [tempNotesArray, setTempNotesArray] = useState<VideoNote[]>([...notesArray]);
  const [tagRefs, setTagRefs] = useState<HTMLTextAreaElement[]>([]);
  const [currentNote, setCurrentNote] = useState(notesArray[0]);
  const notesRef = useRef<(HTMLTextAreaElement | null)[]>([]);
  const [showTagViewer, setShowTagViewer] = useState(false);
  // const toggleTagViewer = useCallback(() => {
  //   setShowTagViewer((prev) => !prev);
  // }, []);

  const clearBlankEntries = useCallback(() => {
    if (notesArray) {
      const tempArray = [...notesArray].filter((el) => el.text.trim() !== "");
      setNotesArray(tempArray);
    }
  }, [notesArray, setNotesArray]);

  const scrollToUpdatedNote = useCallback(() => {
    const noteIndex: number | undefined = notesArray?.findIndex((note: VideoNote) => note?.timeInSeconds === updatedNote);
    if (noteIndex) notesRef?.current[noteIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [notesArray, notesRef, updatedNote]);

  const scrollToClickedNote = useCallback(
    (noteRef: HTMLTextAreaElement) => {
      noteRef?.scrollIntoView({ behavior: "smooth", block: "center" });
      noteRef.select();
      handleSeekTo(Number(noteRef.id));
    },
    [handleSeekTo]
  );

  const getHashtagsFromNotes = useCallback(() => {
    //https://stackoverflow.com/questions/25693456/get-hashtags-from-string-with-trim-spaces-and-new-lines
    const regexp: RegExp = new RegExp("[#@][a-zA-Z0-9-]+", "g");
    let tempHashtagArray: string[] | null = [];
    let tempTagRefArray: HTMLTextAreaElement[] | null = [];
    if (notesArray) {
      notesArray.forEach((note: VideoNote, index: number) => {
        const testArray: RegExpMatchArray | null = note.text.match(regexp);
        if (testArray) {
          tempHashtagArray = tempHashtagArray ? [...tempHashtagArray, ...testArray] : [...testArray];
          testArray.forEach(() => {
            if (notesRef.current[index]) {
              tempTagRefArray = tempTagRefArray
                ? [...tempTagRefArray, notesRef?.current[index] as HTMLTextAreaElement]
                : [notesRef?.current[index] as HTMLTextAreaElement];
            }
          });
        }
      });
    }
    const updatedHashtagArray = tempHashtagArray.map((item) => item.slice(1));
    setTagRefs(tempTagRefArray);
    setHashtagArray(updatedHashtagArray);
  }, [notesArray, notesRef, setHashtagArray]);

  const handleKeyPressOnCurrentNote = useCallback(
    (event: any) => {
      if (event.target && event.key === "Enter") {
        setNotesArray(tempNotesArray);
        event.target.value?.trim() === "" && clearBlankEntries();
        event.target.blur();
        handlePlay();
        event.preventDefault(); //prevents enter key from adding line break
      }
    },
    [handlePlay, tempNotesArray, clearBlankEntries, setNotesArray]
  );

  const handleFocusOnCurrentNote = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const handleBlurOnCurrentNote = useCallback(() => {
    setNotesArray(tempNotesArray);
    clearBlankEntries();
  }, [clearBlankEntries, tempNotesArray, setNotesArray]);

  const handleOnChangeOfCurrentNote = useCallback(
    (event: { target: { id: string | number; value: string } }) => {
      if (notesArray) {
        const changedNoteArray = [...notesArray];
        setIsPlaying(false);
        const noteIndex = changedNoteArray.findIndex((note) => note?.timeInSeconds === parseInt(event.target.id.toString()));
        changedNoteArray[noteIndex].text = event.target.value || "";
        setUpdatedNote(parseInt(event.target.id as string));
        setTempNotesArray(changedNoteArray);
      }
    },
    [notesArray, setIsPlaying, setUpdatedNote]
  );

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedTitle: e.target?.value || "" });
    },
    [setEntryFormState, entryFormState]
  );
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setEntryFormState({ ...entryFormState, editedText: e.target?.value || "" });
    },
    [setEntryFormState, entryFormState]
  );

  const renderNotes = useCallback(() => {
    if (notesArray) {
      return notesArray.map((note: VideoNote, index: number) => {
        const nextNote = notesArray?.[index + 1];
        const isCurrentNote = note?.timeInSeconds < played && (!nextNote || nextNote?.timeInSeconds > played);
        if (isCurrentNote && note?.timeInSeconds !== currentNote?.timeInSeconds) {
          setCurrentNote(note);
          width > theme.breakpoints.videoEntry &&
            notesRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return (
          <div
            key={note.timeInSeconds + "note"}
            css={styles.card(isCurrentNote, color)}
          >
            <div
              css={styles.timestamp(color)}
              onClick={() => handleSeekTo(note?.timeInSeconds)}
            >
              {formatPlayerTime(note?.timeInSeconds)}
            </div>
            <textarea
              ref={(el) => (notesRef.current[index] = el as HTMLTextAreaElement)}
              id={note?.timeInSeconds.toString()}
              onChange={handleOnChangeOfCurrentNote}
              onKeyPress={handleKeyPressOnCurrentNote}
              onBlur={handleBlurOnCurrentNote}
              onFocus={handleFocusOnCurrentNote}
              css={styles.userNotes}
              value={note.text}
            />
          </div>
        );
      });
    }
  }, [
    notesArray,
    played,
    currentNote?.timeInSeconds,
    color,
    handleOnChangeOfCurrentNote,
    handleKeyPressOnCurrentNote,
    handleBlurOnCurrentNote,
    handleFocusOnCurrentNote,
    width,
    handleSeekTo,
  ]);

  const renderTags = useCallback(() => {
    const hashtags = hashtagArray?.map((tag: string, index: number) => (
      <span
        css={styles.hashtags(color)}
        key={tag + index}
        onClick={() => scrollToClickedNote(tagRefs[index])}
      >
        {tag}
      </span> //todo make sure multiple hashtags in same note don't count for key
    ));
    return (
      <>
        <div css={styles.tagWrapper}>{hashtagArray?.length !== 0 ? hashtags : "Use @ in your notes to to add @bookmarks"}</div>
      </>
    );
  }, [hashtagArray, scrollToClickedNote, tagRefs, color]);

  useEffect(() => {
    scrollToUpdatedNote();
    notesRef.current.forEach((note) => {
      if (note) {
        note.style.height = "1px"; //I know this looks hacky but it isn't. Makes sure the textarea is the right height even if lines are deleted.
        note.style.height = note.scrollHeight + "px";
      }
    });
  }, [tempNotesArray, notesArray, scrollToUpdatedNote]);

  useEffect(() => {
    getHashtagsFromNotes();
  }, [notesArray, tempNotesArray, getHashtagsFromNotes]);

  return (
    <>
      <div css={styles.createEntry(isCreate)}>
        <div css={[styles.makeColumn, styles.noteSectionWrapper]}>
          <div css={styles.tagListWrapper}>
            {/* <div
              onClick={toggleTagViewer}
              css={styles.tagViewerButton(color)}
            >
              <TagIcon css={styles.tagIcon} />
              <span>
                Video Tags
                {!entryFormState?.editedTagIds?.length && (
                  <span
                    css={css`
                      color: red;
                    `}
                  >
                    *
                  </span>
                )}
                <span>
                  {entryFormState?.editedTagIds?.length ? (
                    <span> ({entryFormState?.editedTagIds?.length})</span>
                  ) : (
                    <span
                      css={css`
                        color: red;
                      `}
                    >
                      {" "}
                      ({entryFormState?.editedTagIds?.length})
                    </span>
                  )}
                </span>
              </span>
            </div> */}
            <div css={styles.responsiveInputWrapper}>
              <div css={styles.inputColumn}>
                <label
                  css={styles.inputLabel}
                  htmlFor={"title"}
                >
                  Video title
                </label>
                <input
                  placeholder={"Video title"}
                  css={styles.titleInput(color)}
                  onChange={handleTitleChange}
                  value={entryFormState.editedTitle}
                  required
                  id={"title"}
                />
              </div>
              <div css={styles.inputColumn}>
                <label
                  css={styles.inputLabel}
                  htmlFor={"description"}
                >
                  Video description
                </label>
                <textarea
                  placeholder={"Video description"}
                  css={styles.descriptionInput(color)}
                  onChange={handleTextChange}
                  value={entryFormState.editedText}
                  required
                  id={"description"}
                  rows={2}
                />
              </div>
            </div>
          </div>
          <div css={styles.tagListWrapper}>
            <span css={styles.inputLabel}>Bookmarks</span>
            {renderTags()}
          </div>
          <div css={styles.videoNotesWrapper}>
            <label
              htmlFor="video-notes"
              css={styles.videoNoteLabel}
            >
              Video Notes
            </label>
            <div
              id={"video-notes"}
              css={styles.noteListWrapper}
            >
              {renderNotes()}
            </div>
          </div>
        </div>
      </div>
      <div css={styles.viewEntry(isCreate)}>
        <div css={[styles.makeColumn, styles.noteSectionWrapper]}>
          <div css={styles.notepadForEntryViewer}>
            <div css={styles.viewEntryTitleWrapper(color)}>
              <input
                placeholder={"Video title"}
                css={styles.titleInputViewer}
                onChange={handleTitleChange}
                value={entryFormState.editedTitle}
                required
                id={"title"}
              />
              <textarea
                placeholder={"Video description"}
                css={styles.descriptionInputViewer}
                onChange={handleTextChange}
                value={entryFormState.editedText}
                required
                id={"description"}
                rows={2}
              />
            </div>
            {/* <div
              onClick={toggleTagViewer}
              css={styles.tagViewerButtonForViewer(color)}
            >
              <TagIcon css={styles.tagIcon} />
              <span>
                Video Tags
                {!entryFormState?.editedTagIds?.length && (
                  <span
                    css={css`
                      color: red;
                    `}
                  >
                    *
                  </span>
                )}
                <span>
                  {entryFormState?.editedTagIds?.length ? (
                    <span> ({entryFormState?.editedTagIds?.length})</span>
                  ) : (
                    <span
                      css={css`
                        color: red;
                      `}
                    >
                      {" "}
                      ({entryFormState?.editedTagIds?.length})
                    </span>
                  )}
                </span>
              </span>
            </div> */}
          </div>
          <div css={styles.tagListWrapper}>
            <span css={styles.inputLabel}>Bookmarks</span>
            {renderTags()}
          </div>
          <div css={styles.videoNotesWrapper}>
            <label
              htmlFor="video-notes"
              css={styles.videoNoteLabel}
            >
              Video Notes
            </label>
            <div
              id={"video-notes"}
              css={styles.noteListWrapper}
            >
              {renderNotes()}
            </div>
          </div>
        </div>
      </div>
      {showTagViewer ? (
        <GenericModal
          css={styles.genericModal}
          onClose={() => {
            setShowTagViewer(false);
            setEntryFormState({ ...entryFormState, editedTagIds: entryFormState.entry.tags || [] });
          }}
          title={"Add Tags"}
          onConfirm={() => setShowTagViewer(false)}
        >
          <TagViewer
            editPost={true}
            setEntryFormState={setEntryFormState}
            entryFormState={entryFormState}
          />
        </GenericModal>
      ) : null}
    </>
  );
};
export default Notepad;
