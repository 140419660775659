import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {POST_JOURNAL_ENTRY_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {ENTRY_TYPE, JournalEntryObject, VideoNote} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';
import {Round, updateRoundData} from '../../VideoAnalysis/module';

export interface PostActionProps {
  journalId: string;
  text: string;
  entryDateTimeUtc: string;
  title: string;
  tags?: string[];
  type: ENTRY_TYPE;
  videoUrl?: string;
  notes?: VideoNote[];
  excludeFromCksInstructionals: boolean;
  rollAnalysisRounds?: Round[] | null;
}


const KEY_ACTION = ActionType.PostJournalEntryRequest;
const ACTIONS = [
  ActionType.PostJournalEntryRequest,
  ActionType.PostJournalEntryResponse,
  ActionType.PostJournalEntryError,
];

interface Payload extends Response {
  data?: null | {
    journalEntry?: null | JournalEntryObject,
  };
}

export const postEntryAction = (body: PostActionProps, token?: string) => makeApiAction<Payload, PostActionProps>({
  method: 'POST',
  endpoint: () => POST_JOURNAL_ENTRY_ENDPOINT(body.journalId),
  headers: () => ({
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body: JSON.stringify(body),
  types: makeRsaaTypes<Payload, PostActionProps>(ACTIONS, body),
  bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, PostActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.journalEntry) {
    return addError(state, KEY_ACTION, ['POST entry failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  //should cause a refetch
  return {
    ...successState,
    userJournals: undefined,
    // userJournals: addNewEntry(state, action.meta.journalId, payload.data?.journalEntry),
  };
};

export const postEntryReducer = makeApiReducer<Payload, PostActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to post entry.',
);

export const usePostEntry = (
  {
    journalId,
    text = '',
    entryDateTimeUtc,
    title,
    tags,
    type,
    videoUrl,
    notes,
    excludeFromCksInstructionals,
    rollAnalysisRounds = null,
  }: {
    journalId: string,
    text: string,
    entryDateTimeUtc: string,
    title: string,
    tags: string[],
    type: ENTRY_TYPE,
    videoUrl: string,
    notes: VideoNote[],
    excludeFromCksInstructionals: boolean,
    rollAnalysisRounds?: Round[] | null
  },
) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const updatedRollAnalysisRounds = useCallback(() => {
    if (!rollAnalysisRounds || type !== ENTRY_TYPE.SparAnalysis) return null;
    return rollAnalysisRounds.map((round) => updateRoundData(round)) as Round[];
  }, [rollAnalysisRounds, type]);
  
  const postEntry = useCallback(
    async () => await validateAndDispatch(postEntryAction({
      journalId,
      text,
      entryDateTimeUtc,
      title,
      tags,
      type,
      videoUrl,
      notes,
      excludeFromCksInstructionals,
      rollAnalysisRounds: updatedRollAnalysisRounds(),
    }, token)),
    [journalId,
      text,
      entryDateTimeUtc,
      title,
      tags,
      type,
      videoUrl,
      notes,
      validateAndDispatch,
      token,
      excludeFromCksInstructionals,
      updatedRollAnalysisRounds,
    ],
  );
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  
  return {
    loading, errors, value: postEntry, reload: postEntry,
  };
};