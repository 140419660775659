import {RefObject, useEffect, useState} from 'react';
import {debounce} from './module';

export const useContainerDimensions = (myRef: RefObject<HTMLHeadingElement>) => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});
  
  useEffect(() => {
    const getDimensions = () => ({
      width:  myRef?.current?.offsetWidth || 0,
      height: myRef?.current?.offsetHeight || 0,
    });
    
    const handleResize = debounce(() => {
      setDimensions(getDimensions());
    }, 50);
    
    if (myRef.current) {
      setDimensions(getDimensions());
    }
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);
  
  return dimensions;
};