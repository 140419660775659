import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {TagType} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

export interface PostActionProps {
  name: string,
  category: string,
  tagType?: string,
  userId: string,
  bodyPart?: string,
}

const KEY_ACTION = ActionType.PostUserTagRequest;
const ACTIONS = [
  ActionType.PostUserTagRequest,
  ActionType.PostUserTagResponse,
  ActionType.PostUserTagError,
];

interface Payload extends Response {
  data?: null | {
    tag?: null | TagType
  };
}

export const postUserTagAction = (body: PostActionProps, token?: string) => makeApiAction<Payload, PostActionProps>({
  method: 'POST',
  endpoint: () => ENDPOINTS.PostUserTags,
  headers: () => ({
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body: JSON.stringify(body),
  types: makeRsaaTypes<Payload, PostActionProps>(ACTIONS, body),
  bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, PostActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.tag) {
    return addError(state, KEY_ACTION, ['POST User Tag failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
    tags: [...state.tags || [], payload.data.tag],
  };
};

export const postUserTagReducer = makeApiReducer<Payload, PostActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to create tag.',
);

export const usePostUserTag = ({name, category, tagType, bodyPart}: {
  name: string,
  category: string,
  tagType?: string,
  bodyPart?: string,
}) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const userId = useAppState(state => state.auth?.id);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const postUserTag = useCallback(async () => await validateAndDispatch(
    postUserTagAction({
      name,
      tagType,
      category,
      userId: userId || '',
      bodyPart,
    }, token),
  ), [name, userId, category, tagType, bodyPart, token, validateAndDispatch]);
  
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: postUserTag, reload: postUserTag,
  };
};