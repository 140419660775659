/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React from "react";
import { css, keyframes } from "@emotion/react";
import { APP_LAYERS } from "../layers";
import theme from "../Theme";
import { hexToRGBA } from "./module";

const bounce = keyframes`
  45% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px)
  }
  55% {
    transform: translateY(0px);
  }
`;
//const rounder = (offset: number) => (keyframes`
//  0% {
//    border-radius: 0;
//    transform: rotate(${offset}deg);
//  }
//  50% {
//    border-radius: 50%;
//    transform: rotate(${180 + offset}deg);
//  }
//  100% {
//    border-radius: 0;
//    transform: rotate(${360 + offset}deg);
//  }
//`);
const degree = keyframes`
  0% {
    transform: translateY(-200px)
  }
  20% {
    transform: translateY(0px)
  }
  60% {
    transform: translateY(0px)
  }
  80% {
    transform: translateY(200px)
  }
  100% {
    transform: translateY(200px)
  }
`;
const beltColor = keyframes`
  0% {
    background-color: ${theme.beltColor.white}
  }
  19% {
    background-color: ${theme.beltColor.white}
  }
  20% {
    background-color: ${theme.beltColor.blue}
  }
  39% {
    background-color: ${theme.beltColor.blue}
  }
  40% {
    background-color: ${theme.beltColor.purple}
  }
  59% {
    background-color: ${theme.beltColor.purple}
  }
  60% {
    background-color: ${theme.beltColor.brown}
  }
  79% {
    background-color: ${theme.beltColor.brown}
  }
  80% {
    background-color: ${theme.beltColor.black}
  }
  99% {
    background-color: ${theme.beltColor.black}
  }
  100% {
    background-color: ${theme.beltColor.white}
  }
`;
const beltBarColor = keyframes`
  0% {
    background-color: ${theme.beltColor.black}
  }
  79% {
    background-color: ${theme.beltColor.black}
  }
  80% {
    background-color: ${theme.beltColor.red}
  }
  99% {
    background-color: ${theme.beltColor.red}
  }
  100% {
    background-color: ${theme.beltColor.black}
  }
`;

const styles = {
  root: ({ localLoader, fullScreen }: { localLoader: boolean; fullScreen: boolean }) => css`
    position: ${localLoader ? "relative" : "absolute"};
    z-index: ${APP_LAYERS.loader};
    ${!localLoader && "height: 100vh;"}
    ${!localLoader && "width: 100vw;"}
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${!localLoader && `background-color: ${hexToRGBA(theme.colors.background, fullScreen ? 1 : 0.4)};`}
    backdrop-filter: blur(3px);
  `,
  letterBounce: (delay: number) => css`
    animation: ${bounce} 4s linear infinite;
    animation-delay: ${delay * 100}ms;
    letter-spacing: 2px;
  `,
  belt: (localLoader: boolean) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 160px;
    border: solid 1px ${theme.colors.inactive};
    height: ${localLoader ? "12px" : "30px"};
    ${localLoader && "width: 100%;"}
    border-radius: ${theme.divShapes.radius};
    animation: ${beltColor} 25s linear infinite;
  `,
  barBlack: css`
    background-color: ${theme.beltColor.black};
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 8px 0 8px;
    animation: ${beltBarColor};
  `,
  degree: (rank: number) => css`
    background-color: ${theme.beltColor.white};
    transform: translateY(-200px);
    width: 9%;
    height: 100%;
    animation: ${degree} 5s linear infinite;
    animation-delay: ${(rank - 1) * 0.5}s;
  `,
  empty: css`
    background-color: transparent;
    width: 15%;
  `,
  beltBar: css`
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    overflow: hidden;
    animation: ${beltBarColor} 25s linear infinite;
  `,
  blankSpace: css`
    min-width: 5px;
  `,
  textWrapper: css`
    position: absolute;
    top: 53%;
    display: flex;
    flex-direction: row;
    background-color: #ffffff40;
    padding: 3px 10px;
    border-radius: 5px;
  `,
};

interface Props {
  message?: string;
  localLoader?: boolean;
  fullScreen?: boolean;
}

const Loader: React.FC<Props> = ({ message, localLoader = false, fullScreen = false }) => {
  const renderText = () => (
    <>
      {message &&
        [...message].map((letter, index) =>
          letter === " " ? (
            <div
              css={styles.blankSpace}
              key={letter + index}
            >
              {" "}
            </div>
          ) : (
            <div
              key={letter + index}
              css={styles.letterBounce(index)}
            >
              {letter}
            </div>
          )
        )}
    </>
  );

  return (
    <div css={styles.root({ localLoader, fullScreen })}>
      <div css={styles.belt(localLoader)}>
        <div css={styles.barBlack}>
          <div css={styles.beltBar}>
            <div css={styles.degree(1)} />
            <div css={styles.degree(2)} />
            <div css={styles.degree(3)} />
            <div css={styles.degree(4)} />
          </div>
        </div>
      </div>
      {message && <div css={styles.textWrapper}>{renderText()}</div>}
    </div>
  );
};
export default Loader;
