/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useRef, useState } from "react";
import theme from "../Theme";
import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { DATE_FORMAT } from "./module";
import { EntryFormState } from "../Utils/types";
import { ReactEditor, withReact } from "slate-react";
import { createEditor } from "slate";
import TagViewer from "../Tags/TagViewer";
import { withCorrectVoidBehavior, withImages, withInlines } from "../Utils/RichText/richTextModule";
import { withHistory } from "slate-history";
import TextInput from "../Utils/TextInput";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import RichTextEditor from "../Utils/RichText/RichTextEditor";
import { hexToRGBA } from "../Utils/module";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.contentBackground};
    width: 100%;
    align-self: center;
    height: 100%;
  `,
  entryWrapper: css`
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  datePicker: (color?: string) => css`
    padding: 5px;
    border-color: lightgray;
    box-shadow: none;
    border-width: 2px;
    border: none;
    width: 100%;
    border-radius: 6px;
    background-color: ${hexToRGBA(color || "white", 0.05)};
    color: ${color ? color : "black"};
    ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="${
    color ? "%23" + color.slice(1) : "%23bbbbbb"
  }" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
}

  `,
  inputWrapper: css`
    padding: 5px;
    width: 100%;
    min-height: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
  `,

  dropdownArea: css`
    position: relative;
    border-bottom: solid 1px lightgray;
    min-height: 30px;
  `,
  expandButton: (isOpen: boolean) => css`
    position: absolute;
    right: -7px;
    top: -8px;
    background: none;
    outline: none;
    border: none;
    transform: rotate(${isOpen ? "90deg" : "-90deg"});
    transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    user-select: none;
  `,
  areaToExpand: (isOpen: boolean) => css`
    visibility: ${isOpen ? "visible" : "hidden"};
    opacity: ${!isOpen ? 0 : 1};
    height: ${isOpen ? "fit-content" : "20px"};
    transition: ${!isOpen
      ? "opacity 100ms, height 500ms, visibility 500ms"
      : "height 500ms 200ms , opacity 300ms  100ms, visibility 500ms"};
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 60%;
    max-width: 400px;
  `,
  input: css`
    width: 100%;
    .input-fieldset {
      border-radius: 6px;
    }
  `,
  tagViewer: css`
    width: 60%;
    display: flex;
    flex: 1;
  `,
};

interface Props {
  entryFormState: EntryFormState;
  setEntryFormState: (formState: EntryFormState) => void;
  journalColor?: string;
}

const EntryEditor: React.FC<Props> = (props) => {
  const { entryFormState, setEntryFormState, journalColor = "black" } = props;
  const [isOpen, setIsOpen] = useState(true);

  const editorRef = useRef<ReactEditor | null>(null);
  if (!editorRef.current)
    editorRef.current = withCorrectVoidBehavior(withImages(withInlines(withReact(withHistory(createEditor())))));
  const editor = editorRef.current;

  const handleEntryDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedEntryDate: DateTime.fromISO(e.target.value) });
    },
    [setEntryFormState, entryFormState]
  );

  const onTitleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedTitle: e.target?.value || "" });
    },
    [setEntryFormState, entryFormState]
  );

  const handleEntryTextChange = useCallback(
    (value: string) => {
      const isAstChange = editor.operations.some((operation) => "set_selection" !== operation.type);
      if (isAstChange) {
        setEntryFormState({
          ...entryFormState,
          editedText: value,
        });
      }
    },
    [setEntryFormState, entryFormState, editor.operations]
  );

  const handleToggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <div css={styles.root}>
      <div css={styles.entryWrapper}>
        <div css={styles.dropdownArea}>
          <button
            css={styles.expandButton(isOpen)}
            onClick={handleToggleOpen}
          >
            <ChevronLeftIcon />
          </button>
          <div css={styles.areaToExpand(isOpen)}>
            <div css={styles.inputWrapper}>
              <div css={styles.column}>
                <TextInput
                  required={true}
                  value={entryFormState.editedTitle}
                  placeholder={"Title"}
                  id={"entry-editor-title"}
                  onChange={onTitleTextChange}
                  css={styles.input}
                />

                <input
                  id="date-picker-journal-entry"
                  name="journal-entry-date"
                  type="datetime-local"
                  value={entryFormState.editedEntryDate.toFormat(DATE_FORMAT.inputDateTimeLocal)}
                  onChange={handleEntryDateChange}
                  min="1900-01-01"
                  css={styles.datePicker(journalColor)}
                />
              </div>
              <TagViewer
                setEntryFormState={setEntryFormState}
                entryFormState={entryFormState}
                editPost={true}
                css={styles.tagViewer}
                journalColor={journalColor}
              />
            </div>
          </div>
        </div>
      </div>
      <div />
      <RichTextEditor
        editor={editor}
        onChange={handleEntryTextChange}
        value={entryFormState.editedText || ""}
      />
    </div>
  );
};
export default EntryEditor;
