/**
 * Whether or not this is a dev environment e.g. localhost
 */
export const IS_DEV_ENV = !process.env.NODE_ENV
  || process.env.NODE_ENV === 'development'
  || window?.location?.hostname?.includes('cks-web-dev.azurewebsites.net');

export const IS_PROD_ENV = !IS_DEV_ENV;

export const LOCALHOST_ONLY_FEATURE =  process.env.NODE_ENV === 'development';

