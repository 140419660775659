/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useState} from 'react';
import GenericModal from '../modal/GenericModal';
import {AutocompleteOptionType, DEFAULT_ADD_OPTION} from '../Utils/Autocomplete';
import {TagType} from '../Utils/types';
import {useGetTags} from '../Tags/Actions/getTagsAction';
import {useLoading} from '../store/hooks';
import {ActionType} from '../store/actionType';
import Loader from '../Utils/Loader';
import {
  Dominance,
  DraftSparActions,
  DraftSparState,
  renderSetPosition,
  rollTypeAsEnumArray,
  RollTypeDisplayValue,
  startingOptionsArray,
  StartingOptionsEnum,
} from './module';
import {modalStyles} from './VideoModalStyles';


interface Props {
  handleSetPositionModalClose: () => void,
  handlePositionChange: () => void,
  dispatch: React.Dispatch<DraftSparActions>,
  newTagValue?: string,
  state: DraftSparState,
  isStartOfRound?: boolean,
}

const SetPositionModal: React.FC<Props> = (
  {
    handleSetPositionModalClose,
    handlePositionChange,
    newTagValue,
    dispatch,
    state,
    isStartOfRound = false,
  }) => {
  
  const [autofillValue, setAutofillValue] = useState<string | undefined>('');
  const postTagIsLoading = useLoading(ActionType.PostUserTagRequest);
  
  const {value: tags} = useGetTags();
  
  const handleAutofillChange = useCallback((position: string | undefined) => {
    setAutofillValue(position);
    dispatch({type: 'setState', data: {selectedPositionalTagValue: position, selectedPositionalTagId: undefined}});
  }, [dispatch]);
  
  const handleClick = useCallback((event: any) => {
    const id = event.target.id;
    if (id && id === DEFAULT_ADD_OPTION) {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
    } else {
      const tagName = tags?.find(tag => tag.id === id)?.name;
      dispatch({type: 'setState', data: {selectedPositionalTagId: id, selectedPositionalTagValue: tagName}});
      setAutofillValue(tagName);
    }
  }, [tags, dispatch]);
  
  const handleEnterPress = useCallback((option: AutocompleteOptionType) => {
    const tag = option?.option as TagType;
    if (!option.userInput) {
      dispatch({type: 'setState', data: {selectedPositionalTagId: tag.id, selectedPositionalTagValue: tag.name}});
      setAutofillValue(tag.name);
    } else {
      dispatch({type: 'setState', data: {showPositionalAddTagModal: true}});
      
    }
  }, [dispatch]);
  
  const handleSelectStartingOption = useCallback((option: { text: StartingOptionsEnum }) => {
    dispatch({
      type: 'setState',
      data: {
        selectedStartingOption: option.text,
        selectedPositionalTagValue: '',
        selectedPositionalDominance: Dominance.neutral,
      },
    });
    setAutofillValue('');
  }, [dispatch]);
  
  const handleSelectDominance = useCallback((option: Dominance) => {
    dispatch({type: 'setState', data: {selectedPositionalDominance: option}});
  }, [dispatch]);
  
  const handleRollTypeChange = useCallback((event: any) => (
    dispatch({
      type: 'setState',
      data: {selectedRollType: Number(event.target.value)},
    })
  ), [dispatch]);
  
  const getIsDisabled = useCallback(() => {
    let isDisabled = false;
    if (state.selectedStartingOption === StartingOptionsEnum.specific_position) {
      if (!state.selectedPositionalTagId)
        isDisabled = true;
    }
    return isDisabled;
  }, [state.selectedStartingOption, state.selectedPositionalTagId]);
  
  
  const mapMenuItems = () => (
    rollTypeAsEnumArray.map((option) => (
      // @ts-ignore TODO figure this typing out
      <option key={option} value={option}>{RollTypeDisplayValue[option]}</option>
    ))
  );
  
  useEffect(() => {
    const currentTag = tags?.find(tag => tag.name.toLocaleLowerCase() === autofillValue?.toLocaleLowerCase());
    if (currentTag) {
      return dispatch({type: 'setState', data: {selectedPositionalTagId: currentTag.id}});
    }
    return dispatch({type: 'setState', data: {selectedPositionalTagId: ''}});
  }, [tags, autofillValue, dispatch]);
  
  useEffect(() => {
    setAutofillValue(newTagValue);
  }, [newTagValue]);
  
  return (
    <GenericModal
      onClose={handleSetPositionModalClose}
      title={isStartOfRound ? 'How did the round start?' : 'Log a new position'}
      confirmText={isStartOfRound ? 'Let\'s Go!' : 'Log it!'}
      onConfirm={handlePositionChange}
      isDisabled={getIsDisabled()}
    >
      {postTagIsLoading && <Loader message={'Adding Tag...'}/>}
      <div css={modalStyles.modal}>
        {isStartOfRound && (
          <div css={modalStyles.inputField}>
            <label htmlFor={'round-type'} css={modalStyles.inputLabel}>'Select a round type...'</label>
            <select
              css={modalStyles.inputField}
              required
              // placeholder={placeholder}
              id={'roll-type'}
              name={'Roll Type'}
              value={state.selectedRollType}
              onChange={handleRollTypeChange}
            >
              {mapMenuItems()}
            </select>
          </div>
        )}
        <span css={modalStyles.label}>Position Type</span>
        <div css={modalStyles.radioButtonContainer}>
          {startingOptionsArray.map(option => (
            <div
              key={option.text}
              css={modalStyles.radioButton(state.selectedStartingOption === option.text)}
              onClick={() => handleSelectStartingOption(option)}
            >
              <span>{option.text}</span>
            </div>
          ))}
        </div>
        {state.selectedStartingOption === StartingOptionsEnum.specific_position
          && renderSetPosition({
            autofillValue, handleAutofillChange, handleClick,
            handleEnterPress,
            handleSelectDominance,
            state,
          })}
      </div>
    </GenericModal>
  );
};
export default SetPositionModal;
