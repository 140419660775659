import { Reducer } from "@reduxjs/toolkit";
import { loginReducer } from "../login/Actions/loginAction";
import { logoutReducer } from "../login/Actions/logoutAction";
import { setAuthReducer, setTokenReducer } from "../login/Actions/setAuthAction";
import { getUserReducer } from "../login/Actions/getUserAction";
import { getJournalsReducer } from "../Journal/Actions/getJournalsAction";
import { postEntryReducer } from "../Journal/Actions/postEntryAction";
import { deleteJournalReducer } from "../Journal/Actions/deleteJournalAction";
import { postJournalReducer } from "../Journal/Actions/postJournalAction";
import { putJournalReducer } from "../Journal/Actions/putJournalAction";
import { putEntryReducer } from "../Journal/Actions/putEntryAction";
import { deleteEntryReducer } from "../Journal/Actions/deleteEntryAction";
import { getTagsReducer } from "../Tags/Actions/getTagsAction";
import { postUserTagReducer } from "../Tags/Actions/postUserTagAction";
import { putUserTagReducer } from "../Tags/Actions/putUserTagAction";
import { deleteUserTagReducer } from "../Tags/Actions/deleteUserTagAction";
import { getUserProfileReducer } from "../Profile/Actions/getUserProfileAction";
import { postPromotionReducer } from "../Profile/Actions/postPromotionAction";
import { deletePromotionReducer } from "../Profile/Actions/deletePromotionAction";
import { Google_oAuthReducer } from "../google/Actions/oAuthAction";
import { Google_refreshAccessTokenReducer } from "../google/Actions/refreshGoogleAccessTokenAction";
import { Google_getYoutubeChannelsReducer } from "../google/Actions/getYouTubeChannelsAction";
import { Google_updateOauthReducer } from "../google/Actions/googleNeedOauthAction";
import { Google_getYoutubeVidoesFromChannelReducer } from "../google/Actions/getYouTubeVideosFromChannelAction";
import { refreshAccessTokenReducer } from "../login/Actions/refreshAccessTokenAction";
import { updatePasswordReducer } from "../login/Actions/updatePasswordAction";
import { Google_revokeAccessTokenReducer } from "../google/Actions/revokeGoogleAccessTokenAction";
import { moveEntryReducer } from "../Journal/Actions/moveEntryAction";
import { setLoadingReducer, unsetLoadingReducer } from "./Actions/setLoadingAction";
import { getRoundsReducer } from "../Journal/Actions/getRoundsAction";
import { deleteRoundReducer } from "../Journal/Actions/deleteRoundAction";
import { putRoundReducer } from "../Journal/Actions/putRoundAction";
import { getRoundAnalysisReducer } from "../Analyze/Actions/getRoundsAnalysisAction";
import { getUserAnalysisReducer } from "../Journal/Actions/getUserAnalysis";

const reducers = [
  loginReducer,
  refreshAccessTokenReducer,
  Google_oAuthReducer,
  Google_refreshAccessTokenReducer,
  Google_getYoutubeChannelsReducer,
  Google_updateOauthReducer,
  Google_getYoutubeVidoesFromChannelReducer,
  Google_revokeAccessTokenReducer,
  logoutReducer,
  updatePasswordReducer,
  setAuthReducer,
  getUserReducer,
  getUserProfileReducer,
  postPromotionReducer,
  deletePromotionReducer,
  getJournalsReducer,
  postEntryReducer,
  deleteJournalReducer,
  postJournalReducer,
  putJournalReducer,
  putEntryReducer,
  moveEntryReducer,
  deleteEntryReducer,
  getTagsReducer,
  postUserTagReducer,
  putUserTagReducer,
  deleteUserTagReducer,
  getRoundsReducer,
  deleteRoundReducer,
  putRoundReducer,
  setTokenReducer,
  setLoadingReducer,
  unsetLoadingReducer,
  getRoundAnalysisReducer,
  getUserAnalysisReducer,
];

export const rootReducer: Reducer = (state, action) => reducers.reduce((running, next) => next(running, action), state!);
