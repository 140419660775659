/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { css, useTheme } from "@emotion/react";
import LibraryJournalEditor from "./LibraryJournalEditor";
import LibraryJournalSelector from "./LibraryJournalSelector";
import { useJournals } from "./Actions/getJournalsAction";
import { getEntriesForAllJournals, getEntriesFromSearchResults, searchFilteredEntries } from "./module";
import { ENTRY_TYPE, EntryWithJournalId, JournalObject } from "../Utils/types";
import EntrySummary from "./EntrySummary";
import JournalCreatorEditor from "./JournalCreatorEditor";
import GenericModal from "../modal/GenericModal";
import Loader from "../Utils/Loader";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import EntryFilter from "./EntryFilter";
import Searcher from "../Utils/Searcher";

import FilterChip from "../Utils/FilterChip";
import { useLogout } from "../login/Actions/logoutAction";
import { useLoading } from "../store/hooks";
import { ActionType } from "../store/actionType";
import FloatingActionButton from "../Utils/FloatingActionButton";
import { hexToRGBA } from "../Utils/module";
import EntryCreator from "./EntryCreator";
import AddNewEntryModal from "./AddNewEntryModal";
const themeStyles = (theme: any) => ({
  root: css`
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${theme.toolbarHeight.heightMedium});
    background-color: ${theme.colors.background};
    width: 100%;
    padding: 40px 40px 0 0;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
      padding: 10px;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      height: 100%;
    }
  `,
  column: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    padding-bottom: 20px;
  `,
  filterSection: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: ${theme.breakpoints.md}px) {
      height: 134px;
    }
  `,
  filterRow: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px;
    margin-top: 20px;
    gap: 8px;
    background-color: ${theme.colors.contentBackground};
    border-radius: 12px;
    flex-wrap: wrap;
    @media (min-width: ${theme.breakpoints.sm}px) {
      padding-left: 10px;
    }
  `,
  entryPane: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  chipRowDesktop: css`
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.contentBackground};
    width: fit-content;
    border-radius: 500px;
    padding: 6px 14px;
    gap: 6px;
    height: 44px;
    align-items: center;
    @media (max-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  chipRowSmallScreen: css`
    display: none;
    flex-direction: row;
    background-color: ${theme.colors.contentBackground};
    width: fit-content;
    border-radius: 500px;
    padding: 6px 14px;
    gap: 6px;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,

  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @media (max-width: ${theme.breakpoints.md}px) {
      justify-content: flex-end;
    }
  `,
  FAB: css`
    background-image: ${theme.colors.gradient};
  `,
  link: css`
    text-decoration: none;
    cursor: pointer;
  `,
  navJournalItem: (journalColor?: string) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    cursor: pointer;
    color: ${journalColor ? journalColor : theme.fontColor.text};

    &:hover {
      background-color: ${hexToRGBA(journalColor ? journalColor : theme.palette.primary.light, 0.2)};
    }
  `,
});

const LibraryRoot: React.FC = () => {
  const journals = useJournals();
  const putJournalLoading = useLoading(ActionType.PutJournalRequest);
  const postJournalLoading = useLoading(ActionType.PostJournalRequest);
  const globalTagList = useGetTags().value;
  const logout = useLogout();
  const allEntries = useMemo(() => getEntriesForAllJournals(journals.value), [journals.value]);

  const theme = useTheme();
  const styles = themeStyles(theme);

  const [createJournalSelected, setCreateJournalSelected] = useState<boolean>(false);
  const [editJournalSelected, setEditJournalSelected] = useState<boolean>(false);
  const [hasJournal, setHasJournal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [videoFilterChecked, setVideoFilterChecked] = useState(true);
  const [textFilterChecked, setTextFilterChecked] = useState(true);
  const [analysisFilterChecked, setAnalysisFilterChecked] = useState(true);
  const [searchedEntries, setSearchedEntries] = useState<EntryWithJournalId[]>(allEntries);
  const [searched, setSearched] = useState(false);
  const [firstFilteredEntries, setFirstFilteredEntries] = useState<EntryWithJournalId[]>(allEntries);
  const [secondFilteredEntries, setSecondFilteredEntries] = useState<EntryWithJournalId[]>(allEntries);
  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
  const [selectedJournal, setSelectedJournal] = useState<JournalObject | undefined>(undefined);

  const [draftEntryType, setDraftEntryType] = useState<ENTRY_TYPE | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState("");
  const [excludeFromCksInstructionals, setExcludeFromCksInstructionals] = useState(false);

  const handleAddEntry = useCallback(() => {
    setShowAddEntryModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowAddEntryModal(false);
    setSelectedJournal(undefined);
  }, []);

  const handleCreatorCancel = useCallback(() => {
    setCreateJournalSelected(false);
    setSelectedJournal(undefined);
    setDraftEntryType(undefined);
    setShowAddEntryModal(false);
  }, []);
  const handleEditorCancel = useCallback(() => setEditJournalSelected(false), []);

  const handleSearch = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  const handleVideoFilterChecked = useCallback(() => {
    setVideoFilterChecked((prev) => !prev);
  }, []);

  const handleTextFilterChecked = useCallback(() => {
    setTextFilterChecked((prev) => !prev);
  }, []);

  const handleAnalysisFilterChecked = useCallback(() => {
    setAnalysisFilterChecked((prev) => !prev);
  }, []);

  const handleJournalSelect = useCallback((journal: JournalObject) => {
    setSelectedJournal(journal);
  }, []);

  const getFilteredEntries = useCallback(() => {
    let filteredEntries = [...allEntries];
    if (!videoFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Video);
    }
    if (!textFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Text);
    }
    if (!analysisFilterChecked) {
      filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.SparAnalysis);
    }
    return filteredEntries;
  }, [allEntries, videoFilterChecked, textFilterChecked, analysisFilterChecked]);

  const renderJournalList = useCallback(
    () =>
      journals?.value?.map((journal) => (
        <div
          onClick={() => handleJournalSelect(journal)}
          css={styles.link}
          key={journal.id + journal.entries.length}
        >
          <div css={styles.navJournalItem(journal.color)}>
            <span>{journal.name || "Nameless Journal"}</span>
          </div>
        </div>
      )),
    [journals?.value, styles, handleJournalSelect]
  );

  useEffect(() => {
    //once journals load, sets it to state one time
    if (!hasJournal && journals.value) {
      setFirstFilteredEntries(allEntries);
      setSearchedEntries(allEntries);
      setHasJournal(true);
    }
  }, [hasJournal, journals.value, allEntries]);

  useEffect(() => {
    searchTerm ? setSearched(true) : setSearched(false);
    setSearchedEntries(
      getEntriesFromSearchResults(searchFilteredEntries(searchTerm, secondFilteredEntries, globalTagList || []))
    );
  }, [searchTerm, secondFilteredEntries, globalTagList]);

  useEffect(() => {
    allEntries && setFirstFilteredEntries(getFilteredEntries());
  }, [getFilteredEntries, allEntries]);

  if (logout.loading) {
    return <Loader message={"Logging Out..."} />;
  }

  return (
    <div css={styles.root}>
      {draftEntryType !== undefined && selectedJournal ? (
        <EntryCreator
          entryType={draftEntryType}
          journal={selectedJournal}
          handleCreateCancel={handleCreatorCancel}
          videoUrl={videoUrl}
          excludeFromCksInstructionals={excludeFromCksInstructionals}
        />
      ) : (
        <>
          {putJournalLoading && <Loader message={"Saving..."} />}
          {journals.loading && !journals.errors?.length && <Loader message={"Fetching Journals"} />}
          {postJournalLoading && <Loader message={"Creating Journal..."} />}
          <FloatingActionButton
            onClick={handleAddEntry}
            css={styles.FAB}
          />
          <div css={styles.content}>
            <div css={styles.entryPane}>
              <div css={styles.column}>
                <div css={styles.filterSection}>
                  <div css={styles.row}>
                    <div css={styles.chipRowDesktop}>
                      <FilterChip
                        name={"Video Entries"}
                        checked={videoFilterChecked}
                        handleChecked={handleVideoFilterChecked}
                      />
                      <FilterChip
                        name={"Text Entries"}
                        checked={textFilterChecked}
                        handleChecked={handleTextFilterChecked}
                      />
                      <FilterChip
                        name={"Spar Analysis"}
                        checked={analysisFilterChecked}
                        handleChecked={handleAnalysisFilterChecked}
                      />
                    </div>
                    <LibraryJournalSelector
                      createJournalSelected={createJournalSelected}
                      setCreateJournalSelected={setCreateJournalSelected}
                      editJournalSelected={editJournalSelected}
                      setEditJournalSelected={setEditJournalSelected}
                    />
                  </div>
                  <div css={styles.filterRow}>
                    <EntryFilter
                      setFilteredEntries={setSecondFilteredEntries}
                      filteredEntries={secondFilteredEntries}
                      tagList={globalTagList || []}
                      entriesToFilter={firstFilteredEntries}
                      // css={styles.desktopOnly}
                    />
                    <Searcher
                      placeholder={"Search Entries..."}
                      onClick={handleSearch}
                    />
                    <div css={styles.chipRowSmallScreen}>
                      <FilterChip
                        name={"Video Entries"}
                        checked={videoFilterChecked}
                        handleChecked={handleVideoFilterChecked}
                      />
                      <FilterChip
                        name={"Text Entries"}
                        checked={textFilterChecked}
                        handleChecked={handleTextFilterChecked}
                      />
                      <FilterChip
                        name={"Spar Analysis"}
                        checked={analysisFilterChecked}
                        handleChecked={handleAnalysisFilterChecked}
                      />
                    </div>
                  </div>
                </div>
                {journals.errors && journals.errors.map((error) => <span key={error}>Error: {error}</span>)}
                <EntrySummary entries={searched ? searchedEntries : secondFilteredEntries} />
              </div>
            </div>
          </div>
          {editJournalSelected && <LibraryJournalEditor handleCancel={handleEditorCancel} />}
          {createJournalSelected && (
            <GenericModal
              noButtons={true}
              onClose={handleCreatorCancel}
              title={"Create a New Journal"}
            >
              <JournalCreatorEditor
                createNewJournal={true}
                closeModal={handleCreatorCancel}
              />
            </GenericModal>
          )}
          {showAddEntryModal && (
            <GenericModal
              noButtons={true}
              onClose={handleModalClose}
              title="Please select a journal"
            >
              {!selectedJournal ? (
                renderJournalList()
              ) : (
                <AddNewEntryModal
                  setEntryType={setDraftEntryType}
                  closeModal={() => setShowAddEntryModal(false)}
                  createEntry={() => setShowAddEntryModal(false)}
                  color={selectedJournal.color}
                  setVideoUrl={setVideoUrl}
                  setExcludeFromCksInstructionals={setExcludeFromCksInstructionals}
                  journal={selectedJournal}
                />
              )}
              {/* {rollAnalysisState.editedJournalId && (
            <YouTubeVideoSelector
              color={selectedJournalColor}
              handleVideoClick={() => setShowAddEntryModal(false)}
              setVideoUrl={setVideoUrl}
            />
          )} */}
            </GenericModal>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(LibraryRoot);
