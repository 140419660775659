/** @jsxImportSource @emotion/react */ //include this in all jsx files

import theme from "../Theme";
import React from "react";
import { DateTime, Interval } from "luxon";
import NoteIcon from "../icons/NoteIcon";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import VideoCamIcon from "../icons/VideoCamIcon";
import PhotoCameraIcon from "../icons/PhotoCameraIcon";
import TrophyIcon from "../icons/TrophyIcon";
import {
  EntryFormState,
  EntryWithJournalId,
  JournalEntryObject,
  JournalFormState,
  JournalObject,
  SearchEntries,
  TagSummary,
  TagType,
} from "../Utils/types";
import { DEFAULT_JOURNAL_INPUT, journalColorChoices } from "./Actions/journalDefaultsModule";
import { Node } from "slate";
import { isValidJSONTest } from "../Utils/RichText/richTextModule";
import { css } from "@emotion/react";
import { matchSorter } from "match-sorter";
import { History } from "history";
import { BASE_ROUTES } from "../Navigation/routesModule";

export const JOURNAL_ENTRY_TYPES = {
  note: "note",
  achievement: "achievement",
  audio: "audio",
  video: "video",
  photo: "photo",
};

const journalEntryTypes = [
  //todo update color theme names to match icon name
  {
    icon: <NoteIcon />,
    name: "Note",
    color: theme.colors.note,
    type: JOURNAL_ENTRY_TYPES.note,
  },
  {
    icon: <MicrophoneIcon />,
    name: "Audio Entry",
    color: theme.colors.cardio,
    type: JOURNAL_ENTRY_TYPES.audio,
  },
  {
    icon: <VideoCamIcon />,
    name: "Video Entry",
    color: theme.colors.grapple,
    type: JOURNAL_ENTRY_TYPES.video,
  },
  {
    icon: <PhotoCameraIcon />,
    name: "Photo Entry",
    color: theme.colors.youTube,
    type: JOURNAL_ENTRY_TYPES.photo,
  },

  {
    icon: <TrophyIcon />,
    name: "Accomplishments",
    color: theme.colors.achievement,
    type: JOURNAL_ENTRY_TYPES.achievement,
  },
];

export const journalEntryOptionsList = {
  journal: journalEntryTypes,
};

export const getDateFromID = (ID: string) => {
  const dateID = ID.substring(1);
  const day = Number(dateID.substring(6, 8));
  const month = Number(dateID.substring(4, 6));
  const year = Number(dateID.substring(0, 4));
  const entDate = DateTime.local(year, month, day);
  return entDate;
};

export const daysOfWeek = () => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const DATE_FORMAT = {
  yearMonthDay: "yyyyMMdd", //20190423
  longDateNoYear: "cccc, MMMM d", //Wednesday, May 2
  longDate: "MMMM dd, yyyy", //October 23, 1985
  longDateTime: "MMMM dd, yyyy | t", //October 23, 1985 | 12:00
  hugeDate: "cccc, MMMM d, yyyy", //Friday, October 4, 1983
  dayParam: "'d'yyyyMMdd", //d20220729
  monthYear: "MMMM, yyyy", //October, 2034
  inputDateTimeLocal: "yyyy-LL-dd'T'T", //2023-04-28T13:59
  dateInput: "yyyy-MM-dd",
  dateChip: "d MMM yyyy",
};

export const SET_INDEX = {
  initial: journalEntryTypes[0].name,
};

export const weekToRender = (week: number, month: DateTime[]) => {
  const startIndex = (week - 1) * 7;
  const endIndex = startIndex + 7;
  return month.slice(startIndex, endIndex);
};

export const firstDay = (date: DateTime) => DateTime.local(date.year, date.month, 1).startOf("day");

export const lastDay = (date: DateTime) => DateTime.local(date.year, date.month, date.daysInMonth).endOf("day");

export const calculateEndDays = (pickDate: DateTime) => {
  const weekdayNumber = lastDay(pickDate).weekday; // the .weekday method returns 1 for Monday and 7 for Sunday
  const blankDays = 6 - weekdayNumber; //our calendar starts on Sunday so there is a 1 day offset (7-1 = 6)
  return blankDays === -1 ? 6 : blankDays; //if the end of month falls on a sunday, blankDays will return -1
};

export const getMonthArray = (pickDate: DateTime) => {
  if (pickDate) {
    const month: (DateTime | null)[] =
      firstDay(pickDate).weekday !== 7 ? Array.from({ length: firstDay(pickDate).weekday }, () => null) : [];
    month.push(
      ...Interval.fromDateTimes(firstDay(pickDate), lastDay(pickDate))
        .splitBy({ days: 1 })
        .map((d) => d.start)
    );
    month.push(...Array.from({ length: calculateEndDays(pickDate) }, () => null));
    return month;
  }
};

export const dayHasEntry = (day: DateTime, entryDates: Date[]) =>
  day && !!entryDates.find((date) => date.getTime() === day.startOf("day").toJSDate().getTime());

export const getJournalById = (journalId: string, journals?: JournalObject[]) =>
  journals?.find((journal) => journal.id === journalId);

export const getEntriesForJournal = (journalId: string, journals?: JournalObject[]) => {
  const entries = getJournalById(journalId, journals)?.entries || [];
  const entriesWithJournalId: EntryWithJournalId[] = [];
  entries?.map((entry) => entriesWithJournalId.push({ journalId: journalId, journalEntry: entry }));
  return entriesWithJournalId.sort(
    (a, b) =>
      DateTime.fromISO(b.journalEntry.entryDateTimeUtc).toMillis() - DateTime.fromISO(a.journalEntry.entryDateTimeUtc).toMillis()
  );
};

export const serialize = (nodes: string) => {
  const test = isValidJSONTest(nodes);
  return (
    test &&
    JSON.parse(nodes)
      .map((n: Node) => Node.string(n))
      .join("\n")
  );
};

export const getEntriesForAllJournals = (journals: JournalObject[] = []) => {
  const allEntries: EntryWithJournalId[] = [];
  journals.map((journal) =>
    journal.entries.map((entry) =>
      allEntries.push({
        journalId: journal.id,
        journalEntry: entry,
      })
    )
  );
  return allEntries.sort(
    (a, b) =>
      DateTime.fromISO(b.journalEntry.entryDateTimeUtc).toMillis() - DateTime.fromISO(a.journalEntry.entryDateTimeUtc).toMillis()
  );
};

export const getTagNameFromId = (tagId: string, tagList: TagType[]) => {
  if (tagList.length > 1) {
    const tag = tagList.filter((tag) => tag.id === tagId)[0];
    return (tag && tag.name) || "Error loading tag info";
  }
};

export const getJournalEntryDates = (activeEntries: EntryWithJournalId[]) => {
  const entryDates: DateTime[] = [];
  activeEntries?.forEach((entry) => {
    const date = DateTime.fromISO(entry?.journalEntry?.entryDateTimeUtc).startOf("day");
    !entryDates?.find((entryDate) => date.hasSame(entryDate, "day")) && entryDates.push(date);
  });
  return entryDates;
};

export const getEntryText = (entry: EntryWithJournalId) => serialize(entry.journalEntry.text) || entry.journalEntry.text;

export const getSearchTagString = (entry: SearchEntries, tagList: TagType[]) => {
  const tagNameArray = entry.entry?.journalEntry?.tags?.map((tag) => getTagNameFromId(tag, tagList)) || [];
  return tagNameArray.join(" ");
};

export const getSearchNotesString = (entry: EntryWithJournalId) => {
  const tagNameArray = entry?.journalEntry?.notes?.map((note) => note.text || "");
  return tagNameArray?.join(" ") || "";
};

const makeSearchString = (entry: EntryWithJournalId, tagList: TagType[]) => ({
  entryText:
    getEntryText(entry) +
    " " +
    entry.journalEntry.title +
    " " +
    getSearchTagString({ entry: entry, entryText: getEntryText(entry) }, tagList) +
    " " +
    getSearchNotesString(entry),
  entry: entry,
});

export const getSearchEntries = (allEntries: EntryWithJournalId[], tagList: TagType[]) => {
  const searchEntryArray: SearchEntries[] = [];
  allEntries?.map((entry) => searchEntryArray.push(makeSearchString(entry, tagList)));
  return searchEntryArray;
};

export const getEntriesFromSearchResults = (searchEntries: SearchEntries[]) => {
  const entryArray: EntryWithJournalId[] = [];
  searchEntries.map((entry) => entryArray.push(entry.entry));
  return entryArray;
};

export const getJournalColorInfo = (testColor: string) =>
  journalColorChoices.find((colorChoice) => colorChoice.hexCode === testColor) || DEFAULT_JOURNAL_INPUT.color;

export const toEditedEntryObject = (formState: EntryFormState): JournalEntryObject => ({
  journalEntryId: formState.entry.journalEntryId,
  title: formState.editedTitle,
  text: formState.editedText,
  createdDateTimeUtc: formState.entry.createdDateTimeUtc || "",
  entryDateTimeUtc: formState.editedEntryDate.toUTC().toString(),
  tags: formState.editedTagIds,
  type: formState.type,
  videoUrl: formState.editedVideoUrl,
  notes: formState.editedNotes,
  excludeFromCksInstructionals: formState.excludeFromCksInstructionals,
  rollAnalysis: formState.entry.rollAnalysis,
});

export const toEditedJournalObject = (formState: JournalFormState): JournalObject => ({
  name: formState.editedName,
  id: formState.journal.id,
  userId: formState.journal.userId,
  color: formState.editedColor.hexCode,
  description: formState.editedDescription,
  createdDateTimeUtc: formState.journal.createdDateTimeUtc,
  entries: formState.journal.entries,
});

export const isUrl = (url?: string) => {
  if (!url) return false;
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }
  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }
  return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol);
};
export const isShortUrl = (url: string) => url.startsWith("www.");
export const isImageUrl = (url: string) => {
  const IMAGE_FILE_EXTENSIONS = [".bmp", ".svg", ".jpg", ".png", ".gif"];
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }
  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  const urlIsImage = IMAGE_FILE_EXTENSIONS.some((extension) => everythingAfterProtocol.includes(extension));

  if (!urlIsImage) {
    return false;
  }

  return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol);
};

export const isInvalidTitle = (currentName: string, newName: string, journals?: JournalObject[]) => {
  const nameIsEdited = newName !== currentName;
  const nameIsDuplicate = journals?.find((journal) => journal.name?.toUpperCase() === newName.toUpperCase());
  return nameIsEdited && nameIsDuplicate;
};

export const BLANK_JOURNAL: JournalObject = {
  name: "",
  userId: "",
  id: "",
  color: "",
  createdDateTimeUtc: "",
  description: "",
  entries: [],
};

export const renderTags = (
  entry: JournalEntryObject,
  globalTags: TagType[],
  journalColor: string,
  link = false,
  history: History | undefined
) => {
  if (entry.tags) {
    const styles = {
      chipColor: (chipColor: string) => css`
        & span {
          color: ${chipColor};
          font-size: 11px;
          font-weight: bold;
          cursor: pointer;
        }
      `,
    };
    const tagList = entry.tags;
    return tagList?.map((tag) => (
      <div
        key={tag}
        onClick={() => link && history && history.push(BASE_ROUTES.tagLibrary + "/" + tag)}
        css={styles.chipColor(journalColor)}
      >
        <span>#{getTagNameFromId(tag, globalTags)} </span>
      </div>
    ));
  }
};

export const getTagList = (allEntries: EntryWithJournalId[], globalTagList: TagType[]) => {
  const allTags: string[] = [];
  const tagSummaryWithName: TagSummary[] = [];
  allEntries.map((entry) => entry?.journalEntry?.tags?.map((tag) => allTags.push(tag)));
  const tagMap = allTags.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
  Array.from(tagMap).map(([tagId, count]) => {
    const name = (globalTagList && getTagNameFromId(tagId, globalTagList)) || "error finding tag name";
    return tagSummaryWithName.push({ id: tagId, name: name, count: count });
  });
  return tagSummaryWithName.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const searchFilteredEntries = (searchText: string, entries: EntryWithJournalId[], tagList: TagType[]) => {
  const newSearchedEntries: SearchEntries[] = matchSorter(getSearchEntries(entries, tagList), searchText, {
    keys: [(entry) => entry.entryText],
    threshold: matchSorter.rankings.CONTAINS,
  });
  return newSearchedEntries;
};

export const searchFilteredTags = (searchText: string, tagList: TagType[]) => {
  const newSearchedTags: TagType[] = matchSorter(tagList, searchText, {
    keys: [(tag) => tag.name, (tag) => tag.bodyPart || ""],
  });
  return newSearchedTags;
};

export const getTagCount = (tagSummary: TagSummary[]) => {
  let tagCount = 0;
  tagSummary.map((tag) => (tagCount = tagCount + tag.count));
  return tagCount;
};

export const getYoutubeId = (url: string) => {
  const regExp = /(?<=v=|v\/|vi\/|shorts\/|embed\/|youtu.be\/|\/videos\/|\/embed\/|\/v\/|\/e\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  if (match) {
    return match[1];
  } else {
    return false;
  }
};
