/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import theme from "../Theme";
import { playlistResource, YouTubeChannelResource } from "../google/module";
import { useYouTubeChannels } from "../google/Actions/getYouTubeChannelsAction";
import { useYouTubeVideosFromChannel } from "../google/Actions/getYouTubeVideosFromChannelAction";
import { hexToRGBA } from "../Utils/module";
import Loader from "../Utils/Loader";
import { useAppState } from "../store/hooks";

const styles = {
  root: css`
    height: 100%;
    max-height: 450px;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
    gap: 10px;
    background-color: ${theme.colors.contentBackground};
  `,
  videoRow: (color: string) => css`
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 90px;
    text-overflow: ellipsis;

    :hover {
      background-color: ${hexToRGBA(color ? color : theme.palette.primary.light, 0.2)};
    }
  `,
  title: (color = "#000000") => css`
    font-weight: 700;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    color: ${color};
  `,
  titleAndDescriptionWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 3px;
  `,
  description: css`
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  `,
};

interface Props {
  setVideoUrl: (url: string) => void;
  handleVideoClick: () => void;
  color: string;
}

const YouTubeVideoSelector: React.FC<Props> = ({ setVideoUrl, handleVideoClick, color }) => {
  const [videos, setVideos] = useState<playlistResource[]>([]);
  const [channels, setChannels] = useState<YouTubeChannelResource[]>([]);

  const youTubeChannels = useYouTubeChannels();
  const userVideos = useYouTubeVideosFromChannel();
  const googleState = useAppState((state) => state.google);

  const handleRefreshChannel = useCallback(() => {
    youTubeChannels.reload && youTubeChannels.reload();
  }, [youTubeChannels]);

  const handleRefreshVideos = useCallback(() => {
    userVideos.reload && userVideos.reload();
  }, [userVideos]);

  useEffect(() => {
    setChannels(youTubeChannels.value?.items || []);
  }, [youTubeChannels.value]);

  useEffect(() => {
    if (channels) {
      if (!userVideos.value || userVideos.loading) {
        return;
      } else {
        setVideos(userVideos.value || []);
      }
    }
  }, [channels, userVideos]);

  const handleVideoSelect = useCallback(
    (video: playlistResource) => {
      setVideoUrl("https://www.youtube.com/watch?v=" + video.snippet.resourceId.videoId);
      handleVideoClick();
    },
    [setVideoUrl, handleVideoClick]
  );

  if (userVideos.loading || youTubeChannels.loading) {
    return <Loader localLoader={true} />;
  }

  if (!googleState) {
    return (
      <div css={styles.root}>
        <span>You must link a google account to access this feature.</span>
        <span>If a popup didn't appear, please go to your profile and link an account.</span>
        <span>If you recently linked your account, you might need to refresh the page.</span>
      </div>
    );
  }

  if (channels.length === 0) {
    return (
      <div css={styles.root}>
        <span css={styles.description}>Could not find any YouTube channels linked to your Google account.</span>
        <span css={styles.description}>
          Please go to{" "}
          <a
            href={"https://youtube.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
          , create a channel, and upload at least one video.
        </span>
        <span>
          When you're done, refresh <strong onClick={handleRefreshChannel}>here</strong>.
        </span>
      </div>
    );
  }

  if (videos.length === 0) {
    return (
      <div css={styles.root}>
        <span css={styles.description}>
          Could not find any videos on your YouTube channel <strong>{channels[0].snippet.title}</strong>
        </span>
        <span css={styles.description}>
          Please go to{" "}
          <a
            href={"https://youtube.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>{" "}
          and upload at least one video.
        </span>
        <span>
          When you're done, refresh <strong onClick={handleRefreshVideos}>here</strong>.
        </span>
      </div>
    );
  }

  return (
    <div css={styles.root}>
      {videos.map((video) => (
        <div
          onClick={() => handleVideoSelect(video)}
          key={video.id}
          css={styles.videoRow(color)}
        >
          <img
            alt={"video thumbnail"}
            src={video.snippet.thumbnails.default.url}
          />
          <div css={styles.titleAndDescriptionWrapper}>
            <span css={styles.title(color)}>{video.snippet.title}</span>
            <span css={styles.description}>{video.snippet.description}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default YouTubeVideoSelector;
