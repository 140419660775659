/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Login from "./Login";
import { useAppState, useLoading, useLoggedIn } from "../store/hooks";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";
import Loader from "../Utils/Loader";
import { useGetUser } from "./Actions/getUserAction";
import AppHeader from "../Navigation/AppHeader";
import { useRefreshGoogleAccessToken } from "../google/Actions/refreshGoogleAccessTokenAction";
import { useGetUserProfile } from "../Profile/Actions/getUserProfileAction";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ROUTES } from "../Navigation/routesModule";
import PrivacyNotice from "../Legal/PrivacyNotice";
import TermsOfService from "../Legal/TermsOfService";
import { css } from "@emotion/react";
import theme from "../Theme";
import { ActionType } from "../store/actionType";
import { useGetUserAnalysis } from "../Journal/Actions/getUserAnalysis";
import { useJournals } from "../Journal/Actions/getJournalsAction";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    width: 100vw;
    font-family: ${theme.typography.fontFamily};
    background-color: ${theme.colors.background};
    height: 100%;
    overflow-y: hidden;
  `,
  navigation: css`
    display: none;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
};
interface Props {
  children: ReactNode;
}
const Authenticator: React.FC<Props> = (props) => {
  const loggedIn = useLoggedIn();
  const refreshLoading = useLoading(ActionType.RefreshTokenRequest);

  const state = useAppState((state) => state);
  const [didInitialGoogleRefresh, setDidInitialGoogleRefresh] = useState(false);
  const refreshGoogleAccessToken = useRefreshGoogleAccessToken();
  const hasAuthenticatedGoogle = !!state.user?.googleApi?.accessToken;

  useGetUser();
  useGetUserProfile();
  useJournals();
  // useGetUserAnalysis({
  //   startDateTimeUtc: DateTime.now().minus({ days: 30 }).startOf("day").toUTC().toString(),
  //   endDateTimeUtc: DateTime.now().endOf("day").toUTC().toString(),
  // });

  const decodedToken = useMemo(() => state.auth?.accessToken && (jwt_decode(state.auth?.accessToken || "") as any), [state.auth]);

  const expiresIn = useMemo(
    () =>
      decodedToken?.exp
        ? DateTime.fromMillis(decodedToken?.exp * 1000)
            .diff(DateTime.now(), "milliseconds")
            .toObject().milliseconds
        : -1,
    [decodedToken]
  );

  const isExpired = (expiresIn || -1) <= 0;

  //get fresh google access token
  useEffect(() => {
    if (hasAuthenticatedGoogle && !didInitialGoogleRefresh) {
      refreshGoogleAccessToken.value && refreshGoogleAccessToken.value();
      setDidInitialGoogleRefresh(true);
    }
  }, [hasAuthenticatedGoogle, refreshGoogleAccessToken, didInitialGoogleRefresh]);

  useEffect(() => {
    //refresh token every 58 minutes
    if (hasAuthenticatedGoogle && didInitialGoogleRefresh) {
      const refreshInterval = setInterval(() => {
        refreshGoogleAccessToken.value && refreshGoogleAccessToken.value();
      }, 3480000); //3480000
      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [didInitialGoogleRefresh, hasAuthenticatedGoogle, refreshGoogleAccessToken, state.user?.googleApi?.accessToken]);

  if (!loggedIn)
    return (
      <Router>
        <Switch>
          <Route path={ROUTES.privacy}>
            <div css={styles.container}>
              <AppHeader
                renderByDefault={true}
                hideSignIn={true}
              />
              <PrivacyNotice />
            </div>
          </Route>
          <Route path={ROUTES.termsOfService}>
            <div css={styles.container}>
              <AppHeader
                renderByDefault={true}
                hideSignIn={true}
              />
              <TermsOfService />
            </div>
          </Route>
          <Route path="*">
            <Login />
          </Route>
        </Switch>
      </Router>
    );

  return (
    <>
      {isExpired && (
        <Loader
          fullScreen={true}
          message={"Authenticating..."}
        />
      )}
      {refreshLoading && <Loader message={"Loading..."} />}
      {props.children}
    </>
  );
};

export default Authenticator;
