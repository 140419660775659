/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { css } from "@emotion/react";
import ReactPlayer from "react-player/lazy";
import theme from "../Theme";
import { useContainerDimensions } from "../Utils/useContainerDimensions";
import GenericModal from "../modal/GenericModal";
import { DraftRollAnalysisType, draftSparReducer, sparInitialState } from "./module";
import SparringPlayerControls from "../Journal/VideoNoteTaker/SparringPlayerControls";
import SparNotePanel from "./SparNotePanel";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import Loader from "../Utils/Loader";
import {
  getVideoDuration,
  handleError,
  handleOnReady,
  handlePause,
  handlePlay,
  handleProgress,
  handleVideoEnd,
  playerOptions,
  seekTo,
} from "./playerModule";
import Scoreboard from "./Scoreboard";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-width: 100%;
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
    }
  `,
  noteInputWrapper: css`
    resize: none;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px;
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 400px;
    }
  `,
  iframe: css`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.lightestGray};
    position: relative;
    //.react-player {
    //  object-fit: cover;
    //}
  `,
  wrapper: css`
    position: relative;
    display: flex;
    flex: 1;
    min-height: 400px;
  `,
  playerWindow: css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
  `,
  modalBody: css`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,
  topBar: css`
    height: 70px;
    width: 100%;
    background-color: #000000;
  `,
};

interface Props {
  rollAnalysisState: DraftRollAnalysisType;
  setRollAnalysisState: React.Dispatch<React.SetStateAction<DraftRollAnalysisType>>;
  color: string;
}

const SparAnalyzer: React.FC<Props> = ({ rollAnalysisState, setRollAnalysisState, color = "#000000" }) => {
  const url = rollAnalysisState.editedVideoUrl;
  const [state, dispatch] = useReducer(draftSparReducer, sparInitialState);
  const { videoDuration, played, isPlaying, isMuted, currentRound, minimumScrollTime, maximumScrollTime, errorCode } = state;
  const componentRef = useRef(null);
  const { width: playerWidth } = useContainerDimensions(componentRef);
  const player = useRef<ReactPlayer>(null);
  const tagListHook = useGetTags();
  const tagList = tagListHook.value;

  const handleModalClose = useCallback(() => dispatch({ type: "setState", data: { errorCode: null } }), []);

  useEffect(() => {
    dispatch({ type: "setState", data: { playedPosition: played / videoDuration } });
  }, [played, videoDuration]);

  useEffect(() => {
    dispatch({ type: "setState", data: { updatedNote: undefined } });
  }, [url]);

  useEffect(() => {
    if (rollAnalysisState.clearCurrentRoundFlag) {
      dispatch({ type: "setState", data: { currentRound: undefined, previousRoundIsComplete: true } });
      setRollAnalysisState((prev) => ({ ...prev, clearCurrentRoundFlag: false }));
    }
  }, [rollAnalysisState.clearCurrentRoundFlag, setRollAnalysisState]);
  if (tagListHook.loading) return <Loader />;

  return (
    <div css={styles.root}>
      <div css={styles.wrapper}>
        <div
          ref={componentRef}
          css={styles.playerWindow}
        >
          <div css={styles.topBar} />
          <div css={styles.iframe}>
            {currentRound !== undefined && (
              <Scoreboard
                currentRound={currentRound}
                allRounds={rollAnalysisState.editedRounds || []}
                played={played}
                tagList={tagList}
                isOverlay={true}
              />
            )}
            <ReactPlayer
              ref={player}
              width={"100%"}
              height={"auto"}
              className={"react-player"}
              url={url}
              playing={isPlaying}
              onReady={() => handleOnReady(dispatch, player, played)}
              // onBuffer={handleBuffer}
              // onBufferEnd={handleBufferEnd}
              onPause={() => handlePause(dispatch)}
              onPlay={() => handlePlay(dispatch)}
              onDuration={() => getVideoDuration({ player, dispatch })}
              onEnded={() => handleVideoEnd(player)}
              onSeek={(e) => console.log("onSeek", e)}
              controls={false}
              onError={(e) => handleError(e)}
              onProgress={(event) =>
                handleProgress({ event, dispatch, maximumScrollTime, videoDuration, isPlaying, currentRound })
              }
              progressInterval={250}
              config={playerOptions}
              volume={undefined}
              muted={isMuted}
            />
          </div>
          <SparringPlayerControls
            player={player}
            playerWidth={playerWidth}
            dispatch={dispatch}
            state={state}
            seekTo={(seconds: number) =>
              seekTo({
                dispatch,
                seconds,
                currentRound,
                allRounds: rollAnalysisState.editedRounds || [],
                videoDuration,
                player,
              })
            }
            color={color}
            setRollAnalysisState={setRollAnalysisState}
            rollAnalysisState={rollAnalysisState}
            allRounds={rollAnalysisState.editedRounds}
          />
        </div>
      </div>
      <div css={styles.noteInputWrapper}>
        <SparNotePanel
          dispatch={dispatch}
          state={state}
          played={played}
          isPlaying={isPlaying}
          rollAnalysisState={rollAnalysisState}
          setRollAnalysisState={setRollAnalysisState}
          currentRound={currentRound}
          player={player}
        />
        {(errorCode === "150" || errorCode === "101") && (
          <GenericModal
            onClose={handleModalClose}
            title={" Uh oh! I can't play this video."}
            onlyConfirmButton={true}
            confirmText={"Got it"}
            onConfirm={handleModalClose}
          >
            <div css={styles.modalBody}>
              <span>If you're sure the video URL is correct, the privacy settings may be set to 'private'.</span>
              <span>
                Click{" "}
                <a
                  href={"https://support.google.com/youtube/answer/157177?hl=en&co=GENIE.Platform%3DDesktop"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{" "}
                for detailed instructions on updating privacy settings to "public" or "unlisted'.
              </span>
              <span>
                You can go directly to your YouTube account{" "}
                <a
                  href={"https://studio.youtube.com/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </span>
            </div>
          </GenericModal>
        )}
      </div>
    </div>
  );
};
export default SparAnalyzer;
