/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Dominance, VideoAnalysisNote } from "./module";
import { getTagNameFromId } from "../Journal/module";
import { formatPlayerTime } from "../Journal/VideoNoteTaker/module";
import theme from "../Theme";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import DeleteIcon from "../icons/DeleteIcon";
import { hexToRGBA } from "../Utils/module";

const cardStyles = {
  card: (canEdit: boolean, isCurrentNote = false, color = theme.colors.contentBackground) => css`
    display: grid;
    grid-template-columns: 60px 1fr ${canEdit ? "30px" : "0px"};
    width: 100%;
    align-items: flex-start;
    border-radius: 4px;
    background-color: ${isCurrentNote ? hexToRGBA(color, 0.2) : theme.colors.contentBackground};
  `,
  contentColumn: (isCurrentNote: boolean, color = theme.colors.ultraLightBlue) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    text-transform: capitalize;
    font-size: 12px;
    background-color: ${isCurrentNote ? hexToRGBA(color, 0.2) : ""};
    color: ${isCurrentNote ? color : theme.fontColor.text};
    height: 100%;
    padding: 10px;
    border-radius: 0 5px 5px 0;
  `,
  timestamp: (isCurrentNote: boolean, color: string) => css`
    user-select: none;
    font-size: 12px;
    font-weight: 600;
    color: ${color};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    height: 100%;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    border-left: ${isCurrentNote ? "solid 5px " + color : ""};
    background-color: ${isCurrentNote ? hexToRGBA(color, 0.2) : ""};
  `,
  movementText: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 600;
  `,
  buttonGroup: css`
    display: flex;
    flex-direction: row;
    gap: 5px;
  `,
};

interface Props {
  note: VideoAnalysisNote;
  handleSeek: (seconds: number | undefined) => void;
  roundStartTime?: number;
  canEdit?: boolean;
  onEditNote?: () => void;
  onDeleteNote?: () => void;
  isCurrentNote: boolean;
  color?: string;
  className?: string;
}

const AnalysisNoteCard: React.FC<Props> = ({
  note,
  handleSeek,
  roundStartTime = 0,
  canEdit = false,
  onDeleteNote,
  isCurrentNote = false,
  color = theme.fontColor.googleBlue,
  className,
}) => {
  const tags = useGetTags().value;
  const currentPosition = tags && note.positionTagId && getTagNameFromId(note.positionTagId, tags);

  const getMovementName = () => {
    if (!tags) return;
    if (note.movementDefended)
      return {
        name: getTagNameFromId(note.movementDefended.tagId, tags),
        success: note.movementDefended.wasSuccessful ? "You Failed to defend" : "You Successfully defended movement: ",
        userInitiated: false,
      };
    if (note.movementAttack)
      return {
        name: getTagNameFromId(note.movementAttack.tagId, tags),
        success: note.movementAttack.wasSuccessful ? "You Successfully moved: " : "You Failed movement: ",
        userInitiated: true,
      };
    return null;
  };
  const getSubmissionName = () => {
    if (!tags) return;
    if (note.submissionDefended)
      return {
        name: getTagNameFromId(note.submissionDefended.tagId, tags),
        success: note.submissionDefended.wasSuccessful ? "Lost by: " : "Successfully defended submission:",
        userInitiated: false,
      };
    if (note.submissionAttack)
      return {
        name: getTagNameFromId(note.submissionAttack.tagId, tags),
        success: note.submissionAttack.wasSuccessful ? "Won by: " : "You failed submission attempt: ",
        userInitiated: true,
      };
    return null;
  };

  return (
    <React.Fragment key={note.timeSeconds}>
      {note?.submissionAttack?.wasSuccessful ? (
        <div
          className={className}
          css={cardStyles.card(canEdit, isCurrentNote, color)}
        >
          <span
            css={cardStyles.timestamp(isCurrentNote, color)}
            onClick={() => handleSeek(note.timeSeconds)}
          >
            {formatPlayerTime(note.timeSeconds - roundStartTime)}
          </span>
          <div css={cardStyles.contentColumn(isCurrentNote, color)}>
            {!(note.submissionAttack?.wasSuccessful || note.submissionDefended?.wasSuccessful) && getSubmissionName() && (
              <p css={cardStyles.movementText}>{getSubmissionName()?.success + " " + getSubmissionName()?.name}</p>
            )}
            <p>{getSubmissionName()?.success + " " + getSubmissionName()?.name}</p>
          </div>
          {canEdit && (
            <div css={cardStyles.buttonGroup}>
              <DeleteIcon
                variant={"outlined"}
                onClick={onDeleteNote}
              />
            </div>
          )}
        </div>
      ) : note.submissionDefended?.wasSuccessful ? (
        <div
          className={className}
          css={cardStyles.card(canEdit)}
        >
          <span
            css={cardStyles.timestamp(isCurrentNote, color)}
            onClick={() => handleSeek(note.timeSeconds - roundStartTime)}
          >
            {formatPlayerTime(note.timeSeconds)}
          </span>
          <div css={cardStyles.contentColumn(isCurrentNote, color)}>
            {!(note.submissionAttack?.wasSuccessful || note.submissionDefended?.wasSuccessful) && getSubmissionName() && (
              <p css={cardStyles.movementText}>{getSubmissionName()?.success + " " + getSubmissionName()?.name}</p>
            )}
            <p>{getSubmissionName()?.success + " " + getSubmissionName()?.name}</p>
          </div>
          {canEdit && (
            <div css={cardStyles.buttonGroup}>
              <DeleteIcon
                variant={"outlined"}
                onClick={onDeleteNote}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className={className}
          css={cardStyles.card(canEdit)}
        >
          <span
            css={cardStyles.timestamp(isCurrentNote, color)}
            onClick={() => handleSeek(note.timeSeconds)}
          >
            {formatPlayerTime(note.timeSeconds - roundStartTime)}
          </span>
          <div css={cardStyles.contentColumn(isCurrentNote, color)}>
            {getMovementName() && (
              <em css={cardStyles.movementText}>{getMovementName()?.success + " " + getMovementName()?.name}</em>
            )}
            {!(note.submissionAttack?.wasSuccessful || note.submissionDefended?.wasSuccessful) && getSubmissionName() && (
              <em css={cardStyles.movementText}>{getSubmissionName()?.success + " " + getSubmissionName()?.name}</em>
            )}
            <p>
              <strong>Position:</strong> {Dominance[note.userDominance] + " " + currentPosition}
            </p>
          </div>
          {canEdit && (
            <div css={cardStyles.buttonGroup}>
              <DeleteIcon
                variant={"outlined"}
                onClick={onDeleteNote}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AnalysisNoteCard;
