/** @jsxImportSource @emotion/react */
import React, { RefObject, useCallback, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { formatPlayerTime } from "./module";
import ReactPlayer from "react-player";
import { VideoNote } from "../../Utils/types";
import PlayIcon from "../../icons/PlayIcon";
import PauseIcon from "../../icons/PauseIcon";
import VolumeUpIcon from "../../icons/VolumeUpIcon";
import VolumeOffIcon from "../../icons/VolumeOffIcon";
import { PLAYBACK_RATE_OPTIONS } from "./VideoNoteTaker";
import PlaybackRateIcon from "../../icons/PlaybackRateIcon";
import CheckIcon from "../../icons/CheckIcon";
import theme, { MENU_LEFT_OFFSET } from "../../Theme";
import ReplayTenIcon from "../../icons/ReplayTenIcon";
import ForwardTenIcon from "../../icons/ForwardTenIcon";
import { DraftRollAnalysisType, getNextRoundStartTime, Round, SparActions, SparState } from "../../VideoAnalysis/module";
import useWindowDimensions from "../../Utils/useWindowDimensions";

const styles = {
  makeColumn: css`
    display: flex;
    flex-direction: column;
  `,
  makeRow: css`
    display: flex;
    flex-direction: row;
  `,
  spaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  iconCluster: css`
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
  `,
  spaceEvenly: css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  `,
  controlWrapper: (isOverlay: boolean) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    user-select: none;
    height: 120px;
    padding: 5px;
    background-color: #fff;
    color: #000;
    position: relative;
    font-size: 12px;
    ${isOverlay && "position: absolute;"}
  `,
  meterFlexBox: css`
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: black;
  `,
  roundFlexBox: css`
    height: 20px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: black;
  `,
  roundWrapper: (width: number) => css`
    width: ${width}px;
    position: relative;
    align-items: center;
    display: flex;
    margin: 15px 0 5px 0;
    height: 15px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  meterWrapper: (width: number) => css`
    width: ${width}px;
    position: relative;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin: 15px 0 15px 0;
    height: 5px;
    transition: all 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

    .meterFlexBox:hover & {
      height: 6px;
    }
  `,
  tagIcon: (color = "#000000") => css`
    color: ${color};
  `,
  baseMeter: (width: number) => css`
    width: ${width}%;
    height: 100%;
    background-color: #424242;
    position: absolute;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  disabledMeterStart: css`
    width: var(--startRoundTime);
    height: 10px;
    opacity: 0.9;
    left: 0;
    position: absolute;
    background-color: #fff;
    pointer-events: none;
    transition: height 50ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 30ms linear;
  `,
  disabledMeterEnd: css`
    width: var(--endRoundWidth);
    height: 10px;
    left: var(--startRoundTime);
    position: absolute;
    background-color: black;
    opacity: 0.9;
    pointer-events: none;
    transition: height 50ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 30ms linear;
  `,

  playedMeter: css`
    width: var(--played);
    height: 100%;
    left: 0;
    position: absolute;
    background-color: red;
    pointer-events: none;
    transition: height 50ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 30ms linear;
  `,
  loadedMeter: css`
    width: var(--loaded);
    height: 100%;
    left: 0;
    position: absolute;
    background-color: #8e8e8e;
    pointer-events: none;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  roundMeter: css`
    width: var(--roundWidth);
    height: 100%;
    left: var(--startPosition);
    position: absolute;
    background-color: ${theme.fontColor.googleBlue};
    color: #fff;
    cursor: pointer;
    padding-left: 5px;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    ::before {
      height: 25px;
      width: 2px;
      background-color: ${theme.fontColor.googleBlue};
      position: absolute;
      left: 0;
      top: 5px;
      content: "";
    }

    ::after {
      height: 25px;
      width: 2px;
      background-color: ${theme.fontColor.googleBlue};
      position: absolute;
      right: 0;
      top: 5px;
      content: "";
    }
  `,
  playButton: css`
    color: ${theme.palette.primary.main};
  `,
  playerIcon: css`
    justify-content: center;
    display: flex;
    background-color: transparent;
    color: ${theme.fontColor.text};
    border: none;
    padding: 0;
    font-size: 24px;
  `,
  genericModal: css`
    > div {
      max-width: 650px;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -5px;
  `,

  modalContainer: css`
    position: relative;
  `,
  modal: css`
    position: absolute;
    width: 150px;
    bottom: 44px;
    left: 18px;
    background-color: #0f0f0fbf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    font-size: 14px;
  `,
  seekingNode: css`
    left: var(--seeking-left);
    transform: translateX(var(--seeking-transform));
    position: absolute;
    width: var(--seeking-size);
    height: var(--seeking-size);
    border-radius: 30px;
    background-color: red;
    pointer-events: none;
    transition: height 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5), width 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5), left 30ms linear;
  `,
  speedButton: css`
    background-color: transparent;
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;

    :hover {
      background-color: ${theme.colors.mediumGray};
    }
  `,
};

const snapSensitivity = 0.03; //higher percentage will create a stickier node when scrubbing tracker
const PLAYER_WINDOW_PADDING = 7;
const LARGER_SCREEN_OFFSET = 20;
const PLAYER_WINDOW_OFFSET = MENU_LEFT_OFFSET + 2 * LARGER_SCREEN_OFFSET;

export interface Props {
  dispatch: React.Dispatch<SparActions>;
  state: SparState;
  player: RefObject<ReactPlayer>;
  seekTo: Function;
  playerWidth: number;
  color: string;
  rollAnalysisState?: DraftRollAnalysisType;
  setRollAnalysisState?: (analysisState: DraftRollAnalysisType) => void;
  allRounds?: Round[];
  isEditing?: boolean;
  isOverlay?: boolean;
  className?: string;
}

const SparringPlayerControls = (props: Props) => {
  const {
    dispatch,
    seekTo,
    playerWidth,
    state,
    player,
    rollAnalysisState,
    setRollAnalysisState,
    allRounds,
    isEditing = false,
    isOverlay = false,
    className = "",
  } = props;
  const { videoDuration, played, loadedPosition, playedPosition, isPlaying, isMuted, playbackRate, seeking } = state;

  const setSeeking = useCallback((value: boolean) => dispatch({ type: "setState", data: { seeking: value } }), [dispatch]);
  const setIsPlaying = useCallback((value: boolean) => dispatch({ type: "setState", data: { isPlaying: value } }), [dispatch]);
  const [closestEntry, setClosestEntry] = useState<VideoNote | undefined>(undefined);
  const [showTracker, setShowTracker] = useState<boolean>(false);
  const [showPlaybackRateModal, setShowPlaybackRateModal] = useState(false);
  const { width } = useWindowDimensions();
  const isMediumOrLarger = width > theme.breakpoints.md;

  const isDraftRound = !!state.currentRound;
  const roundStartPosition = isDraftRound ? (state.currentRound?.roundStartTimeSeconds || 0) / videoDuration : 0;

  const nextRoundStartTime =
    isDraftRound && getNextRoundStartTime(state.currentRound?.roundStartTimeSeconds, rollAnalysisState?.editedRounds);
  //entry viewer doesn't pass rollAnalysisState
  const isEntryViewer = !rollAnalysisState;

  const rounds = useMemo(() => allRounds || rollAnalysisState?.editedRounds || [], [allRounds, rollAnalysisState?.editedRounds]);

  const convertToSeconds = useCallback(
    (position: number) => (position / playerWidth) * videoDuration,
    [playerWidth, videoDuration]
  );

  const convertToPosition = useCallback(
    (seconds: number) => (seconds / videoDuration) * playerWidth,
    [playerWidth, videoDuration]
  );

  const handlePause = useCallback(() => {
    dispatch({ type: "setState", data: { isPlaying: false } });
  }, [dispatch]);

  const handlePlay = useCallback(() => {
    dispatch({ type: "setState", data: { isPlaying: true } });
  }, [dispatch]);

  const toggleMute = useCallback(() => {
    dispatch({ type: "setState", data: { isMuted: !isMuted } });
  }, [isMuted, dispatch]);

  const handleViewRound = useCallback(
    (index: number) => {
      const roundToView = rounds?.[index];
      const filteredRounds =
        rounds && rounds.filter((round) => round.roundStartTimeSeconds !== roundToView?.roundStartTimeSeconds);
      console.log("viewing");
      //if creating/editing round, prevent clicking on a different round
      if (state.currentRound && !isEntryViewer) return;

      //if viewing a round and clicking that round, unfocus the round
      if (state.currentRound && state.currentRound.roundStartTimeSeconds === roundToView?.roundStartTimeSeconds) {
        return dispatch({
          type: "setState",
          data: {
            currentRound: undefined,
            maximumScrollTime: undefined,
            minimumScrollTime: undefined,
          },
        });
      }
      const maximumScrollTime = getNextRoundStartTime(roundToView?.roundStartTimeSeconds, allRounds) || videoDuration;

      dispatch({
        type: "setState",
        data: {
          //set endtime to undefined so round can be extended when editing.
          currentRound: { ...roundToView },
          isPlaying: true,
          played: roundToView?.roundStartTimeSeconds,
          playedPosition: roundToView?.roundStartTimeSeconds / videoDuration,
          maximumScrollTime: maximumScrollTime,
          minimumScrollTime: roundToView?.roundStartTimeSeconds,
        },
      });
      player.current?.seekTo(roundToView?.roundStartTimeSeconds);
      rollAnalysisState &&
        setRollAnalysisState &&
        setRollAnalysisState({
          ...rollAnalysisState,
          editedRounds: filteredRounds,
          currentEditingRound: { roundStartTimeSeconds: roundToView?.roundStartTimeSeconds },
        });
    },
    [
      player,
      rounds,
      dispatch,
      state.currentRound,
      rollAnalysisState,
      setRollAnalysisState,
      isEntryViewer,
      videoDuration,
      allRounds,
    ]
  );

  const renderRound = useCallback(
    (round: Round, index: number) => {
      const startPosition = round.roundStartTimeSeconds / videoDuration;
      const endPosition = round.roundEndTimeSeconds / videoDuration;
      const roundLength = endPosition - startPosition;
      const isCurrentRound = state.currentRound?.roundStartTimeSeconds === round.roundStartTimeSeconds;

      return (
        <div
          key={round.roundStartTimeSeconds?.toString()}
          onClick={() => !isEditing && handleViewRound(index)}
          css={styles.roundMeter}
          style={{
            ["--startPosition" as any]: startPosition * 100 + "%",
            ["--roundWidth" as any]: roundLength * 100 + "%",
          }}
        >
          <span>{index + 1}</span>
          {isCurrentRound ? isEditing ? <span> Editing...</span> : <span> Viewing...</span> : null}
          {/*{isCurrentRound && isEditing ? <span>   Editing...</span> :<span>  Viewing...</span>}*/}
        </div>
      );
    },
    [handleViewRound, videoDuration, state.currentRound?.roundStartTimeSeconds, isEditing]
  );

  const seekingSnapToClosest = useCallback(
    (mousePosition: number) => {
      // if (notesArray) {
      const mousePosToSeconds = convertToSeconds(mousePosition);
      const snapRange = snapSensitivity * videoDuration;
      const distanceToClosest = closestEntry ? Math.abs(closestEntry.timeInSeconds - mousePosToSeconds) : videoDuration;

      //find closest node to tracker if notes exist
      //
      // notesArray.forEach((entry) => {
      //   !closestEntry && setClosestEntry(entry);
      //   const distanceToTracker = Math.abs(entry.timeInSeconds - mousePosToSeconds);
      //   distanceToTracker < distanceToClosest && setClosestEntry(entry);
      // });

      //snap to closest node if it's within range
      const closestEntryToPosition = closestEntry
        ? convertToPosition(closestEntry.timeInSeconds)
        : convertToPosition(videoDuration);

      return distanceToClosest > snapRange ? mousePosition : closestEntryToPosition;
      // }
    },
    [
      videoDuration,
      closestEntry,
      // notesArray,
      convertToPosition,
      convertToSeconds,
    ]
  );

  const handleMouseMoveWhenSeeking = useCallback(
    (event: any) => {
      if (seeking) {
        const mousePosition = event.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : 2 * PLAYER_WINDOW_PADDING); //pixels
        const mousePosIsValid = mousePosition >= 0 && mousePosition <= playerWidth;
        const seekTime = Math.floor(convertToSeconds(Number(seekingSnapToClosest(mousePosition))));
        mousePosIsValid && seekTo(seekTime);
      }
    },
    [seeking, seekingSnapToClosest, convertToSeconds, playerWidth, seekTo, isMediumOrLarger]
  );
  const handleTouchMoveWhenSeeking = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (seeking) {
        const touch = event.touches[0];
        const mousePosition = touch.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : 2 * PLAYER_WINDOW_PADDING); //pixels
        const mousePosIsValid = mousePosition >= 0 && mousePosition <= playerWidth;
        const seekTime = Math.floor(convertToSeconds(Number(seekingSnapToClosest(mousePosition))));
        mousePosIsValid && seekTo(seekTime);
      }
    },
    [seeking, seekingSnapToClosest, convertToSeconds, playerWidth, seekTo, isMediumOrLarger]
  );

  const handleSkip = useCallback(
    (skipSeconds: number) => {
      const tempTime = played + skipSeconds;
      const newTime = () => {
        if (tempTime < 0) {
          return 0;
        }
        if (tempTime > videoDuration) {
          return videoDuration;
        }
        return tempTime;
      };
      seekTo(newTime());
    },
    [played, seekTo, videoDuration]
  );

  const handleSeekingMouseUp = useCallback(() => {
    dispatch({ type: "setState", data: { isPlaying: true, seeking: false } });
  }, [dispatch]);

  const handleSeekingMouseDown = useCallback(
    (event: any) => {
      setSeeking(true);
      setIsPlaying(false);
      const mousePosition = event.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : 2 * PLAYER_WINDOW_PADDING);

      const mousePosToSeconds = convertToSeconds(mousePosition);
      seekTo(mousePosToSeconds);
    },
    [seekTo, convertToSeconds, setIsPlaying, setSeeking, isMediumOrLarger]
  );
  const handleSeekingTouchDown = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      setSeeking(true);
      setIsPlaying(false);
      const touch = event.touches[0];
      const touchPosition = touch.clientX - (isMediumOrLarger ? PLAYER_WINDOW_OFFSET : 2 * PLAYER_WINDOW_PADDING);

      const touchPosToSeconds = convertToSeconds(touchPosition);
      seekTo(touchPosToSeconds);
    },
    [seekTo, convertToSeconds, setIsPlaying, setSeeking, isMediumOrLarger]
  );
  const handleMeterMouseEnter = useCallback(() => {
    setShowTracker(true);
  }, []);

  const handleMeterMouseLeave = useCallback(() => {
    dispatch({ type: "setState", data: { seeking: false } });
    setShowTracker(false);
  }, [dispatch]);

  // const renderNoteNodes = useCallback(() => (
  //   notesArray?.map((note) => {
  //     const nodePosition = (note.timeInSeconds / videoDuration * 100);
  //     return (
  //       <div
  //         key={note.timeInSeconds + 'node'}
  //         css={styles.noteNode(nodePosition)}
  //       />
  //     );
  //   })
  // ), [notesArray, videoDuration]);
  const togglePlaybackRateModal = useCallback((e: any) => {
    e.preventDefault();
    setShowPlaybackRateModal((prev) => !prev);
  }, []);

  const handlePlaybackRateChange = useCallback(
    (e: any, rate: number) => {
      togglePlaybackRateModal(e);
      dispatch({ type: "setState", data: { playbackRate: rate } });
    },
    [dispatch, togglePlaybackRateModal]
  );

  const renderPlaybackMeter = useCallback(
    () => (
      <div
        onMouseDown={handleSeekingMouseDown}
        onMouseUp={handleSeekingMouseUp}
        onMouseMove={handleMouseMoveWhenSeeking}
        onMouseLeave={handleMeterMouseLeave}
        onMouseEnter={handleMeterMouseEnter}
        onTouchStart={handleSeekingTouchDown}
        onTouchEnd={handleSeekingMouseUp}
        onTouchMove={handleTouchMoveWhenSeeking}
        css={styles.meterFlexBox}
        className="meterFlexBox"
      >
        <div css={styles.meterWrapper(playerWidth)}>
          <div css={styles.baseMeter(100)} />
          <div
            css={styles.loadedMeter}
            style={{
              ["--loaded" as any]: loadedPosition * 100 + "%",
            }}
          />
          <div
            css={styles.playedMeter}
            style={{
              ["--played" as any]: playedPosition * 100 + "%",
            }}
          />
          {isDraftRound && (
            <div
              css={styles.disabledMeterStart}
              style={{
                ["--startRoundTime" as any]: roundStartPosition * 100 + "%",
              }}
            />
          )}
          {isDraftRound && nextRoundStartTime && (
            <div
              css={styles.disabledMeterEnd}
              style={{
                ["--startRoundTime" as any]: (nextRoundStartTime / videoDuration) * 100 + "%",
                ["--endRoundWidth" as any]: ((videoDuration - nextRoundStartTime) / videoDuration) * 100 + "%",
              }}
            />
          )}
          {/*{renderNoteNodes()}*/}
          {showTracker || seeking ? (
            <div
              css={styles.seekingNode}
              style={{
                ["--seeking-left" as any]: playedPosition * 100 + "%",
                ["--seeking-size" as any]: 14 + "px",
                ["--seeking-transform" as any]: -7 + "px",
              }}
            />
          ) : (
            <div
              css={styles.seekingNode}
              style={{
                ["--seeking-left" as any]: playedPosition * 100 + "%",
                ["--seeking-size" as any]: 0 + "px",
                ["--seeking-transform" as any]: -7 + "px",
              }}
            />
          )}
        </div>
      </div>
    ),
    [
      handleSeekingMouseDown,
      handleSeekingMouseUp,
      handleMouseMoveWhenSeeking,
      handleMeterMouseLeave,
      handleMeterMouseEnter,
      handleSeekingTouchDown,
      handleTouchMoveWhenSeeking,
      playerWidth,
      loadedPosition,
      playedPosition,
      isDraftRound,
      roundStartPosition,
      nextRoundStartTime,
      videoDuration,
      showTracker,
      seeking,
    ]
  );

  const renderRoundMeter = useCallback(
    () => (
      <div css={styles.roundFlexBox}>
        <div css={styles.roundWrapper(playerWidth)}>
          {!!rounds?.length && rounds.map((round, index) => renderRound(round, index))}
        </div>
      </div>
    ),
    [playerWidth, rounds, renderRound]
  );

  //TODO abstract this
  const renderPlaybackRateModal = useCallback(() => {
    const options = Object.keys(PLAYBACK_RATE_OPTIONS)
      .filter((key) => Number.isNaN(+key))
      .map((key: string) => ({
        key,
        value: PLAYBACK_RATE_OPTIONS[key as keyof typeof PLAYBACK_RATE_OPTIONS],
      }));
    return (
      <div css={styles.modal}>
        {options.map((option) => (
          <button
            css={styles.speedButton}
            key={option.key}
            onClick={(e) => handlePlaybackRateChange(e, option.value)}
          >
            {playbackRate === option.value ? <CheckIcon /> : <div />}
            {PLAYBACK_RATE_OPTIONS[option.key as keyof typeof PLAYBACK_RATE_OPTIONS] === 1
              ? "Normal"
              : PLAYBACK_RATE_OPTIONS[option.key as keyof typeof PLAYBACK_RATE_OPTIONS]}
          </button>
        ))}
      </div>
    );
  }, [handlePlaybackRateChange, playbackRate]);

  return (
    <div
      className={className}
      css={[styles.controlWrapper(isOverlay), styles.makeColumn]}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div css={[styles.makeRow, styles.spaceBetween]}>
        <div css={styles.iconCluster}>
          <button
            css={styles.playerIcon}
            onClick={() => handleSkip(-10)}
          >
            <ReplayTenIcon css={styles.playerIcon} />
          </button>
          {isPlaying ? (
            <button
              css={styles.playerIcon}
              onClick={handlePause}
            >
              <PauseIcon css={[styles.playerIcon, styles.playButton]} />
            </button>
          ) : (
            <button
              css={styles.playerIcon}
              onClick={handlePlay}
            >
              <PlayIcon css={[styles.playerIcon, styles.playButton]} />
            </button>
          )}
          <button
            css={styles.playerIcon}
            onClick={() => handleSkip(10)}
          >
            <ForwardTenIcon css={styles.playerIcon} />
          </button>
        </div>
        <div css={styles.iconCluster}>
          <button
            css={[styles.playerIcon, styles.modalContainer]}
            onClick={togglePlaybackRateModal}
          >
            <PlaybackRateIcon css={styles.playerIcon} />
          </button>
          {showPlaybackRateModal && renderPlaybackRateModal()}

          <button
            css={styles.playerIcon}
            onClick={toggleMute}
          >
            {isMuted ? <VolumeOffIcon css={styles.playerIcon} /> : <VolumeUpIcon css={styles.playerIcon} />}
          </button>
        </div>
      </div>
      {renderRoundMeter()}
      {renderPlaybackMeter()}
      <div css={styles.row}>
        <span>{formatPlayerTime(played)}</span>
        <span>{formatPlayerTime(videoDuration)}</span>
      </div>
      {/* {showTagViewer ? (
				<GenericModal
					css={styles.genericModal}
					onClose={() => {
						setShowTagViewer(false);
						// setRollAnalysisState({...rollAnalysisState, editedTagIds: rollAnalysisState.originalEntry?.tagIds || []});
						rollAnalysisState && setRollAnalysisState && setRollAnalysisState({ ...rollAnalysisState });
					}}
					title={'Add Tags'}
					onConfirm={() => setShowTagViewer(false)}
				>
					<TagViewer editPost={true} setEntryFormState={setEntryFormState} entryFormState={entryFormState}/>
				</GenericModal>
			) : null} */}
    </div>
  );
};
export default SparringPlayerControls;
