import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useValidateAndDispatch} from './setAuthAction';

export interface PostActionProps {
  password: string;
}


const KEY_ACTION = ActionType.UpdatePasswordRequest;
const ACTIONS = [
  ActionType.UpdatePasswordRequest,
  ActionType.UpdatePasswordResponse,
  ActionType.UpdatePasswordError,
];

interface Payload extends Response {
}

export const updatePasswordAction = (body: PostActionProps, token?: string) => makeApiAction<Payload, PostActionProps>({
  method: 'POST',
  endpoint: () => ENDPOINTS.updatePassword,
  credentials: 'include',
  headers: () => ({
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body: JSON.stringify(body),
  types: makeRsaaTypes<Payload, PostActionProps>(ACTIONS, body),
  bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, PostActionProps> = (state, action, errorMsg, isLoading) => {
  
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
  };
};

export const updatePasswordReducer = makeApiReducer<Payload, PostActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to update password.',
);

export const useUpdatePassword = (
  password: string,
) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  
  const updatePassword = useCallback(
    async () => await validateAndDispatch(updatePasswordAction({password}, token)),
    [password, validateAndDispatch, token],
  );
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  
  return {
    loading, errors, value: updatePassword, reload: updatePassword,
  };
};