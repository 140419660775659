/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import theme from "../Theme";
import { useGetRoundAnalysis } from "./Actions/getRoundsAnalysisAction";
import Loader from "../Utils/Loader";
import { RoundConclusion } from "../VideoAnalysis/module";
import { getTagNameFromId } from "../Journal/module";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.contentBackground};
    position: relative;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
  `,
  expandButton: (isOpen: boolean) => css`
    position: absolute;
    right: 7px;
    top: 8px;
    background: none;
    outline: none;
    border: none;
    transform: rotate(${isOpen ? "90deg" : "-90deg"});
    transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    user-select: none;
  `,
  areaToExpand: (isOpen: boolean) => css`
    visibility: ${isOpen ? "visible" : "hidden"};
    opacity: ${!isOpen ? 0 : 1};
    height: ${isOpen ? "fit-content" : "30px"};
    transition: ${!isOpen
      ? "opacity 100ms, height 500ms, visibility 500ms"
      : "height 500ms 200ms , opacity 300ms  100ms, visibility 500ms"};
  `,
  statColumn: css`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 12px 14px;
    font-size: 10px;
    gap: 3px;
    color: ${theme.fontColor.text};
  `,
  statHeader: css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.fontColor.text};
  `,
  showStatHeader: css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.fontColor.text};
    position: absolute;
    cursor: pointer;
    right: 40px;
    top: 12px;
  `,
  statHeaderOffense: css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.offense};
  `,
  statHeaderDefense: css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.defense};
  `,
  roundData: css``,
  column: css`
    display: flex;
    flex-direction: column;
  `,
  statsContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;

    & h4 {
      line-height: 15px;
      margin-bottom: 3px;
    }
  `,
  offensiveStat: css`
    font-size: 10px;
    color: ${theme.colors.offense};
    font-weight: 700;
  `,
  defensiveStat: css`
    font-size: 10px;
    color: ${theme.colors.defense};
    font-weight: 700;
  `,
  divider: css`
    border-top: 1px solid ${theme.colors.border};
    margin: 10px 0;
  `,
  neutralStat: css`
    font-size: 10px;
    font-weight: 700;
  `,
  dropdownArea: css`
    position: relative;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
  `,
};

interface Props {
  roundId: string;
}

const RoundAnalysisAccordion: React.FC<Props> = ({ roundId }) => {
  const roundAnalysisHook = useGetRoundAnalysis(roundId);
  const tagsHook = useGetTags();
  const tags = tagsHook.value;
  const roundAnalysis = roundAnalysisHook.value?.[0]?.analysis;

  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);
  if (roundAnalysisHook.loading || tagsHook.loading) {
    return <Loader message={"Getting analysis"} />;
  }
  if (!roundAnalysis || !tags) {
    return null;
  }
  return (
    <div css={styles.root}>
      <div css={styles.dropdownArea}>
        {!isOpen && (
          <span
            onClick={handleToggleOpen}
            css={styles.showStatHeader}
          >
            Show Round Stats
          </span>
        )}
        {isOpen && (
          <span
            onClick={handleToggleOpen}
            css={styles.showStatHeader}
          >
            Hide Round Stats
          </span>
        )}
        <button
          css={styles.expandButton(isOpen)}
          onClick={handleToggleOpen}
        >
          <ChevronLeftIcon />
        </button>
        {isOpen && (
          <div css={styles.statsContainer}>
            <div css={styles.statColumn}>
              <span css={styles.statHeader}>You</span>
              <div>
                <span>
                  Time in Offense (seconds): <span css={styles.offensiveStat}>{roundAnalysis.totalTimeInOffenseSeconds}</span>{" "}
                </span>
              </div>
              <div>
                <span>
                  Subs Attempted: <span css={styles.offensiveStat}> {roundAnalysis.submissionAttackCount}</span>
                </span>
              </div>
              <div>
                <span>
                  Sub Attempts per minute:{" "}
                  <span css={styles.offensiveStat}>
                    {Math.floor(roundAnalysis.attackingSubmissionAttemptsPerMinute * 100) / 100}
                  </span>
                </span>
              </div>
              <div>
                <span>
                  Aggressiveness Index:{" "}
                  <span css={styles.offensiveStat}>{Math.floor(roundAnalysis.aggressivenessIndex * 100) / 100}</span>
                </span>
              </div>

              <div>
                <span>
                  Your Defense Index:{" "}
                  <span css={styles.offensiveStat}>{Math.floor(roundAnalysis.defensivenessIndex * 100) / 100}</span>
                </span>
              </div>
              <div css={styles.divider} />
              <span css={styles.statHeader}>Your Opponent</span>
              <div>
                <span>
                  Time in Offense (seconds): <span css={styles.defensiveStat}>{roundAnalysis.totalTimeInDefenseSeconds}</span>
                </span>
              </div>
              <div>
                <span>
                  Subs Attempted: <span css={styles.defensiveStat}>{roundAnalysis.submissionDefendedCount}</span>
                </span>
              </div>
              <div>
                <span>
                  Sub Attempts per minute:{" "}
                  <span css={styles.defensiveStat}>
                    {Math.floor(roundAnalysis.defendingSubmissionAttemptsPerMinute * 100) / 100}
                  </span>
                </span>
              </div>
              <div css={styles.divider} />
              <span css={styles.statHeader}>Round Data</span>
              <div>
                <span>
                  Total round time (seconds): <span css={styles.neutralStat}>{roundAnalysis.totalRoundTimeSeconds}</span>
                </span>
              </div>
              <div css={styles.divider} />
              {roundAnalysis.roundConclusion === RoundConclusion.win && (
                <>
                  <span css={styles.statHeaderOffense}>Victory!</span>
                  <span>
                    Winning submission:{" "}
                    <span css={styles.offensiveStat}>{getTagNameFromId(roundAnalysis.finalSubmissionTagId, tags)}</span>
                  </span>
                  <span>
                    Ending position:{" "}
                    <span css={styles.offensiveStat}>{getTagNameFromId(roundAnalysis.finalPositionTagId, tags)}</span>
                  </span>
                </>
              )}
              {roundAnalysis.roundConclusion === RoundConclusion.lose && (
                <>
                  <span css={styles.statHeaderDefense}>Crushing Defeat!</span>
                  <span>
                    Losing submission:{" "}
                    <span css={styles.defensiveStat}>{getTagNameFromId(roundAnalysis.finalSubmissionTagId, tags)}</span>
                  </span>
                  <span>
                    Ending position:{" "}
                    <span css={styles.defensiveStat}>{getTagNameFromId(roundAnalysis.finalPositionTagId, tags)}</span>
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RoundAnalysisAccordion;
