import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { GET_ROUNDS_ANALYSIS_ENDPOINT } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useCallback, useEffect, useMemo } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";
import { RoundAnalysis } from "../../Utils/types";

const KEY_ACTION = ActionType.GetRoundAnalysisRequest;

const ACTIONS = [ActionType.GetRoundAnalysisRequest, ActionType.GetRoundAnalysisResponse, ActionType.GetRoundAnalysisError];

//TODO update this type
interface Payload extends Response {
  data?: null | {
    roundAnalysis: RoundAnalysis;
  };
}

export const getRoundAnalysisAction = (roundId: string) =>
  makeApiAction<Payload>({
    method: "GET",
    endpoint: () => GET_ROUNDS_ANALYSIS_ENDPOINT(roundId),
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.auth?.accessToken}`,
      "content-type": "application/json",
    }),
    types: makeRsaaTypes<Payload>(ACTIONS),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ["get rounds failure"]);
  }
  //TODO do we need this?
  // if (!payload?.data.rounds) {
  //   return addError(state, KEY_ACTION, ['no rounds']);
  // }

  const successState: AppState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    roundAnalysis: successState.roundAnalysis
      ? [
          ...successState.roundAnalysis,
          {
            roundId: payload.data.roundAnalysis.roundId,
            analysis: { ...payload.data.roundAnalysis },
          },
        ]
      : [
          {
            roundId: payload.data.roundAnalysis.roundId,
            analysis: { ...payload.data.roundAnalysis },
          },
        ],
  };
};

export const getRoundAnalysisReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to fetch round analysis. Please try again."
);

export const useGetRoundAnalysis = (roundId: string): ApiHookReturn<{ roundId: string; analysis: RoundAnalysis }[]> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const getRoundAnalysis = useCallback(
    async () => await validateAndDispatch(getRoundAnalysisAction(roundId || "")),
    [validateAndDispatch, roundId]
  );

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  const currentRoundsInState = useAppState((state) => state.roundAnalysis);

  const roundsToGet: string[] = useMemo(() => [], []);

  const getRequestedRounds = useCallback(() => {
    const requestedRounds: { roundId: string; analysis: RoundAnalysis }[] = [];
    const roundInState = currentRoundsInState?.find((stateRound) => stateRound.roundId === roundId);
    if (roundInState && !requestedRounds.some((round) => round.roundId === roundId)) {
      requestedRounds.push(roundInState);
    } else {
      if (!roundsToGet.some((round) => round === roundId)) {
        roundsToGet.push(roundId);
      }
    }
    return requestedRounds;
  }, [currentRoundsInState, roundId, roundsToGet]);

  useEffect(() => {
    if (!!roundsToGet.length && !loading && !errors) {
      getRoundAnalysis()
        .catch((error) => console.error(error))
        .finally(() => roundsToGet.splice(0, roundsToGet.length));
    }
  }, [errors, getRoundAnalysis, loading, roundsToGet]);

  return {
    loading,
    errors,
    value: getRequestedRounds(),
    reload: getRoundAnalysis,
  };
};
