/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect } from "react";
import isHotkey from "is-hotkey";
import { Editable, ReactEditor, Slate } from "slate-react";
import { css } from "@emotion/react";
import { Descendant, Location, Path, Transforms } from "slate";
import { isValidJSONTest, renderElement, renderLeaf, RICH_TEXT_INITIAL_VALUE, toggleMark } from "./richTextModule";
import { CustomElement, HOTKEYS } from "./richTextTypesModule";
import RichTextToolbar from "./RichTextToolbar";
import { isBackspacePressed, isEnterPressed } from "../module";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 10px 20px 20px 20px;
    & [role="textbox"] {
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 8px;
    }
  `,
  editorTextBox: css`
    margin-top: 5px;
    display: flex;
    overflow: hidden;
    width: 100%;
  `,
};

interface Props {
  editor: ReactEditor;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

//https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
const RichTextEditor: React.FC<Props> = (props) => {
  const { editor, value, placeholder, onChange } = props;

  const entryText = useCallback(() => {
    if (isValidJSONTest(value)) {
      return JSON.parse(value) as Descendant[];
    }
    return [
      {
        type: "paragraph",
        children: [{ text: value }],
      },
    ] as Descendant[];
  }, [value]);

  const handleChange = useCallback(
    (value: Descendant[]) => {
      onChange(JSON.stringify(value));
    },
    [onChange]
  );

  const handleDrop = useCallback((event: any) => {
    // prevents dragging text and especially images into the caption of an image.
    // the innerText checks if it's possible that a new line "/n" is rendering and the text content ensures there isn't actual text there.
    // it's ugly and //todo find a better way to handle this.
    if (event.target.nodeName !== "P" || event.target.innerText.length !== 2 || event.target.textContent.length !== 1) {
      event.preventDefault();
    }
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      //Prevents a new figcaption node from being created when pressing enter
      if (isEnterPressed(event) && editor.selection) {
        const selectedNodePath: Location = Path.parent(editor.selection.anchor.path);
        if (editor.children[selectedNodePath[0]].type === "figure") {
          const nextPath = selectedNodePath[0] + 1;
          const nextNode: Location = [nextPath, 0];
          Transforms.insertNodes(editor, RICH_TEXT_INITIAL_VALUE, { at: [nextPath] });
          event.preventDefault();
          Transforms.select(editor, nextNode);
        }
      }

      //prevent backspace from deleting the caption of a photo instead of the photo itself.
      if (isBackspacePressed(event) && editor.selection) {
        const anchorOffset = editor.selection.anchor.offset;
        const focusOffset = editor.selection.focus.offset;
        const firstOffset = anchorOffset >= focusOffset ? focusOffset : anchorOffset;

        //if text is highlighted, delete that without question.
        // If backspacing character by character, highlights entire figure first and deletes it as a unit on the next backspace press
        if (firstOffset === 0 && focusOffset === anchorOffset) {
          const selectedParentNodePath: Location = Path.parent(editor.selection.anchor.path);
          const selectedNodeType = editor.children[editor.selection.anchor.path[0]].type;
          const previousNodePath: Location = selectedParentNodePath[0] > 0 ? [selectedParentNodePath[0] - 1, 0] : [0, 0];
          const previousNodeType = editor.children[previousNodePath[0]].type;

          //Won't delete the caption node from when selected within the caption.
          if (selectedNodeType === "figure") {
            event.preventDefault();
          }

          if (previousNodeType === "figure") {
            event.preventDefault();
            Transforms.select(editor, Path.parent(previousNodePath));
          }
        }
      }
      for (const hotkey in HOTKEYS) {
        if (HOTKEYS.hasOwnProperty(hotkey) && isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }
    },
    [editor]
  );

  //Adds empty text node below image so user can select and keep typing.
  useEffect(() => {
    const indexOfLastNode = editor.children.length - 1;
    const insertLocation = indexOfLastNode + 1;
    const lastNode = editor.children[indexOfLastNode] as CustomElement;
    if (lastNode.type === "image" || lastNode.type === "figure") {
      Transforms.insertNodes(editor, RICH_TEXT_INITIAL_VALUE, { at: [insertLocation] });
    }
  }, [editor.children.length, editor]);

  return (
    <div css={styles.root}>
      <Slate
        editor={editor}
        value={entryText() || RICH_TEXT_INITIAL_VALUE}
        onChange={handleChange}
      >
        <RichTextToolbar editor={editor} />
        <div css={styles.editorTextBox}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder?.length ? placeholder : "Start typing here..."}
            spellCheck
            onKeyDown={handleKeyDown}
            autoFocus={true}
            onDrop={handleDrop}
            style={{ width: "100%" }}
          />
        </div>
      </Slate>
    </div>
  );
};
export default RichTextEditor;
