import {getLocalStorageValue} from './module';
import * as React from 'react';
import {LocalStorageKey} from './localStorageKey';


const storage = ({
  setValue: <T>(key: LocalStorageKey, value: T): boolean => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
      
    } catch (ex) {
      console.log(`Failed to set local storage value for key (${key}):`, ex);
      return false;
    }
  },
  
  getValue: getLocalStorageValue,
  
  removeValue: (key: LocalStorageKey): void => {
    try {
      localStorage.removeItem(key);
    } catch (ex) {
      console.log(`Failed to remove local storage value for key (${key}):`, ex);
    }
  },
});


/**
 * A hook that interfaces with local storage. Does not update if local storage is updated through some means other
 * than this hook.
 */
export const useLocalStorage = <T>(
  key: LocalStorageKey,
  defaultValue?: T,
): [T | undefined, (obj: T) => void, () => void] => {
  const [value, setValue] = React.useState<T | undefined>(() => storage.getValue(key) ?? defaultValue);
  
  const set = React.useCallback(
    (value: T) => storage.setValue(key, value) && setValue(value),
    [key, setValue],
  );
  
  const remove = React.useCallback(() => storage.removeValue(key), [key]);
  
  return React.useMemo(() => [value, set, remove], [value, set, remove]);
};
