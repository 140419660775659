/** @jsxImportSource @emotion/react */ //include this in all jsx files
import { css } from "@emotion/react";
import React from "react";

const styles = {
  icon: (primaryColor?: string, secondaryColor?: string) => css`
    .fa-primary {
      ${primaryColor ? `fill: ${primaryColor};` : secondaryColor ? `fill: ${secondaryColor};` : ""}
      ${!primaryColor && secondaryColor && `opacity: 0.4;`}
    }
    .fa-secondary {
      ${secondaryColor ? `fill: ${secondaryColor};` : primaryColor ? `fill: ${primaryColor};` : ""}
      ${!secondaryColor && `opacity: 0.4;`}
    }
  `,
};

interface Props {
  primaryColor?: string;
  secondaryColor?: string;
  variant?: "smile" | "frown" | undefined;
  className?: string;
}

const OpponentIcon: React.FC<Props> = ({ primaryColor, secondaryColor, className, variant }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        css={styles.icon(primaryColor, secondaryColor)}
      >
        <defs>
          <style>.fa-secondary</style>
        </defs>
        {variant && variant === "frown" && (
          <>
            <path
              className="fa-secondary"
              d="M64 256c0-24.6 3.5-48.4 9.9-70.9C89.5 131 122.4 84.3 166.2 51.3C209.1 19.1 262.3 0 320 0s110.9 19.1 153.8 51.3c43.8 33 76.7 79.7 92.3 133.8c6.5 22.5 9.9 46.3 9.9 70.9c0 141.4-114.6 256-256 256S64 397.4 64 256zm112.8-37.1c-2.8 8.4 1.7 17.4 10.1 20.2l30.7 10.2c-5.8 5.8-9.3 13.8-9.3 22.6c0 17.7 14.3 32 32 32s32-14.3 32-32c0-1.5-.1-3-.3-4.4l10.9 3.6c8.4 2.8 17.4-1.7 20.2-10.1s-1.7-17.4-10.1-20.2l-96-32c-8.4-2.8-17.4 1.7-20.2 10.1zm59.3 170.4c-5.9 6.6-5.4 16.7 1.2 22.6s16.7 5.4 22.6-1.2c11.7-13 31.6-26.7 60.1-26.7s48.4 13.7 60.1 26.7c5.9 6.6 16 7.1 22.6 1.2s7.1-16 1.2-22.6C387.8 371.4 359.7 352 320 352s-67.8 19.4-83.9 37.3zM336.8 261.1c2.8 8.4 11.9 12.9 20.2 10.1l11.7-3.9c-.2 1.5-.3 3.1-.3 4.7c0 17.7 14.3 32 32 32s32-14.3 32-32c0-8.9-3.6-17-9.5-22.8l30.2-10.1c8.4-2.8 12.9-11.9 10.1-20.2s-11.9-12.9-20.2-10.1l-96 32c-8.4 2.8-12.9 11.9-10.1 20.2z"
            />
            <path
              className="fa-primary"
              d="M566.1 185.1C550.5 131 517.6 84.3 473.8 51.3c17-3.3 39.7-8.6 63-15.5c29.8-8.9 59.1-20.2 78.4-33.1c5.4-3.6 12.4-3.6 17.8 0s8.1 10.1 6.8 16.4c-6.2 31-29.3 106.6-73.6 166zM103.3 35.8c23.3 7 46 12.2 63 15.5c-43.8 33-76.7 79.7-92.3 133.8C29.6 125.8 6.5 50.1 .3 19.1C-1 12.8 1.7 6.3 7.1 2.7s12.4-3.6 17.8 0c19.3 12.9 48.5 24.2 78.4 33.1zM240.4 304c-17.7 0-32-14.3-32-32c0-8.8 3.6-16.8 9.3-22.6l-30.7-10.2c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1l96 32c8.4 2.8 12.9 11.9 10.1 20.2s-11.9 12.9-20.2 10.1l-10.9-3.6c.2 1.5 .3 2.9 .3 4.4c0 17.7-14.3 32-32 32zm192-32c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-1.6 .1-3.2 .3-4.7l-11.7 3.9c-8.4 2.8-17.4-1.7-20.2-10.1s1.7-17.4 10.1-20.2l96-32c8.4-2.8 17.4 1.7 20.2 10.1s-1.7 17.4-10.1 20.2l-30.2 10.1c5.9 5.8 9.5 13.9 9.5 22.8z"
            />
          </>
        )}
        {!variant ||
          (variant === "smile" && (
            <>
              <path
                className="fa-secondary"
                d="M64 256c0-24.6 3.5-48.4 9.9-70.9C89.5 131 122.4 84.3 166.2 51.3C209.1 19.1 262.3 0 320 0s110.9 19.1 153.8 51.3c43.8 33 76.7 79.7 92.3 133.8c6.5 22.5 9.9 46.3 9.9 70.9c0 141.4-114.6 256-256 256S64 397.4 64 256zm112.8-37.1c-2.8 8.4 1.7 17.4 10.1 20.2l30.7 10.2c-5.8 5.8-9.3 13.8-9.3 22.6c0 17.7 14.3 32 32 32s32-14.3 32-32c0-1.5-.1-3-.3-4.4l10.9 3.6c8.4 2.8 17.4-1.7 20.2-10.1s-1.7-17.4-10.1-20.2l-96-32c-8.4-2.8-17.4 1.7-20.2 10.1zm27.1 151.5c22.2 25.7 61 53.5 116.1 53.5s93.8-27.9 116.1-53.5c5.8-6.7 5.1-16.8-1.6-22.6s-16.8-5.1-22.6 1.6C394 370.2 363.4 392 320 392s-74-21.8-91.9-42.5c-5.8-6.7-15.9-7.4-22.6-1.6s-7.4 15.9-1.6 22.6zM336.8 261.1c2.8 8.4 11.9 12.9 20.2 10.1l11.7-3.9c-.2 1.5-.3 3.1-.3 4.7c0 17.7 14.3 32 32 32s32-14.3 32-32c0-8.9-3.6-17-9.5-22.8l30.2-10.1c8.4-2.8 12.9-11.9 10.1-20.2s-11.9-12.9-20.2-10.1l-96 32c-8.4 2.8-12.9 11.9-10.1 20.2z"
              />
              <path
                className="fa-primary"
                d="M566.1 185.1C550.5 131 517.6 84.3 473.8 51.3c17-3.3 39.7-8.6 63-15.5c29.8-8.9 59.1-20.2 78.4-33.1c5.4-3.6 12.4-3.6 17.8 0s8.1 10.1 6.8 16.4c-6.2 31-29.3 106.6-73.6 166zM103.3 35.8c23.3 7 46 12.2 63 15.5c-43.8 33-76.7 79.7-92.3 133.8C29.6 125.8 6.5 50.1 .3 19.1C-1 12.8 1.7 6.3 7.1 2.7s12.4-3.6 17.8 0c19.3 12.9 48.5 24.2 78.4 33.1zM240.4 304c-17.7 0-32-14.3-32-32c0-8.8 3.6-16.8 9.3-22.6l-30.7-10.2c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1l96 32c8.4 2.8 12.9 11.9 10.1 20.2s-11.9 12.9-20.2 10.1l-10.9-3.6c.2 1.5 .3 2.9 .3 4.4c0 17.7-14.3 32-32 32zm192-32c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-1.6 .1-3.2 .3-4.7l-11.7 3.9c-8.4 2.8-17.4-1.7-20.2-10.1s1.7-17.4 10.1-20.2l96-32c8.4-2.8 17.4 1.7 20.2 10.1s-1.7 17.4-10.1 20.2l-30.2 10.1c5.9 5.8 9.5 13.9 9.5 22.8z"
              />
            </>
          ))}
      </svg>
    </div>
  );
};

export default OpponentIcon;
