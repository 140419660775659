
export const bodyPartOptions = [
  {
    name: 'Foot and Ankle',
  },
  {
    name: 'Knee',
  },
  {
    name: 'Hip',
  },
  {
    name: 'Wrist',
  },
  {
    name: 'Elbow',
  },
  {
    name: 'Shoulder',
  },
  {
    name: 'Neck',
  },
  {
    name: 'Spine',
  },

];