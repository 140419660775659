/** @jsxImportSource @emotion/react */
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { css } from "@emotion/react";
import AddIcon from "../icons/AddIcon";
import { APP_LAYERS } from "../layers";
import theme from "../Theme";
import { useClickOutsideListener } from "../modal/hooks";
import EditIcon from "../icons/EditIcon";
import MoreVert from "../icons/MoreVert";
import { hexToRGBA } from "./module";

const styles = {
  actionButton: (isRelativePosition = false, color?: string, variant?: string) => css`
    position: ${isRelativePosition ? "relative" : "absolute"};
    bottom: ${isRelativePosition ? "" : "60px"};
    right: ${isRelativePosition ? "" : "90px"};
    width: 40px;
    height: 40px;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${variant === "outlined" ? theme.fontColor.text : theme.colors.contentBackground};
    ${variant === "outlined" && `border: 1px solid ${color || theme.palette.primary};`}
    ${variant === "outlined" && `box-shadow: 2px 2px 8px ` + hexToRGBA(color || theme.palette.primary.main, 0.75) + ";"}
    ${color
      ? variant === "outlined"
        ? `background-color: ${theme.colors.contentBackground};`
        : `background-color: ${color};`
      : `background-image: ${theme.colors.gradient}`};
    z-index: ${APP_LAYERS.overlays};
    ${!isRelativePosition && "transform: translate(50%, 50%);"}
  `,
  buttonContainer: css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  button: css`
    display: flex;

    &:hover {
      filter: brightness(85%);
      font-size: 28px;
    }
  `,
  menuContainer: (isOpen: boolean, menuPosition: "top" | "bottom" = "top") => css`
		position: absolute;
		visibility: ${isOpen ? "visible" : "hidden"};
		opacity: ${isOpen ? "1" : "0"};
		background-color: ${theme.colors.contentBackground};
		transition: bottom 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5), visibility ${!isOpen ? "150ms" : "0ms"} ease, opacity 140ms ease;
		top: ${menuPosition === "top" ? (isOpen ? "50px;" : "0;") : "red;"}
		bottom: ${menuPosition === "bottom" ? (isOpen ? "-10px;" : "0;") : "blue;"}
    transform: translateY(${menuPosition === "top" ? "-100%" : "100%"});
		border-radius: 12px;
		right: 10px;
		width: 262px;
		padding: 16px !important;
		gap: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid ${theme.colors.border};
	`,
  menuItem: (journalColor = "#000000") => css`
    width: 100%;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 6px;
    height: 36px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: ${theme.fontColor.text};
    background-color: ${theme.colors.background};
    &:hover {
      background-color: ${hexToRGBA(journalColor, 0.2)};
    }
    > div > span {
      font-size: 20px;
    }
  `,
};

export interface MenuItem {
  text: string;
  icon?: ReactNode;
  onClick: (e?: any) => void;
}

interface Props {
  color?: string;
  onClick?: () => void;
  className?: string;
  menuItems?: MenuItem[];
  icon?: "edit" | "hamburgerMenu" | "add" | undefined;
  isRelativePosition?: boolean;
  menuPosition?: "top" | "bottom";
  variant?: "default" | "outlined";
}

const FloatingActionButton: React.FC<Props> = ({
  icon,
  color,
  onClick,
  className,
  menuItems,
  isRelativePosition = false,
  menuPosition = "top",
  variant = "default",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const childRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = useCallback(() => {
    if (menuItems) {
      return setIsOpen((prev) => !prev);
    }
    if (onClick) {
      return onClick();
    }
  }, [onClick, menuItems]);

  useClickOutsideListener(childRef, () => setIsOpen(false));

  const getIcon = useCallback(() => {
    switch (icon) {
      case "edit":
        return (
          <EditIcon
            css={styles.button}
            onClick={handleButtonClick}
          />
        );
      case "hamburgerMenu":
        return (
          <MoreVert
            css={styles.button}
            onClick={handleButtonClick}
          />
        );
      case "add":
        return (
          <AddIcon
            css={styles.button}
            onClick={handleButtonClick}
          />
        );
      default:
        return (
          <AddIcon
            css={styles.button}
            onClick={handleButtonClick}
          />
        );
    }
  }, [icon, handleButtonClick]);

  const handleMenuItemClick = useCallback((itemOnClick: () => void) => {
    itemOnClick();
    setIsOpen(false);
  }, []);

  return (
    <div
      className={className}
      css={styles.actionButton(isRelativePosition, color, variant)}
      ref={childRef}
    >
      <div css={styles.buttonContainer}>
        {getIcon()}
        <ul css={styles.menuContainer(isOpen, menuPosition)}>
          {menuItems &&
            menuItems.map((item) => (
              <li
                css={styles.menuItem(color)}
                key={item.text}
                onClick={() => handleMenuItemClick(item.onClick)}
              >
                {item.text}
                {item.icon}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default FloatingActionButton;
