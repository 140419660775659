/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useRef } from 'react';
import theme from '../Theme';
import { css } from '@emotion/react';
import { DrawerMenuItems } from './module';
import { Link, useLocation } from 'react-router-dom';
import BugIcon from '../icons/BugIcon';
import { ROUTES } from './routesModule';
import ForumIcon from '../icons/ForumIcon';
import PolicyIcon from '../icons/PolicyIcon';
import { useLoggedIn } from '../store/hooks';
import { APP_LAYERS } from '../layers';

const styles = {
	root: (isVisible: boolean, isStatic = false) => css`
		width: ${!isVisible ? '0' : !isStatic ? theme.menuWidth : null};
	`,
	background: (isVisible: boolean, isStatic = false) => css`
		width: ${isVisible && !isStatic ? '100%' : '0'};
		height: 100%;
		background-color: black;
		opacity: ${isVisible ? '.4' : '0'};
		position: ${isStatic ? 'relative' : 'fixed'};
		top: 0;
		left: 0;
		z-index: ${APP_LAYERS.overlays};
		transition: opacity 150ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
	`,
	isVisible: (isVisible: boolean) => css`
		visibility: ${isVisible ? 'visible' : 'hidden'};
		width: ${isVisible ? theme.menuWidth : '0'};
		height: calc(100vh - ${theme.toolbarHeight.heightMedium});
		padding: 0 40px;

		background-color: ${theme.colors.background};
		gap: 10px;
		left: 0;
		display: flex;
		white-space: nowrap;
		flex-direction: column;
		transition: all 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		position: fixed;
		z-index: ${APP_LAYERS.navBarAndHeader};
		@media (min-width: ${theme.breakpoints.lg}px) {
			padding-top: 0;
		}
		@media (min-width: ${theme.breakpoints.md}px) {
			position: relative;
			height: 100%;
		}
	`,
	drawerCard: css`
		background-color: ${theme.colors.contentBackground};
		display: flex;
		flex-direction: column;
		gap: 6px;
    	padding: 20px; 16px;
    	border-radius: 12px;
		height: 100%;
		margin: 40px 0 20px 0;
	`,
	link: css`
		text-decoration: none;
	`,
	legalSection: css`
		display: flex;
		flex-direction: column;
		justify-self: flex-end;
		margin-top: 32px;
		margin-bottom: 10px;
		align-items: center;
		gap: 6px;
		margin-top: auto;
	`,
	menuItem: (isActive = false) => css`
		padding-left: 10px;
		display: flex;
		flex-direction: row;
		color: ${isActive ? theme.colors.contentBackground : theme.palette.toolbars.fontColorPrimary};
		font-size: 16px;
		text-decoration: none;
		gap: 10px;
		align-items: center;
		border-radius: 500px;
		height: 48px;
		background-color: ${isActive ? theme.palette.primary.main : theme.colors.background};
		&:hover {
			color: ${theme.colors.contentBackground};
			background-color: ${theme.palette.toolbars.fontColorPrimary};
		}
	`,
	menuItemLegal: css`
		padding-left: 10px;
		width: 100%;
		display: flex;
		flex-direction: row;
		color: ${theme.palette.toolbars.fontColorPrimary};
		font-size: 16px;
		text-decoration: none;
		gap: 10px;
		align-items: center;
		border-radius: 500px;
		height: 48px;
		background-color: ${theme.colors.background};
		&:hover {
			color: ${theme.colors.contentBackground};
			background-color: ${theme.palette.toolbars.fontColorPrimary};
		}
	`,
};

export interface Props {
	toggleDrawer?: () => void;
	open: boolean;
	isStatic?: boolean;
	className?: string;
}

const Drawer: React.FC<Props> = (props) => {
	const { toggleDrawer, open, isStatic = false, className = '' } = props;
	const drawerRef = useRef<HTMLDivElement>(null);
	const loggedIn = useLoggedIn();
	const location = useLocation();
	const path = location.pathname;

	const isActive = (link: string) => path.includes(link);

	const renderMenuItems = () =>
		DrawerMenuItems.items.map((item) => {
			return (
				<Link
					to={{ pathname: item.link }}
					onClick={() => toggleDrawer?.()}
					css={styles.menuItem(isActive(item.link))}
					key={item.title}
				>
					{item.icon}
					<span>{item.title}</span>
				</Link>
			);
		});

	const handleKeydown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && !isStatic) {
				toggleDrawer?.();
			}
		},
		[toggleDrawer, isStatic]
	);

	const handleClick = useCallback(() => {
		!isStatic && toggleDrawer?.();
	}, [toggleDrawer, isStatic]);

	useEffect(() => {
		open && !isStatic && document.addEventListener('keydown', handleKeydown, false);
		return () => {
			document.removeEventListener('keydown', handleKeydown, false);
		};
	}, [open, handleKeydown, isStatic]);

	return (
		<div
			className={className}
			css={styles.root(open, isStatic)}
		>
			<div
				css={styles.background(open, isStatic)}
				onClick={handleClick}
			/>
			<div
				css={styles.isVisible(open)}
				ref={drawerRef}
			>
				<div css={styles.drawerCard}>
					{loggedIn && renderMenuItems()}
					<a
						href={'https://discord.gg/qyDFxBj6Vs'}
						target='_blank'
						rel='noopener noreferrer'
						css={styles.menuItem()}
					>
						<ForumIcon />
						<span>Discord Community</span>
					</a>
					<a
						href={'https://forms.gle/cQ5FPUzGak8kKjEp8'}
						target='_blank'
						rel='noopener noreferrer'
						css={styles.menuItem()}
					>
						<BugIcon />
						<span>Report a bug</span>
					</a>
					<div css={styles.legalSection}>
						<Link
							to={{ pathname: ROUTES.termsOfService }}
							onClick={() => toggleDrawer?.()}
							css={styles.menuItemLegal}
						>
							<PolicyIcon />
							<span>Terms of Service</span>
						</Link>
						<Link
							to={{ pathname: ROUTES.privacy }}
							onClick={() => toggleDrawer?.()}
							css={styles.menuItemLegal}
						>
							<PolicyIcon />
							<span>Privacy Notice</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Drawer;
