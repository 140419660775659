import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from '../../store/module';
import {useCallback} from 'react';
import {useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {getNewOauthState, TokenPayload} from './module';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

export interface OAuthActionProps {
  code: string,
  clientId: string,
  redirectUri: string,
  scope: string,
}

const KEY_ACTION = ActionType.OAuthRequest;
const ACTIONS = [
  ActionType.OAuthRequest,
  ActionType.OAuthResponse,
  ActionType.OAuthError,
];


export const oAuthAction = (body: OAuthActionProps) =>
  makeApiAction<TokenPayload, OAuthActionProps>({
    method: 'POST',
    endpoint: () => ENDPOINTS.OAuth,
    headers: (state: AppState) => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${state.auth?.accessToken}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<TokenPayload, OAuthActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, KEY_ACTION)
      || !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<TokenPayload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (payload?.data?.error && payload.data.errorDescription) {
    return addError(state, KEY_ACTION, [payload.data?.errorDescription]);
  }
  
  if (!payload?.data) {
    return addError(state, KEY_ACTION, ['oAuth Error']);
  }
  const getStateWithoutRefreshErrors = noLoadingNoError(state, ActionType.Google_RefreshAccessTokenRequest);
  const successState = noLoadingNoError(getStateWithoutRefreshErrors, KEY_ACTION);
  
  return getNewOauthState(successState, payload);
};
export const Google_oAuthReducer = makeApiReducer<TokenPayload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to OAuth.',
);

export const useOAuth = (
  code: string,
  clientId: string,
  redirectUri: string,
  scope: string,
): ApiHookReturn<() => void> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const oAuth = useCallback(async () => await validateAndDispatch(oAuthAction({code, clientId, redirectUri, scope})),
    [validateAndDispatch, clientId, code, redirectUri, scope]);

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: oAuth, reload: oAuth,
  };
};