/**
 * Enum of all of the Redux action types in the app.
 */
export enum ActionType {
  // Auth
  LoginRequest = "LoginRequest",
  LoginResponse = "LoginResponse",
  LoginError = "LoginError",

  Logout = "Logout",
  LogoutResponse = "LogoutResponse",
  LogoutError = "LogoutError",

  UpdatePasswordRequest = "UpdatePasswordRequest",
  UpdatePasswordResponse = "UpdatePasswordResponse",
  UpdatePasswordError = "UpdatePasswordError",

  OAuthRequest = "OAuthRequest",
  OAuthResponse = "OAuthResponse",
  OAuthError = "OAuthError",

  Google_RefreshAccessTokenRequest = "Google_RefreshAccessTokenRequest",
  Google_RefreshAccessTokenResponse = "Google_RefreshAccessTokenResponse",
  Google_RefreshAccessTokenError = "Google_RefreshAccessTokenError",

  Google_RevokeAccessTokenRequest = "Google_RevokeAccessTokenRequest",
  Google_RevokeAccessTokenResponse = "Google_RevokeAccessTokenResponse",
  Google_RevokeAccessTokenError = "Google_RevokeAccessTokenError",

  Google_GetChannelRequest = "Google_GetChannelRequest",
  Google_GetChannelResponse = "Google_GetChannelResponse",
  Google_GetChannelError = "Google_GetChannelError",

  Google_GetVideosFromChannelRequest = "Google_GetVideosFromChannelRequest",
  Google_GetVideosFromChannelResponse = "Google_GetVideosFromChannelResponse",
  Google_GetVideosFromChannelError = "Google_GetVideosFromChannelError",

  GOOGLE_UPDATE_NEED_OAUTH = "GOOGLE_UPDATE_NEED_OAUTH",

  SetAuth = "SetAuth",
  SetToken = "SetToken",
  SetLoading = "SetLoading",
  UnsetLoading = "UnsetLoading",

  RefreshTokenRequest = "RefreshTokenRequest",
  RefreshTokenResponse = "RefreshTokenResponse",
  RefreshTokenError = "RefreshTokenError",

  GetUserRequest = "GetUserRequest",
  GetUserResponse = "GetUserResponse",
  GetUserError = "GetUserError",

  PutUserRequest = "PutUserRequest",
  PutUserResponse = "PutUserResponse",
  PutUserError = "PutUserError",

  GetUserProfileRequest = "GetUserProfileRequest",
  GetUserProfileResponse = "GetUserProfileResponse",
  GetUserProfileError = "GetUserProfileError",

  PostPromotionRequest = "PostPromotionRequest",
  PostPromotionResponse = "PostPromotionResponse",
  PostPromotionError = "PostPromotionError",

  DeletePromotionRequest = "DeletePromotionRequest",
  DeletePromotionResponse = "DeletePromotionResponse",
  DeletePromotionError = "DeletePromotionError",

  // Journal
  GetJournalsRequest = "GetJournalsRequest",
  GetJournalsResponse = "GetJournalsResponse",
  GetJournalsError = "GetJournalsError",

  GetRoundsRequest = "GetRoundsRequest",
  GetRoundsResponse = "GetRoundsResponse",
  GetRoundsError = "GetRoundsError",

  GetRoundAnalysisRequest = "GetRoundAnalysisRequest",
  GetRoundAnalysisResponse = "GetRoundAnalysisResponse",
  GetRoundAnalysisError = "GetRoundAnalysisError",

  DeleteRoundRequest = "DeleteRoundRequest",
  DeleteRoundResponse = "DeleteRoundResponse",
  DeleteRoundError = "DeleteRoundError",

  PutRoundRequest = "PutRoundRequest",
  PutRoundResponse = "PutRoundResponse",
  PutRoundError = "PutRoundError",

  PostJournalEntryRequest = "PostJournalEntryRequest",
  PostJournalEntryResponse = "PostJournalEntryResponse",
  PostJournalEntryError = "PostJournalEntryError",

  PostJournalRequest = "PostJournalRequest",
  PostJournalResponse = "PostJournalResponse",
  PostJournalError = "PostJournalError",

  DeleteJournalRequest = "DeleteJournalRequest",
  DeleteJournalResponse = "DeleteJournalResponse",
  DeleteJournalError = "DeleteJournalError",

  PutJournalRequest = "PutJournalRequest",
  PutJournalResponse = "PutJournalResponse",
  PutJournalError = "PutJournalError",

  PutEntryRequest = "PutEntryRequest",
  PutEntryResponse = "PutEntryResponse",
  PutEntryError = "PutEntryError",

  MoveEntryRequest = "MoveEntryRequest",
  MoveEntryResponse = "MoveEntryResponse",
  MoveEntryError = "MoveEntryError",

  DeleteEntryRequest = "DeleteEntryRequest",
  DeleteEntryResponse = "DeleteEntryResponse",
  DeleteEntryError = "DeleteEntryError",

  GetTagsRequest = "GetTagsRequest",
  GetTagsResponse = "GetTagsResponse",
  GetTagsError = "GetTagsError",

  PostUserTagRequest = "PostUserTagRequest",
  PostUserTagResponse = "PostUserTagResponse",
  PostUserTagError = "PostUserTagError",

  PutUserTagRequest = "PutUserTagRequest",
  PutUserTagResponse = "PutUserTagResponse",
  PutUserTagError = "PutUserTagError",

  DeleteUserTagRequest = "DeleteUserTagRequest",
  DeleteUserTagResponse = "DeleteUserTagResponse",
  DeleteUserTagError = "DeleteUserTagError",

  GetUserAnalysisRequest = "GetUserAnalysisRequest",
  GetUserAnalysisResponse = "GetUserAnalysisResponse",
  GetUserAnalysisError = "GetUserAnalysisError",
}
