/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback } from "react";
import theme, { StyleTypes } from "../Theme";
import { css } from "@emotion/react";
import CancelIcon from "../icons/CancelIcon";
import CloseIcon from "../icons/CloseIcon";

const styles = {
  chip: css`
    border: solid 1.9px black;
    border-radius: 500px;
    padding: 4px;
    margin: 4px;
    font-size: 12px;
    font-family: ${theme.typography.fontFamily};
    display: flex;
    flex-direction: row;
  `,
  cancelIcon: css`
    font-size: 13px;
    display: flex;
    color: black !important;
  `,
  text: css`
    padding: 0 5px;
  `,
};

interface Props {
  label?: string;
  onClick?: () => void;
  className?: StyleTypes;
  onDelete?: () => void;
}

const Chip: React.FC<Props> = (props) => {
  const { label, onClick, onDelete } = props;

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <div
      onClick={handleClick}
      css={[styles.chip, props.className]}
    >
      <span css={styles.text}>{label}</span>
      {!onDelete ? null : (
        <CloseIcon
          variant="outlined"
          css={styles.cancelIcon}
          onClick={onDelete}
        />
      )}
    </div>
  );
};
export default Chip;
