/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback } from "react";
import { useSlate } from "slate-react";
import { css } from "@emotion/react";
import { insertLink, isBlockActive, isLinkActive, toggleBlock, unwrapLink } from "./richTextModule";
import { CombinedElementTypes, TEXT_ALIGN_TYPES } from "./richTextTypesModule";
import theme from "../../Theme";
import { isShortUrl, isUrl } from "../../Journal/module";

const styles = {
  button: (active: boolean) => css`
    cursor: pointer;
    background-color: ${!active ? theme.colors.background : theme.palette.toolbars.activeBackground};
    color: ${!active ? theme.colors.darkGray : theme.palette.toolbars.active};
    display: flex;
    align-items: center;
    border: none;
    padding: 10px;
    > span {
      font-size: 18px;
    }
    &:hover {
      background-color: ${!active ? theme.palette.toolbars.hover : theme.palette.toolbars.activeBackground};
    }
  `,
};

interface Props {
  format: CombinedElementTypes;
  icon: string;
}

const RichTextBlockButton: React.FC<Props> = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      if (format === "link") {
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        else if (isUrl(url)) {
          insertLink(editor, url);
        } else if (isShortUrl(url)) {
          const longUrl = "https://" + url;
          isUrl(longUrl) && insertLink(editor, longUrl);
        }
      } else if (format === "link-off") {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      } else toggleBlock(editor, format);
    },
    [editor, format]
  );

  return (
    <button
      onMouseDown={handleMouseDown}
      css={styles.button(isActive)}
    >
      <span className="material-icons">{icon}</span>
    </button>
  );
};
export default RichTextBlockButton;
