import { AppState } from "./appState";
import { useSelector } from "react-redux";
import { strictEquals } from "../lang/module";
import { ActionType } from "./actionType";
import { getError, getLoading } from "./module";
import { useJournals } from "../Journal/Actions/getJournalsAction";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import { TagInfo } from "../Utils/types";
import { useMemo } from "react";

/**
 * This hook uses the given selector function to pull something out of app state. Uses strict equality to determine when
 * to re-run. This hook exists for two reasons:
 *   1. The Redux useSelector hook needs a lot of boiler (it can't even do proper type inference)
 *   1. This hook decouples Redux from app logic even more (not the main purpose, but it's a nice decoupling point)
 * @param selector - Function that pulls your value out of app state
 */
export const useAppState = <T>(selector: (state: AppState) => T): T => useSelector<AppState, T>(selector, strictEquals);

export const useLoggedIn = (): boolean => {
  const auth = useAppState((state) => state.auth);
  const isValid = (id?: string, accessToken?: string) => !!id?.length && !!accessToken?.length;
  return isValid(auth?.id || "", auth?.accessToken || "");
};

/**
 * Returns the loading state from AppState for the given action type and request ID
 */
export const useLoading = (actionType: ActionType, requestId: string | number | undefined = undefined): boolean =>
  useAppState((state) => !!getLoading(state, actionType, requestId));

/**
 * Returns the error from AppState for the given action type and request ID (if an error exists)
 */
export const useErrors = (actionType: ActionType, requestId: string | number | undefined = undefined): string[] | undefined =>
  useAppState((state) => getError(state, actionType, requestId)?.errorMsg);

export const useRetryCount = (actionType: ActionType, requestId: string | number | undefined = undefined): number =>
  useAppState((state) => getError(state, actionType, requestId)?.retryCount) || 0;

export const useTagInfo = () => {
  const journals = useJournals().value;
  const tags = useGetTags().value;
  return useMemo(() => {
    const tempTagInfo: TagInfo[] = [];
    tags &&
      tags.forEach((tag) =>
        tempTagInfo.push({
          tagId: tag.id,
          uses: {
            journalEntryIds: [],
          },
        })
      );
    journals &&
      journals.forEach((journal) => {
        journal.entries.forEach((entry) => {
          entry.tags?.forEach((tag) => {
            const index = tempTagInfo.findIndex((testTag) => testTag.tagId === tag);
            if (index > 0) {
              tempTagInfo[index].uses.journalEntryIds.push(entry.journalEntryId);
            }
          });
        });
      });
    return tempTagInfo;
  }, [journals, tags]);
};
