import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  is500Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { GOOGLE_GET_YOUTUBE_VIDEOS_FROM_CHANNEL } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { playlistResource, YouTubeVideoListResponse } from "../module";

const KEY_ACTION = ActionType.Google_GetVideosFromChannelRequest;

interface GetVideosFromChannelActionProps {
  channelId: string;
}

const ACTIONS = [
  ActionType.Google_GetVideosFromChannelRequest,
  ActionType.Google_GetVideosFromChannelResponse,
  ActionType.Google_GetVideosFromChannelError,
];

type Payload = null | YouTubeVideoListResponse;

export const Google_getYoutubeVideosFromChannelAction = (body: GetVideosFromChannelActionProps) =>
  makeApiAction<Payload, GetVideosFromChannelActionProps>({
    method: "GET",
    endpoint: () => GOOGLE_GET_YOUTUBE_VIDEOS_FROM_CHANNEL(body.channelId),
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.user?.googleApi?.accessToken}`,
      Accept: "application/json",
    }),
    types: makeRsaaTypes<Payload, GetVideosFromChannelActionProps>(ACTIONS, body),
    bailout: (state) =>
      !!getLoading(state, KEY_ACTION) || !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload, GetVideosFromChannelActionProps> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (is500Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (!payload) {
    return addError(state, KEY_ACTION, ["get video failure 2"]);
  }

  const successState: AppState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    google: {
      ...state.google,
      youTube: {
        ...state.google?.youTube,
        videoList: payload,
      },
    },
  };
};

export const Google_getYoutubeVidoesFromChannelReducer = makeApiReducer<Payload, GetVideosFromChannelActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "failed to get youtube channels"
);

export const useYouTubeVideosFromChannel = (): ApiHookReturn<playlistResource[]> => {
  const dispatch = useDispatch();
  const state = useAppState((state) => state);

  const channelList = state.google?.youTube?.channelList?.items || [];
  const channelId = channelList[0]?.contentDetails.relatedPlaylists.uploads;
  const videoList = state.google?.youTube.videoList?.items;
  const googleAccessToken = state.user?.googleApi?.accessToken;
  const gettingOauth = state.getGoogleOauth;

  const getVideos = useCallback(() => {
    channelId && dispatch(Google_getYoutubeVideosFromChannelAction({ channelId }));
  }, [dispatch, channelId]);

  const loading = useLoading(KEY_ACTION);
  const isRefreshingToken = useLoading(ActionType.Google_RefreshAccessTokenRequest);
  const errors = useErrors(KEY_ACTION);

  useEffect(() => {
    if (!videoList && channelId && !loading && !errors && !isRefreshingToken && !gettingOauth) {
      getVideos();
    }
  }, [channelId, loading, errors, videoList, getVideos, googleAccessToken, isRefreshingToken, gettingOauth]);

  return {
    loading,
    errors,
    value: videoList,
    reload: getVideos,
  };
};
