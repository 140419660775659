import {ColorObject} from '../../Utils/types';
import theme from '../../Theme';

export const DEFAULT_JOURNAL_INPUT = {
  editTitleBlank:       'Journal Title',
  editDescriptionBlank: 'Journal Description',
  color:                {hexCode: theme.palette.primary.main, name: 'dark gray'},
};

export const journalColorChoices: ColorObject[] = [
  DEFAULT_JOURNAL_INPUT.color,
  {hexCode: '#ff8502', name: 'orange'},
  {hexCode: '#402fd7', name: 'purple'},
  {hexCode: '#4CAF50', name: 'green'},
  {hexCode: '#FF2A02', name: 'red'},
  {hexCode: '#9f2fd7', name: 'magenta'},
  {hexCode: '#000000', name: 'black'},
];
