/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useState } from "react";
import { css } from "@emotion/react";
import RichTextBlockButton from "./RichTextBlockButton";
import RichTextMarkButton from "./RichTextMarkButton";
import ImageIcon from "../../icons/ImageIcon";
import theme from "../../Theme";
import { ReactEditor } from "slate-react";
import PickerOverlayWrapper from "./PickerOverlayWrapper";

const styles = {
  toolbarBigScreens: css`
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 12px;
    padding-bottom: 15px;
    @media (min-width: ${theme.breakpoints.sm}px) {
      display: flex;
    }
  `,
  toolbarSmallScreens: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
    @media (min-width: ${theme.breakpoints.sm}px) {
      display: none;
    }
  `,
  button: css`
    cursor: pointer;
    background-color: ${theme.colors.background};
    color: ${theme.colors.darkGray};
    display: flex;
    align-items: center;
    border: none;
    padding: 10px;
    &:hover {
      background-color: ${theme.colors.background};
    }
  `,
  blockButtonWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
};

interface Props {
  editor: ReactEditor;
}

const RichTextToolbar: React.FC<Props> = ({ editor }) => {
  const [showUploader, setShowUploader] = useState(false);
  //filestack documentation at https://www.filestack.com/docs/uploads/pickers/web/

  const onImageClick = useCallback(() => {
    setShowUploader(!showUploader);
  }, [showUploader, setShowUploader]);

  return (
    <>
      <div css={styles.toolbarBigScreens}>
        <RichTextMarkButton
          format="bold"
          icon="format_bold"
        />
        <RichTextMarkButton
          format="italic"
          icon="format_italic"
        />
        <RichTextMarkButton
          format="underline"
          icon="format_underlined"
        />
        <div css={styles.blockButtonWrapper}>
          <RichTextBlockButton
            format="link"
            icon="link"
          />
          <RichTextBlockButton
            format="link-off"
            icon="link_off"
          />
          <RichTextBlockButton
            format="block-quote"
            icon="format_quote"
          />
          <RichTextBlockButton
            format="numbered-list"
            icon="format_list_numbered"
          />
          <RichTextBlockButton
            format="bulleted-list"
            icon="format_list_bulleted"
          />
          <RichTextBlockButton
            format="left"
            icon="format_align_left"
          />
          <RichTextBlockButton
            format="center"
            icon="format_align_center"
          />
          <RichTextBlockButton
            format="right"
            icon="format_align_right"
          />
          <RichTextBlockButton
            format="justify"
            icon="format_align_justify"
          />
        </div>
        <ImageIcon
          onClick={onImageClick}
          css={styles.button}
        />
        {showUploader && (
          <PickerOverlayWrapper
            editor={editor}
            setShowUploader={setShowUploader}
          />
        )}
      </div>
      <div css={styles.toolbarSmallScreens}>
        <RichTextMarkButton
          format="bold"
          icon="format_bold"
        />
        <RichTextMarkButton
          format="italic"
          icon="format_italic"
        />
        <RichTextMarkButton
          format="underline"
          icon="format_underlined"
        />
        <div css={styles.blockButtonWrapper}>
          <RichTextBlockButton
            format="link"
            icon="link"
          />
          <RichTextBlockButton
            format="link-off"
            icon="link_off"
          />
          <RichTextBlockButton
            format="block-quote"
            icon="format_quote"
          />
        </div>
        <div css={styles.blockButtonWrapper}>
          <RichTextBlockButton
            format="numbered-list"
            icon="format_list_numbered"
          />
          <RichTextBlockButton
            format="bulleted-list"
            icon="format_list_bulleted"
          />
          <RichTextBlockButton
            format="left"
            icon="format_align_left"
          />
          <RichTextBlockButton
            format="center"
            icon="format_align_center"
          />
          <RichTextBlockButton
            format="right"
            icon="format_align_right"
          />
          <RichTextBlockButton
            format="justify"
            icon="format_align_justify"
          />
          <ImageIcon
            onClick={onImageClick}
            css={styles.button}
          />
        </div>

        {showUploader && (
          <PickerOverlayWrapper
            editor={editor}
            setShowUploader={setShowUploader}
          />
        )}
      </div>
    </>
  );
};
export default RichTextToolbar;
