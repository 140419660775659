import { useCallback, useEffect, useState } from "react";
import { IS_PROD_ENV } from "../../environment/environment";
import { useOAuth } from "./oAuthAction";
import { CLIENT_ID } from "../module";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import { useStore } from "react-redux";
import { ActionType } from "../../store/actionType";

export const useGoogleOauth = () => {
  const [authCode, setAuthCode] = useState("");
  const [authScope, setAuthScope] = useState("");
  const REDIRECT_URI = IS_PROD_ENV
    ? "https://cks-web-prod.azurewebsites.net"
    : window?.location?.hostname?.includes("cks-web-dev.azurewebsites.net")
    ? "https://cks-web-dev.azurewebsites.net"
    : "https://localhost:3000";

  const SCOPE = "https://www.googleapis.com/auth/youtube.readonly";
  const oAuth = useOAuth(authCode, CLIENT_ID, REDIRECT_URI, authScope);
  const store = useStore();

  const handleOauth = useCallback(() => {
    oAuth.value && oAuth.value();
    setAuthCode("");
    setAuthScope("");
  }, [oAuth]);

  const handleCodeResponse = useCallback((codeResponse: Omit<CodeResponse, "error" | "error_description" | "error_uri">) => {
    setAuthCode(codeResponse.code);
    setAuthScope(codeResponse.scope);
  }, []);
  console.log(authScope);
  const handleNonOauthError = useCallback(
    (e: unknown) => {
      store.dispatch({ type: ActionType.GOOGLE_UPDATE_NEED_OAUTH, payload: e });
      alert("Permissions must be granted to upload videos. Please try again");
    },
    [store]
  );

  const handleError = useCallback(
    (err: Pick<CodeResponse, "error" | "error_description" | "error_uri">) => {
      store.dispatch({ type: ActionType.GOOGLE_UPDATE_NEED_OAUTH, payload: false });
      console.log("Google oAuth Error: ", err);
    },
    [store]
  );

  useEffect(() => {
    !oAuth.loading && !oAuth.errors && authCode.length && authScope.length && handleOauth();
  }, [authScope, authCode, handleOauth, oAuth.loading, oAuth.errors]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleCodeResponse(codeResponse),
    onNonOAuthError: (e) => handleNonOauthError(e), //todo modal
    onError: handleError,
    flow: "auth-code",
    scope: SCOPE,
    state: "google-login",
  });

  return login;
};
