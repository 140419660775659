/** @jsxImportSource @emotion/react */ //include this in all jsx files

import {ROUTES} from './routesModule';
import LibraryIcon from '../icons/LibraryIcon';
import TagIcon from '../icons/TagIcon';
import React from 'react';
import HomeIcon from '../icons/HomeIcon';
import AnalyzeIcon from '../icons/AnalyzeIcon';

export interface MenuItems {
  icon: JSX.Element,
  title: string,
  link: string,
}

const drawerItems: MenuItems[] = [
  // {
  //   icon: <HomeIcon/>,
  //   title: 'Home',
  //   link: ROUTES.home,
  // },
  {
    icon: <LibraryIcon/>,
    title: 'Library',
    link: ROUTES.journalLibrary,
  },
  {
    icon: <AnalyzeIcon/>,
    title: 'Analyze',
    link: ROUTES.analyze,
  },
  {
    icon: <TagIcon/>,
    title: 'Tag Manager',
    link: ROUTES.tagLibrary,
  },
];

export const DrawerMenuItems = {items: drawerItems};



