import {AppState} from '../../store/appState';
import {Response} from '../../store/module';

export interface TokenPayload extends Response {
  data: {
    error: null | string,
    errorDescription: null | string,
    accessToken: null | string,
    scope?: null | string,
  },
}

export const getNewOauthState = (successState: AppState, payload: TokenPayload) => (
  {
    ...successState,
    user:           {
      email:                successState.user?.email || '',
      id:                   successState.user?.id || '',
      lastLoginDateTimeUtc: successState.user?.lastLoginDateTimeUtc || '',
      name:                 {
        firstName: successState.user?.name.firstName || '',
        lastName:  successState.user?.name.lastName || '',
      },
      signUpDateTimeUtc:    successState.user?.signUpDateTimeUtc || '',
      username:             successState.user?.username || '',
      googleApi:            {accessToken: payload.data.accessToken || null},
    },
    getGoogleOauth: false,
  }
);