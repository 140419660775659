/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import theme from '../Theme';
import { useTagInfo } from '../store/hooks';
import { useParams } from 'react-router-dom';
import {
	getEntriesForAllJournals,
	getEntriesFromSearchResults,
	getTagNameFromId,
	searchFilteredEntries,
} from '../Journal/module';
import { useGetTags } from './Actions/getTagsAction';
import Loader from '../Utils/Loader';
import { useJournals } from '../Journal/Actions/getJournalsAction';
import EntryPreview from '../Journal/EntryPreview';
import FilterChip from '../Utils/FilterChip';
import Searcher from '../Utils/Searcher';
import { ENTRY_TYPE, EntryWithJournalId } from '../Utils/types';

const styles = {
	root: css`
		height: calc(100vh - 56px);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: ${theme.colors.background};
		width: 100%;
		padding: 40px;
		gap: 10px;
		@media (max-width: ${theme.breakpoints.md}px) {
			margin-left: 0;
			padding: 10px 40px;
		}
	`,
	header: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    width: 100%;
	border-radius: 12px;
    background-color: ${theme.colors.contentBackground};
	align-items: center;
  }
  `,
	title: css`
		font-weight: 700;
		font-size: 24px;
		color: ${theme.fontColor.text};
	`,
	body: css`
		padding-top: 20px;
		display: flex;
		width: 100%;
	`,
	row: css`
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 100%;
		cursor: pointer;
		width: fit-content;
		font-weight: 400;
	`,
	card: css`
		border-radius: 10px;
		width: 100%;
		overflow: hidden;
		border: solid 1px ${theme.colors.lightestGray};
		box-shadow: rgb(0 30 43 / 10%) 0 4px 4px 0;
		background-color: ${theme.colors.contentBackground};
		position: relative;
		min-height: 120px;
		padding: 10px;
		cursor: pointer;
	`,
	noTagCard: css`
		border-radius: 12px;
		width: 100%;
		overflow: hidden;
		background-color: ${theme.colors.contentBackground};
		position: relative;
		min-height: 120px;
		padding: 20px;
	`,
	layout: css`
		display: flex;
		flex-direction: column;
		gap: 12px;
	`,
	emptyLayout: css`
		display: flex;
		flex-direction: column;
		flex: 1;
	`,
	filterRow: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 10px;
		gap: 8px;
		background-color: ${theme.colors.contentBackground};
		border-radius: 12px;
		flex-wrap: wrap;
		@media (min-width: ${theme.breakpoints.sm}px) {
			padding-left: 10px;
		}
	`,
	chipRowDesktop: css`
		display: flex;
		flex-direction: row;
		background-color: ${theme.colors.contentBackground};
		width: fit-content;
		border-radius: 500px;
		padding: 6px 14px;
		gap: 6px;
		height: 44px;
		align-items: center;
		// @media (max-width: ${theme.breakpoints.md}px) {
		// 	display: none;
		// }
	`,
};

const TagDetail: React.FC = () => {
	const tagInfo = useTagInfo();
	const tagHook = useGetTags();
	const journals = useJournals();
	const allEntries = useMemo(() => getEntriesForAllJournals(journals.value), [journals.value]);
	const allTags = tagHook.value;
	const { tagId } = useParams<{ tagId: string }>();

	const [searchTerm, setSearchTerm] = useState('');
	const [videoFilterChecked, setVideoFilterChecked] = useState(true);
	const [textFilterChecked, setTextFilterChecked] = useState(true);
	const [analysisFilterChecked, setAnalysisFilterChecked] = useState(true);

	const [filteredEntries, seFilteredEntries] = useState<EntryWithJournalId[]>([]);

	const [searchedEntries, setSearchedEntries] = useState<EntryWithJournalId[]>(allEntries);
	const [searched, setSearched] = useState(false);
	const currentTag = useMemo(() => tagInfo.find((tag) => tag.tagId === tagId), [tagId, tagInfo]);

	const getFilteredEntries = useCallback(() => {
		let filteredEntries: EntryWithJournalId[] = [];
		allEntries.forEach((entry) => {
			if (currentTag?.uses.journalEntryIds.includes(entry.journalEntry.journalEntryId)) {
				filteredEntries.push(entry);
			}
		});

		if (!videoFilterChecked) {
			filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Video);
		}
		if (!textFilterChecked) {
			filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.Text);
		}
		if (!analysisFilterChecked) {
			filteredEntries = filteredEntries.filter((entry) => entry.journalEntry.type !== ENTRY_TYPE.SparAnalysis);
		}
		return filteredEntries;
	}, [allEntries, videoFilterChecked, textFilterChecked, analysisFilterChecked, currentTag?.uses]);

	const handleSearch = useCallback((searchTerm: string) => {
		setSearchTerm(searchTerm);
	}, []);

	const handleVideoFilterChecked = useCallback(() => {
		setVideoFilterChecked((prev) => !prev);
	}, []);

	const handleTextFilterChecked = useCallback(() => {
		setTextFilterChecked((prev) => !prev);
	}, []);

	const handleAnalysisFilterChecked = useCallback(() => {
		setAnalysisFilterChecked((prev) => !prev);
	}, []);
	console.log('first', filteredEntries);

	useEffect(() => {
		searchTerm ? setSearched(true) : setSearched(false);
		setSearchedEntries(getEntriesFromSearchResults(searchFilteredEntries(searchTerm, filteredEntries, allTags || [])));
	}, [searchTerm, allTags, filteredEntries]);

	useEffect(() => {
		allEntries && seFilteredEntries(getFilteredEntries());
		console.log('firing');
	}, [getFilteredEntries, allEntries]);

	const entriesToRender = searched ? searchedEntries : filteredEntries;

	if (tagHook.loading || journals.loading) {
		return <Loader />;
	}
	if (!currentTag) {
		return <span>Error: Can't find tag information</span>;
	}
	return (
		<div css={styles.root}>
			{allTags && <span css={styles.title}>{getTagNameFromId(currentTag.tagId, allTags)}</span>}

			<div css={styles.filterRow}>
				<Searcher
					placeholder={'Search Entries...'}
					onClick={handleSearch}
				/>
				<div css={styles.chipRowDesktop}>
					<FilterChip
						name={'Video Entries'}
						checked={videoFilterChecked}
						handleChecked={handleVideoFilterChecked}
					/>
					<FilterChip
						name={'Text Entries'}
						checked={textFilterChecked}
						handleChecked={handleTextFilterChecked}
					/>
					<FilterChip
						name={'Spar Analysis'}
						checked={analysisFilterChecked}
						handleChecked={handleAnalysisFilterChecked}
					/>
				</div>
			</div>
			<div css={styles.body}>
				{currentTag.uses.journalEntryIds.length && entriesToRender.length ? (
					<div css={styles.layout}>
						<div css={styles.card}>
							<EntryPreview
								previewLines={2}
								entries={entriesToRender}
							/>
						</div>
					</div>
				) : (
					<div css={styles.emptyLayout}>
						<div css={styles.noTagCard}>
							<span>No entries were found. </span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TagDetail;
