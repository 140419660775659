export const positionPriorityOptions = [
  {
    name: 'Inside Leg Control',
  },
  {
    name: 'Outside Leg Control',
  },
  {
    name: 'Inside Arm Control',
  },
  {
    name: 'Outside Arm Control',
  },
  {
    name: 'Posture/Head/Shoulder Control',
  },
  {
    name: 'Gi/Lapel Guards',
  },
];



