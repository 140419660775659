/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import theme from "../Theme";
import { css } from "@emotion/react";
import { ENTRY_TYPE, JournalEntryObject, JournalObject } from "../Utils/types";
import { useJournals } from "./Actions/getJournalsAction";
import { ApiHookReturn } from "../store/module";
import Loader from "../Utils/Loader";
import { useErrors, useLoading } from "../store/hooks";
import { ActionType } from "../store/actionType";
import TextEntryViewer from "./EntryViewers/TextEntryViewer";
import VideoNoteEntryViewer from "./EntryViewers/VideoNoteEntryViewer";
import AnalyzerEntryViewer from "./EntryViewers/AnalyzerEntryViewer";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background};
    width: 100%;
    position: relative;
    height: 100%;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
      margin-top: 20px;
      padding: 0 0 20px 0;
    }
    padding: 40px 40px 20px 0;
  `,
};

const EntryViewerContainer: React.FC = () => {
  const { entryId, journalId } = useParams<{ entryId: string; journalId: string }>();
  const journals: ApiHookReturn<JournalObject[]> = useJournals();
  const history = useHistory();

  const journal: JournalObject | undefined =
    journals.value && JSON.parse(JSON.stringify(journals.value.find((journal) => journal.id === journalId)));

  const entry: JournalEntryObject | undefined =
    journal?.entries && JSON.parse(JSON.stringify(journal.entries?.find((entry) => entry.journalEntryId === entryId) || {}));

  const [updatedURL, setUpdatedURL] = useState("");

  const putEntryLoading = useLoading(ActionType.PutEntryRequest);
  const deleteEntryLoading = useLoading(ActionType.DeleteEntryRequest);
  const moveIsLoading = useLoading(ActionType.MoveEntryRequest);
  const moveHasErrors = useErrors(ActionType.MoveEntryRequest);

  useEffect(() => {
    if (updatedURL && !moveHasErrors && !moveIsLoading) {
      history.push(updatedURL);
    }
  }, [history, moveIsLoading, moveHasErrors, updatedURL]);

  if (deleteEntryLoading) {
    return <Loader message={"Deleting..."} />;
  }
  if (!journal) {
    return <Loader message={"Fetching Journal..."} />;
  }
  if (!entry) {
    return <span>Error: could not locate entry. Please try again later.</span>;
  }

  return (
    <>
      {!entry ? (
        <div>
          <span>Entry Not Found or Can't Be Displayed</span>
        </div>
      ) : (
        <div css={styles.root}>
          {putEntryLoading && <Loader message={"Saving..."} />}
          {entry.type === ENTRY_TYPE.Text && <TextEntryViewer setUpdatedURL={setUpdatedURL} />}
          {entry.type === ENTRY_TYPE.Video && <VideoNoteEntryViewer setUpdatedURL={setUpdatedURL} />}
          {entry.type === ENTRY_TYPE.SparAnalysis && <AnalyzerEntryViewer setUpdatedURL={setUpdatedURL} />}
        </div>
      )}
    </>
  );
};
export default EntryViewerContainer;
