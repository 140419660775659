import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from '../../store/module';
import {ENDPOINTS} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useCallback, useEffect} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {TagType} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

const KEY_ACTION = ActionType.GetTagsRequest;

const ACTIONS = [
  ActionType.GetTagsRequest,
  ActionType.GetTagsResponse,
  ActionType.GetTagsError,
];

interface Payload extends Response {
  data?: null | {
    tags?: TagType[];
  }
}

export const getTagAction = () => (
  makeApiAction<Payload, string>({
    method: 'GET',
    endpoint: () => ENDPOINTS.GetTags,
    headers: (state: AppState) => ({
      'Authorization': `Bearer ${state.auth?.accessToken}`,
      'content-type': 'application/json',
    }),
    types: makeRsaaTypes<Payload, string>(ACTIONS),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  })
);

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data) {
    return addError(state, KEY_ACTION, ['get tags failure']);
  }
  
  const successState: AppState = noLoadingNoError(state, KEY_ACTION);
  return {
    ...successState,
    tags: payload.data.tags,
  };
};

export const getTagsReducer = makeApiReducer<Payload>(ACTIONS, KEY_ACTION, reducer, 'failed to get tags');

export const useGetTags = (): ApiHookReturn<TagType[]> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  const tags = useAppState(state => state.tags);
  
  const getTags = useCallback(async () => await validateAndDispatch(getTagAction()), [validateAndDispatch]);
  
  useEffect(
    () => {
      if (!tags && !loading && !errors) {
        getTags().catch(error => console.error(error));
      }
    },
    [loading, errors, tags, getTags],
  );
  
  return {
    loading, errors, value: tags, reload: getTags,
  };
};