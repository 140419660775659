import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from "../../store/module";
import { useCallback } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { DELETE_JOURNAL_ENDPOINT } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";

export interface DeleteActionProps {
  journalId: string;
}

const KEY_ACTION = ActionType.DeleteJournalRequest;
const ACTIONS = [ActionType.DeleteJournalRequest, ActionType.DeleteJournalResponse, ActionType.DeleteJournalError];

interface Payload extends Response {}

export const deleteJournalAction = (body: DeleteActionProps, token?: string) =>
  makeApiAction<Payload, DeleteActionProps>({
    method: "DELETE",
    endpoint: () => DELETE_JOURNAL_ENDPOINT(body.journalId),
    headers: () => ({
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<Payload, DeleteActionProps>(ACTIONS, body),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload, DeleteActionProps> = (state, action, errorMsg, isLoading) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  const successState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    userJournals: state.userJournals?.filter((journal) => journal.id !== action.meta.journalId),
  };
};

export const deleteJournalReducer = makeApiReducer<Payload, DeleteActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to delete journal."
);

export const useDeleteJournal = (journalId: string) => {
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const deleteJournal = useCallback(
    async () => await validateAndDispatch(deleteJournalAction({ journalId }, token)),
    [journalId, validateAndDispatch, token]
  );

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);

  return {
    loading,
    errors,
    value: deleteJournal,
    reload: deleteJournal,
  };
};
