import { css } from '@emotion/react';
import theme from '../Theme';
import { TagType } from '../Utils/types';
import React from 'react';

export const TAG_CATEGORY_TYPES = {
	position: 'Position',
	submission: 'Submission',
	movement: 'Movement',
	custom: 'Custom',
};
export const tagCategoryOptions = [
	{
		name: TAG_CATEGORY_TYPES.position,
	},
	{
		name: TAG_CATEGORY_TYPES.submission,
	},
	{
		name: TAG_CATEGORY_TYPES.movement,
	},
	{
		name: TAG_CATEGORY_TYPES.custom,
	},
];

export const radioButtonStyle = (selected?: boolean) => css`
	width: 120px;
	border: solid 1px ${theme.palette.primary.main};
	padding: 5px;
	cursor: pointer;
	${selected && 'background-color: ' + theme.palette.primary.main + ';'}
	${selected && 'color: ' + theme.colors.contentBackground + ';'}
  &:first-of-type {
		border-radius: 3px 0 0 3px;
	}

	&:last-of-type {
		border-radius: 0 3px 3px 0;
	}

	&:hover {
		${!selected && 'background-color: ' + theme.palette.primary.main + ';'}
		${!selected && 'opacity: .85;'}
    ${!selected && 'color: ' + theme.colors.contentBackground + ';'}
	}
`;

export const filterByCustomTag = (showCustomOnly: boolean, tags: TagType[]) =>
	showCustomOnly ? tags.filter((tag) => tag.tagSource === 1) : [...tags];

export const filterTagsByState = (tagsToFilter: TagType[], filteredState: boolean[]) => {
	let filteredString = '';
	const newFilteredTags: TagType[] = [];
	tagsToFilter.forEach((tag) => {
		filteredState.forEach((state, index) => {
			state ? (filteredString = filteredString + 1) : (filteredString = filteredString + 0);
			if (state) {
				const tagHasCategory = tag.tagType.includes(tagCategoryOptions[index].name);
				if (tagHasCategory) {
					newFilteredTags.push(tag);
				}
			}
		});
	});
	return { filteredString, newFilteredTags };
};

export const mapMenuItems = (menuItems: { name: string }[]) =>
	menuItems.map((option) => (
		<option
			key={option.name}
			value={option.name}
		>
			{option.name}
		</option>
	));

export const getUpdatedTag = (tag: TagType, name: string, tagType: string, category: string, bodyPart: string = '') =>
	({ ...tag, name: name, category: category, tagType: tagType, bodyPart: bodyPart } as TagType);
