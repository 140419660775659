import * as React from 'react';
import {useEffect} from 'react';
import {useAppState} from '../hooks';
import {useLocalStorage} from './useLocalStorage';
import {AppState} from '../appState';


/**
 * Hook which connects Redux and LocalStorage
 */
const useBridge = (key: keyof AppState) => {
  const value = useAppState(state => state[key]);
  const [, setValue] = useLocalStorage<typeof value>(key);
  
  useEffect(() => setValue(value), [value, setValue]);
};


/**
 * LocalStorageBridge: a non-rendering component that listens to Redux and puts key app state into Local Storage.
 */
const LocalStorageBridge: React.FC = () => {
  useBridge('auth');
  // Non-rendering
  return null;
};

export default LocalStorageBridge;
