import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {PUT_USER_TAG_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {TagType} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';


export interface PutUserTagActionProps {
  tag: TagType
}

const KEY_ACTION = ActionType.PutUserTagRequest;
const ACTIONS = [
  ActionType.PutUserTagRequest,
  ActionType.PutUserTagResponse,
  ActionType.PutUserTagError,
];

export interface PutUserTagPayload extends Response {
  data?: null | {
    tag?: null | TagType
  }
}

export const putUserTagAction = (body: PutUserTagActionProps, token?: string) =>
  makeApiAction<PutUserTagPayload, PutUserTagActionProps>({
    method: 'PUT',
    endpoint: () => PUT_USER_TAG_ENDPOINT(body.tag?.id || ''),
    headers: () => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<PutUserTagPayload, PutUserTagActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<PutUserTagPayload, PutUserTagActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.tag) {
    return addError(state, KEY_ACTION, ['PUT Tag failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState, tags: undefined,
  };
};

export const putUserTagReducer = makeApiReducer<PutUserTagPayload, PutUserTagActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to update tag.',
);

export const usePutUserTag = (tag: TagType) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const putUserTag = useCallback(async () => await validateAndDispatch(putUserTagAction({tag: {...tag}}, token)),
    [tag, validateAndDispatch, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: putUserTag, reload: putUserTag,
  };
};