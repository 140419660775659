/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { css } from "@emotion/react";
import ReactPlayer from "react-player/lazy";
import PlayerControls from "./PlayerControls";
import Notepad from "./Notepad";
import { EntryFormState, VideoNote } from "../../Utils/types";
import { useContainerDimensions } from "../../Utils/useContainerDimensions";
import theme from "../../Theme";
import GenericModal from "../../modal/GenericModal";
import TagIcon from "../../icons/TagIcon";
import TagViewer from "../../Tags/TagViewer";
import TextInput from "../../Utils/TextInput";
import { hexToRGBA } from "../../Utils/module";
import { useHoverOfRef } from "../../tooltip/hooks";
import { APP_LAYERS } from "../../layers";
import { formatPlayerTime } from "./module";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-width: 100%;
    position: relative;

    gap: 20px;
    border-radius: 12px;
    background-color: ${theme.colors.contentBackground};
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 20px;
    }
  `,
  noteInput: css`
    resize: none;
    width: 100%;
    flex-direction: column;
    height: 60px;
    display: none;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
    padding: 12px;
    font-size: 12px;
    @media (min-width: ${theme.breakpoints.videoEntry}px) {
      max-width: 400px;
      display: flex;
    }
  `,
  noteInputMobile: css`
    resize: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
    padding: 12px;
    height: 60px;
    font-size: 12px;
    @media (min-width: ${theme.breakpoints.videoEntry}px) {
      display: none;
    }
  `,
  noteInputWrapper: css`
    resize: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    height: 100%;

    @media (min-width: ${theme.breakpoints.md}px) {
      min-width: 200px;
      max-width: 350px;
    }
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      width: 100%;
    }
  `,
  iframe: css`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.lightestGray};
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    //.react-player {
    //  object-fit: cover;
    //}
  `,
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    max-width: 100%;
    position: relative;
    border-radius: 12px;

    background-color: ${theme.colors.contentBackground};
    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      padding: 24px 0;
    }
  `,
  wrapper: css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-height: 400px;
    min-width: 400px;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      min-width: unset;
    }
  `,
  playerWindow: css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    gap: 20px;
  `,
  modalBody: css`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,

  urlInput: (color: string) => css`
    width: 100%;
    border: solid 1px ${theme.colors.border};
    border-radius: 6px;
    display: flex;
    min-height: 42px;
    padding: 0 14px;

    &:focus {
      outline: solid 2px ${color};
    }
  `,
  urlInputMobile: (color: string) => css`
    width: 100%;
    display: flex;
    min-width: 0;
    @media (min-width: ${theme.breakpoints.videoEntry}px) {
      display: none;
    }
    fieldset {
      border-radius: 6px;
    }
  `,
  inputLabel: css`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -10px;
  `,
  inputNoteLabel: css`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -10px;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      display: none;
    }
  `,
  inputNoteLabelMobile: css`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -10px;
    display: none;
    @media (max-width: ${theme.breakpoints.videoEntry}px) {
      display: flex;
    }
  `,
  noteCard: css`
    display: none;
    position: absolute;
    height: 48px;
    top: 0;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
    color: ${theme.fontColor.text};
    div {
      background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
      color: ${theme.fontColor.text};
    }
    span {
      border-left: none;
      background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
      color: ${theme.fontColor.text};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      height: 60px;
    }
  `,
  card: css`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 60px 1fr 10px;
    width: 100%;
    font-size: 12px;
    align-items: flex-start;
    color: ${theme.colors.contentBackground};
    background-color: ${hexToRGBA("#000", 0.5)};
    transition: background-color 300ms linear;
    transition: background-color 300ms linear, border-left-color 300ms linear;
    border-radius: 4px;
    position: absolute;
    z-index: ${APP_LAYERS.overlays};
    top: 0;
    right: 0;

    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  timestamp: (color: string) => css`
    user-select: none;
    font-weight: 600;
    color: ${color ? color : theme.fontColor.googleBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  `,
  userNotes: css`
    border: none;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    height: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    transition: background-color 300ms linear;
    color: ${theme.colors.background};
    font-weight: 500;
  `,
  playerRow: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    padding-top: 5px;
  `,
  playerColumn: css`
    display: none;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    @media (min-width: ${theme.breakpoints.videoEntry}px) {
    display: flex;
  `,
  genericModal: css`
    > div {
      max-width: 650px;
    }
  `,
  tagIcon: css`
    color: ${theme.fontColor.text};
  `,
  tagViewerButton: (color = theme.palette.primary.main) => css`
    display: flex;
    flex-direction: row;
    height: 42px;
    align-items: center;
    gap: 10px;
    justify-content: center;
    border-radius: 200px;
    border: 1px solid ${color};
    color: ${theme.fontColor.text};
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    min-width: 175px;
    max-width: 350px;
  `,
  desktopPlayer: css`
    display: none;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.5)};
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
  mobilePlayer: (isPlayerHovered: boolean) => css`
    display: none;
    background-color: ${hexToRGBA(theme.colors.contentBackground, 0.95)};
    bottom: 0;
    transition: height 100ms 50ms, opacity 100ms 50ms, visibility 500ms;
    z-index: ${APP_LAYERS.overlays};
    @media (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      height: ${isPlayerHovered ? "100px" : "0"};
      opacity: ${isPlayerHovered ? 1 : 0};
    }
  `,
};

const opts = {
  youtube: {
    playerVars: {
      loop: true,
      origin: "https://www.youtube.com",
    },
    embedOptions: {
      host: "https://www.youtube-nocookie.com",
    },
  },
};

interface Props {
  entryFormState: EntryFormState;
  setEntryFormState: (formState: EntryFormState) => void;
  color: string;
}

export enum PLAYBACK_RATE_OPTIONS {
  PointTwoFive = 0.25,
  PointFive = 0.5,
  PointSevenFive = 0.75,
  One = 1,
  OnePointTwoFive = 1.25,
  OnePointFive = 1.5,
  OnePointSevenFive = 1.75,
  Two = 2,
}

const VideoNoteTaker: React.FC<Props> = ({ entryFormState, setEntryFormState, color = "#000000" }) => {
  const notesArray = useMemo(() => entryFormState.editedNotes || [], [entryFormState.editedNotes]);

  const setNotesArray = useCallback(
    (value: VideoNote[]) => {
      setEntryFormState({
        ...entryFormState,
        editedNotes: value,
      });
    },
    [entryFormState, setEntryFormState]
  );

  const url = entryFormState.editedVideoUrl;

  const [hashtagArray, setHashtagArray] = useState<string[] | undefined>(undefined);
  const [videoDuration, setVideoDuration] = useState(0);
  const [played, setPlayed] = useState(0);
  const [loadedPosition, setLoadedPosition] = useState(0);
  const [playedPosition, setPlayedPosition] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(PLAYBACK_RATE_OPTIONS.One);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [updatedNote, setUpdatedNote] = useState<number | undefined>(undefined);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [createdNote, setCreatedNote] = useState<VideoNote | undefined>(undefined);
  const [seeking, setSeeking] = useState(false);
  const [showTagViewer, setShowTagViewer] = useState(false);

  const toggleTagViewer = useCallback(() => {
    setShowTagViewer((prev) => !prev);
  }, []);
  const [playerWasClicked, setPlayerWasClicked] = useState(false);

  const playerWindow = useRef<HTMLDivElement | null>(null);
  const isPlayerHovered = useHoverOfRef<HTMLDivElement | null>(playerWindow);

  const showPlayerControls = useCallback(() => {
    return playerWasClicked || !isPlaying || isPlayerHovered;
  }, [isPlayerHovered, isPlaying, playerWasClicked]);

  const componentRef = useRef(null);
  const { width: playerWidth } = useContainerDimensions(componentRef);
  const player = useRef<ReactPlayer>(null);

  const convertToPosition = useCallback(
    (seconds: number) => (seconds / videoDuration) * playerWidth,
    [playerWidth, videoDuration]
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handlePlayerClick = useCallback(() => {
    setPlayerWasClicked(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setPlayerWasClicked(false);
    }, 2000);
  }, []);

  const handleOnReady = useCallback(() => {
    setErrorCode(null);
  }, []);

  const handlePause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const handlePlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  // const handleBuffer = useCallback(() => {
  //   setIsBuffering(true);
  // }, []);
  //
  // const handleBufferEnd = useCallback(() => {
  //   setIsBuffering(false);
  // }, []);
  //
  const getVideoDuration = useCallback(() => {
    //video length
    player.current && setVideoDuration(player.current.getDuration());
  }, [player]);

  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
    player?.current?.seekTo(0);
    setIsPlaying(true);
  }, [player]);

  const seekTo = useCallback(
    (seconds: number) => {
      setPlayedPosition(seconds / videoDuration);
      setPlayed(seconds);
      player?.current?.seekTo(seconds);
    },
    [player, videoDuration]
  );

  const handleProgress = useCallback(
    (e: any) => {
      setPlayed(e.playedSeconds);
      setLoadedPosition(e.loadedSeconds / videoDuration);
    },
    [videoDuration]
  );
  const handleTogglePlay = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isPlaying]);

  const handleError = useCallback((e: string) => {
    console.error(e);
    setErrorCode(e);
  }, []);

  const updateNotesArray = useCallback(() => {
    if (createdNote !== undefined) {
      const isBlank = createdNote.text.trim() === "";
      if (!isBlank) {
        const isNewValue = !notesArray?.some((note: VideoNote) => note?.timeInSeconds === createdNote?.timeInSeconds);
        isNewValue && setUpdatedNote(createdNote?.timeInSeconds);
        isNewValue &&
          setNotesArray(
            [
              ...notesArray,
              {
                timeInSeconds: createdNote?.timeInSeconds,
                text: createdNote.text,
              },
            ].sort(function (a, b) {
              return a?.timeInSeconds - b?.timeInSeconds;
            })
          );
        setCreatedNote({ timeInSeconds: Math.floor(played), text: "" }); //resets text box
        handlePlay();
      }
    }
  }, [notesArray, played, createdNote, handlePlay, setNotesArray]);

  const handleModalClose = useCallback(() => setErrorCode(null), []);

  const createNewDraftNote = useCallback(() => {
    setIsPlaying(false);
    createdNote && setCreatedNote({ timeInSeconds: Math.floor(played), text: createdNote.text });
  }, [played, createdNote]);

  const handleKeyPressOnDraftNote = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        if (!isPlaying) {
          updateNotesArray();
          event.preventDefault(); //prevents enter key from adding line break
          handlePlay();
        } else {
          event.preventDefault(); //prevents enter key from adding line break
          handlePause();
        }
      }
    },
    [updateNotesArray, handlePlay, handlePause, isPlaying]
  );
  const onURLTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntryFormState({ ...entryFormState, editedVideoUrl: e.target?.value || "" });
    },
    [entryFormState, setEntryFormState]
  );
  const renderNotes = useCallback(() => {
    if (notesArray) {
      return notesArray.map((note: VideoNote, index: number) => {
        const nextNote = notesArray?.[index + 1];
        const isCurrentNote = note?.timeInSeconds < played && (!nextNote || nextNote?.timeInSeconds > played);
        if (isCurrentNote) {
          return (
            <div
              key={note.timeInSeconds + "note"}
              css={styles.card}
            >
              <div
                css={styles.timestamp(color)}
                onClick={() => seekTo(note?.timeInSeconds)}
              >
                {formatPlayerTime(note?.timeInSeconds)}
              </div>
              <span css={styles.userNotes}>{note.text}</span>
            </div>
          );
        }
        return null;
      });
    }
  }, [color, notesArray, played, seekTo]);
  const handleOnChangeDraftNote = useCallback(
    (event: { target: { value: any } }) => {
      handlePause();
      const noteText = event.target.value;
      setCreatedNote({ timeInSeconds: Math.floor(played), text: noteText });
      setUpdatedNote(played);
    },
    [played, handlePause]
  );

  useEffect(() => {
    setPlayedPosition(played / videoDuration);
  }, [played, convertToPosition, videoDuration]);

  useEffect(() => {
    setUpdatedNote(undefined);
  }, [url]);

  return (
    <div css={styles.root}>
      <div css={styles.wrapper}>
        <div css={styles.playerRow}>
          <div css={styles.playerColumn}>
            <label
              css={styles.inputLabel}
              htmlFor="video-url"
            >
              Video URL
            </label>
            <input
              value={entryFormState.entry.videoUrl || entryFormState.editedVideoUrl || ""}
              placeholder="Video URL"
              id="video-url"
              disabled={!!entryFormState.entry.videoUrl}
              css={styles.urlInput(color)}
              onChange={onURLTextChange}
            />
          </div>
          <TextInput
            value={entryFormState.entry.videoUrl || entryFormState.editedVideoUrl || ""}
            placeholder={"Video URL"}
            id={"video url"}
            onChange={onURLTextChange}
            css={styles.urlInputMobile(color)}
          />
          <div
            onClick={toggleTagViewer}
            css={styles.tagViewerButton(color)}
          >
            <TagIcon css={styles.tagIcon} />
            <span>
              Video Tags
              {!entryFormState?.editedTagIds?.length && (
                <span
                  css={css`
                    color: red;
                  `}
                >
                  *
                </span>
              )}
              <span>
                {entryFormState?.editedTagIds?.length ? (
                  <span> ({entryFormState?.editedTagIds?.length})</span>
                ) : (
                  <span
                    css={css`
                      color: red;
                    `}
                  >
                    {" "}
                    ({entryFormState?.editedTagIds?.length})
                  </span>
                )}
              </span>
            </span>
          </div>
        </div>
        <div css={styles.container}>
          <div
            css={styles.wrapper}
            ref={playerWindow}
            onClick={handlePlayerClick}
            onTouchStart={handlePlayerClick}
          >
            <div
              css={styles.playerWindow}
              ref={componentRef}
            >
              {renderNotes()}
              <PlayerControls
                player={player}
                playerWidth={playerWidth}
                seeking={seeking}
                setSeeking={setSeeking}
                played={played}
                isMuted={isMuted}
                isPlaying={isPlaying}
                setIsMuted={setIsMuted}
                setIsPlaying={setIsPlaying}
                loadedPosition={loadedPosition}
                playedPosition={playedPosition}
                setPlayed={setPlayed}
                videoDuration={videoDuration}
                notesArray={notesArray}
                seekTo={seekTo}
                playBackRate={playbackRate}
                setPlaybackRate={setPlaybackRate}
                color={color}
                setEntryFormState={setEntryFormState}
                entryFormState={entryFormState}
                isOverlay={true}
                css={styles.mobilePlayer(showPlayerControls())}
              />
              <div
                css={styles.iframe}
                onClick={handleTogglePlay}
              >
                <ReactPlayer
                  ref={player}
                  width={"100%"}
                  height={"auto"}
                  className={"react-player"}
                  url={url}
                  playing={isPlaying}
                  onReady={handleOnReady}
                  // onBuffer={handleBuffer}
                  // onBufferEnd={handleBufferEnd}
                  onPause={handlePause}
                  onPlay={handlePlay}
                  onDuration={getVideoDuration}
                  onEnded={handleVideoEnd}
                  onSeek={(e) => console.log("onSeek", e)}
                  controls={false}
                  onError={(e) => handleError(e)}
                  onProgress={handleProgress}
                  progressInterval={250}
                  config={opts}
                  volume={undefined}
                  muted={isMuted}
                  css={{ backgroundColor: "#fff", borderRadius: "10px", pointerEvents: "none" }}
                  playbackRate={playbackRate}
                />
              </div>
              <PlayerControls
                player={player}
                playerWidth={playerWidth}
                seeking={seeking}
                setSeeking={setSeeking}
                played={played}
                isMuted={isMuted}
                isPlaying={isPlaying}
                setIsMuted={setIsMuted}
                setIsPlaying={setIsPlaying}
                loadedPosition={loadedPosition}
                playedPosition={playedPosition}
                setPlayed={setPlayed}
                videoDuration={videoDuration}
                notesArray={notesArray}
                seekTo={seekTo}
                playBackRate={playbackRate}
                setPlaybackRate={setPlaybackRate}
                color={color}
                setEntryFormState={setEntryFormState}
                entryFormState={entryFormState}
                css={styles.desktopPlayer}
              />
            </div>
          </div>
          <div css={styles.noteInputWrapper}>
            <label
              htmlFor="draftNote"
              css={styles.inputNoteLabelMobile}
            >
              Add a New Note
            </label>
            <textarea
              id="draftNoteMobile"
              onFocus={createNewDraftNote}
              onChange={handleOnChangeDraftNote}
              onKeyPress={handleKeyPressOnDraftNote}
              placeholder="Add a new note..."
              css={styles.noteInputMobile}
              value={createdNote?.text}
            />
            <Notepad
              player={player}
              notesArray={notesArray}
              setNotesArray={setNotesArray}
              hashtagArray={hashtagArray}
              setHashtagArray={setHashtagArray}
              videoDuration={videoDuration}
              setPlayed={setPlayed}
              setPlayedPosition={setPlayedPosition}
              setIsPlaying={setIsPlaying}
              updatedNote={updatedNote}
              setUpdatedNote={setUpdatedNote}
              handlePlay={handlePlay}
              entryFormState={entryFormState}
              setEntryFormState={setEntryFormState}
              color={color}
              played={played}
            />
            <label
              htmlFor="draftNote"
              css={styles.inputNoteLabel}
            >
              Add a New Note
            </label>
            <textarea
              id="draftNote"
              onFocus={createNewDraftNote}
              onChange={handleOnChangeDraftNote}
              onKeyPress={handleKeyPressOnDraftNote}
              placeholder="Add a new note..."
              css={styles.noteInput}
              value={createdNote?.text}
            />
            {(errorCode === "150" || errorCode === "101") && (
              <GenericModal
                onClose={handleModalClose}
                title={" Uh oh! I can't play this video."}
                onlyConfirmButton={true}
                confirmText={"Got it"}
                onConfirm={handleModalClose}
              >
                <div css={styles.modalBody}>
                  <span>If you're sure the video URL is correct, the privacy settings may be set to 'private'.</span>
                  <span>
                    Click{" "}
                    <a
                      href={"https://support.google.com/youtube/answer/157177?hl=en&co=GENIE.Platform%3DDesktop"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{" "}
                    for detailed instructions on updating privacy settings to "public" or "unlisted'.
                  </span>
                  <span>
                    You can go directly to your YouTube account{" "}
                    <a
                      href={"https://studio.youtube.com/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here.
                    </a>
                  </span>
                </div>
              </GenericModal>
            )}
            {showTagViewer ? (
              <GenericModal
                css={styles.genericModal}
                onClose={() => {
                  setShowTagViewer(false);
                  setEntryFormState({ ...entryFormState, editedTagIds: entryFormState.entry.tags || [] });
                }}
                title={"Add Tags"}
                onConfirm={() => setShowTagViewer(false)}
              >
                <TagViewer
                  editPost={true}
                  setEntryFormState={setEntryFormState}
                  entryFormState={entryFormState}
                />
              </GenericModal>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoNoteTaker;
