/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { getTagCount, getTagList } from './module';
import { EntryWithJournalId, JournalObject, TagType } from '../Utils/types';
import { hexToRGBA } from '../Utils/module';
import theme from '../Theme';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { useClickOutsideListener } from '../modal/hooks';
import { APP_LAYERS } from '../layers';

const styles = {
	tagRow: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	`,
	labelRow: (journalColor: string) => css`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 36px;
		border-radius: 6px;
		font-size: 14px;
		padding: 8px 12px;
		background-color: ${theme.colors.background};
		input[type='checkbox'] {
			accent-color: ${theme.palette.primary.main};
		}
		&:hover {
			background-color: ${hexToRGBA(journalColor, 0.4)};
		}
	`,
	filterWrapperWrapper: css`
		display: flex;
		flex: 1;
		position: relative;
		flex-direction: column;
		height: 38px;
		margin: 3px 0;
		max-width: 300px;
	`,
	filterWrapper: (isOpen: boolean) => css`
		border: solid 1px ${isOpen ? theme.palette.primary.main : theme.colors.border};
		border-radius: 400px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: rgba(0, 0, 0, 0.6);
		padding: 10px;
		flex: 1;
		height: 38px;
		font-size: 14px;
		white-space: nowrap;
		color: ${theme.palette.toolbars.fontColorPrimary};
	`,
	expandIcon: (isOpen: boolean) => css`
		color: ${theme.fontColor.text};
		transform: rotate(${!isOpen ? '-90deg' : '90deg'});
		transition: all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		user-select: none;
	`,
	selector: (isOpen: boolean) => css`
		opacity: ${!isOpen ? 0 : 1};
		position: absolute;
		top: 40px;
		background-color: ${theme.colors.contentBackground};
		overflow-y: scroll;
		border-radius: 12px;
		padding: 8px 16px;
		z-index: ${APP_LAYERS.overlays};
		height: ${!isOpen ? '0' : '350px'};
		right: 0;
		width: 262px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px;
		visibility: ${!isOpen ? 'hidden' : 'visible'};
		transition: ${!isOpen
			? 'opacity 300ms, height 500ms, visibility 500ms'
			: 'height 500ms , opacity 300ms  100ms, visibility 500ms'};
	`,
};

interface Props {
	setFilteredEntries: (entries: EntryWithJournalId[]) => void;
	filteredEntries: EntryWithJournalId[];
	tagList: TagType[];
	entriesToFilter: EntryWithJournalId[];
	journal?: JournalObject;
	className?: string;
}

const EntryFilter: React.FC<Props> = (props) => {
	const { filteredEntries, setFilteredEntries, entriesToFilter, tagList, journal, className = '' } = props;
	const journalColor = journal ? journal.color : theme.palette.primary.main;

	const tagSummary = useMemo(() => getTagList(entriesToFilter, tagList || []), [tagList, entriesToFilter]);

	const [isUnfiltered, setIsUnfiltered] = useState(true);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [filterCount, setFilterCount] = useState<number | string>(0);
	const [filteredState, setFilteredState] = useState<string[]>([]);
	const childrenRef = useRef<HTMLDivElement>(null);

	const handleRemoveAllFilters = useCallback(() => {
		if (!isUnfiltered) {
			setFilteredState([]);
			setIsUnfiltered(true);
		}
	}, [isUnfiltered]);

	const handleFilterEntries = useCallback(
		(tagId: string) => {
			setIsUnfiltered(false);
			const isAlreadyChecked = filteredState.includes(tagId);
			if (isAlreadyChecked) return setFilteredState(filteredState.filter((filter) => filter !== tagId));
			return setFilteredState([...filteredState, tagId]);
		},
		[filteredState]
	);

	const handleToggleFilter = useCallback(() => {
		setFilterIsOpen((prev) => !prev);
	}, []);

	const handleCloseFilter = useCallback(() => {
		setFilterIsOpen(false);
	}, []);

	const getFilterCount = useCallback(() => {
		let testFilterCount: string | number = 0;
		filteredState.forEach((state) => {
			if (state) testFilterCount = Number(testFilterCount) + 1;
		});
		if (testFilterCount === 0) {
			testFilterCount = 'All';
		}
		return testFilterCount;
	}, [filteredState]);

	useClickOutsideListener(childrenRef, handleCloseFilter);

	useEffect(() => {
		if (!filteredState.length) {
			setIsUnfiltered(true);
		}
	}, [filteredState, entriesToFilter]);

	useEffect(() => {
		if (isUnfiltered && filteredEntries.length !== entriesToFilter.length) {
			return setFilteredEntries(entriesToFilter);
		}
	}, [entriesToFilter, filteredEntries.length, isUnfiltered, setFilteredEntries]);

	useEffect(() => {
		if (!entriesToFilter.length) {
			setFilteredEntries([]);
			setFilterCount(getFilterCount());
			return;
		}
		const tempEntryList: EntryWithJournalId[] = [];
		if (!filteredState.length) {
			setFilteredEntries(entriesToFilter);
			setFilterCount(getFilterCount());
			return;
		}
		entriesToFilter.forEach((entry) =>
			filteredState.forEach((tagId) => {
				if (entry.journalEntry.tags?.includes(tagId)) {
					return tempEntryList.push(entry);
				}
			})
		);
		setFilteredEntries(tempEntryList);
		setFilterCount(getFilterCount());
	}, [entriesToFilter, filteredState, getFilterCount, setFilteredEntries]);

	return (
		<div
			className={className}
			css={styles.filterWrapperWrapper}
			ref={childrenRef}
		>
			<div
				css={styles.filterWrapper(filterIsOpen)}
				onClick={handleToggleFilter}
			>
				<div>
					<span>Filter Entries By Tags</span>
					<span> ({filterCount})</span>
				</div>
				<ChevronRightIcon css={styles.expandIcon(filterIsOpen)} />
			</div>
			<div css={styles.selector(filterIsOpen)}>
				<label css={styles.labelRow(journalColor)}>
					<input
						type='checkbox'
						id={'showAll'}
						checked={isUnfiltered}
						onChange={handleRemoveAllFilters}
					/>
					<div css={styles.tagRow}>
						<span>Show All</span>
						<span>{getTagCount(tagSummary)}</span>
					</div>
				</label>
				{tagSummary.map((tag) => (
					<label
						key={tag.id}
						css={styles.labelRow(journalColor)}
					>
						<input
							type='checkbox'
							id={tag.id}
							checked={filteredState.includes(tag.id) || false}
							onChange={() => handleFilterEntries(tag.id)}
						/>
						<div css={styles.tagRow}>
							<span>{tag.name}</span>
							<span>{tag.count}</span>
						</div>
					</label>
				))}
			</div>
		</div>
	);
};

export default EntryFilter;
