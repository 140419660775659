import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  is500Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { GOOGLE_ENDPOINTS } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { YouTubeChannelListResponse } from "../module";

const KEY_ACTION = ActionType.Google_GetChannelRequest;

interface GetYoutubeChannelActionProps {
  requestId: string;
}

const ACTIONS = [ActionType.Google_GetChannelRequest, ActionType.Google_GetChannelResponse, ActionType.Google_GetChannelError];

type Payload = null | YouTubeChannelListResponse;

export const Google_getYoutubeChannelAction = (body: GetYoutubeChannelActionProps) =>
  makeApiAction<Payload, GetYoutubeChannelActionProps>({
    method: "GET",
    endpoint: () => GOOGLE_ENDPOINTS.GetYouTubeChannel,
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.user?.googleApi?.accessToken}`,
      Accept: "application/json",
    }),
    types: makeRsaaTypes<Payload, GetYoutubeChannelActionProps>(ACTIONS, body),
    bailout: (state) =>
      !!getLoading(state, KEY_ACTION, body.requestId) ||
      !!getLoading(state, ActionType.Google_RefreshAccessTokenRequest) ||
      !!getLoading(state, ActionType.RefreshTokenRequest, body.requestId) ||
      state.getGoogleOauth,
  });

const reducer: ApiReducer<Payload, GetYoutubeChannelActionProps> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION, action.meta.requestId);
  }

  if (is401Response(action)) {
    removeLoading(state, KEY_ACTION, action.meta.requestId);
  }

  if (is500Response(action)) {
    removeLoading(state, KEY_ACTION, action.meta.requestId);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg, action.meta.requestId);
  }

  if (!payload) {
    return addError(state, KEY_ACTION, ["get channel failure 2"], action.meta.requestId);
  }

  const successState: AppState = noLoadingNoError(state, KEY_ACTION, action.meta.requestId);

  return {
    ...successState,
    google: {
      ...state.google,
      youTube: {
        ...state.google?.youTube,
        channelList: payload,
      },
    },
  };
};

export const Google_getYoutubeChannelsReducer = makeApiReducer<Payload, GetYoutubeChannelActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "failed to get youtube channels"
);

export const useYouTubeChannels = (): ApiHookReturn<YouTubeChannelListResponse> => {
  const dispatch = useDispatch();
  const state = useAppState((state) => state);

  const requestId = "google_getChannels_" + state.auth?.id;
  const refreshRequestId = "google_refreshAccessToken_" + state.auth?.id;
  const googleAccessToken = state.user?.googleApi?.accessToken;
  const channelList = state.google?.youTube.channelList;
  const gettingOauth = state.getGoogleOauth;

  const getChannels = useCallback(() => {
    dispatch(Google_getYoutubeChannelAction({ requestId }));
  }, [dispatch, requestId]);

  const loading = useLoading(KEY_ACTION, requestId);
  const isRefreshingToken = useLoading(ActionType.Google_RefreshAccessTokenRequest, refreshRequestId);
  const errors = useErrors(KEY_ACTION, requestId);

  useEffect(() => {
    if (!channelList && !loading && !errors && !isRefreshingToken && !gettingOauth && googleAccessToken) {
      getChannels();
    }
  }, [loading, errors, channelList, getChannels, googleAccessToken, isRefreshingToken, gettingOauth]);

  return {
    loading,
    errors,
    value: channelList,
    reload: getChannels,
  };
};
