import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { ENDPOINTS } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { useCallback, useEffect, useMemo } from "react";
import { useAppState, useErrors, useLoading } from "../../store/hooks";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";
import { UserAnalysis } from "../../VideoAnalysis/module";
import { isSameDay } from "date-fns";

const KEY_ACTION = ActionType.GetUserAnalysisRequest;

const ACTIONS = [ActionType.GetUserAnalysisRequest, ActionType.GetUserAnalysisResponse, ActionType.GetUserAnalysisError];

interface Payload extends Response {
  data?: null | {
    userAnalysis: UserAnalysis;
  };
}

export interface GetUserAnalysisActionProps {
  startDateTimeUtc: string;
  endDateTimeUtc: string;
}

export const getUserAnalysisAction = (body: GetUserAnalysisActionProps) =>
  makeApiAction<Payload, GetUserAnalysisActionProps>({
    method: "GET",
    endpoint: () =>
      ENDPOINTS.GetUserAnalysis + "?startDateTimeUtc=" + body.startDateTimeUtc + "&endDateTimeUtc=" + body.endDateTimeUtc,
    headers: (state: AppState) => ({
      Authorization: `Bearer ${state.auth?.accessToken}`,
      "content-type": "application/json",
    }),
    types: makeRsaaTypes<Payload, GetUserAnalysisActionProps>(ACTIONS, body),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ["get rounds failure"]);
  }

  const successState: AppState = noLoadingNoError(state, KEY_ACTION);

  return {
    ...successState,
    userAnalysis: successState.userAnalysis
      ? [...successState.userAnalysis, { ...payload.data.userAnalysis }]
      : [{ ...payload.data.userAnalysis }],
  };
};

export const getUserAnalysisReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to fetch userAnalysis. Please try again."
);

export const useGetUserAnalysis = (dates: GetUserAnalysisActionProps): ApiHookReturn<UserAnalysis> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const getUserAnalysis = useCallback(
    async () => await validateAndDispatch(getUserAnalysisAction(dates)),
    [validateAndDispatch, dates]
  );

  const refreshingToken = useLoading(ActionType.RefreshTokenRequest, "refreshAccessToken");
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);

  const currentAnalysisInState = useAppState((state) => state.userAnalysis);

  const analysisToGet: GetUserAnalysisActionProps[] = useMemo(() => [], []);

  const getRequestedAnalysis = useCallback(() => {
    const requestedAnalysis: UserAnalysis[] = [];

    const analysisInState = currentAnalysisInState?.find(
      (stateAnalysis) =>
        isSameDay(new Date(stateAnalysis.startDateTimeUtc), new Date(dates.startDateTimeUtc)) &&
        isSameDay(new Date(stateAnalysis.endDateTimeUtc), new Date(dates.endDateTimeUtc))
    );

    if (analysisInState) {
      requestedAnalysis.push(analysisInState);
    } else {
      if (
        !analysisToGet?.find(
          (stateAnalysis) =>
            isSameDay(new Date(stateAnalysis.startDateTimeUtc), new Date(dates.startDateTimeUtc)) &&
            isSameDay(new Date(stateAnalysis.endDateTimeUtc), new Date(dates.endDateTimeUtc))
        )
      ) {
        analysisToGet.push(dates);
      }
    }

    return requestedAnalysis[0];
  }, [currentAnalysisInState, dates, analysisToGet]);

  useEffect(() => {
    if (!!analysisToGet.length && !loading && !errors && !refreshingToken) {
      getUserAnalysis()
        .catch((error) => console.error(error))
        .finally(() => analysisToGet.splice(0, analysisToGet.length));
    }
  }, [errors, getUserAnalysis, loading, analysisToGet, refreshingToken]);

  return {
    loading,
    errors,
    value: getRequestedAnalysis(),
    reload: getUserAnalysis,
  };
};
