/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import theme from "../../Theme";
import { css } from "@emotion/react";
import { ENTRY_TYPE, EntryFormState, EntryWithJournalId, JournalEntryObject, JournalObject } from "../../Utils/types";
import { useJournals } from "../Actions/getJournalsAction";
import { ApiHookReturn } from "../../store/module";
import { DateTime } from "luxon";
import { DATE_FORMAT, serialize, toEditedEntryObject } from "../module";
import { usePutEntry } from "../Actions/putEntryAction";
import EntryEditor from "../EntryEditor";
import EditIcon from "../../icons/EditIcon";
import { useDeleteEntry } from "../Actions/deleteEntryAction";
import { BASE_ROUTES } from "../../Navigation/routesModule";
import RichTextViewer from "../../Utils/RichText/RichTextViewer";
import GenericModal from "../../modal/GenericModal";
import Loader from "../../Utils/Loader";
import TagViewer from "../../Tags/TagViewer";
import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import MoveItemIcon from "../../icons/MoveItemIcon";
import MoveEntryModal from "../MoveEntryModal";
import { hexToRGBA } from "../../Utils/module";
import { usePostEntry } from "../Actions/postEntryAction";
import { RICH_TEXT_INITIAL_VALUE } from "../../Utils/RichText/richTextModule";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
  `,
  entryHeaderWrapper: (color?: string) => css`
    color: ${color ? color : theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    text-align: center;
    font-weight: bold;
    gap: 20px;
  `,
  topButtonRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 20px;
    }
  `,
  headerRight: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding-right: 20px;
  `,
  editButtonWrapper: (color?: string) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: semibold;
    gap: 6px;
    cursor: pointer;
    color: ${theme.colors.contentBackground};
    border-radius: 200px;
    background-color: ${color ? color : theme.palette.primary.main};
    &:hover {
      background-color: ${hexToRGBA(color ? color : theme.palette.primary.main, 0.75)};
    }
  `,
  journalName: css`
    color: ${theme.fontColor.text};
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-self: center;
    justify-self: center;
  `,
  backIcon: (color?: string) => css`
    color: ${color ? color : theme.palette.primary.main};
    display: flex;
    align-self: center;
    justify-self: center;
  `,
  entryWrapper: css`
    width: 100%;
    align-self: center;
    padding: 32px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${theme.colors.contentBackground};
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  bottomBar: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: ${theme.colors.contentBackground};
    justify-content: space-between;
  `,
  date: css`
    font-size: 16px;
    color: ${theme.colors.contentBackground};
    white-space: nowrap;
  `,
  journalColorButton: css`
    align-self: center;
    background-color: ${theme.palette.primary.main};
    color: ${theme.colors.contentBackground};
    cursor: pointer;
    padding: 6px 20px;
    font-size: 16px;
    border-radius: 200px;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  `,
  cancelButton: css`
    align-self: center;
    color: ${theme.fontColor.text};
    background-color: ${theme.palette.toolbars.hover};
    cursor: pointer;
    padding: 6px 14px;
    font-size: 16px;
    border-radius: 200px;
    &:hover {
      background-color: ${theme.colors.lightestGray};
    }
  `,
  entryInfoWrapper: (color: string = "black") => css`
    margin-bottom: 10px;
    padding: 24px;
    background-color: ${color};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  `,
  entry: css`
    color: ${theme.fontColor.text};
    padding: 15px 5px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    overflow-x: hidden;
    width: 100%;
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `,
  textEntry: (color: string = "black") => css`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 40px;
    background-color: ${color};
    color: ${theme.colors.contentBackground};
    text-align: left;
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 24px;
    }
  `,
  titleWrapper: css`
    width: 100%;
    justify-content: left;
    background-color: ${theme.colors.contentBackground};
    position: relative;
  `,
  modalBody: css`
    display: flex;
    width: 100%;
    padding: 5px;
  `,
  actionIcon: css`
    color: ${theme.colors.contentBackground};
  `,
  link: (color?: string) => css`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    background-color: ${theme.colors.contentBackground};
    border-radius: 200px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${color ? color : theme.palette.primary.main};
    &:hover {
      background-color: ${hexToRGBA(color ? color : theme.palette.primary.main, 0.1)};
    }
  `,
  deleteButton: (color: string) => css`
    padding: 10px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
    color: ${color ? color : theme.colors.cancel};
    font-weight: 600;
    font-size: 14px;
    background-color: ${theme.palette.toolbars.hover};
    padding: 6px 14px;
    border-radius: 200px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    &:hover {
      background-color: ${theme.colors.cancel};
      color: ${theme.colors.contentBackground};
    }
  `,
  editor: (color: string = "lightgray") => css`
    border: solid 1px ${color};
    align-self: center;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  moveIcon: css`
    color: ${theme.fontColor.text};
    font-size: 18px;
  `,
  moveButtonLargeScreen: css`
    display: flex;
    flex-direction: row;
    color: ${theme.fontColor.text};
    background-color: ${theme.colors.contentBackground};
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 200px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    white-space: nowrap;
    &:hover {
      background-color: ${theme.colors.lightGrayHover};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  moveButtonSmallScreen: css`
    display: flex;
    flex-direction: row;
    color: ${theme.fontColor.text};
    background-color: ${theme.colors.contentBackground};
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 200px;
    font-size: 14px;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    gap: 6px;
    white-space: nowrap;
    width: 138px;
    &:hover {
      background-color: ${theme.colors.lightGrayHover};
    }
    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  headerStatRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    width: 100%;
    gap: 15px;
  `,
  showOnBigScreen: css`
    display: none;
    @media (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
    }
  `,
  showOnSmallScreen: css`
    display: flex;
    @media (min-width: ${theme.breakpoints.lg}px) {
      display: none;
    }
  `,
};
interface Props {
  setUpdatedURL?: (newURL: string) => void;
  newEntry?: boolean;
  handleCreateCancel?: () => void;
  selectedJournal?: JournalObject;
}
const TextEntryViewer: React.FC<Props> = ({ setUpdatedURL, newEntry, handleCreateCancel, selectedJournal }) => {
  const { entryId, journalId } = useParams<{ entryId: string; journalId: string }>();
  const journals: ApiHookReturn<JournalObject[]> = useJournals();

  const history = useHistory();

  const journal: JournalObject | undefined = selectedJournal
    ? selectedJournal
    : journals.value && JSON.parse(JSON.stringify(journals.value.find((journal) => journal.id === journalId)));

  const entry: JournalEntryObject | undefined =
    journal?.entries && JSON.parse(JSON.stringify(journal.entries?.find((entry) => entry.journalEntryId === entryId) || {}));

  const entryDate = useMemo(() => entry && entry.entryDateTimeUtc && DateTime.fromISO(entry.entryDateTimeUtc), [entry]);
  const entryDateFormatted = useMemo(() => entryDate && entryDate.toFormat(DATE_FORMAT.hugeDate), [entryDate]);

  const [showBlankTitleModal, setShowBlankTitleModal] = useState(false);
  const [editPostSelected, setEditPostSelected] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [hasEntry, setHasEntry] = useState(false);
  const [entryFormState, setEntryFormState] = useState<EntryFormState>({
    editedTitle: entry?.title || "",
    editedText: entry?.text || "",
    editedEntryDate: entryDate || DateTime.now(),
    entry: entry || {
      journalEntryId: "",
      title: "",
      text: "",
      createdDateTimeUtc: "",
      entryDateTimeUtc: "",
      videoUrl: "",
      type: 0,
      notes: [],
      tags: [],
    },
    editedTagIds: entry?.tags || [],
    type: entry?.type || ENTRY_TYPE.Text,
    editedVideoUrl: entry?.videoUrl || "",
    editedNotes: entry?.notes ? [...entry?.notes] : [],
  });
  const [showMoveEntryModal, setShowMoveEntryModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<EntryWithJournalId | undefined>(undefined);

  const editsWereMade = useMemo(() => {
    if (entry) {
      const titleChanged = entryFormState.editedTitle !== entry.title;
      const dateChanged = !entryFormState.editedEntryDate.hasSame(DateTime.fromISO(entry.entryDateTimeUtc), "minute");
      const textChanged = entryFormState.editedText !== entry.text;
      const tagsChanged = JSON.stringify(entryFormState.editedTagIds) !== JSON.stringify(entry.tags);
      const videoUrlChanged = entryFormState.editedVideoUrl !== entry.videoUrl;
      const videoNotesChanged = JSON.stringify(entryFormState.editedNotes) !== JSON.stringify(entry?.notes);
      return titleChanged || dateChanged || textChanged || tagsChanged || videoUrlChanged || videoNotesChanged;
    }
    return false;
  }, [entry, entryFormState]);

  const putEntry = usePutEntry(journal?.id || "", toEditedEntryObject(entryFormState));
  const deleteEntry = useDeleteEntry(journal?.id || "", entryId);

  const setDefaultEntryFormState = useCallback(() => {
    setEntryFormState({
      editedText: entry?.text || "",
      editedTitle: entry?.title || "",
      editedEntryDate: entryDate || DateTime.now(),
      editedTagIds: entry?.tags || [],
      entry: entry || {
        journalEntryId: "",
        title: "",
        text: "",
        createdDateTimeUtc: "",
        entryDateTimeUtc: "",
        videoUrl: "",
        type: 0,
        notes: [],
        tags: [],
      },
      type: entry?.type || ENTRY_TYPE.Text,
      editedVideoUrl: entry?.videoUrl || "",
      editedNotes: entry?.notes ? [...entry?.notes] : [],
    });
  }, [entry, entryDate]);

  const handleEditClick = useCallback(() => {
    setEditPostSelected(!editPostSelected);
  }, [editPostSelected]);

  const postEntry = usePostEntry({
    journalId: journal?.id || "",
    text: entryFormState.editedText,
    entryDateTimeUtc: entryFormState.editedEntryDate.toUTC().toString(),
    title: entryFormState.editedTitle /*|| entryText.substring(0, 50)+"..."*/,
    tags: entryFormState.editedTagIds,
    type: entryFormState.type,
    videoUrl: entryFormState.editedVideoUrl,
    notes: entryFormState.editedNotes,
    excludeFromCksInstructionals: false,
  });

  const handleSaveNewEntry = useCallback(async () => {
    if (entryFormState.type === ENTRY_TYPE.Text) {
      if (entryFormState.editedText === JSON.stringify(RICH_TEXT_INITIAL_VALUE)) {
        return alert("Entry cannot be blank"); //todo make modal
      }
      if (
        entryFormState.editedText.length &&
        entryFormState.editedTitle.length &&
        entryFormState.editedEntryDate &&
        postEntry.value
      ) {
        await postEntry.value().then((res) => {
          if (!res?.error) {
            return handleCreateCancel?.();
          }
          alert("There was an error. Please try again.");
        });
      } else {
        setShowBlankTitleModal(true);
      }
    } else {
      if (!entryFormState.editedVideoUrl) {
        return alert("URL cannot be blank.");
      }
      if (!entryFormState.editedTitle) {
        return alert("Please add a title to this entry.");
      }
      if (!entryFormState.editedTagIds?.length) {
        return alert("Please add at least one tag.");
      }
      if (entryFormState.editedTitle.length && postEntry.value) {
        await postEntry.value().then((res) => {
          if (!res?.error) {
            return handleCreateCancel?.();
          }
          alert("There was an error. Please try again.");
        });
      }
    }
  }, [entryFormState, postEntry, handleCreateCancel]);

  const handleSaveUpdates = useCallback(async () => {
    const hasTitle = !!entryFormState.editedTitle.length;
    const hasDate = !!entryFormState.editedEntryDate;
    const needsSerialized = entryFormState.editedText.trim()[0] === "[";
    const editedText = needsSerialized ? serialize(entryFormState.editedText).trim() : entryFormState.editedText.trim();
    const isBlankEntry = entryFormState.editedText.length <= 50;
    if ((editedText || !isBlankEntry) && hasTitle && hasDate) {
      if (editsWereMade) {
        await putEntry.value().then((res) => {
          if (!res?.error) {
            setEditPostSelected(false);
            return setHasEntry(false);
          }
          alert("There was an error. Please try again.");
        });
      } else {
        setEditPostSelected(false);
      }
    } else {
      setShowBlankTitleModal(true);
    }
  }, [entryFormState, putEntry, editsWereMade]);

  const handleDelete = useCallback(async () => {
    if (journalId && entryId && deleteEntry.value) {
      await deleteEntry.value().then((res) => {
        if (!res?.error) {
          setEditPostSelected(false);
          return history.push(BASE_ROUTES.journal + "/" + journalId);
        }
        setShowDeleteConfirm(false);
        alert("There was an error. Please try again.");
      });
    }
  }, [journalId, entryId, deleteEntry, history]);

  const handlePostSelectCancel = useCallback(() => {
    setEditPostSelected(false);
    setShowDeleteConfirm(false);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const handleShowDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(true);
  }, [setShowDeleteConfirm]);

  const handleModalClose = useCallback(() => {
    setShowBlankTitleModal(false);
  }, []);

  const handleCloseMoveEntryModal = useCallback(() => {
    setShowMoveEntryModal(false);
  }, []);

  const handleEntryMove = useCallback((entry: EntryWithJournalId, event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedEntry(entry);
    setShowMoveEntryModal(true);
  }, []);

  useEffect(() => {
    //once entry loads, sets it to state one time
    !hasEntry && entry && setDefaultEntryFormState();
    entry && setHasEntry(true);
  }, [entry, hasEntry, setDefaultEntryFormState]);

  useEffect(() => {
    putEntry.loading && setHasEntry(false);
  }, [putEntry]);

  if (deleteEntry.loading) {
    return <Loader message={"Deleting..."} />;
  }
  if (!journal) {
    return <Loader message={"Fetching Journal..."} />;
  }
  if (!entry) {
    return <span>Error: could not locate entry. Please try again later.</span>;
  }

  return (
    <div css={styles.root}>
      {postEntry.loading && <Loader message={"Saving Entry"} />}
      <div css={styles.entryHeaderWrapper(theme.palette.primary.main)}>
        <div css={styles.topButtonRow}>
          {newEntry ? (
            <button
              css={styles.link(journal.color)}
              onClick={handleCreateCancel}
            >
              <ChevronLeftIcon css={styles.backIcon(journal?.color)} />
              <span css={styles.journalName}>{journal?.name}</span>
            </button>
          ) : (
            <Link
              css={styles.link(journal.color)}
              to={{
                pathname: `${BASE_ROUTES.journal + "/" + journalId}`,
              }}
            >
              <ChevronLeftIcon css={styles.backIcon(journal?.color)} />
              <span css={styles.journalName}>{journal?.name}</span>
            </Link>
          )}
          {!editPostSelected && !newEntry ? (
            <div
              css={styles.editButtonWrapper(journal?.color)}
              onClick={handleEditClick}
            >
              <span>Edit Entry</span>
              <EditIcon css={styles.actionIcon} />
            </div>
          ) : newEntry ? (
            <div css={styles.headerRight}>
              <span
                css={styles.cancelButton}
                onClick={handleCreateCancel}
              >
                {" "}
                Cancel{" "}
              </span>
              <span
                css={styles.journalColorButton}
                onClick={handleSaveNewEntry}
              >
                Save
              </span>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {editPostSelected || newEntry ? (
        <div css={styles.editor(journal?.color)}>
          {!showDeleteConfirm ? null : (
            <GenericModal
              onClose={handleDeleteCancel}
              onConfirm={handleDelete}
              title="Warning!"
              confirmText="Delete"
              isWarning={true}
            >
              <span css={styles.modalBody}>Are you sure you want to delete this entry?</span>
            </GenericModal>
          )}
          <EntryEditor
            entryFormState={entryFormState}
            setEntryFormState={setEntryFormState}
            journalColor={journal?.color}
          />
        </div>
      ) : (
        <div css={styles.entryWrapper}>
          <div css={styles.entryInfoWrapper(journal?.color)}>
            <div css={styles.titleWrapper}>
              <div css={styles.textEntry(journal?.color)}>
                <span>{entry.title}</span>
              </div>
            </div>
            <div css={styles.headerStatRow}>
              <span css={styles.date}>{entryDateFormatted}</span>
              <TagViewer
                setEntryFormState={setEntryFormState}
                entryFormState={entryFormState}
                editPost={editPostSelected}
                journalColor={theme.colors.contentBackground}
                css={styles.showOnBigScreen}
              />
              <div
                css={styles.moveButtonLargeScreen}
                onClick={(event) =>
                  handleEntryMove(
                    {
                      journalId: journal.id,
                      journalEntry: entry,
                    },
                    event
                  )
                }
              >
                <span>Move Entry</span>
                <MoveItemIcon css={styles.moveIcon} />
              </div>
            </div>
            <TagViewer
              setEntryFormState={setEntryFormState}
              entryFormState={entryFormState}
              editPost={editPostSelected}
              journalColor={theme.colors.contentBackground}
              css={styles.showOnSmallScreen}
            />
            <div
              css={styles.moveButtonSmallScreen}
              onClick={(event) =>
                handleEntryMove(
                  {
                    journalId: journal.id,
                    journalEntry: entry,
                  },
                  event
                )
              }
            >
              <span>Move Entry</span>
              <MoveItemIcon css={styles.moveIcon} />
            </div>
          </div>
          <div css={styles.entry}>
            <RichTextViewer entryText={entry.text} />
          </div>
        </div>
      )}
      {showBlankTitleModal && (
        <GenericModal
          onClose={handleModalClose}
          title={"Warning!"}
          onlyConfirmButton={true}
          confirmText={"Got it"}
          onConfirm={handleModalClose}
        >
          <span>Entry, title, and date are required fields.</span>
        </GenericModal>
      )}
      {showMoveEntryModal && selectedEntry && setUpdatedURL && (
        <MoveEntryModal
          entry={selectedEntry}
          handleCloseModal={handleCloseMoveEntryModal}
          setNewURL={setUpdatedURL}
        />
      )}
      {editPostSelected && (
        <div css={styles.bottomBar}>
          <div
            onClick={handleShowDeleteConfirm}
            css={styles.deleteButton(journal?.color)}
          >
            <span>Delete this entry</span>
          </div>
          <div css={styles.headerRight}>
            <span
              css={styles.cancelButton}
              onClick={handlePostSelectCancel}
            >
              {" "}
              Cancel{" "}
            </span>
            <span
              css={styles.journalColorButton}
              onClick={handleSaveUpdates}
            >
              Save
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEntryViewer;
