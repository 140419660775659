import { ActionType } from '../../store/actionType';
import {
	addError,
	addLoading,
	ApiReducer,
	getLoading,
	is401Response,
	makeApiAction,
	makeApiReducer,
	makeRsaaTypes,
	noLoadingNoError,
	removeLoading,
	Response,
} from '../../store/module';
import { useCallback } from 'react';
import { useAppState, useErrors, useLoading } from '../../store/hooks';
import { PUT_JOURNAL_ENTRY_ENDPOINT } from '../../store/endpoints';
import { AppState } from '../../store/appState';
import { JournalEntryObject } from '../../Utils/types';
import { useValidateAndDispatch } from '../../login/Actions/setAuthAction';

export interface PutEntryActionProps {
	journalEntry: JournalEntryObject;
	journalId: string;
}

const KEY_ACTION = ActionType.PutEntryRequest;
const ACTIONS = [ActionType.PutEntryRequest, ActionType.PutEntryResponse, ActionType.PutEntryError];

export interface PutEntryPayload extends Response {
	data?: null | {
		journalEntry?: null | JournalEntryObject;
	};
}

export const putEntryAction = (body: PutEntryActionProps, token?: string) =>
	makeApiAction<PutEntryPayload, PutEntryActionProps>({
		method: 'PUT',
		endpoint: () => PUT_JOURNAL_ENTRY_ENDPOINT(body.journalId, body.journalEntry.journalEntryId),
		headers: () => ({
			'content-type': 'application/json',
			Authorization: `Bearer ${token}`,
		}),
		body: JSON.stringify(body),
		types: makeRsaaTypes<PutEntryPayload, PutEntryActionProps>(ACTIONS, body),
		bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
	});

const reducer: ApiReducer<PutEntryPayload, PutEntryActionProps> = (state, action, errorMsg, isLoading, payload) => {
	if (isLoading) {
		return addLoading(state, KEY_ACTION);
	}

	if (is401Response(action)) {
		return removeLoading(state, KEY_ACTION);
	}

	if (errorMsg) {
		return addError(state, KEY_ACTION, errorMsg);
	}

	if (!payload?.data?.journalEntry) {
		return addError(state, KEY_ACTION, ['PUT Entry failure']);
	}

	const successState = noLoadingNoError(state, KEY_ACTION);

	return {
		...successState,
		userJournals: undefined,
	};
};

export const putEntryReducer = makeApiReducer<PutEntryPayload, PutEntryActionProps>(
	ACTIONS,
	KEY_ACTION,
	reducer,
	'Failed to update entry.'
);

export const usePutEntry = (journalId: string, entry: JournalEntryObject) => {
	const token = useAppState((state: AppState) => state.auth?.accessToken);
	const validateAndDispatchHook = useValidateAndDispatch();
	const validateAndDispatch = validateAndDispatchHook.value;

	const putEntry = useCallback(
		async () =>
			await validateAndDispatch(
				putEntryAction(
					{
						journalId,
						journalEntry: entry,
					},
					token
				)
			),
		[journalId, entry, validateAndDispatch, token]
	);

	const loading = useLoading(KEY_ACTION);
	const errors = useErrors(KEY_ACTION);

	return {
		loading,
		errors,
		value: putEntry,
		reload: putEntry,
	};
};
