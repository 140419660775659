/** @jsxImportSource @emotion/react */ //include this in all jsx files
import { css } from "@emotion/react";
import React, { useCallback } from "react";
import { StyleTypes } from "../Theme";
import { ICON_VARIANTS, IconTypes } from "../Utils/types";

const styles = {
  icon: css`
    justify-self: center;
    align-self: center;
    cursor: pointer;
  `,
  iconWrapper: css`
    display: flex;
  `,
};

interface Props extends IconTypes {
  icon: string;
  className?: StyleTypes;
}

const CKSIcon: React.FC<Props> = ({ variant, onClick, className, icon, isSymbol = false }) => {
  const variantClass = useCallback(() => {
    const iconOrSymbol = isSymbol ? "-symbols" : "-icons";
    switch (variant) {
      case ICON_VARIANTS.outlined:
        return "material" + iconOrSymbol + "-outlined";
      case ICON_VARIANTS.filled:
        return "material" + iconOrSymbol;
      case ICON_VARIANTS.rounded:
        return "material" + iconOrSymbol + "-round";
      case ICON_VARIANTS.sharp:
        return "material" + iconOrSymbol + "-sharp";
      case ICON_VARIANTS.twoTone:
        return "material" + iconOrSymbol + "-two-tone";
      default:
        return "material" + iconOrSymbol + (isSymbol ? "-outlined" : "");
    }
  }, [variant, isSymbol]);

  return (
    <div
      css={styles.iconWrapper}
      onClick={onClick}
    >
      <span
        className={variantClass() + " " + className}
        css={styles.icon}
      >
        {icon}
      </span>
    </div>
  );
};
export default CKSIcon;
