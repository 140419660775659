/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import theme from "../Theme";
import FloatingActionButton from "../Utils/FloatingActionButton";
import GenericModal from "../modal/GenericModal";
import { useJournals } from "../Journal/Actions/getJournalsAction";
import { hexToRGBA } from "../Utils/module";
import Loader from "../Utils/Loader";
import { DEFAULT_JOURNAL_INPUT } from "../Journal/Actions/journalDefaultsModule";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import YouTubeVideoSelector from "../Journal/YouTubeVideoSelector";
import SparAnalyzer from "../VideoAnalysis/SparAnalyzer";
import { DraftRollAnalysisType } from "../VideoAnalysis/module";
import { ENTRY_TYPE } from "../Utils/types";
import { DateTime } from "luxon";
import { usePostEntry } from "../Journal/Actions/postEntryAction";
import { useLoading } from "../store/hooks";
import { ActionType } from "../store/actionType";
import { useHistory } from "react-router-dom";
import Card from "../Home/Card";
import { useGetUserAnalysis } from "../Journal/Actions/getUserAnalysis";

const styles = {
  container: css`
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${theme.colors.background};
    width: 100%;
    padding: 40px 40px 0 0;
    @media (max-width: ${theme.breakpoints.md}px) {
      margin-left: 0;
      padding: 10px 40px;
    }
  `,
  link: css`
    text-decoration: none;
    cursor: pointer;
  `,
  navJournalItem: (journalColor?: string) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    cursor: pointer;
    color: ${journalColor ? journalColor : theme.fontColor.text};

    &:hover {
      background-color: ${hexToRGBA(journalColor ? journalColor : theme.palette.primary.light, 0.2)};
    }
  `,
  header: (journalColor?: string) => css`
    background-color: ${journalColor ? journalColor : DEFAULT_JOURNAL_INPUT.color.hexCode};
    color: ${theme.colors.contentBackground};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
  `,
  backButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  `,
  saveButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: ${theme.palette.secondary.main};
    outline: none;
    border: none;
    border-radius: 3px;

    :hover {
      background-color: ${theme.palette.secondary.dark};
    }
  `,
  saveButtonDisabled: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    border-radius: 3px;
    color: darkgray;
  `,
  headerTitle: css`
    align-items: center;
    display: flex;
    user-select: none;
  `,
  card: css`
    height: fit-content;
    padding: 20px;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
  `,
  cancelButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: ${theme.colors.cancel};
    outline: none;
    border: none;
    border-radius: 3px;
    color: #fff;
    :hover {
      background-color: ${theme.palette.secondary.dark};
    }
  `,
  FAB: css`
    background-image: ${theme.colors.gradient};
  `,
};

const defaultRollAnalysisState: DraftRollAnalysisType = {
  editedTagIds: [],
  editedJournalId: "",
  editedVideoUrl: "",
  editedVideoDate: DateTime.now(),
  editedJournalEntryId: "",
  editedTitle: "",
  editedText: "", // description
  editedType: ENTRY_TYPE.SparAnalysis,
  editedRounds: [],
  currentEditingRound: undefined,
  clearCurrentRoundFlag: false,
};
const AnalyzeRoot: React.FC = () => {
  // const [clickedNode, setClickedNode] = useState<string | null>(null);
  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
  const [rollAnalysisState, setRollAnalysisState] = useState<DraftRollAnalysisType>(defaultRollAnalysisState);
  const [analysisDates, setAnalysisDates] = useState<{ start: DateTime; end: DateTime }>({
    start: DateTime.now().minus({ days: 30 }),
    end: DateTime.now(),
  });
  const journals = useJournals();
  const saveLoading = useLoading(ActionType.PostJournalEntryRequest);
  const history = useHistory();
  const userAnalysis = useGetUserAnalysis({
    startDateTimeUtc: analysisDates?.start.startOf("day").toUTC().toString(),
    endDateTimeUtc: analysisDates?.end.endOf("day").toUTC().toString(),
  });
  const analysis = userAnalysis.value;
  console.log(analysis);
  const params = new URLSearchParams(history.location.search);
  const journalId = params.get("journal");

  const postEntry = usePostEntry({
    journalId: rollAnalysisState.editedJournalId,
    text: rollAnalysisState.editedText || "test", //TODO
    entryDateTimeUtc: rollAnalysisState.editedVideoDate.toUTC().toString(),
    title: rollAnalysisState.editedTitle /*|| entryText.substring(0, 50)+"..."*/ || "test", //TODO,
    tags: rollAnalysisState.editedTagIds,
    type: rollAnalysisState.editedType,
    videoUrl: rollAnalysisState.editedVideoUrl,
    notes: [],
    excludeFromCksInstructionals: true,
    rollAnalysisRounds: rollAnalysisState.editedRounds,
  });
  const selectedJournalColor =
    journals?.value?.find((journal) => journal.id === rollAnalysisState.editedJournalId)?.color || theme.palette.primary.main;

  const handleAddEntry = useCallback(() => {
    setShowAddEntryModal(true);
  }, []);

  const setVideoUrl = useCallback(
    (url: string) => {
      setRollAnalysisState({ ...rollAnalysisState, editedVideoUrl: url });
    },
    [rollAnalysisState]
  );

  const handleBack = useCallback(() => {
    setRollAnalysisState(defaultRollAnalysisState);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowAddEntryModal(false);
    setRollAnalysisState(defaultRollAnalysisState);
  }, []);

  const handleJournalSelect = useCallback(
    (journalId: string) => {
      setRollAnalysisState({ ...rollAnalysisState, editedJournalId: journalId });
    },
    [rollAnalysisState]
  );

  const handleSave = useCallback(async () => {
    if (postEntry.value) {
      await postEntry.value().then((res) => {
        if (!res?.error) {
          return setRollAnalysisState(defaultRollAnalysisState);
        }
        alert("There was an error. Please try again.");
      });
    }
  }, [postEntry]);

  const handleCancel = useCallback(() => {
    setRollAnalysisState((prev) => ({ ...prev, currentEditingRound: undefined, clearCurrentRoundFlag: true }));
  }, []);

  const renderJournalList = useCallback(
    () =>
      journals?.value?.map((journal) => (
        <div
          onClick={() => handleJournalSelect(journal.id)}
          css={styles.link}
          key={journal.id + journal.entries.length}
        >
          <div css={styles.navJournalItem(journal.color)}>
            <span>{journal.name || "Nameless Journal"}</span>
          </div>
        </div>
      )),
    [journals.value, handleJournalSelect]
  );

  //fires if navigating here from library create entry
  useEffect(() => {
    if (journalId) {
      setShowAddEntryModal(true);
      setRollAnalysisState((prev) => ({
        ...prev,
        editedJournalId: journalId,
      }));
    }
  }, [journalId]);
  if (journals.loading) return <Loader message={"loading..."} />;

  return (
    <div css={styles.container}>
      {saveLoading && <Loader message={"saving..."} />}
      {rollAnalysisState.editedJournalId ? (
        <>
          <div css={styles.header(selectedJournalColor)}>
            <div
              onClick={handleBack}
              css={styles.backButton}
            >
              <ChevronLeftIcon />
              <span>Back</span>
            </div>
            <div css={styles.headerTitle}>
              <span>{rollAnalysisState.editedTitle ? rollAnalysisState.editedTitle : "Add title..."}</span>
            </div>
            {rollAnalysisState.editedRounds?.length && !rollAnalysisState.currentEditingRound ? (
              <>
                <button
                  css={styles.saveButton}
                  onClick={handleSave}
                >
                  <span>Save</span>
                </button>
              </>
            ) : (
              <div css={styles.row}>
                {rollAnalysisState.currentEditingRound && (
                  <button
                    css={styles.cancelButton}
                    onClick={handleCancel}
                  >
                    <span>Cancel Round</span>
                  </button>
                )}
                <button
                  css={styles.saveButtonDisabled}
                  onClick={() => rollAnalysisState.currentEditingRound && alert("You must end the round before saving.")}
                >
                  <span>Save</span>
                </button>
              </div>
            )}
          </div>
          <SparAnalyzer
            rollAnalysisState={rollAnalysisState}
            setRollAnalysisState={setRollAnalysisState}
            color={selectedJournalColor}
          />
        </>
      ) : (
        <Card
          title={"Where is my analysis?!"}
          css={styles.card}
        >
          <br />
          <span>Click on the plus icon below to create an analysis entry.</span>
          <br />
          <br />
          <span>We are working on building the data analytics dashboard, but we need good data make it happen!</span>
          <br />
          <br />
          <span>The more data you put in, the better the analytics will be when we are ready to roll out the feature!</span>
          {/*<BodyMap clickedNode={clickedNode} setClickedNode={setClickedNode} showWins={true} type={'winLoss'}/>*/}
          <FloatingActionButton
            onClick={handleAddEntry}
            css={styles.FAB}
          />
        </Card>
      )}
      {showAddEntryModal && (
        <GenericModal
          noButtons={true}
          onClose={handleModalClose}
          title={rollAnalysisState.editedJournalId ? "Please select a video" : "Please select a journal"}
        >
          {!rollAnalysisState.editedJournalId && renderJournalList()}
          {rollAnalysisState.editedJournalId && (
            <YouTubeVideoSelector
              color={selectedJournalColor}
              handleVideoClick={() => setShowAddEntryModal(false)}
              setVideoUrl={setVideoUrl}
            />
          )}
        </GenericModal>
      )}
    </div>
  );
};
export default React.memo(AnalyzeRoot);
