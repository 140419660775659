import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {PUT_JOURNAL_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {JournalObject} from '../../Utils/types';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

export interface PutJournalActionProps {
  journal: JournalObject
}

const KEY_ACTION = ActionType.PutJournalRequest;
const ACTIONS = [
  ActionType.PutJournalRequest,
  ActionType.PutJournalResponse,
  ActionType.PutJournalError,
];

interface Payload extends Response {
  data?: null | {
    journal?: null | JournalObject
  }
}

export const putJournalAction = (body: PutJournalActionProps, token?: string) =>
  makeApiAction<Payload, PutJournalActionProps>({
    method: 'PUT',
    endpoint: () => PUT_JOURNAL_ENDPOINT(body.journal.id),
    headers: () => ({
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<Payload, PutJournalActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  });

const reducer: ApiReducer<Payload, PutJournalActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.journal) {
    return addError(state, KEY_ACTION, ['PUT journal failure']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState, userJournals: state?.userJournals?.map(journal => (
      journal.id === payload.data?.journal?.id ? payload.data.journal : journal),
    ),
  };
};

export const putJournalReducer = makeApiReducer<Payload, PutJournalActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to edit journal.',
);

export const usePutJournal = (journal: JournalObject) => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  
  const putJournal = useCallback(async () => await validateAndDispatch(putJournalAction({journal}, token)),
    [journal, validateAndDispatch, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: putJournal, reload: putJournal,
  };
  
};