import { ApiError, ApiLoading } from "./module";
import { HashMap } from "../lang/module";
import { User } from "../login/Actions/getUserAction";
import { JournalObject, RoundAnalysis, TagType } from "../Utils/types";
import { loadLocalStorage } from "./localStorage/module";
import { UserProfile } from "../Profile/Actions/getUserProfileAction";
import { GoogleState } from "../google/module";
import { Round } from "../VideoAnalysis/module";

export interface AppState {
  // API
  readonly loading: HashMap<ApiLoading>;
  readonly error: HashMap<ApiError>;

  // AUTH
  readonly auth?: {
    id: string;
    accessToken: string;
  };
  readonly user?: User;
  readonly userJournals?: JournalObject[];
  readonly rounds?: Round[];
  readonly roundAnalysis?: { roundId: string; analysis: RoundAnalysis }[];
  readonly userAnalysis?: { userId: string; startDateTimeUtc: string; endDateTimeUtc: string }[];
  readonly tags?: TagType[];
  readonly userProfile?: UserProfile;
  readonly google?: GoogleState;
  getGoogleOauth: boolean;
  userCancelledOauth: boolean;
  notes?: string;
}
export const LOGIN_MESSAGE = "making default app state";
export const makeDefaultAppState = (): AppState => ({
  // API
  loading: {},
  error: {},

  // AUTH
  auth: loadLocalStorage().auth,
  user: undefined,
  userProfile: undefined,

  // GOOGLE
  google: undefined,
  getGoogleOauth: false,
  userCancelledOauth: false,
  notes: LOGIN_MESSAGE,
});

export const LOGOUT_MESSAGE = "making logout app state";
export const makeLogoutAppState = (): AppState => ({
  // API
  loading: {},
  error: {},

  // AUTH
  auth: undefined,
  user: undefined,

  // Google
  google: undefined,
  getGoogleOauth: false,
  userCancelledOauth: false,
  notes: LOGOUT_MESSAGE,
});
