/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { TagType } from '../Utils/types';
import theme from '../Theme';
import { TAG_CATEGORY_TYPES } from './tagModule';
import EditIcon from '../icons/EditIcon';
import { mediaBreakpoints } from '../Utils/module';
import GenericModal from '../modal/GenericModal';
import PutTagModal from './PutUserTagModal';
import { useTagInfo } from '../store/hooks';
import { useHistory } from 'react-router-dom';
import { BASE_ROUTES } from '../Navigation/routesModule';

const styles = {
	root: css`
		display: flex;
		flex-direction: column;
	`,
	layout: css`
		display: grid;
		gap: 30px;
		padding-right: 30px;
		@media (min-width: ${mediaBreakpoints.xs}px) {
			grid-template-columns: 1fr;
		}
		@media (min-width: ${mediaBreakpoints.sm}px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (min-width: ${mediaBreakpoints.md}px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media (min-width: ${mediaBreakpoints.lg}px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (min-width: ${mediaBreakpoints.xl}px) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	`,
	tag: (source: number) => css`
		${source === 1 && 'color: blue'}
	`,
	card: css`
		border-radius: 10px;
		width: 100%;
		overflow: hidden;
		background-color: ${theme.colors.contentBackground};
		position: relative;
		min-height: 120px;
		padding: 10px;
		cursor: pointer;
	`,
	header: css`
		max-width: 90%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 5px;
		align-items: center;
		font-weight: bold;
		position: relative;
		line-height: 28px;
		font-size: 16px;
		font-weight: 600;
		color: ${theme.fontColor.text};
	`,
	body: css`
		display: flex;
		flex-direction: column;
		padding: 5px;
		gap: 8px;
		font-size: 14px;
	`,
	editIcon: css`
		color: ${theme.palette.primary.main};
		display: flex;
		justify-self: flex-end;
	`,
	editIconWrapper: css`
		position: absolute;
		top: 5px;
		right: 5px;
		padding: 3px 3px 0 0;
		height: 30px;
	`,
	tagInfo: css`
		font-size: 15px;
		padding-left: 20px;
	`,
	modal: css`
		div {
			max-width: 400px;
		}
	`,
};

const renderTagCategory = (tag: TagType) => {
	if (tag.tagType === TAG_CATEGORY_TYPES.position) {
		return <span css={styles.tagInfo}>Positional Priority: {tag.category}</span>;
	} else if (tag.tagType === TAG_CATEGORY_TYPES.movement) {
		return <span css={styles.tagInfo}>Movement Type: {tag.category}</span>;
	} else if (tag.tagType === TAG_CATEGORY_TYPES.submission) {
		return (
			<>
				<span css={styles.tagInfo}>Target Body Part: {tag.bodyPart}</span>
			</>
		);
	} else {
		return null;
	}
};

interface Props {
	tagsToRender: TagType[];
	setFilteredTags: (tags: TagType[]) => void;
}

const TagList: React.FC<Props> = ({ tagsToRender, setFilteredTags }) => {
	const [showPutTagModal, setShowPutTagModal] = useState(false);
	const [tagToEdit, setTagToEdit] = useState<TagType>(tagsToRender[0]);
	const sortedTagsToRender = tagsToRender.sort((a, b) => a.name.localeCompare(b.name));
	const tagInfo = useTagInfo();
	const history = useHistory();

	const handleCloseTagModal = useCallback(() => {
		setShowPutTagModal(false);
	}, []);

	const handleEditTag = useCallback((tag: TagType, event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setTagToEdit(tag);
		setShowPutTagModal(true);
	}, []);

	const getUseCount = useCallback(
		(tag: string) => {
			const index = tagInfo.findIndex((testTag) => testTag.tagId === tag);
			if (index > 0) {
				return tagInfo[index].uses.journalEntryIds.length;
			}
			return 0;
		},
		[tagInfo]
	);

	return (
		<div css={styles.root}>
			<div css={styles.layout}>
				{sortedTagsToRender.map((tag) => (
					<div
						onClick={() => history.push(BASE_ROUTES.tagLibrary + '/' + tag.id)}
						key={tag.id}
						css={styles.card}
					>
						<div css={styles.editIconWrapper}>
							{tag.tagSource === 1 && (
								<EditIcon
									onClick={(e) => handleEditTag(tag, e)}
									css={styles.editIcon}
								/>
							)}
						</div>
						<div css={styles.header}>
							<span>{tag.name}</span>
						</div>
						<div css={styles.body}>
							<span css={styles.tagInfo}>Tag Type: {tag.tagType}</span>
							{renderTagCategory(tag)}
							<span css={styles.tagInfo}>
								Used in <strong>{getUseCount(tag.id)}</strong> Posts
							</span>
						</div>
					</div>
				))}
			</div>
			{showPutTagModal && (
				<GenericModal
					css={styles.modal}
					noButtons={true}
					title={'Edit ' + tagToEdit?.name}
					onClose={handleCloseTagModal}
				>
					<PutTagModal
						currentTag={tagToEdit}
						handleClose={handleCloseTagModal}
					/>
				</GenericModal>
			)}
		</div>
	);
};

export default TagList;
