/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import CKSIcon from './CKSIcon';
import React from 'react';
import { IconTypes } from '../Utils/types';

const styles = {
	icon: css`
		color: black;
	`,
};

const MoveItemIcon: React.FC<IconTypes> = (props) => (
	<CKSIcon
		isSymbol={true}
		variant={props.variant}
		onClick={props.onClick}
		icon='move_item'
		className={props.className + ' ' + styles.icon}
	/>
);

export default MoveItemIcon;
