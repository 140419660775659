import {AppState} from '../../store/appState';
import {AnyAction} from '@reduxjs/toolkit';
import {makeApiReducer, Response} from '../../store/module';
import {ActionType} from '../../store/actionType';

interface Payload extends Response {
}

export const updateOauthReducer = (state: AppState, action: AnyAction) => {
  
  if (action.type !== ActionType.GOOGLE_UPDATE_NEED_OAUTH) return state;
  
  if (action.payload === true) {
    return {
      ...state,
      getGoogleOauth: true,
    };
  }
  if (action.payload.type === 'popup_closed') {
    return {
      ...state,
      getGoogleOauth:     false,
      userCancelledOauth: true,
    };
  }
  return {
    ...state,
    getGoogleOauth: false,
  };
};

export const Google_updateOauthReducer = makeApiReducer<Payload>(
  [ActionType.GOOGLE_UPDATE_NEED_OAUTH],
  ActionType.GOOGLE_UPDATE_NEED_OAUTH,
  updateOauthReducer,
  'failed to get youtube channels',
);

