import { ActionType } from "../../store/actionType";
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  is500Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from "../../store/module";
import { useCallback } from "react";
import { useErrors, useLoading } from "../../store/hooks";
import { ENDPOINTS } from "../../store/endpoints";
import { AppState } from "../../store/appState";
import { CLIENT_ID } from "../module";
import { getNewOauthState, TokenPayload } from "./module";
import { useValidateAndDispatch } from "../../login/Actions/setAuthAction";

export interface RefreshGoogleAccessTokenActionProps {
  clientId: string;
}

const KEY_ACTION = ActionType.Google_RefreshAccessTokenRequest;
const ACTIONS = [
  ActionType.Google_RefreshAccessTokenRequest,
  ActionType.Google_RefreshAccessTokenResponse,
  ActionType.Google_RefreshAccessTokenError,
];

export const refreshGoogleAccessTokenAction = (body: RefreshGoogleAccessTokenActionProps) =>
  makeApiAction<TokenPayload, RefreshGoogleAccessTokenActionProps>({
    method: "POST",
    endpoint: () => ENDPOINTS.RefreshGoogleAccessToken,
    headers: (state: AppState) => ({
      "content-type": "application/json",
      Authorization: `Bearer ${state.auth?.accessToken}`,
    }),
    body: JSON.stringify(body),
    types: makeRsaaTypes<TokenPayload, RefreshGoogleAccessTokenActionProps>(ACTIONS, body),
    bailout: (state) => !!getLoading(state, ActionType.RefreshTokenRequest, "refreshAccessToken"),
  });

const reducer: ApiReducer<TokenPayload, RefreshGoogleAccessTokenActionProps> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }

  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (is500Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }

  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }

  if (payload?.data?.error && payload.data.errorDescription) {
    return addError(state, KEY_ACTION, [payload.data?.errorDescription]);
  }

  if (!payload?.data) {
    return addError(state, KEY_ACTION, ["refresh token Error"]);
  }

  const successState = noLoadingNoError(state, KEY_ACTION);

  return getNewOauthState(successState, payload);
};

export const Google_refreshAccessTokenReducer = makeApiReducer<TokenPayload, RefreshGoogleAccessTokenActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  "Failed to refresh google access token."
);

export const useRefreshGoogleAccessToken = (): ApiHookReturn<() => void> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;

  const refreshToken = useCallback(
    async () =>
      await validateAndDispatch(
        refreshGoogleAccessTokenAction({
          clientId: CLIENT_ID,
        })
      ),
    [validateAndDispatch]
  );

  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);

  return {
    loading,
    errors,
    value: refreshToken,
    reload: refreshToken,
  };
};
