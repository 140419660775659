/** @jsxImportSource @emotion/react */
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Profile from "../Profile/Profile";
import LibraryRoot from "../Journal/LibraryRoot";
import JournalViewer from "../Journal/JournalViewer";
import { ROUTES } from "./routesModule";
import Login from "../login/Login";
import Loader from "../Utils/Loader";
import TagLibrary from "../Tags/TagLibrary";
import TagDetail from "../Tags/TagDetail";
import OAuthPopup from "../google/OAuthPopup";
import { useAppState, useLoading, useRetryCount } from "../store/hooks";
import { ActionType } from "../store/actionType";
import FourOhFour from "../Utils/FourOhFour";
import PrivacyNotice from "../Legal/PrivacyNotice";
import TermsOfService from "../Legal/TermsOfService";
import AppHeader from "./AppHeader";
import { css } from "@emotion/react";
import theme from "../Theme";
import Drawer from "./Drawer";
import AnalyzeRoot from "../Analyze/AnalyzeRoot";
import EntryViewerContainer from "../Journal/EntryViewerContainer";
import { useGetUser } from "../login/Actions/getUserAction";
import { useLogout } from "../login/Actions/logoutAction";

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: ${theme.typography.fontFamily};
    background-color: ${theme.colors.background};
    height: 100%;
    overflow-y: hidden;
  `,
  navigation: css`
    display: none;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `,
};

const LOGIN_REQUEST_ACTION = ActionType.LoginRequest;
const REFRESH_ACCESS_TOKEN_ACTION = ActionType.RefreshTokenRequest;
const GET_USER_ACTION = ActionType.GetUserRequest;

const AppRoutes: React.FC = () => {
  const state = useAppState((state) => state);
  const isLoginLoading = useLoading(LOGIN_REQUEST_ACTION);
  const isRefreshingAccessToken = useLoading(REFRESH_ACCESS_TOKEN_ACTION, "refreshAccessToken");
  const isUserLoading = useLoading(GET_USER_ACTION);
  const user = useGetUser().value;
  const userError = useRetryCount(GET_USER_ACTION) >= 3;

  if ((!user && !userError) || isUserLoading || isLoginLoading) {
    return <Loader />;
  }

  if (userError) {
    return (
      <>
        <Login />
      </>
    );
  }

  if (state.auth?.accessToken || (isRefreshingAccessToken && state.user)) {
    return (
      <>
        <AppHeader />
        <div css={styles.container}>
          {isRefreshingAccessToken && <Loader />}
          <Drawer
            css={styles.navigation}
            isStatic={true}
            open={true}
          />
          <Switch>
            <Route path={ROUTES.tag}>
              <TagDetail />
            </Route>
            <Route path={ROUTES.tagLibrary}>
              <TagLibrary />
            </Route>
            <Route path={ROUTES.profile}>
              <Profile />
            </Route>
            <Route path={ROUTES.journalLibrary}>
              <LibraryRoot />
            </Route>
            <Route path={ROUTES.journal}>
              <JournalViewer />
            </Route>
            <Route path={ROUTES.analyze}>
              <AnalyzeRoot />
            </Route>
            <Route path={ROUTES.journalEntry}>
              <EntryViewerContainer />
            </Route>
            <Route path="/callback">
              <OAuthPopup />
            </Route>
            <Route path={ROUTES.privacy}>
              <PrivacyNotice />
            </Route>
            <Route path={ROUTES.termsOfService}>
              <TermsOfService />
            </Route>
            <Route
              exact
              path="/"
            >
              {" "}
              {/*//needs to be the last route*/}
              <LibraryRoot />
            </Route>
            <Route path="*">
              <FourOhFour />
            </Route>
          </Switch>
        </div>
      </>
    );
  }
  return (
    <>
      <Login />
    </>
  );
};
export default React.memo(AppRoutes);
