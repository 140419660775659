import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
  Response,
} from '../../store/module';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {POST_PROMOTION_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {Promotion} from '../../Utils/types';

export interface PostActionProps {
  userProfileId: string,
  rank: string,
  promotionDateTimeUtc: string,
  professor: string,
  gym: string,
  
}

const KEY_ACTION = ActionType.PostPromotionRequest;
const ACTIONS = [
  ActionType.PostPromotionRequest,
  ActionType.PostPromotionResponse,
  ActionType.PostPromotionError,
];

interface Payload extends Response {
  data?: null | {
    promotion: Promotion
  }
}

export const postPromotionAction = (body: PostActionProps, token?: string) => makeApiAction<Payload, PostActionProps>({
  method:   'POST',
  endpoint: () => POST_PROMOTION_ENDPOINT(body.userProfileId),
  headers:  () => ({
    'content-type':  'application/json',
    'Authorization': `Bearer ${token}`,
  }),
  body:     JSON.stringify(body),
  types:    makeRsaaTypes<Payload, PostActionProps>(ACTIONS, body),
  bailout:  state => !!getLoading(state, KEY_ACTION)
    || !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload, PostActionProps> = (state, action, errorMsg, isLoading, payload) => {
  
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.promotion.promotionId) {
    return addError(state, KEY_ACTION, ['POST promotion failure']);
  }
  if (!state.userProfile) {
    return addError(state, KEY_ACTION, ['POST failure: no user profile']);
  }
  
  const successState = noLoadingNoError(state, KEY_ACTION);
  return {
    ...successState,
    userProfile: {...state.userProfile, promotions: [...state.userProfile?.promotions || [], payload.data.promotion]},
  };
};

export const postPromotionReducer = makeApiReducer<Payload, PostActionProps>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to create promotion.',
);

export const usePostPromotion = (
  userProfileId: string,
  rank: string,
  promotionDateTimeUtc: string,
  professor: string,
  gym: string,
): ApiHookReturn<() => void> => {
  
  const token = useAppState((state: AppState) => state.auth?.accessToken);
  
  const dispatch = useDispatch();
  
  const postPromotion = useCallback(() => {
    //TODO update dispatch
    
    // userProfileId && rank && promotionDateTimeUtc && professor && gym && dispatch(postPromotionAction({
    //   userProfileId,
    //   rank,
    //   promotionDateTimeUtc,
    //   professor,
    //   gym,
    // }, token));
  }, [userProfileId, rank, promotionDateTimeUtc, professor, gym, dispatch, token]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: postPromotion, reload: postPromotion,
  };
};