/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { forwardRef, useCallback, useState } from "react";
import { css, keyframes } from "@emotion/react";
import theme from "../Theme";

//hack for monitoring if autofill has been done
const onAutoFillStart = keyframes`
`;
const onAutoFillCancel = keyframes`
`;

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    border: 0;
    vertical-align: top;
    padding: 0;
    margin: 3px 0;
    width: 100%;
    min-width: 300px;
  `,
  inputContainer: (focused: boolean) => css`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    align-items: center;
    border-radius: 200px;
    background-color: ${theme.colors.contentBackground};
    justify-content: center;
    &:hover > .input-fieldset {
      ${!focused && "border-color: darkgray"};
    }

    & > .input-fieldset {
      border-color: ${focused ? theme.palette.primary.main : theme.colors.border};
    }
  `,
  label: (focused: boolean, isAutoFilled: boolean, value?: string) => css`
    color: ${focused ? theme.palette.primary.main : "rgba(0, 0, 0, 0.6)"};
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${focused || isAutoFilled || value ? "calc(133% - 24px)" : "calc(100% - 24px)"};
    position: absolute;
    left: 0;
    top: 0;
    transform: ${focused || isAutoFilled || value ? "translate(14px, -9px) scale(.75)" : "translate(14px, 8px) scale(1)"};
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  `,
  fieldset: css`
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -6px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: 100px;
    border-style: solid;
    border-width: 1px;
    min-width: 0;
  `,
  legend: (focused: boolean, isAutoFilled: boolean, value?: string) => css`
    float: unset;
    overflow: hidden;
    display: block;
    width: auto;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: ${focused || isAutoFilled || value ? "hidden" : "visible"};
    background: #fff;
    max-width: ${focused || isAutoFilled || value ? "100%" : "0.01px"};
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    white-space: nowrap;
  `,
  textInput: (isFullWidth: boolean, isDisabled: boolean) => css`
    font: inherit;
    letter-spacing: inherit;
    color: ${isDisabled ? theme.fontColor.disabled : theme.fontColor.text};
    border: 0;
    outline: none;
    box-sizing: content-box;
    background: none;
    height: 38px;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: ${isFullWidth ? "100%" : "220px"};
    padding: ${isFullWidth ? " 0 14px" : 0};

    &:-webkit-autofill {
      animation-name: ${onAutoFillStart};
      transition: background-color 50000s ease-in-out 0s;
    }

    &:not:-webkit-autofill {
      animation-name: ${onAutoFillCancel};
    }
  `,
  textArea: (isFullWidth: boolean, isDisabled: boolean) => css`
    font: inherit;
    letter-spacing: inherit;
    color: ${isDisabled ? theme.fontColor.disabled : theme.fontColor.text};
    border: 0;
    outline: none;
    box-sizing: content-box;
    background: none;

    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: ${isFullWidth ? "100%" : "220px"};
    padding: ${isFullWidth ? " 0 14px" : 0};
    resize: none;
    &:-webkit-autofill {
      animation-name: ${onAutoFillStart};
      transition: background-color 50000s ease-in-out 0s;
    }

    &:not:-webkit-autofill {
      animation-name: ${onAutoFillCancel};
    }
  `,
  labelText: css`
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
  `,
  icon: css`
    position: absolute;
    right: 10px;
    color: ${theme.palette.toolbars.fontColorPrimary};
  `,
};

interface Props {
  /**
   * The string value of the text input
   */
  value: string | undefined;
  /**
   * Optional function for custom rendering of options.
   * Required format: (Props, option <can be object or string>) =>
   *     < Li {...Props}>{option<if string> || option.string<output the desired string>}</ Li>
   * @param arg0 use Props and use {...Props} it in the <Li/> tag
   * @param arg1 the string or object to be included in the <Li> element.
   */
  placeholder: string;
  id: string;
  onChange?: (event: React.ChangeEvent<any>) => void;
  type?: string;
  isFullWidth?: boolean;
  required?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<any>) => void;
  className?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isTextArea?: boolean;
  rows?: number;
}

const TextInput = forwardRef<any, Props>((props, ref) => {
  const {
    id,
    placeholder,
    onChange,
    value,
    isFullWidth = true,
    required = false,
    onKeyDown,
    type = "search",
    className,
    icon,
    isDisabled = false,
    isTextArea = false,
    rows,
  } = props;
  const [focused, setFocused] = useState(false);
  const [isAutoFilled, setIsAutoFilled] = useState(false);

  const handleInputChange = useCallback(
    (event: any) => {
      onChange && onChange(event);
      setIsAutoFilled(false);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  const handleAutoFill = useCallback((event: React.AnimationEvent) => {
    setIsAutoFilled(event.animationName === onAutoFillStart.name);
  }, []);

  return (
    <div
      css={styles.root}
      onBlur={handleBlur}
      onFocus={handleFocus}
      className={className}
    >
      <div css={styles.inputContainer(focused)}>
        {isTextArea ? (
          <textarea
            ref={ref}
            id={id}
            className="search-box"
            onChange={handleInputChange}
            value={value}
            css={styles.textArea(isFullWidth, isDisabled)}
            autoComplete={"off"}
            onKeyDown={onKeyDown}
            onAnimationStart={handleAutoFill}
            disabled={isDisabled}
            rows={rows}
          />
        ) : (
          <input
            ref={ref}
            id={id}
            type={type}
            className="search-box"
            onChange={handleInputChange}
            value={value}
            css={styles.textInput(isFullWidth, isDisabled)}
            autoComplete={"off"}
            onKeyDown={onKeyDown}
            onAnimationStart={handleAutoFill}
            disabled={isDisabled}
          />
        )}
        <fieldset
          className="input-fieldset"
          css={styles.fieldset}
        >
          <legend
            css={styles.legend(focused, isAutoFilled, value)}
            className="default-text"
          >
            <span css={styles.labelText}>{placeholder}</span>
          </legend>
        </fieldset>
        <label css={styles.label(focused, isAutoFilled, value)}>
          {placeholder}
          {required && !value && !isAutoFilled && (
            <span
              css={css`
                color: red;
              `}
            >
              *
            </span>
          )}
        </label>
        <div css={styles.icon}>{icon && icon}</div>
      </div>
    </div>
  );
});
export default TextInput;
