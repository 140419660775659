import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from '../../store/module';
import {GET_ROUNDS_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useCallback, useEffect, useMemo} from 'react';
import {useAppState, useErrors, useLoading} from '../../store/hooks';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';
import {Round} from '../../VideoAnalysis/module';

const KEY_ACTION = ActionType.GetRoundsRequest;

const ACTIONS = [
  ActionType.GetRoundsRequest,
  ActionType.GetRoundsResponse,
  ActionType.GetRoundsError,
];

interface Payload extends Response {
  data?: null | {
    rounds?: Round[];
  };
}

export const getRoundsAction = (rounds: string[]) => (
  makeApiAction<Payload>({
    method: 'GET',
    endpoint: () => GET_ROUNDS_ENDPOINT(rounds),
    headers: (state: AppState) => ({
      'Authorization': `Bearer ${state.auth?.accessToken}`,
      'content-type': 'application/json',
    }),
    types: makeRsaaTypes<Payload>(ACTIONS),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  })
);

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data) {
    return addError(state, KEY_ACTION, ['get rounds failure']);
  }
  if (!payload?.data.rounds) {
    return addError(state, KEY_ACTION, ['no rounds']);
  }
  
  const successState: AppState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
    rounds: (successState.rounds) ? [...successState.rounds, ...payload.data.rounds] : [...payload.data.rounds],
  };
};

export const getRoundsReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to fetch rounds. Please try again.',
);

export const useGetRounds = (rounds: string[]): ApiHookReturn<Round[]> => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const getRounds = useCallback(async () => await validateAndDispatch(getRoundsAction(rounds || [])),
    [validateAndDispatch, rounds]);
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  const currentRoundsInState = useAppState(state => state.rounds);
  
  const roundsToGet: string[] = useMemo(() => [], []);
  
  const getRequestedRounds = useCallback(() => {
    const requestedRounds: Round[] = [];
    rounds.forEach(testRoundId => {
      const roundInState = currentRoundsInState?.find(stateRound => stateRound.id === testRoundId);
      if (roundInState) {
        requestedRounds.push(roundInState);
      } else {
        roundsToGet.push(testRoundId);
      }
    });
    return requestedRounds;
  }, [currentRoundsInState, rounds, roundsToGet]);
  
  useEffect(() => {
    if (!!roundsToGet.length && !loading && !errors) {
      getRounds().catch(error => console.error(error)).finally(() => roundsToGet.splice(0, roundsToGet.length));
    }
  }, [errors, getRounds, loading, roundsToGet]);
  
  return {
    loading, errors, value: getRequestedRounds(), reload: getRounds,
  };
};