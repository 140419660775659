/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Dominance, getCurrentNote, getCurrentPosition, getDraftRoundNumber, Round } from "./module";

import { TagType } from "../Utils/types";
import theme from "../Theme";
import { getDominanceTime } from "./playerModule";
import { hexToRGBA } from "../Utils/module";
import OpponentIcon from "../icons/OpponentIcon";
import SwordsIcon from "../icons/SwordsIcon";

const styles = {
  roundDataContainer: (isOverlay: boolean) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${isOverlay ? " 8px " : "16px"};
    background-color: ${isOverlay ? "transparent" : theme.colors.background};
    border-radius: 8px;
    position: ${isOverlay && "absolute"};
    bottom: 0;
  `,
  roundDataRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `,
  roundNumber: css`
    font-weight: 600;
    font-size: 20px;
    color: ${theme.fontColor.text};
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: flex-end;
    justify-content: center;
  `,
  currentPosition: (userDominance: Dominance = Dominance.neutral) => css`
    color: ${userDominance === Dominance.attacking
      ? theme.colors.offense
      : userDominance === Dominance.defending
      ? theme.colors.defense
      : theme.fontColor.text};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  `,
  userStatColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    min-width: 135px;
    height: 100%;
    margin-right: 10px;
    @media (max-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 0;
    }
  `,
  opponentStatColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    min-width: 135px;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    @media (max-width: ${theme.breakpoints.md}px) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 0;
    }
  `,
  meterColumn: (isOverlay: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
    background-color: ${isOverlay ? "transparent" : theme.colors.contentBackground};
    padding: ${isOverlay ? "" : "16px"};
    border-radius: 6px;
  `,
  meterContainerLargeScreen: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0;
    width: 100%;
    border-radius: 200px;
    border: 1px solid ${theme.colors.border};
    height: 20px;
    background-color: ${theme.colors.contentBackground};
    @media (max-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,
  meterContainerSmallScreen: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0;
    width: 100%;
    margin-top: 10px;
    border-radius: 200px;
    border: 1px solid ${theme.colors.border};
    height: 20px;
    background-color: ${theme.colors.contentBackground};
    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
  `,

  neutralMeter: css`
    background-color: ${theme.colors.neutral};
    width: var(--neutralMeterWidth);
    transition: all 1000ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    border-radius: 200px;
  `,
  offenseMeter: css`
    background-color: ${theme.colors.offense};
    width: var(--offenseMeterWidth);
    background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, ${hexToRGBA(theme.colors.offense, 1)} 100%);
    justify-self: flex-start;
    transition: all 1000ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    border-radius: 200px;
  `,
  defenseMeter: css`
    background-color: ${theme.colors.defense};
    width: var(--defenseMeterWidth);
    background: linear-gradient(90deg, ${hexToRGBA(theme.colors.defense, 1)} 0%, rgba(2, 0, 36, 0) 100%);
    border-radius: 200px;
    transition: all 1000ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  `,
  nameRow: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    @media (max-width: ${theme.breakpoints.md}px) {
      width: unset;
    }
  `,
  accountImage: (isActive = false) => css`
    width: 36px;
    height: 36px;
    font-size: 36px;
    color: ${theme.fontColor.text};
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${isActive ? theme.colors.offense : "transparent"};
  `,
  imageBorder: (isActive = false) => css`
    border: 2px solid ${isActive ? theme.colors.offense : "transparent"};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  opponentImage: (isActive = false, borderColor: string) => css`
    width: 36px;
    height: 36px;
    font-size: 36px;
    color: ${theme.fontColor.text};
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${isActive ? borderColor : "transparent"};
    background-color: ${theme.colors.contentBackground};
  `,
  offenseChip: css`
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: solid 1px ${theme.colors.border};
    border-radius: 200px;
    width: 100%;
    max-width: 100px;
    background-color: ${theme.colors.contentBackground};
  `,
  defenseChip: css`
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: solid 1px ${theme.colors.border};
    border-radius: 200px;
    width: 100%;
    max-width: 100px;
    margin-left: auto;
    background-color: ${theme.colors.contentBackground};
  `,
  swordIcon: css`
    color: ${theme.fontColor.text};
    font-size: 24px;
  `,
  scoreText: (isDefense: boolean) => css`
    font-size: 16px;
    font-weight: 600;
    color: ${isDefense ? theme.colors.defense : theme.colors.offense};
  `,
};

const Scoreboard = ({
  currentRound,
  allRounds,
  played,
  tagList,
  className = "",
  isOverlay = false,
}: {
  currentRound?: Partial<Round>;
  allRounds: Round[];
  played: number;
  tagList: TagType[] | undefined;
  className?: string;
  isOverlay?: boolean;
}) => {
  const currentNote = getCurrentNote(played, currentRound);
  const roundTime = (currentRound?.roundEndTimeSeconds || 0) - (currentRound?.roundStartTimeSeconds || 0);
  const adjustedRoundTime = roundTime;
  const { offenseTime, defenseTime, neutralTime } = getDominanceTime(currentRound, played);

  const widths = {
    offense: ((offenseTime || 3) / adjustedRoundTime) * 100,
    defense: ((defenseTime || 3) / adjustedRoundTime) * 100,
    neutral: ((neutralTime || 3) / adjustedRoundTime) * 100,
  };
  return (
    <div
      className={className}
      css={styles.roundDataContainer(isOverlay)}
    >
      <div css={styles.roundDataRow}>
        <div css={styles.userStatColumn}>
          <div css={styles.nameRow}>
            <OpponentIcon
              secondaryColor={theme.colors.offense}
              css={styles.opponentImage(currentNote?.userDominance === Dominance.attacking, theme.colors.offense)}
              variant={"smile"}
            />
            {!isOverlay && <span>You</span>}
          </div>
          <div css={styles.offenseChip}>
            <SwordsIcon css={styles.swordIcon} />
            <span css={styles.scoreText(false)}>{offenseTime}</span>
          </div>
        </div>
        <div css={styles.meterColumn(isOverlay)}>
          <span css={styles.roundNumber}>
            {currentRound !== undefined && !isOverlay
              ? "Round " + getDraftRoundNumber({ testRound: currentRound, allRounds: allRounds })
              : ""}
            <span css={styles.currentPosition(currentNote?.userDominance)}>
              {currentRound !== undefined && !isOverlay ? getCurrentPosition(currentNote, tagList) : ""}
            </span>
            {/* <span>{neutralTime}</span> */}
          </span>
          <div css={styles.meterContainerLargeScreen}>
            <div
              css={styles.offenseMeter}
              style={{ ["--offenseMeterWidth" as any]: widths.offense + "%" }}
            ></div>
            <div
              css={styles.neutralMeter}
              style={{ ["--neutralMeterWidth" as any]: widths.neutral + "%" }}
            ></div>
            <div
              css={styles.defenseMeter}
              style={{ ["--defenseMeterWidth" as any]: widths.defense + "%" }}
            ></div>
          </div>
        </div>
        <div css={styles.opponentStatColumn}>
          <div css={styles.nameRow}>
            {!isOverlay ? <span>Opponent</span> : <span></span>}
            <OpponentIcon
              primaryColor={theme.colors.defense}
              css={styles.opponentImage(currentNote?.userDominance === Dominance.defending, theme.colors.defense)}
              variant={"frown"}
            />
          </div>
          <div css={styles.defenseChip}>
            <span css={styles.scoreText(true)}>{defenseTime}</span>
            <SwordsIcon css={styles.swordIcon} />
          </div>
        </div>
      </div>
      <div css={styles.meterContainerSmallScreen}>
        <div
          css={styles.offenseMeter}
          style={{ ["--offenseMeterWidth" as any]: widths.offense + "%" }}
        ></div>
        <div
          css={styles.neutralMeter}
          style={{ ["--neutralMeterWidth" as any]: widths.neutral + "%" }}
        ></div>
        <div
          css={styles.defenseMeter}
          style={{ ["--defenseMeterWidth" as any]: widths.defense + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default Scoreboard;
