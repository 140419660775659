/** @jsxImportSource @emotion/react */
import React from "react";
import { Dominance, getSeekToSeconds, Round, SparActions } from "./module";
import ReactPlayer from "react-player";

export const handleOnReady = (dispatch: React.Dispatch<SparActions>, player?: React.RefObject<ReactPlayer>, played?: number) => {
  dispatch({ type: "setState", data: { errorCode: null } });
};

export const handlePause = (dispatch: React.Dispatch<SparActions>) => {
  dispatch({ type: "setState", data: { isPlaying: false } });
};

export const handlePlay = (dispatch: React.Dispatch<SparActions>) => {
  dispatch({ type: "setState", data: { isPlaying: true } });
};

export const getVideoDuration = ({
  player,
  dispatch,
}: {
  player: React.RefObject<ReactPlayer>;
  dispatch: React.Dispatch<SparActions>;
}) => {
  //video length
  player.current && dispatch({ type: "setState", data: { videoDuration: player.current.getDuration() } });
};

export const handleVideoEnd = (player: React.RefObject<ReactPlayer>, seekTime = 0) => {
  player?.current?.seekTo(seekTime);
};

export const seekTo = ({
  dispatch,
  seconds,
  currentRound,
  allRounds,
  videoDuration,
  player,
}: {
  currentRound: Partial<Round> | undefined;
  allRounds: Round[];
  dispatch: React.Dispatch<SparActions>;
  seconds: number;
  player: React.RefObject<ReactPlayer>;
  videoDuration: number;
}) => {
  const seekToSeconds = getSeekToSeconds({ seekingPositionInSeconds: seconds, currentRound, allRounds: allRounds });
  dispatch({
    type: "setState",
    data: {
      playedPosition: seekToSeconds / videoDuration,
      played: seekToSeconds,
    },
  });
  player?.current?.seekTo(seekToSeconds, "seconds");
};

export const handleProgress = ({
  event,
  dispatch,
  maximumScrollTime,
  videoDuration,
  isPlaying,
  isFirstRender = false,
  setIsFirstRender,
  currentRound,
}: {
  event: any;
  dispatch: React.Dispatch<SparActions>;
  maximumScrollTime: number | undefined;
  videoDuration: number;
  isPlaying: boolean;
  isFirstRender?: boolean;
  setIsFirstRender?: (isFirstRender: boolean) => void;
  currentRound?: Partial<Round> | undefined;
}) => {
  const maximumPlayTime = currentRound?.roundEndTimeSeconds || maximumScrollTime || videoDuration;
  const shouldKeepPlaying = event.playedSeconds < maximumPlayTime;
  const newIsPlaying = isPlaying && shouldKeepPlaying;
  const newPlayedTime = shouldKeepPlaying ? event.playedSeconds : maximumPlayTime;

  isFirstRender
    ? setIsFirstRender?.(false)
    : dispatch({
        type: "setState",
        data: {
          isPlaying: newIsPlaying,
          played: newPlayedTime,
          loadedPosition: event.loadedSeconds / videoDuration,
          playedPosition: newPlayedTime / videoDuration,
        },
      });

  if (event.playedSeconds >= maximumPlayTime) {
    dispatch({
      type: "setState",
      data: {
        isPlaying: false,
        played: maximumPlayTime,
        loadedPosition: event.loadedSeconds / videoDuration,
        playedPosition: newPlayedTime / videoDuration,
      },
    });
  }
};

export const handleError = ({ e, dispatch }: { e: any; dispatch: React.Dispatch<SparActions> }) => {
  console.error(e);
  dispatch({ type: "setState", data: { errorCode: e } });
};

export const playerOptions = {
  youtube: {
    playerVars: {
      loop: true,
      origin: "https://www.youtube.com",
    },
    embedOptions: {
      host: "https://www.youtube-nocookie.com",
    },
  },
};

export const getDominanceTime = (currentRound: Partial<Round> | undefined, played: number) => {
  let offenseTime = 0;
  let defenseTime = 0;
  let neutralTime = 0;
  const floorPlayed = Math.floor(played);
  if (currentRound) {
    currentRound?.videoAnalysisNotes?.forEach((note, index) => {
      const nextNote = currentRound?.videoAnalysisNotes?.[index + 1];
      const isFutureNote = floorPlayed < note.timeSeconds;
      const roundEndTimeSeconds = currentRound?.roundEndTimeSeconds;
      const noteDuration = nextNote
        ? nextNote.timeSeconds - note.timeSeconds
        : roundEndTimeSeconds
        ? roundEndTimeSeconds - note.timeSeconds
        : 0;
      //future note - ignore
      if (isFutureNote) {
        return;
      } else {
        //previous notes - add entire note duration
        if (nextNote && floorPlayed > nextNote.timeSeconds) {
          if (note.userDominance === Dominance.attacking) {
            return (offenseTime += noteDuration);
          } else if (note.userDominance === Dominance.defending) {
            return (defenseTime += noteDuration);
          } else {
            return (neutralTime += noteDuration);
          }
          //on current note - add floorPlayed
        } else {
          if (note.userDominance === Dominance.attacking) {
            return (offenseTime += floorPlayed - note.timeSeconds);
          } else if (note.userDominance === Dominance.defending) {
            return (defenseTime += floorPlayed - note.timeSeconds);
          } else {
            return (neutralTime += floorPlayed - note.timeSeconds);
          }
        }
      }
    });
  }
  return { offenseTime, defenseTime, neutralTime };
};
