import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiHookReturn,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  Response,
} from '../../store/module';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useErrors, useLoading} from '../../store/hooks';
import {ENDPOINTS} from '../../store/endpoints';
import {makeDefaultAppState} from '../../store/appState';

export interface LoginActionProps {
  emailOrUsername: string;
  password: string;
}

export interface UserAuth {
  id: string;
  accessToken: string;
}

const KEY_ACTION = ActionType.LoginRequest;
const ACTIONS = [
  ActionType.LoginRequest,
  ActionType.LoginResponse,
  ActionType.LoginError,
];

interface Payload extends Response {
  data?: UserAuth | null;
}

export const loginAction = (body: LoginActionProps) => makeApiAction<Payload, LoginActionProps>({
  method:      'POST',
  endpoint:    () => ENDPOINTS.Login,
  options:     {mode: 'cors'},
  headers:     () => ({
    'content-type': 'application/json',
  }),
  credentials: 'include',
  body:        JSON.stringify(body),
  types:       makeRsaaTypes<Payload, LoginActionProps>(ACTIONS, body),
  bailout:     state => !!getLoading(state, KEY_ACTION)
    || !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
});

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading, payload) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return addError(state, KEY_ACTION, ['Invalid username and password combination.']);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  if (!payload?.data?.id || !payload?.data?.accessToken) {
    return addError(state, KEY_ACTION, ['login failure']);
  }
  
  const successState = noLoadingNoError(makeDefaultAppState(), KEY_ACTION);
  
  return {
    ...successState,
    auth: payload.data,
  };
};

export const loginReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to login.',
);

export const useLogin = (emailOrUsername: string, password: string): ApiHookReturn<() => void> => {
  const dispatch = useDispatch();
  const login = useCallback(
    () => {
      dispatch(loginAction({emailOrUsername, password}));
    },
    [emailOrUsername, password, dispatch],
  );
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  return {
    loading, errors, value: login, reload: login,
  };
};