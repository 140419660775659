/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { ENTRY_TYPE, EntryWithJournalId } from "../Utils/types";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { getEntryRoute } from "../Navigation/routesModule";
import { css } from "@emotion/react";
import theme from "../Theme";
import { useJournals } from "./Actions/getJournalsAction";
import { DATE_FORMAT, getYoutubeId, renderTags, serialize } from "./module";
import { hexToRGBA } from "../Utils/module";
import { useGetTags } from "../Tags/Actions/getTagsAction";
import PlayIcon from "../icons/PlayIcon";

const styles = {
  root: (journalColor?: string) => css`
    padding: 16px;
    display: flex;
    flex-direction: row;
    max-width: 515px;
    border-radius: 12px;
    height: 164px;
    margin-bottom: 12px;
    background-color: ${theme.colors.background};
    border-right: solid 4px ${journalColor ? hexToRGBA(journalColor, 0.45) : "none"};
    &:hover {
      background-color: ${hexToRGBA(theme.palette.primary.light, 0.1)};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 12px;
      height: unset;
      padding: 8px;
    }
  `,
  entryWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 10px;
    gap: 10px;
  `,
  textEntry: (previewLines: number = 1) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${previewLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    font-size: 14px;
    margin-top: 8px;
    & * {
      color: ${theme.fontColor.text};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 12px;
      margin-top: 0;
    }
  `,
  titleRow: css`
    display: flex;
    flex-direction: row;
  `,

  link: (isTextEntry: boolean) => css`
    text-decoration: none;
    display: ${isTextEntry ? "flex" : "grid"};
    grid-template-columns: 160px 1fr;
    flex-direction: row;
    width: 100%;
    gap: 12px;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-template-columns: 130px 1fr;
      margin-top: 0;
    }
  `,
  tagWrapper: css`
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 8px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  datebox: (journalColor?: string) => css`
    background-color: ${journalColor || theme.palette.primary.main};
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 700;
    width: fit-content;
    padding: 5px;
    border-radius: 4px;
  `,
  previewWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  image: css`
    height: 132px;
    width: 160px;
    border-radius: 8px;
    object-fit: cover;
    @media (max-width: ${theme.breakpoints.md}px) {
      height: unset;
      width: unset;
    }
  `,
  thumbnailContainer: css`
    position: relative;
    height: fit-content;
    width: fit-content;
  `,
  playIcon: css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    background-color: ${hexToRGBA(theme.colors.youTube, 0.5)};
    width: 30px;
    text-align: center;
    border-radius: 5px;
  `,
  chipRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  `,
  dateChip: css`
    font-size: 10px;
    font-weight: 600;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 400px;
    padding: 0 10px;
    color: ${theme.palette.toolbars.fontColorPrimary};
    background-color: ${theme.colors.contentBackground};
    border: solid 1px ${theme.colors.border};
  `,
  entryChip: (color: string) => css`
    font-size: 10px;
    font-weight: 600;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 400px;
    padding: 0 10px;
    background-color: ${hexToRGBA(color, 0.15)};
    color: ${theme.palette.toolbars.fontColorPrimary};
  `,
  entryTitle: css`
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
    color: ${theme.fontColor.text};
    margin-top: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 14px;
      margin-top: 0;
    }
  `,
};

interface Props {
  entryDate?: DateTime;
  entries?: EntryWithJournalId[];
  specificEntry?: string;
  previewLines?: number;
}

const getEntryTypeText = (entryType: ENTRY_TYPE) => {
  switch (entryType) {
    case ENTRY_TYPE.Text:
      return "Text Notes";
    case ENTRY_TYPE.Video:
      return "Video Notes";
    case ENTRY_TYPE.SparAnalysis:
      return "Spar Analysis";
    default:
      return "Text";
  }
};
const EntryPreview: React.FC<Props> = (props) => {
  const { entryDate, entries, specificEntry, previewLines } = props;

  const journals = useJournals();
  const globalTagHook = useGetTags();
  const globalTags = useMemo(() => globalTagHook.value || [], [globalTagHook.value]);

  const specificEntryToRender = useCallback(() => {
    if (!specificEntry) return;
    const journal = journals.value?.find((journal) => journal.entries.find((entry) => entry.journalEntryId === specificEntry));
    const entry = journal?.entries.find((entry) => entry.journalEntryId === specificEntry);
    if (!journal || !entry) return;
    return [{ journalId: journal.id, journalEntry: entry }] as EntryWithJournalId[];
  }, [journals.value, specificEntry]);

  const entriesToRender = useMemo(
    () =>
      specificEntryToRender()
        ? specificEntryToRender()
        : entries?.filter((entry) =>
            entryDate ? DateTime.fromISO(entry.journalEntry.entryDateTimeUtc).hasSame(entryDate, "day") : entry
          ),
    [entries, entryDate, specificEntryToRender]
  );

  const findJournalByEntry = useCallback(
    (entry: EntryWithJournalId) => journals.value?.find((journal) => journal.id === entry.journalId),
    [journals.value]
  );

  const renderEntries = useMemo(
    () =>
      entriesToRender?.map((entry) => {
        const journalColor = findJournalByEntry(entry)?.color;
        const date = DateTime.fromISO(entry.journalEntry.entryDateTimeUtc);
        const isVideo = entry.journalEntry.type === ENTRY_TYPE.Video || entry.journalEntry.type === ENTRY_TYPE.SparAnalysis;
        const videoID = isVideo && getYoutubeId(entry.journalEntry.videoUrl || "");
        const thumbNail = videoID ? "https://img.youtube.com/vi/" + videoID + "/default.jpg" : null;
        const chipColor = () => {
          switch (entry.journalEntry.type) {
            case ENTRY_TYPE.Text:
              return theme.colors.textEntry;
            case ENTRY_TYPE.Video:
              return theme.colors.videoEntry;
            case ENTRY_TYPE.SparAnalysis:
              return theme.colors.sparAnalysis;
            default:
              return theme.colors.textEntry;
          }
        };
        return (
          <React.Fragment key={entry.journalEntry.journalEntryId}>
            <div css={styles.root(journalColor)}>
              <Link
                css={styles.link(!isVideo)}
                to={{
                  pathname: `${getEntryRoute(entry.journalId, entry.journalEntry.journalEntryId)}`,
                }}
              >
                {isVideo && thumbNail && (
                  <div css={styles.thumbnailContainer}>
                    <img
                      css={styles.image}
                      alt={"thumbnail"}
                      src={thumbNail}
                    />
                    <PlayIcon css={styles.playIcon} />
                  </div>
                )}
                <div css={styles.previewWrapper}>
                  <div css={styles.chipRow}>
                    <div css={styles.dateChip}>{date.toFormat(DATE_FORMAT.dateChip)}</div>
                    <div css={styles.entryChip(chipColor() || theme.colors.contentBackground)}>
                      {getEntryTypeText(entry.journalEntry.type)}
                    </div>
                  </div>
                  <span css={styles.entryTitle}>{entry.journalEntry.title}</span>
                  <div css={styles.textEntry(previewLines)}>
                    <span>
                      {/* {entry.journalEntry.type === ENTRY_TYPE.Text
												? serialize(entry.journalEntry.text) || entry.journalEntry.text
												: entry.journalEntry.type === ENTRY_TYPE.SparAnalysis
												? ''
												: entry.journalEntry.text} */}
                      {serialize(entry.journalEntry.text) || entry.journalEntry.text}
                    </span>
                  </div>
                  <div css={styles.tagWrapper}>
                    {renderTags(entry.journalEntry, globalTags, theme.fontColor.text, false, undefined)}
                  </div>
                </div>
              </Link>
            </div>
          </React.Fragment>
        );
      }),
    [entriesToRender, findJournalByEntry, globalTags, previewLines]
  );

  return <>{renderEntries}</>;
};
export default EntryPreview;
