import {ActionType} from '../../store/actionType';
import {
  addError,
  addLoading,
  ApiReducer,
  getLoading,
  is401Response,
  makeApiAction,
  makeApiReducer,
  makeRsaaTypes,
  noLoadingNoError,
  removeLoading,
} from '../../store/module';
import {DELETE_ROUND_ENDPOINT} from '../../store/endpoints';
import {AppState} from '../../store/appState';
import {useCallback} from 'react';
import {useErrors, useLoading} from '../../store/hooks';
import {useValidateAndDispatch} from '../../login/Actions/setAuthAction';

const KEY_ACTION = ActionType.DeleteRoundRequest;

const ACTIONS = [
  ActionType.DeleteRoundRequest,
  ActionType.DeleteRoundResponse,
  ActionType.DeleteRoundError,
];

export interface DeleteActionProps {
  roundId: string;
}

interface Payload extends Response {
}

export const deleteRoundAction = (body: DeleteActionProps) => (
  makeApiAction<Payload, DeleteActionProps>({
    method: 'DELETE',
    endpoint: () => DELETE_ROUND_ENDPOINT(body.roundId),
    headers: (state: AppState) => ({
      'Authorization': `Bearer ${state.auth?.accessToken}`,
      'content-type': 'application/json',
    }),
    types: makeRsaaTypes<Payload, DeleteActionProps>(ACTIONS, body),
    bailout: state => !!getLoading(state, ActionType.RefreshTokenRequest, 'refreshAccessToken'),
  })
);

const reducer: ApiReducer<Payload> = (state, action, errorMsg, isLoading) => {
  if (isLoading) {
    return addLoading(state, KEY_ACTION);
  }
  
  if (is401Response(action)) {
    return removeLoading(state, KEY_ACTION);
  }
  
  if (errorMsg) {
    return addError(state, KEY_ACTION, errorMsg);
  }
  
  
  const successState: AppState = noLoadingNoError(state, KEY_ACTION);
  
  return {
    ...successState,
    userJournals: undefined,
  };
};

export const deleteRoundReducer = makeApiReducer<Payload>(
  ACTIONS,
  KEY_ACTION,
  reducer,
  'Failed to delete round. Please try again.',
);

export const useDeleteRound = (roundId: string) => {
  const validateAndDispatchHook = useValidateAndDispatch();
  const validateAndDispatch = validateAndDispatchHook.value;
  
  const loading = useLoading(KEY_ACTION);
  const errors = useErrors(KEY_ACTION);
  
  const deleteRound = useCallback(
    async () => await validateAndDispatch(deleteRoundAction({roundId})),
    [validateAndDispatch, roundId]);
  
  return {
    loading, errors, value: deleteRound, reload: deleteRound,
  };
};